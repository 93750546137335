import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router'
import {Observable, of} from 'rxjs'
import {AuthService, UserSession} from '../auth/services/auth.service'
import {switchMap, tap} from "rxjs/operators";

const groupCheck = (session: UserSession, allowedGroups: string[]) => {
  const groups: string[] = (session?.accessToken?.payload?.['cognito:groups']) || []
  return session && session.isValid() && groups.some(group => allowedGroups.includes(group))
}

export class SectionGuard  {
  hasAccess: Observable<boolean>

  constructor(private authService: AuthService, private router: Router, groups: string[]) {
    this.hasAccess = authService.onUserSession.pipe(
        switchMap(session => {
          return of(groupCheck(session, groups))
        }),
        tap(hasAccess => {
          if (!hasAccess) {
            this.router.navigate(['/'])
          }
        })
    )
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAccess
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.hasAccess
  }
}
