export const PRIVACYPOLICY = `<div class="termsText">
    <h2>1. Introduction</h2>
<p>We are Delta Capita Limited whose registered office is at 2nd Floor, 40 Bank Street, Canary Wharf, London, E14 5NR (<strong>“Delta Capita”, “we”, “us”, “our”</strong>). </p>
<p>We are registered with the UK Information Commissioner’s Office and our registration number is ZA286607.</p>
<p>Delta Capita is committed to protecting and respecting your privacy.  This policy describes how we collect, use and otherwise process personal information to provide the InSPire Due Diligence Solution including its portals and data rooms (together the <strong>“InSPire Solution”</strong>) and our related Services (as defined below).</p>
<p>The InSPire Solution facilitates:</p>
<ul>
    <li>the due diligence activities of issuers of structured retail products on their distributors; and</li>

    <li>the provision of information by distributors to their counterparty issuers which is made available by the distributor.</li>
</ul>
<p>This policy applies to all the services we supply via the InSPire Solution including the Know Your Distributor Shared Service (<strong>“KYD Service”</strong>), Distribution Agreement Drafting Service (<strong>“DA Service”</strong>) and Know Your Client Data Collection Shared Service (</strong>“KYC Service”</strong>) (together the <strong>“Services”</strong>).</p>
<p>Some of the information processed by the InSPire Solution and/or as part of the Services may contain personal information. This policy applies to all personal information processed by the InSPire Solution and/or as part of the Services, including personal information supplied to us by email. </p>
<p>Personal information is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read this policy carefully to understand our practices regarding personal information. </p>
<p>We process personal information as either data controller or data processor. We act as data controller where we process personal information related to the users of the InSPire Solution and/or the Services and we use that information for our own purposes such as to set up their user accounts or to support their use of the InSPire Solution and/or the Services. We act as data processor on behalf of our issuer client where we process personal information to supply our services to them.</p>
    <h2>2. What personal information do we collect? </h2>
<p>We collect and use the following types of personal information:</p>
<ul>
    <li><strong>Users of the InSPire Solution and/or the Services:</strong> user’s name, email address, employer details, log-ins and passwords, job title and information about their use of the InSPire Solution and/or the Services and our interactions with them. We may also collect certain technical information related to the user’s use of the InSPire Solution including their IP address and browser type.<br><br></li>
    <li><strong>KYD Service: </strong></li>
    <ul>
    <li>Key stakeholder contact name, email address, phone number, employer details, job title;</li>
<li>Organisational charts;</li>
<li>Information and comments submitted via the interactive features on the InSPire Solution;</li>
    </ul>
<li><strong>DA Service:</strong></li>
<ul>
    <li>Key stakeholder contact name, email address, phone number, employer details, job title;</li>
    <li>Distribution agreement signatory details including signatures;</li>
    <li>Information and comments submitted via the interactive features on the InSPire Solution;</li>
</ul>
<li><strong>KYC Service:</strong></li>
<ul>
    <li>Key stakeholder contact name, email address, phone number, employer details, job title;</li>
    <li>Organisational charts;</li>
    <li>Shareholder structures;</li>
    <li>List of authorised signatories;</li>
    <li>Details of ultimate beneficial ownership, including official identification documentation such as copies of passports;</li>
    <li>Results of politically exposed persons and sanctions checks;</li>
    <li>Information and comments submitted via the interactive features on the InSPire Solution;</li>
    </ul>
    </ul>
<p>If you choose to withhold requested information, you may not be able to access all of the InSPire Solution’s content and features and we may not be able to provide the InSPire Solution and/or the Services to you.</p>
    <h2>3. How do we use personal information?</h2>
<p>We use personal information that we collect, or that you provide to us, for the purposes of our legitimate interests and/or to perform our contract as follows:</p>
<ul>    
<li>to allow you to access and use the InSPire Solution and to provide you with the Services that you request from us;</li>
<li>to provide support for the InSPire Solution and/or the Services;</li>
<li>to ensure that content from the InSPire Solution is presented optimally for you and for your computer;</li>
<li>to notify you about changes to the InSPire Solution and /or the Services;</li>
<li>as part of our efforts to keep the InSPire Solution and the Services safe and secure;</li>
<li>for risk management and fraud prevention;</li>
<li>to manage and improve our products and services; and</li>
<li>to protect our business and to enforce our rights including the defence and prosecution of legal claims.</li>
</ul>
<p>We use personal information that we collect, or that you provide to us, to comply with our legal obligations as follows:</p>
<ul>
    <li>to comply with our accounting and tax obligations; and</li>
<li>where we are required to disclose personal information under applicable law such as a court order.</li>
</ul>
<p>Our issuer clients will use personal information that is shared with them as part of our services for the purposes of their legitimate interests, to comply with their legal obligations, to prevent unlawful acts and/or to prevent fraud. </p>
    <h2>4. How do we share personal information with third parties? </h2>
<p>We may disclose personal information to third parties in certain circumstances including (but not limited to) the following:</p>
<ul>    
    <li>the issuers and distributors as part of the Services;</li>
    <li>third parties we use to carry out checks including the Know Your Distributor and Know Your Client checks;</li>
    <li>other third parties that we engage to help us run our business, such as IT services suppliers;</li>
    <li>analytics and search engine service providers that assist us in the improvement and optimisation of the InSPire Solution; </li>
    <li>our professional advisors, including lawyers, accountants, tax advisors and auditors;</li>
    <li>law enforcement bodies, agencies, courts of law or as otherwise required or authorised by law;</li>
    <li>any person to whom information is required to be disclosed in connection with any litigation, arbitration, administrative or other investigation or proceedings; and</li>
    <li>regulatory or government bodies for the purposes of resolving complaints or disputes, both internal and external, or to comply with any investigation by one of those bodies.</li>
</ul>
<p>We may also disclose personal information to third parties:</p>
<ul>
    <li>in the event that we sell any business or assets, in which case we may disclose personal information to the prospective buyer of such business or assets and their advisors;</li>
    <li>if we or substantially all of our assets are acquired by a third party, in which case personal information held by us will be one of the transferred assets; </li>
    <li>if we restructure our business, or if we integrate our business with another group of companies, in which case personal information held by us will be disclosed to the new owner to be used in the same ways set out in this policy;</li>
    <li>if we seek investment, in which case personal information held by us may be disclosed to investors and potential investors and their advisors;</li>
    <li>if we are under a duty to disclose or share personal information in order to comply with any legal or regulatory obligation, or in order to enforce or apply our terms and conditions on which we supply the InSPire Solution and/or the Services and other agreements; or to protect the rights, property, or safety of us or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction; and</li>
    <li>for any other purposes that you would reasonably expect.</li>
</ul>
   <h2> 5. Cookies and similar technologies</h2>
<p>We use personal information collected via non-essential cookies and other similar technologies on the basis of your consent.</p>
    <h2>6. Where do we store personal information?</h2>
<p>The personal information that we process may be transferred to, and stored at, locations outside the United Kingdom (<strong>“UK”</strong>) and European Economic Area (<strong>“EEA”</strong>). It may also be processed by staff operating outside the UK and EEA who work for Delta Capita or for one of our suppliers or service providers. </p>
<p>Countries where personal information may be stored and/or processed, or where recipients of personal information may be located, may have data protection laws which differ from the data protection laws in your country of residence.</p>
<p>You accept that personal information may be transferred, stored or processed in this way. Where we make such transfers, we will take all appropriate steps to ensure that all personal information is treated securely and in accordance with this policy.</p>
    <h2>7. How do we protect personal information?</h2>
<p>Unfortunately, the transmission of information via the Internet is not completely secure. Although we will do our best to protect personal information, we cannot guarantee the security of such information and any transmission is at your own risk. Once we have received personal information, we use strict procedures and security features to try to prevent unauthorised access. </p>
<p>All information provided to Delta Capita is stored on secure servers. The safety and security of personal information also depends on you. Where we have given you (or where you have chosen) a password that enables you to access certain parts of the InSPire Solution and/or the Services, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</p>
    <h2>8. How long do we keep personal information?</h2>
<p>We retain personal information for the duration of the relevant business relationships and for a period of time afterwards to allow the personal information to be destroyed, returned or de-identified. </p>
<p>We will also retain personal information for as long as necessary under applicable law, including to defend potential legal claims.</p>
<p>We take steps to destroy return or de-identify personal information that is no longer needed and that we are no longer required by law to retain.</p>
    <h2>9. Your rights</h2>
<p>You have the right to access personal information held relating to you. To protect your privacy, we may take steps to verify your identity before taking any action in response to any request. You will not have to pay a fee to access personal information relating to you (or to exercise any of the other rights).  However, we may charge a reasonable fee if your request for access is excessive. Alternatively, we may refuse to comply with the request in such circumstances. </p>
<p>We also want to make sure that personal information relating to you that we hold is accurate and up to date. You may ask us to correct or remove any information you think is inaccurate.</p>
<p>You may also have the right, in certain circumstances, to:</p>
<ul>
    <li>the erasure of personal information relating to you that we hold;</li>
    <li>the restriction of processing of personal information relating to you that we hold;</li>
    <li>object to our processing of personal information relating to you that we hold; and </li>
    <li>the portability of personal information relating to you that we hold.</li>
</ul>
<p>Requests in this section should be sent by email or by writing to us using the contact details set out at the end of this policy.</p>
<p>If you have a concern about the way we are collecting or using your personal information, we request that you raise your concern with us in the first instance. Alternatively, you can contact the Information Commissioner’s Office at <a href="https://ico.org.uk/concerns/" target="_blank">https://ico.org.uk/concerns/</a>.</p>
    <h2>10. Changes to this policy</h2>
<p>Delta Capita reviews and amends its privacy policy from time to time.  Any changes we make to this policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check this page occasionally to review any updates or changes to this Policy. This helps you remain informed of what information we collect, how we use it and under what circumstances, if any, it is disclosed.</p>
    <h2>11. Contacting Us</h2>
<p>If there are any questions regarding this privacy policy, you may contact our data protection manager using the information below.</p>
<p>Delta Capita, 40 Bank Street, Canary Wharf, London, E14 5NR</p>
<p>Email: <a href="mailto:Inspirekyd@dealtacapita.com" target="_blank">Inspirekyd@dealtacapita.com</a></p>
<br>
<p>Last updated: 16/01/23</p></div>
`;
