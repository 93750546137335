<div class="login-container" *ngIf="displayed">
  <div class="login-panel">
    <div class="login-logo">
      <img class="inspire-logo" src="assets/inspire-white-magenta.svg" />
    </div>

    <div class="login-form">
      <div class="login-title">Log in</div>
      <form id="loginForm">
        <div class="form-group">
          <div class="input-group input-group-lg">
            <!--            <label for="username">Username</label>-->
            <input
              class="form-control form-control-lg"
              id="username"
              type="text"
              [formControl]="username"
              required
              data-lpignore="true"
              name="username"
              placeholder="Email Address"
            />
            <div [hidden]="username.valid || username.pristine" class="invalid-feedback">Email address is required</div>
          </div>
        </div>

        <div class="form-group">
          <!--          <label for="password">Password</label>-->
          <div class="input-group input-group-lg">
            <input
              class="form-control form-control-lg"
              id="password"
              [type]="showPassword ? 'text' : 'password'"
              [formControl]="password"
              name="password"
              required
              data-lpignore="true"
              placeholder="Password"
            />
            <div class="input-group-append">
              <button *ngIf="!showPassword" (click)="showPassword = true" [attr.aria-label]="'Show password'">
                <fa-icon [icon]="faEyeSlash" title="Show Password"></fa-icon>
              </button>
              <button *ngIf="showPassword" (click)="showPassword = false" [attr.aria-label]="'Hide password'">
                <fa-icon [icon]="faEye" title="Hide Password"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div [hidden]="!infoMessage" class="alert alert-info">
          {{ infoMessage }}
        </div>
        <div [hidden]="!errorMessage" class="alert alert-danger login">
          {{ errorMessage }}
        </div>
        <button [disabled]="!canLogin()" class="sign-in-button" (click)="login()">
          <ng-container *ngIf="submitted">
            <span
              class="sign-in-progress spinner-border spinner-border-sm mr-1"
              role="status"
              aria-hidden="true"
            ></span>
            Logging in
          </ng-container>
          <ng-container *ngIf="!submitted"> Log in </ng-container>
        </button>
      </form>
      <div class="forgot-password">
        <div (click)="forgotPassword()">Forgot your password?</div>
      </div>
    </div>
    <div class="need-help">
      Need help?
      <a class="contact-team" href="mailto:inspirekyd@deltacapita.com?subject=Website%20Support%20Query" target="_blank"
        >Get in touch</a
      >
      with our team.<br />
      <a (click)="openPrivacyPolicy('privacy')" class="contact-team mt-1 mb-1">Privacy Policy</a> - <a (click)="openPrivacyPolicy('terms')" class="contact-team mt-1 mb-1">Terms of Use</a><br />
      <a href="//www.deltacapita.com" target="_blank">www.deltacapita.com</a>
    </div>
  </div>
</div>
