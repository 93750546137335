export const TERMSOFUSE = `<div class="termsText">
<p>These terms and conditions (the <strong>"Terms and Conditions"</strong>) are entered into by and between you (the <strong>“User”</strong> or <strong>“you”</strong>) and Delta Capita Limited, a company incorporated under the laws of England and Wales, with registered office at 40 Bank Street, London E14 5NR (<strong>“Delta Capita”</strong>, <strong>“we”</strong>, <strong>“us”</strong> or <strong>“our”</strong>). If you are representing and/or working for a legal entity, the terms “User” and “you” in these Terms and Conditions jointly refers to the entity you represent and/or work for and you warrant and undertake you have authority to bind the entity to these Terms and Conditions.</p>
<p>The User and us are hereinafter together referred to as the <strong>“Parties”</strong> and individually as a <strong>“Party”</strong>.</p>

    <h2>1. Overview</h2>
<p>We provide a distributor due diligence solution known as InSPire for the issuers of structured retail products, to:</p>
<ol class="alpha">
    <li>facilitate their due diligence activities on their distributors (the "InSPire Solution”); and</li>
<li>facilitate the provision of information by distributors to their counterparty issuers which is made available via the secure portal service portal (the <strong>“Portal”</strong>). </li>
</ol>
<p>The Portal includes without limitation: (a) certain software services; (b) user interfaces and documentation as well as any programming fixes, modifications, enhancements, improvements, updates, additions, derivative works and related material; and (c) data, including without limitation, third party data uploaded on the Portal. These Terms and Conditions govern the access and use of the Portal as well as additional services provided by us in relation to the maintenance and update of the Portal to deliver the InSPire Solution (the <strong>“Services”</strong>).</p>
<p>Please read these Terms and Conditions carefully prior to accessing and/or using the Portal. We recommend that you print or save a copy of these Terms and Conditions on your hard drive at the time you use the Portal for the first time.</p>


<h2>2. Acceptance of the Terms and Conditions</h2>
<p>By clicking on the “I have read, understood and accept the terms and conditions” button, you acknowledge and agree to have read, understood and be bound by these <u>Terms and Conditions</u>.</p>
<p>If you do not wish to be bound by the Terms and Conditions, you must not use the Portal and Services.</p>


<h2>3. Account creation</h2>
<p>Access to the Portal is governed by Delta Capita and requires a User account to be created. This account (the “Account”) will be protected by a personal username and password (the <strong>“User ID”</strong>).</p>
<p>The User acknowledges that its Account is strictly personal and that it may not create more than one Account to access and/or use the Portal, either with the same User ID or with a different User ID.</p>
<p>The User is responsible for all access and use of the Portal made with its User ID, and is responsible for taking all appropriate measures to keep its User ID confidential.</p>
<p>Furthermore, the User undertakes to take all appropriate measures to prevent harmful agents or components from entering its systems and to prevent any unauthorized or unlawful or infringing access or use of the Portal through its User ID.</p>
<p>The User agrees that it may not give access to its Account to any third party.</p>
<h2>4. Intellectual Property Rights</h2>
        <h3>4.1. Our Intellectual Property Rights</h3>
    <p>Subject to the limited rights expressly granted to the User below, the User expressly acknowledges and agrees that we hold and reserve all intellectual property rights in, to and in relation to (i) the Portal and (ii) data made available through the Portal (other than the User Content as defined below), in particular but without limitation any present or future ideas and rights to inventions (whether or not patentable or reduced to practice), patents, all copyright and author’s rights and rights in software (including without limitation moral rights, as well as the rights of reproduction, distribution, communication to the public, renting and lending), trademarks (whether registered and unregistered), service marks, brand names, product names, logos, slogans, trade names, company names and reputation, domain names, database right, rights in confidential and/or proprietary information (including without limitation know-how and trade secrets), rights in software (including without limitation algorithms, APIs, source, executable and object code, apparatus, circuit designs and assemblies), network configurations and architectures, concepts, marketing and development plans, methods and any other intellectual property rights, in each case whether registered or unregistered and including all applications for and renewals or extensions of such rights, and all similar or equivalent rights or forms of protection in any part of the world. For the avoidance of doubt, Intellectual Property Rights also includes any programming fixes, updates, upgrades, modifications, enhancements, improvements, configurations, extensions, additions and derivative works of the foregoing and related documentation (hereinafter <strong>“Our Intellectual Property Rights”</strong>).</p>
<p>We hereby grant the User a limited, non-exclusive, revocable, non-sublicensable and non-transferable right to access and use Our Intellectual Property Rights for the sole and exclusive purpose of accessing and using the Portal and the Services under the conditions set forth in these Terms and Conditions. All rights not expressly granted to you under these Terms and Conditions are reserved by us (and/or our licensors).</p>
<p>You are responsible for installing any software and/or hardware and making any other arrangements required to use the Portal and Services at your own costs (including but not limited to making available an internet connection).</p>
<p>You agree that the Portal and Services and Our Intellectual Property Rights are our sole property or, where appropriate, the property of our licensor and that you will not (without express written consent from us):</p>
<ol style="list-style-type:lower-roman" class="roman">
            <li>create derivative works incorporating any of the elements of, or based on, Our Intellectual Property Rights except to the extent such derivative works are an essential technical or management function of your use of the Portal and Services;</li>
        <li>reproduce Our Intellectual Property Rights, sell or assign, license or disclose or otherwise transfer or make available Our Intellectual Property Rights in any form to any third party;</li>
    <li>remove or alter any proprietary notices or marks on the Portal or Services; or</li>
<li>copy, distribute, communicate, modify, translate, reuse, disassemble, decompile, reverse compile, reverse engineer, frame, mirror or otherwise access in any way the source codes of any of Our Intellectual Property Rights or any portion thereof except and only to the extent that such activity is expressly permitted by applicable law notwithstanding this limitation or these Terms and Conditions.</li>
        </ol>
        <p>You must not under any circumstances (i) use information from the Portal to build a database for resale, or for access by, you or any third party in competition with us; (ii) authorise or, by failure to exercise commercially reasonable efforts to protect information from the Portal, permit such information to be made available in any way to a third party, including any third party that resells data in competition with us.</p>
    <h3>4.2. User Content</h3>
    <p>As part of your use of the Portal, you may be invited to post content on the Portal that may include data and/or information in text or any another format about yourself (the <strong>“User Content”</strong>).</p>
<p>To ensure the confidentiality of User Content, the Portal enables you to manage access rights and grant certain manufacturers or distributors with access to specific documents. We undertake to keep such User Content confidential, except towards those users that you have granted access to the User Content concerned.</p>
<p>You have sole responsibility for and hereby warrant to us the accuracy, quality, integrity, legality, non-infringement of any third party intellectual property rights, reliability and appropriateness of such User Content. For the avoidance of doubt, you are responsible for obtaining and maintaining all licences required for the use of the User Content within the context of the Portal, including payment of all associated licence fees and other costs and you must ensure that such User Content complies with generally accepted content standards.</p>
<p>The User acknowledges and accepts that part of the Portal’s value proposition resides in pooling and analysing information about distributors notably by extracting such information from User Content. The User grants us a non-exclusive, sub-licensable, worldwide and free-of-charge license in and to the User Content for the entire duration of protection of the intellectual property rights protecting them (including any renewal and extension thereof) including, inter alia, the right to store, use, sell, sub-license, reproduce, display, distribute, modify, create derivative works from or integrate in any way whole or part of any such User Content in a database or otherwise exploit it for the purpose of providing the Services and granting access to the Portal. We may also anonymise User content as may be appropriate, including to generate statistics and make such statistics available to third parties.</p>
<p>The User hereby expressly warrants that it holds all necessary rights to publish, submit and/or communicate the User Content and that it holds all necessary rights to grant us the license on User Content as mentioned above.</p>
<p>Save as specified in these Terms and Conditions, all intellectual property rights in the User Content will remain yours (or your relevant licensors).</p>
<p>You will defend, indemnify and hold us (including our directors and employees) harmless in the event of any third party claim arising out of, or related in any way to, our use of the User Content as set out above.</p>


<h2>5. Use of the Portal</h2>
<p>You agree that you will use the Portal and Services only for lawful purposes and in accordance with these Terms and Conditions. In connection with the provision of or your use of the Portal and the Services, you will comply at all times with all applicable laws, rules and regulations.</p>
<p>You must not:</p>
<ol style="list-style-type:lower-roman" class="roman">
<li>attempt to circumvent any security measures or technical limitations of the Portal;</li>
<li>use the Portal in a way which harms our interests, the Portal, any of our affiliates or other customers of ours (including, without limitation, by introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful or by attacking the Portal via a denial-of- service attack or a distributed denial-of-serve attack);</li>
<li>forge headers or otherwise manipulate identifiers (including without limitation URLs) in order to disguise the origin of any User Content transmitted through the Portal;</li>
<li>create an application that may be used to violate the Terms and Conditions;</li>
<li>adversely affect us or our name, reputation, image or goodwill in connection with your use of the Portal or the Services; and/or</li>
<li>without our express permission, use manual or automated software, devices, scripts, robots or other means or processes to access, crawl, scrape, copy or duplicate any content on the Portal.</li>
</ol>
<p>We reserve the absolute right to limit the volume of enquiries placed via the Portal and/or use of the Services.</p>


<h2>6. Confidentiality</h2>
<p><strong>“Confidential Information”</strong> means any information (verbal, visual or written) relating to a Party (the <strong>“Disclosing Party”</strong>) that is not generally known outside of this Party and acquired directly or indirectly by another Party (the <strong>“Receiving Party”</strong>) during the conclusion or in the course of the performance of these Terms and Conditions, including, where appropriate and without limitation, information relating to the Disclosing Party’s intellectual property, research, technology and know-how, business plans, licenses, data, patent disclosures, patent applications, structures, models, techniques, processes, compositions, compounds, third party confidential information, software, algorithms, current and future products and services, business and financial information, client or shareholder information, procurement requirements, manufacturing, business forecasts, marketing information, the list of distributors, information concerning accounts or products, as well as any information that has been labelled or identified as “Confidential” or that the Receiving Party ought reasonably to have known was confidential at the time of disclosure.</p>
<p>The Receiving Party may not without permission of the Disclosing Party release any Confidential Information to any person or entity, other than, on a “need to know” basis in the scope of its rights and obligations under these Terms and Conditions, to its personnel or members of management or to its accountants, attorneys, sub-
contractors and other professional advisers, all of whom are to be bound by confidentiality obligations not less strict than those set out herein.</p>
<p>The confidentiality obligations set out in this clause does not apply to information that:</p>
<ol style="list-style-type:lower-roman" class="roman">
    <li>at the time of disclosure is generally known or publicly available, or after disclosure becomes generally known or publicly available through no fault of the Receiving Party; or</li>
<li>the Receiving Party rightfully obtained from a third party that is entitled to disclose the information and that is not under a confidentiality obligation to the Disclosing party in respect of such information.</li>
</ol>
<p>Each Party must preserve the confidentiality of Confidential Information with the same level of protection it uses to protect its own confidential information but in no case any less than reasonable care.</p>
<p>This clause stays in effect for five years after termination of these Terms and Conditions.</p>


<h2>7. Data protection</h2>

<p>Where the User provides us with personal data, meaning information relating to the identified or identifiable natural person, whether via the Portal or by any other means including email, the User shall ensure that such disclosure complies with applicable law, including applicable data protection and data privacy laws (<strong>“Data Privacy Laws"</strong>), and that there is nothing that would prevent or restrict the personal data from being processed in accordance with these Terms and Conditions and our privacy policy (<strong>“Privacy Policy”</strong>). The User further ensures that each natural person to which the personal data relate has received a copy of our Privacy Policy, including any updates of the Privacy Policy, which we will bring to the User’s attention through appropriate means.</p>
<p>Where the User provides us with personal data, whether via the Portal or by any other means, with the User’s knowledge and intention that the personal data will be transferred or made available to a third-party data receiver such as a counterparty issuer, the User acknowledges it is initiating and agreeing to the transfer and therefore:</p>
<ol style="list-style-type:lower-roman" class="roman">
<li>the User is solely responsible for ensuring the transfer of the personal data to the data receiver, and the data receiver’s processing of the personal data, complies with Data Privacy Laws; and</li>
<li>the User shall enter into any contractual arrangements directly with the data receiver, including where applicable the EU and UK standard contractual clauses for international transfers of personal data, and carry out any transfer risk assessments, to ensure the transfer of the personal data to the data receiver complies with Data Privacy Laws.</li>
        </ol>
        <p>The User shall indemnify Delta Capita against any claims, costs, damages and expenses (including reasonable legal expenses) suffered or incurred by Delta Capita as a result of the User’s breach of this clause </p>


    <h2>8. Maintenance and Support</h2>
<h3>8.1. Maintenance</h3>
    <p>We shall maintain and update the Portal and Services.</p>
<p>Should you determine that the Portal or Services include a defect, you may at any time file error reports by contacting our helpdesk at <a href="mailto:inspirekyd@deltacapita.com" target="_blank">inspirekyd@deltacapita.com</a>.</p>
<p>We shall use our reasonable efforts to ensure that maintenance of the Portal and Services (including all regularly scheduled error corrections and software updates), which may require interruption of your access to the Portal or Services or any material part of them, shall not be performed during “Normal Business Hours" (9.00 am to 6.00 pm local London time, Monday to Friday, excluding public holidays) provided that we may interrupt access to the Portal and Services at any time to perform essential emergency maintenance.</p>
<p>During maintenance periods, we may, at our discretion, upgrade versions, install error corrections and apply patches to the Portal and Services or any part of them.</p>
<h3>8.2. Support</h3>
    <p>You have sole responsibility for providing support in connection with equipment, data integration tools and processes operated, developed or maintained by you, including without limitation those used to: (i) access the Portal and the Services via the internet; and (ii) connect the Portal and the Services to your other software and databases.</p>
<p>You acknowledge that the Portal and Services may include software, data and information provided to us by third parties, and therefore the correction of errors and resolution of defects and other problems may require third party action and not be entirely within our control.</p>


<h2>9. Representations and Warranties</h2>
<h3>9.1. User’s representation</h3>
    <p>You represent and warrant that:</p>
    <ol style="list-style-type:lower-roman" class="roman">
    <li>you possess the legal right and ability to enter into the Terms and Conditions;</li>
<li>you will at all times supply truthful and accurate information to us and will not misrepresent yourself to the public through your use of the Portal or the Services;</li>
<li>neither the performance of your obligations under these Terms and Conditions nor your use of the Portal or the Services will violate any applicable laws, rules or regulations of governments having jurisdiction or cause a breach of any agreements you have with any third parties or unreasonably interfere with our other customers' use of our Portal or Services.</li>
        </ol>
        <p>In the event of any breach of any of the foregoing warranties, in addition to any other remedies available, we reserve the right to suspend or terminate these Terms and Conditions with immediate effect and/or your use of the Portal and the Services.</p>
    <h3>9.2. Warranties</h3>
    <p>The Portal is made available on an “as is” basis.</p>
<p>We do not warrant that it will operate error-free or in an uninterrupted manner or that the Portal content shall meet the User’s requirements or expectations or that it will fit any particular purpose.</p>


<h2>10. Limitation of Liability</h2>
<p>The content accessed through use of the Portal or the Services is provided for general information only and is not intended to be used as the sole basis for any decision. It is not intended to amount to advice (of any nature) on which you should rely. You must obtain professional or specialty advice before taking or refraining from any action on the back of content accessed through use of the Services.</p>
<p>We make reasonable commercial efforts to ensure content accessible through the Portal or the Services is up to date and accurate. However, because we obtain the content from a number of different sources (including information provided by you) we do not endorse, support, represent, warrant or guarantee the completeness, truthfulness, accuracy, or reliability of any content accessed or accessible using the Portal or Services. You understand that by using the Portal and Services, you may be exposed to content that might be inaccurate or deceptive. If you do become aware of any inaccurate or incorrect content accessed or accessible using the Portal or Services (in particular pertaining to yourself or other individuals) please let us know at <a href="mailto:inspirekyd@deltacapita.com" target="_blank">inspirekyd@deltacapita.com</a> and we will use our reasonable endeavours to investigate your concern and, where appropriate and possible, correct inaccurate data.</p>
<p>We assume no responsibility for the content of websites linked on the Portal. Such links should not be interpreted as endorsement by us of those linked websites will not be liable for any loss or damage that may arise from your use of them.</p>

<p>In no event shall we be liable to you, whether in contract, tort (including negligence), breach of duty or otherwise, for any loss or damage, whether direct or indirect, including but not limited to expectation damages, loss of profit, loss of reputation or loss of data, resulting from business interruption or the unavailability of the Portal or Services.</p>
<p>We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of the Portal or the Services, or on any website linked to the Portal or Services.</p>
<p>Nothing in the Terms and Conditions shall be construed as excluding or limiting our liability for death or personal injury caused by our negligence, for fraud, or for any other liability which cannot be excluded or limited under applicable law.</p>

<h2>11. Third Party Claims</h2>
<p>In the event that you are notified by a third party that such party claims rights in the Portal or the Services or that the use of the Portal or the Services infringes the rights of such third party, you will immediately notify us and at our request immediately cease to use the Portal or the Services.</p>
<p>You will defend, indemnify and hold us (including our directors and employees) harmless from and against any liability, costs (including without limitation any legal or other professional costs), expenses, losses, damages (including any direct, indirect or consequential losses, loss of profit, loss of business opportunity, loss of reputation), interest and penalties suffered or incurred by us arising from or in any way related to (i) your use of the Portal and/or the Services (except to the extent such claims arise directly from our breach of applicable law); and/or (ii) your breach of these Terms and Conditions.</p>


<h2>12. Term and termination</h2>
<h3>12.1. Term</h3>
    <p>These Terms and Conditions will remain in force for an indefinite period of time from the date of their acceptance.</p>
<h3>12.2. Termination</h3>
    <p>We may terminate these Terms and Conditions at any time and for any reason. Termination of these Terms and Conditions (or of any element of them) will not affect any rights, obligations or liabilities of either Party which have accrued before termination (or which are expressly stated in these Terms and Conditions to continue to have effect beyond termination). Your entitlement and licence to use the Portal and Services will cease on termination of these Terms and Conditions and you must completely delete all electronic copies of all or part of the Portal and of the Services resident in your systems or elsewhere.</p>
<p>We may terminate these Terms and Conditions, without reason and without recourse to the courts, by written notice sent to the User via e-mail. The Terms and Conditions must then be terminated following a thirty (30) calendar days’ notice as from the date of said written notice.</p>
<p>The User may terminate these Terms and Conditions for convenience, by addressing a request by email at the following address: <a href="mailto:inspirekyd@deltacapita.com" target="_blank">inspirekyd@deltacapita.com</a>. We will revert to you shortly and terminate your Account accordingly. Termination will be effective ten (10) calendar days from the date of your request.</p>
<p>In the event of material breach of these Terms and Conditions, we are entitled to suspend the User’s Account, remove the concerned User Content or terminate these Terms and Conditions with immediate effect as from the date of notification of such breach by us to the User, without recourse to the courts .The User expressly acknowledges and agrees that any violation by the User of Our Intellectual Property Rights, any infringement by the User of the rights of any third party while accessing and/or using the Portal or the Services, or any dissemination of User Content that is related to or promotes illegal, illicit or immoral activities will constitute a material breach of these Terms and Conditions.</p>
<h3>12.3. Consequences of termination</h3>
    <p>In the event of termination for any reason, the User acknowledges that we will be entitled to delete the User related Account and any User Content and that as such, the User may no longer access the Portal or the Services.</p>
<p>Until the end of the notice of termination period, the User may retrieve and download the documents and User Content made accessible through the Account, including relevant due diligence questionnaires. The User acknowledges that after termination becomes effective, we have no obligation whatsoever to provide the User with a copy of any data, including the User Content that may no longer be accessible to the User as a result of the termination of the Terms and Conditions.</p>


<h2>13. Right of reference and use of logo</h2>
<p>We shall not use your company’s logo, without your prior written permission.</p>


<h2>14. Anti-bribery and corruption</h2>
<p>Each Party hereby undertakes to comply with all applicable anti-corruption and anti-bribery laws and regulations, including but not limited to any applicable anti-corruption regulations with extraterritorial reach, such as the US Foreign Corrupt Practices Act and the UK Bribery Act (the “Anti-Corruption and Anti-Bribery Regulations”), at all times in connection with and throughout the use of the Portal. Each Party warrants that it has taken reasonable measures to ensure that itself, its directors, officers, employees, agents, subcontractors or any other third party under its control or influence, comply with the Anti-Corruption and Anti-Bribery Regulations.</p>
<p>Each Party shall promptly report to the other Party any request or demand made to the other Party by the first Party for any undue financial or other advantage of any kind received in connection with the use of the Services or Portal. In this paragraph, a reference to a Party shall be deemed to include a reference to its directors, officers, employees, agents, subcontractors or any other third party under its control or influence.</p>


<p>If a Party brings evidence that the other Party has been engaging in material or several repeated breaches of the Anti-Corruption and Anti-Bribery Regulations, it will notify the latter Party accordingly and require such Party to take the necessary remedial action in a reasonable time and to inform it about such action. If the latter Party fails to take the necessary remedial action or fails to prove that it has not been engaged in material or several repeated breaches of the Anti-Corruption and Anti-Bribery Regulations, the first Party may, on written notice sent by registered mail with acknowledgment of receipt, either suspend the use of the Services and Portal or terminate it. The suspension or the termination of the use of the Services and Portal is effective from the date of such a written notice, it being understood that all amounts contractually due at the time of suspension or termination of the use of the Services and Portal will remain payable, as far as permitted by Luxembourg law.</p>


<h2>15. Amendment of the Terms and Conditions</h2>
<p>We reserve the right, from time to time and at our sole discretion, to amend the provisions of these Terms and Conditions or to change, suspend or discontinue the Services and Portal (or any part thereof). We may also impose limits on certain features and services or restrict your access to parts or all of the Portal or the Services without notice or liability.</p>
<p>You will be informed of any modification of these Terms and Conditions or the Privacy Policy via an appropriate method of information, such as e-mail, and will be deemed to have accepted any of these modifications in the event you continue accessing or using the Services or Portal after thirty (30) calendar days as from the date of our notification of the modification without having terminated these Terms and Conditions in the conditions set out in clause 12 above. These Terms and Conditions, as modified, will then be applicable to the access and use of the Services and Portal.</p>
<p>If you do not wish to be bound by any modification of the Terms and Conditions, you undertake to immediately delete your account and stop accessing and using the Services and Portal.</p>


<h2>16. General</h2>
<h3>16.1. Notices</h3>
    <p>All notices, demands and requests of the User to us will be made at the following e-mail address: <a href="mailto:inspirekyd@deltacapita.com" target="_blank">inspirekyd@deltacapita.com</a>.</p>
<h3>16.2. Entire agreement</h3>
    <p>These Terms and Conditions, the Privacy policy, as well as the services agreement concluded between the parties represents the entire agreement between you and us relating to the subject matter hereof and supersedes all prior agreements, covenants, arrangements, communications, representations or warranties, whether oral or written, by any officer, agent, employee or representative of either of the Parties as well as applicable non- mandatory local laws and international regulations.</p>
<h3>16.3. Force Majeure</h3>
    <p>Neither party will be responsible or liable for any failure to perform its obligations due to causes beyond its reasonable control, including but not limited to acts of God, war, riots, terrorist acts, embargoes, acts of civil or military authorities, fires, floods, earthquakes, accidents, labour conflicts, failure of any communications services for the duration of any such circumstances or cause.</p>
<h3>17.4 Assignment</h3>
<p>We are entitled to assign, transfer or otherwise sub-contract any or all of our rights or obligations under these Terms and Conditions to a third party. In case of sub-contracting, we will remain responsible for the acts and omissions of the sub-contractor to the same extent as if it had carried out the obligations itself pursuant to these Terms and Conditions.</p>
<h3>17.5. Severability</h3>
    <p>If any provision of these Terms and Conditions is held by any competent authority to be invalid or unenforceable in whole or in part then such provision must be construed, as nearly as possible, to reflect the intentions of the invalid or unenforceable provision and the validity of the other provisions of these Terms and Conditions will not be affected thereby.</p>
<h3>17.6. No agency</h3>
    <p>Nothing in these Terms and Conditions will be construed as creating a partnership or joint venture of any kind between the Parties or as constituting either party as the agent of the other Party for any purpose whatsoever and neither Party will have the authority or power to bind the other Party or to contract in the name of or create a liability against the other Party in any way or for any purpose.</p>


<h2>17. Applicable law and jurisdiction</h2>
<p>These Terms and Conditions and any dispute or claim arising out of or in connection with them or their subject matter or formation will be governed by and construed in accordance with English law.</p>
<p>The Parties irrevocably agree that the courts of England will have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with these Terms and Conditions or their subject matter or formation.</p></div>`