import { LegalEntity } from './../../model/legal-entity';
import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { CompanyInfo, Distributor, NewDistributorConfig } from '../model/distributor';
import { Observable, of, Subject } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { catchError, filter, map, mergeMap, switchMap, toArray } from 'rxjs/operators';
import { Contact, ContactTableItem, UserStatus } from '../model/contact';
import { UsersService } from '../../services/users.service';
import { UserInfo } from '../../admin/model/user';
import { CacheService } from './cache.service';
import { LegalEntityService } from 'src/app/services/legal-entity.service';

@Injectable({
  providedIn: 'root',
})
export class DistributorsService {
  refreshSubject = new Subject<boolean>();
  refreshContactsSubject: Subject<void> = new Subject<void>();

  contacts: Contact[] = [];
  users: UserInfo[] = [];
  private csvDownloadSubject = new Subject<string | any>();
  public csvDownloadObservable$: Observable<string | any> = this.csvDownloadSubject;

  constructor(
    private apiService: ApiService,
    private usersService: UsersService,
    private cacheService: CacheService,
    private legalEntityService: LegalEntityService
  ) {}

  getAllCountries(): Observable<any[]> {
    return this.apiService.get<any[]>('ddq/lookups/countries');
  }

  getAllDistributors(): Observable<Distributor[]> {
    if (this.cacheService.getData().distributors) {
      return of(this.cacheService.getData().distributors);
    } else {
      return this.apiService.get<Distributor[]>('ddq/distributors');
    }
  }

  getDistributor(distributorID: string): Observable<Distributor> {
    return this.apiService.get<Distributor>(`ddq/distributors/${distributorID}`);
  }

  getDistributorByRequestId(requestID: string): Observable<any> {
    return this.apiService.get<Distributor>(`ddq/distributors-id/by-requestid/${requestID}`);
  }

  addDistributor(distributor: NewDistributorConfig, subscriber): void {
    if (distributor.lei == null) {
      this.apiService
        .post<NewDistributorConfig, Distributor>('ddq/distributors', Object.assign({}, distributor))
        .subscribe(subscriber);
    } else {
      this.fetchLegalEntity(distributor.lei).subscribe((legalentity) => {
        if (legalentity) {
          this.apiService
            .post<NewDistributorConfig, Distributor>(
              'ddq/distributors',
              Object.assign(
                {
                  legalentity,
                },
                distributor
              )
            )
            .subscribe(subscriber);
        } else {
          console.log('Cannot add distributor as unable to retrieve LEI information\nEnsure LEI is valid');
        }
      });
    }
  }

  getAllDistributorContacts(): Observable<Contact[]> {
    return this.apiService.get<Contact[]>('ddq/distributors/contacts');
  }

  getDistributorsContactsByIssuerUserID(userID: string): Observable<any> {
    return this.apiService.get<any>(`ddq/distributors-contacts/by-userid/${userID}`);
  }

  returnContactsInTable(contactsList, allNonDCUsers) {
    const updatedContactsList = contactsList.map((contact: Contact) => {
      const contactTableItem: ContactTableItem = {
        ...contact,
        distributor: contact.distributorname,
        region: contact.region.join(', '),
        userStatus: this.getUserStatus(allNonDCUsers, contact.email),
        cognitoUserId: this.usersService.getCognitoUserIdFromPool(allNonDCUsers, contact.email),
      };
      return contactTableItem;
    });
    // console.log('updatedContactsList', updatedContactsList);
    return updatedContactsList;
  }

  getAllDistributorContactsForTable(): Observable<any[]> {
    if (this.cacheService.getData().contacts && this.cacheService.getData().users) {
      this.contacts = this.cacheService.getData().contacts;
      this.users = this.cacheService.getData().users;
      return of(this.contacts).pipe(
        switchMap((contactsList: Contact[]) =>
          of(this.users).pipe(
            switchMap((allUsers: UserInfo[]) => {
              const allNonDCUsers = allUsers.filter((user: UserInfo) => !user.email.endsWith('deltacapita.com'));
              return this.returnContactsInTable(contactsList, allNonDCUsers);
            }),
            toArray()
          )
        )
      );
    } else {
      return this.getAllDistributorContacts().pipe(
        switchMap((contactsList: Contact[]) =>
          this.usersService.users().pipe(
            catchError((err) => {
              console.log(`Failed to fetch users from Cognito: ${err}`);
              return this.returnContactsInTable(contactsList, null);
            }),
            switchMap((allUsers: UserInfo[]) => {
              const allNonDCUsers = allUsers.filter((user: UserInfo) => !user.email.endsWith('deltacapita.com'));
              return this.returnContactsInTable(contactsList, allNonDCUsers);
            }),
            toArray()
          )
        )
      );
    }
  }

  getMyDistributorContacts(): Observable<Contact[]> {
    return this.apiService.get<Contact[]>('ddq/distributors/contacts').pipe(take(1));
  }

  getMyCompanyInformation(): Observable<CompanyInfo> {
    return this.apiService.get<CompanyInfo>('ddq/distributors/company');
  }

  getDistributorsCompanyInformation(distributorID): Observable<CompanyInfo> {
    return this.apiService.get<CompanyInfo>(`ddq/distributors/${distributorID}/company`);
  }

  getDistributorContact(contactID): Observable<Contact> {
    return this.apiService.get<Contact>(`ddq/distributors/contacts/${contactID}`).pipe(take(1));
  }

  getDistributorContactsByDistributor(distributorID): Observable<Contact[]> {
    return this.apiService.get<Contact[]>(`ddq/distributors/${distributorID}/contacts`).pipe(take(1));
  }

  addDistributorContact(contact): Observable<Contact> {
    return this.apiService.post<Contact, Contact>(`ddq/distributors/contacts`, contact);
  }

  getLEIFromDistributorID(id): Observable<any> {
    return this.apiService.get<any>(`ddq/distributors/lei-from-id/${id}`);
  }

  updateDistributorContact(contactID, contact): Observable<Contact> {
    return this.apiService.patch<Contact, Contact>(`ddq/distributors/contacts/${contactID}`, contact);
  }

  concatAddress(leiAddress: any): string {
    return (
      leiAddress.addressLines.join('\n') +
      '\n' +
      leiAddress.city +
      '\n' +
      leiAddress.postalCode +
      '\n' +
      leiAddress.country
    );
  }

  fetchLegalEntity(lei: string) {
    if (lei) {
      return this.legalEntityService.get(lei).pipe(
        take(1),
        map((leiRecord) => leiRecord.data)
      );
    }
  }

  getUserStatus(allNonDCUsers: UserInfo[], distributorEmail) {
    if (allNonDCUsers) {
      const distributorUser = allNonDCUsers.find(
        (distributorUserInfo: UserInfo) => distributorUserInfo.email === distributorEmail
      );
      if (distributorUser) {
        if (distributorUser.status === 'CONFIRMED') {
          return UserStatus.active;
        } else {
          return UserStatus.notLoggedOnYet;
        }
      } else {
        return UserStatus.notCreated;
      }
    } else {
      return UserStatus.notAvailable;
    }
  }

  setContactToInvited(contactID: string) {
    return this.apiService.patch<any, any>(`ddq/distributors/contacts/${contactID}/invited`, null);
  }

  setContactToNotInvited(contactID: string) {
    return this.apiService.patch<any, any>(`ddq/distributors/contacts/${contactID}/notinvited`, null);
  }

  deleteContact(contactID: string) {
    return this.apiService.delete<any>(`ddq/distributors/contacts/${contactID}`);
  }

  // UTILITY FUNCTION TO ADD LEGAL ENTITY INFO TO DISTRIBUTORS THAT HAVE IT MISSING
  addLegalEntityInformation() {
    this.getAllDistributors().subscribe((distributors: Distributor[]) => {
      distributors.forEach((distributor: Distributor) => {
        if (!distributor.legalentity?.id) {
          setTimeout(() => {
            this.fetchLegalEntity(distributor.lei).subscribe((legalEntityInformation) => {
              if (!legalEntityInformation) {
                legalEntityInformation = {};
              }
              this.apiService
                .patch<any, any>(`ddq/distributors/${distributor.id}/legalentity`, legalEntityInformation)
                .subscribe();
            });
          }, 300);
        }
      });
    });
  }

  // slightly different than above, please keep both
  updateLegalEntity(id, legalEntityInfo) {
    return this.apiService.patch<any, any>(`ddq/distributors/${id}/legalentity`, legalEntityInfo);
  }

  updateDistributor(id, lei, name) {
    return this.apiService.patch<any, any>(`ddq/distributors/${id}/update/${lei}/${name}`, null);
  }

  removeDistributorLei(id) {
    return this.apiService.patch<any, any>(`ddq/distributors/${id}/remove-lei`, null);
  }

  setDownloadCsvSubject(value: string | any): void {
    this.csvDownloadSubject.next(value);
  }

  deleteDistributor(id: string) {
    return this.apiService.delete<any>(`ddq/distributors/${id}`);
  }
}
