import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

// in the config, when setting the groupArr validator for checkbox groups...
// if there is a single group of checkboxes, use a flat array,
// if there is two or more checkbox groups in the same question, the first group should be flat, the second and third etc should be nested
// eg. groupArr: ['80d9d37b-bb4b-4a50-b964-fd456dcf6b19',  '60c28e3b-1d76-42cd-8dc5-ea19d5360f94', ['8e3afd98-7c02-484b-a30f-933e5abd7815', 'b98b2f0e-4f36-49f2-93cf-7099f7c17c89']]

export function requireCheckboxesToBeCheckedValidator(checkboxIds: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as UntypedFormGroup;

    let numberOfGroups = 1;
    let arrayOfArrays = [[]];

    checkboxIds.map((x) => (Array.isArray(x) ? numberOfGroups++ : ''));

    //Handles multiple checkbox groups
    if (numberOfGroups > 1) {
      checkboxIds.map((y) => {
        if (!Array.isArray(y)) {
          arrayOfArrays[0].push(y);
        }
        if (Array.isArray(y)) {
          arrayOfArrays.push(y);
        }
      });

      let ans = [];

      for (let i = 0; i < arrayOfArrays.length; i++) {
        let index = 0;
        let value = 0;
        let disabled = 0;

        for (let j = 0; j < arrayOfArrays[i].length; j++) {
          const control = formGroup.controls[arrayOfArrays[i][j]];
          const v = control?.value;
          const s = control?.status;

          if (v) {
            value++;
          }
          if (s === 'DISABLED') {
            disabled++;
          }
          index++;
        }

        if (value > 0 || disabled === index) {
          ans.push('pass');
        }
        index = 0;
        value = 0;
        disabled = 0;
      }

      if (ans.length === numberOfGroups) {
        return null;
      } else {
        return {
          requireOneCheckboxToBeChecked: true,
        };
      }
    }

    //Handles single checkbox group
    else {
      let index = 0;
      let value = 0;
      let disabled = 0;

      for (let i = 0; i < checkboxIds.length; i++) {
        const control = formGroup.controls[checkboxIds[i]];
        const v = control?.value;
        const s = control?.status;

        if (v) {
          value++;
        }
        if (s === 'DISABLED') {
          disabled++;
        }
        index++;
      }

      if (value > 0 || disabled === index) {
        return null;
      } else {
        return {
          requireOneCheckboxToBeChecked: true,
        };
      }
    }
  };
}
