class ConfigPopOver {
  label?: string;
  body: string;
}

type StringArrNestedOrFlat = string | StringArrNestedOrFlat[];

class ConfigValidation {
  required?: boolean;
  requiredCheckbox?: boolean;
  email?: boolean;
  requiredEmail?: boolean;
  requiredPhone?: boolean;
  groupArr?: StringArrNestedOrFlat;
  percent?: string[];
  percentCountry?: boolean;
}

class ConfigConditional {
  enable?: string[]; //number[]
  disable?: string[];
  disablePriority?: boolean;
  parent?: ConfigConditional;
}

export class ConfigOption {
  id: string;
  label: string;
  conditional?: ConfigConditional;
  value?: boolean;
}

export class ConfigField {
  id: string;
  type:
    | 'input'
    | 'checkbox'
    | 'textArea'
    | 'radio-inline'
    | 'radio-list'
    | 'date'
    | 'dropdown'
    | 'subHead'
    | 'subHeadLabel'
    | 'dividerLine';
  label?: string;
  placeholder?: string;
  validate?: ConfigValidation;
  conditional?: ConfigConditional;
  options?: (string | ConfigOption)[];
  width: number;
  indent?: number;
  disabled?: boolean;
  source?: string;
  value?: string | string[] | boolean;
  selectAll?: boolean;
  search?: boolean;
  limitSelect?: number;
  checkbox?: boolean;
  singleSelect?: boolean;
  groupBy?: string;
  useTemplate?: boolean;
  text?: string;
  popover?: ConfigPopOver;
}

export class InputField extends ConfigField {}

export class ConfigQuestion {
  id: string;
  text: string;
  externalID?: string;
  nav: string;
  description?: string;
  fields: (ConfigField | ConfigField[])[];
  dynamic?: boolean;
  dynamicButtonText?: string;
  dynamicDisable?: string;
  dynamicDisableObject?: dynamicDisableObject;
  dynamicDisableSubQuestion?: string[];
  dynamicFields?: string[];
  popover?: ConfigPopOver;
  validate?: ConfigValidation;
  disabled?: boolean;
}

export class dynamicDisableObject {
  id: string;
  name?: string;
  arrayId: string;

  conditional?: ConfigConditional;
}

export class ConfigSubsection {
  id: string;
  name: string;
  nav: string;
  questions: ConfigQuestion[];
}

export class ConfigSection {
  id: string;
  name: string;
  nav: string;
  subsections: ConfigSubsection[];
}

export class DDQConfig {
  constructor(public distributorName: string, public version: string, public sections: ConfigSection[]) {}
}
