import { Injectable } from '@angular/core';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { CacheService } from './cache.service';
import { IssuersService } from './issuers.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  issuerUserRole;
  userRoleFilters;
  userID;

  constructor(
    private currentUserService: CurrentUserService,
    private issuersService: IssuersService,
    private cacheService: CacheService
  ) {}

  async getRole() {
    if (!(this.cacheService.getData().role && this.cacheService.getData().roleFilters)) {
      await new Promise((resolve, reject) => {
        this.currentUserService.currentUser.subscribe((result) => {
          let currentUserEmail = result.email;

          this.issuersService.getIssuerContactByEmail(currentUserEmail).subscribe((salesContact) => {
            if (salesContact && salesContact.role) {
              this.userID = salesContact.id;
              this.issuerUserRole = salesContact.role;
              this.userRoleFilters = {
                salesdesk: salesContact.salesdesk,
                salesregion: salesContact.salesregion,
                userID: salesContact.id,
              };
              this.cacheService.addData({
                userID: this.userID,
                role: this.issuerUserRole,
                roleFilters: this.userRoleFilters,
              });
              resolve(true);
            } else {
              //console.log('Attention: You are not logged in with an Issuer account!');
              return { error: 'You are not logged in with an Issuer account!' };
            }
          });
        });
      });
      return { userID: this.userID, role: this.issuerUserRole, roleFilters: this.userRoleFilters };
    } else {
      return {
        userID: this.cacheService.getData().userID,
        role: this.cacheService.getData().role,
        roleFilters: this.cacheService.getData().roleFilters,
      };
    }
  }
}
