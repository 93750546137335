import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

// in the config, when setting the groupArr validator for checkbox groups...
// if there is a single group of checkboxes, use a flat array,
// if there is two or more checkbox groups in the same question, the first group should be flat, the second and third etc should be nested
// eg. groupArr: ['80d9d37b-bb4b-4a50-b964-fd456dcf6b19',  '60c28e3b-1d76-42cd-8dc5-ea19d5360f94', ['8e3afd98-7c02-484b-a30f-933e5abd7815', 'b98b2f0e-4f36-49f2-93cf-7099f7c17c89']]

export function enableDisableCheckboxValidator(options: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // const formGroup = control as FormGroup;
    const parent = control.parent;

    if (parent) {
      if (options) {
        const parentparent = parent.parent;

        // if (options?.disablePriority) {
        //   console.log('disablePriority', options.disablePriority);
        // }

        if (options?.disablePriority == true) {
          if (control.value && control.value === true) {
            if (options?.disable) {
              // disable here
              Object.keys(parent?.controls).forEach((key) => {
                const control = parent.controls[key];
                if (options.disable.includes(key)) {
                  control.disable();
                  control.reset();
                  //  control.updateValueAndValidity();
                }
              });
            }
            if (options.parent) {
              if (options?.parent.disable) {
                Object.keys(parentparent?.controls).forEach((key) => {
                  const control = parentparent.controls[key];
                  if (options?.parent.disable.includes(key)) {
                    control.disable();
                    control.reset();
                    //  control.updateValueAndValidity();
                  }
                });
              }
            }
          } else {
            if (options?.enable) {
              // enable here
              Object.keys(parent?.controls).forEach((key) => {
                const control = parent.controls[key];
                if (options.enable.includes(key)) {
                  control.enable();
                  // control.updateValueAndValidity();
                }
              });
            }
            if (options.parent) {
              if (options?.parent.enable) {
                Object.keys(parentparent?.controls).forEach((key) => {
                  const control = parentparent.controls[key];
                  if (options?.parent.enable.includes(key)) {
                    control.enable();
                    //   control.updateValueAndValidity();
                  }
                });
              }
            }
          }
        } else {
          if (control.value && control.value === true) {
            if (options?.enable) {
              // enable here
              Object.keys(parent?.controls).forEach((key) => {
                const control = parent.controls[key];
                if (options.enable.includes(key)) {
                  // console.log(key);
                  control.enable();
                  //  control.updateValueAndValidity();
                }
              });
            }
          } else {
            Object.keys(parent?.controls).forEach((key) => {
              const control = parent.controls[key];
              if (options?.enable && options?.enable.includes(key)) {
                //console.log(key);
                control.disable();
                control.reset();
                // control.updateValueAndValidity();
              }
            });
          }
        }
      }

      return null;
    }
    return null;
  };
}
