import {Component, OnDestroy, OnInit} from '@angular/core'
import {Subscription} from 'rxjs'
import {UntypedFormControl, Validators} from '@angular/forms'
import {AuthService} from '../../services/auth.service'

@Component({
  selector: 'app-confirm-signin-form',
  templateUrl: './confirm-sign-in-form.component.html',
  styleUrls: ['./confirm-sign-in-form.component.scss'],
})
export class ConfirmSignInFormComponent implements OnInit, OnDestroy {
  sub = new Subscription()

  displayed: boolean = false
  submitted: boolean = false

  verificationCode = new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)])

  errorMessage: string

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.sub.add(
      this.authService.onConfirmSignInRequired.subscribe(confirmSignInRequired => {
        this.displayed = confirmSignInRequired
        this.submitted = false
      }),
    )
    this.sub.add(
      this.authService.onAuthError.subscribe(err => {
        this.errorMessage = err && err.message
        this.submitted = false
      }),
    )

    this.verificationCode.reset()
    this.sub.add(
      this.verificationCode.valueChanges.subscribe(() => {
        this.errorMessage = undefined
      }),
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  canConfirmSignIn() {
    return (!this.submitted && this.verificationCode.valid)
  }

  confirmSignIn() {
    this.submitted = true
    this.authService.confirmSignIn(this.verificationCode.value)
  }
}
