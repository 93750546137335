import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-setup-mfa-form',
  templateUrl: './setup-mfa-form.component.html',
  styleUrls: ['./setup-mfa-form.component.scss'],
})
export class SetupMfaFormComponent implements OnInit, OnDestroy {
  sub = new Subscription();

  displayed: boolean = false;
  submitted: boolean = false;

  qrCode: string = undefined;
  verificationCode = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(6),
  ]);
  sanitizedUrl;
  errorMessage: string;

  constructor(private authService: AuthService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.sub.add(
      this.authService.onSetupMfaRequired.subscribe((qrCode) => {
        this.displayed = qrCode !== undefined;
        this.qrCode = qrCode;
        this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(qrCode);
        this.submitted = false;
      })
    );
    this.sub.add(
      this.authService.onAuthError.subscribe((err) => {
        this.errorMessage = err && err.message;
        this.submitted = false;
      })
    );

    this.verificationCode.reset();
    this.sub.add(
      this.verificationCode.valueChanges.subscribe(() => {
        this.errorMessage = undefined;
      })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  canVerifyMfa() {
    return !this.submitted && this.verificationCode.valid;
  }

  verifyMfa() {
    this.submitted = true;
    this.authService.verifySetupMfa(this.verificationCode.value.trim().replaceAll(/\n/g, ''));
  }
}
