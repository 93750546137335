import { DateService } from './../../services/date.service';
import { CacheService } from './cache.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Distributor } from '../model/distributor';
import { filter, map, mergeMap, switchMap, tap, toArray, catchError, delay, takeWhile } from 'rxjs/operators';
import { combineLatest, Observable, of, pipe, Subject, Subscription, timer } from 'rxjs';
import { DataService } from '../../services/data.service';
import { KYDRequestActions, KYDRequestData, KYDRequestSummary, kydRequestDetails } from '../model/kyd-request';
import { Counts, Region, Request, RequestStatus, RequestTable, RequestTableStatus } from '../model/request';
import { take } from 'rxjs/internal/operators/take';
import { KydUserService } from './kyd-user.service';
import { DDQStatus } from '../model/ddq';
import { ApiService } from '../../services/api.service';
import { CurrentUserService } from '../../services/current-user.service';
import { DistributorsService } from './distributors.service';
import { IssuersService } from './issuers.service';
import { DirtyRequest, Issuer } from '../model/issuer';
import { Contact } from '../model/contact';
import { ConnectionService } from './connection.service';
import { ModalService } from '../../ui-kit/dc-modal/modal.service';
import { ReferencesService } from './references.service';
import { IssuerRequestsService } from './issuer-requests.service';
import { WaitService } from './wait.service';

@Injectable({
  providedIn: 'root',
})
export class KydRequestService implements OnDestroy {
  private allTemplates = [];
  private issuers: any[];
  private contacts: any[];
  private requests: RequestTable[] = [];

  counts: Counts = {
    all: 0,
    inspire: 0,
    issuer: 0,
    distributor: 0,
    completed: 0,
    offPlatform: 0,
  };

  public allActionsDisabled = false;

  public publishModalClosed = false;

  currentUser;
  private sub = new Subscription();
  refreshSubject: Subject<boolean> = new Subject<boolean>();
  draftReleased: Subject<any> = new Subject();
  reviewedDraftReturned: Subject<any> = new Subject();
  private csvDownloadSubject = new Subject<string | any>();
  public csvDownloadObservable$: Observable<string | any> = this.csvDownloadSubject;

  private alive = true;

  ngOnDestroy() {
    this.alive = false;
    this.sub.unsubscribe();
  }

  formatRequestForUITable = () =>
    pipe(
      tap(() => {
        this.issuers = this.cacheService.getData().issuers;
        this.contacts = this.cacheService.getData().contacts;
      }),
      delay(20),
      map((request: Request) => {
        return request as unknown as KYDRequestData;
      }),
      filter((request) => !!request.details),
      mergeMap((request) =>
        this.fetchAllUnresolvedComments(request.details?.form?.id).pipe(
          take(1),
          map((comments: any[]) => {
            return {
              request: request as unknown as Request,
              questionnaireStatus: request.details?.form?.status,
              created: request.createdat,
              issuerName: request.details.requester.name
                .map((issuer) => (!!this.issuers ? this.issuers.find((x) => x.id === issuer).name : ''))
                .join(', '),
              distributorName: request.distributorName,
              lei: request.lei,
              contact: request.details.distributor.contact.map((contact) =>
                !!this.contacts ? this.contacts.find((x) => x.id === contact) : null
              ),
              type: request.type,
              // type: this.lookupsService.lookups.requestTypes.find((requestType) => requestType.value === request.type)
              //   .displayValue,
              comms: comments ? (comments.length ? (comments[0].count ? comments[0].count : '-') : null) : null,
              ddqProgress: request.details?.form?.progress ? request.details.form.progress : '0',
              status: this.getRequestTableStatus(request.status, request.details?.form?.status),
              distributorKey: request.details.distributor.name,
              region: request.details.template.region,
              chaseTimer: this.dateService.getWorkingDays(request.chasestartedat),
              signed: request.details.distributor.signedOffTimeStamp || null,
              expireOn: request.details.distributor.signedOffTimeStamp
                ? this.dateService.addDays(request.details.distributor.signedOffTimeStamp, 365)
                : null,
            } as RequestTable;
          })
        )
      )
    );

  showSupportedRequestsOnly = () =>
    pipe(
      switchMap((requestList: RequestTable[]) => {
        return of(...requestList).pipe(
          filter((request) =>
            this.doWeHandleRequestOnPlatform(
              request.type,
              request.request.details.template.name,
              request.request.details.form.id,
              request.created
            )
          ),
          toArray()
        );
      })
    );

  constructor(
    private dataService: DataService,
    private currentUserService: CurrentUserService,
    private kydUserService: KydUserService,
    private distributorsService: DistributorsService,
    private issuersService: IssuersService,
    private apiService: ApiService,
    private connectionService: ConnectionService,
    private modalService: ModalService,
    private lookupsService: ReferencesService,
    private issuerRequestsService: IssuerRequestsService,
    private cacheService: CacheService,
    private waitService: WaitService,
    private dateService: DateService
  ) {
    this.sub.add(this.currentUserService.currentUser.subscribe((user) => (this.currentUser = user.name)));
  }

  getDistributor(distributorKey, subscriber) {
    this.sub.add(this.dataService.get<Distributor>('distributor', distributorKey).subscribe(subscriber));
  }

  refresh(type: string) {
    this.dataService.forceReload(type);
  }

  cacheAllRequests(id?: string) {
    return this.fetchAllRequests().pipe(
      mergeMap((requestList: Request[]) =>
        this.lookupsService.getLookups().pipe(
          switchMap((lookups: any[]) => {
            if (id) {
              return of(...requestList.filter((s) => s.details.distributor.name == id)).pipe(
                this.formatRequestForUITable(),
                toArray()
              );
            } else {
              return of(...requestList).pipe(this.formatRequestForUITable(), toArray());
            }
          }),
          catchError((error) => {
            console.error(error);
            return of([]);
          })
        )
      )
    );
  }

  getAllRequests(id?: string, isKyd?: boolean, dFilter?: string, isDName?: boolean, selectorValues?: any) {
    if (this.cacheService.getData().requests) {
      if (id) {
        this.requests = this.cacheService.getData().requests.filter((s) => s.distributorKey == id);
      } else {
        this.requests = this.cacheService.getData().requests;
      }
      if (selectorValues?.region) {
        this.requests = this.requests.filter((x) => x.region === selectorValues.region);
      }
      if (selectorValues?.distributorName) {
        this.requests = this.requests.filter((x) => x.distributorName === selectorValues.distributorName);
      }
      if (selectorValues?.status) {
        this.requests = this.requests.filter((x) => x.status === selectorValues.status);
      }

      this.counts.all = this.requests
        .filter((request: RequestTable) => {
          return dFilter && isDName ? request.distributorName.toLowerCase().includes(dFilter.toLowerCase()) : true;
        })
        .filter((request: RequestTable) => {
          return dFilter && !isDName && !isKyd ? this.makeString(request).includes(dFilter.toLocaleLowerCase()) : true;
        })
        .filter((request: RequestTable) => (isKyd ? request.type === 'KYD' : true)).length;
      this.counts.inspire = this.requests
        .filter((request: RequestTable) =>
          id
            ? request.status === RequestTableStatus.inspire && request.distributorKey == id
            : request.status === RequestTableStatus.inspire
        )
        .filter((request: RequestTable) => {
          return dFilter && isDName ? request.distributorName.toLowerCase().includes(dFilter.toLowerCase()) : true;
        })
        .filter((request: RequestTable) => {
          return dFilter && !isDName ? this.makeString(request).includes(dFilter.toLocaleLowerCase()) : true;
        })
        .filter((request: RequestTable) => (isKyd ? request.type === 'KYD' : true)).length;
      this.counts.distributor = this.requests
        .filter((request: RequestTable) =>
          id
            ? request.status === RequestTableStatus.distributor && request.distributorKey == id
            : request.status === RequestTableStatus.distributor
        )
        .filter((request: RequestTable) => {
          return dFilter && isDName ? request.distributorName.toLowerCase().includes(dFilter.toLowerCase()) : true;
        })
        .filter((request: RequestTable) => {
          return dFilter && !isDName ? this.makeString(request).includes(dFilter.toLocaleLowerCase()) : true;
        })
        .filter((request: RequestTable) => (isKyd ? request.type === 'KYD' : true)).length;
      this.counts.issuer = this.requests
        .filter((request: RequestTable) =>
          id
            ? request.status === RequestTableStatus.issuer && request.distributorKey == id
            : request.status === RequestTableStatus.issuer
        )
        .filter((request: RequestTable) => {
          return dFilter && isDName ? request.distributorName.toLowerCase().includes(dFilter.toLowerCase()) : true;
        })
        .filter((request: RequestTable) => {
          return dFilter && !isDName ? this.makeString(request).includes(dFilter.toLocaleLowerCase()) : true;
        })
        .filter((request: RequestTable) => (isKyd ? request.type === 'KYD' : true)).length;
      this.counts.completed = this.requests
        .filter((request: RequestTable) =>
          id
            ? request.status === RequestTableStatus.complete && request.distributorKey == id
            : request.status === RequestTableStatus.complete
        )
        .filter((request: RequestTable) => {
          return dFilter && isDName ? request.distributorName.toLowerCase().includes(dFilter.toLowerCase()) : true;
        })
        .filter((request: RequestTable) => {
          return dFilter && !isDName ? this.makeString(request).includes(dFilter.toLocaleLowerCase()) : true;
        })
        .filter((request: RequestTable) => (isKyd ? request.type === 'KYD' : true)).length;
      this.counts.offPlatform = this.requests
        .filter(
          (request: RequestTable) =>
            !this.doWeHandleRequestOnPlatform(
              request.type,
              request.request.details.template.name,
              request.request.details.form.id,
              request.created
            )
        )
        .filter((request: RequestTable) => {
          return dFilter && isDName ? request.distributorName.toLowerCase().includes(dFilter.toLowerCase()) : true;
        })
        .filter((request: RequestTable) => {
          return dFilter && !isDName ? this.makeString(request).includes(dFilter.toLocaleLowerCase()) : true;
        })
        .filter((request: RequestTable) => (isKyd ? request.type === 'KYD' : true)).length;
      this.allActionsDisabled = false;
      return of(this.requests);
    } else {
      return this.fetchAllRequests().pipe(
        mergeMap((requestList: Request[]) =>
          this.lookupsService.getLookups().pipe(
            switchMap((lookups: any[]) => {
              if (id) {
                return of(...requestList.filter((x) => x.details.distributor.name == id)).pipe(
                  this.formatRequestForUITable(),
                  toArray()
                );
              } else {
                return of(...requestList).pipe(this.formatRequestForUITable(), toArray());
              }
            }),
            tap((requests: RequestTable[]) => {
              this.counts.all = requests
                .filter((request: RequestTable) => (isKyd ? request.type === 'KYD' : true))
                .filter((request: RequestTable) => {
                  return dFilter && isDName
                    ? request.distributorName.toLowerCase().includes(dFilter.toLowerCase())
                    : true;
                })
                .filter((request: RequestTable) => {
                  return dFilter && !isDName ? this.makeString(request).includes(dFilter.toLocaleLowerCase()) : true;
                }).length;
              this.counts.inspire = requests
                .filter((request: RequestTable) =>
                  id
                    ? request.status === RequestTableStatus.inspire && request.distributorKey == id
                    : request.status === RequestTableStatus.inspire
                )
                .filter((request: RequestTable) => {
                  return dFilter && isDName
                    ? request.distributorName.toLowerCase().includes(dFilter.toLowerCase())
                    : true;
                })
                .filter((request: RequestTable) => {
                  return dFilter && !isDName ? this.makeString(request).includes(dFilter.toLocaleLowerCase()) : true;
                })
                .filter((request: RequestTable) => (isKyd ? request.type === 'KYD' : true)).length;
              this.counts.distributor = requests
                .filter((request: RequestTable) =>
                  id
                    ? request.status === RequestTableStatus.distributor && request.distributorKey == id
                    : request.status === RequestTableStatus.distributor
                )
                .filter((request: RequestTable) => {
                  return dFilter && isDName
                    ? request.distributorName.toLowerCase().includes(dFilter.toLowerCase())
                    : true;
                })
                .filter((request: RequestTable) => {
                  return dFilter && !isDName ? this.makeString(request).includes(dFilter.toLocaleLowerCase()) : true;
                })
                .filter((request: RequestTable) => (isKyd ? request.type === 'KYD' : true)).length;
              this.counts.issuer = requests
                .filter((request: RequestTable) =>
                  id
                    ? request.status === RequestTableStatus.issuer && request.distributorKey == id
                    : request.status === RequestTableStatus.issuer
                )
                .filter((request: RequestTable) => {
                  return dFilter && isDName
                    ? request.distributorName.toLowerCase().includes(dFilter.toLowerCase())
                    : true;
                })
                .filter((request: RequestTable) => {
                  return dFilter && !isDName ? this.makeString(request).includes(dFilter.toLocaleLowerCase()) : true;
                })
                .filter((request: RequestTable) => (isKyd ? request.type === 'KYD' : true)).length;
              this.counts.completed = requests
                .filter((request: RequestTable) =>
                  id
                    ? request.status === RequestTableStatus.complete && request.distributorKey == id
                    : request.status === RequestTableStatus.complete
                )
                .filter((request: RequestTable) => {
                  return dFilter && isDName
                    ? request.distributorName.toLowerCase().includes(dFilter.toLowerCase())
                    : true;
                })
                .filter((request: RequestTable) => {
                  return dFilter && !isDName ? this.makeString(request).includes(dFilter.toLocaleLowerCase()) : true;
                })
                .filter((request: RequestTable) => (isKyd ? request.type === 'KYD' : true)).length;
              this.counts.offPlatform = requests
                .filter(
                  (request: RequestTable) =>
                    !this.doWeHandleRequestOnPlatform(
                      request.type,
                      request.request.details.template.name,
                      request.request.details.form.id,
                      request.created
                    )
                )
                .filter((request: RequestTable) => {
                  return dFilter && isDName
                    ? request.distributorName.toLowerCase().includes(dFilter.toLowerCase())
                    : true;
                })
                .filter((request: RequestTable) => {
                  return dFilter && !isDName ? this.makeString(request).includes(dFilter.toLocaleLowerCase()) : true;
                })
                .filter((request: RequestTable) => (isKyd ? request.type === 'KYD' : true)).length;
              this.allActionsDisabled = false;
              // console.log(requests);
            })
          )
        )
      );
    }
  }

  getRequestTableStatus(requestStatus: RequestStatus | string, ddqStatus: DDQStatus | string): RequestTableStatus {
    if (requestStatus === RequestStatus.pending && ddqStatus === DDQStatus.pending) {
      return RequestTableStatus.inspire;
    } else if (
      (requestStatus === RequestStatus.pending && ddqStatus === DDQStatus.open) ||
      (requestStatus === RequestStatus.active && ddqStatus === DDQStatus.pending)
    ) {
      return RequestTableStatus.distributor;
    } else if (requestStatus === RequestStatus.active) {
      if (ddqStatus === DDQStatus.open) {
        return RequestTableStatus.distributor;
      } else if (ddqStatus === DDQStatus.draft) {
        return RequestTableStatus.inspire;
      } else if (ddqStatus === DDQStatus.requestSignoff) {
        return RequestTableStatus.distributor;
      } else if (ddqStatus === DDQStatus.signed) {
        return RequestTableStatus.inspire;
      } else if (ddqStatus === DDQStatus.issued) {
        return RequestTableStatus.issuer;
      } else if (ddqStatus === DDQStatus.chase) {
        return RequestTableStatus.distributor;
      } else if (ddqStatus === DDQStatus.onhold) {
        return RequestTableStatus.issuer;
      } else if (ddqStatus === DDQStatus.completed) {
        return RequestTableStatus.complete;
      }
    } else if (ddqStatus === DDQStatus.onhold || ddqStatus === DDQStatus.issued) {
      return RequestTableStatus.issuer;
    } else if (requestStatus === RequestStatus.completed || ddqStatus === DDQStatus.completed) {
      return RequestTableStatus.complete;
    } else if (ddqStatus === DDQStatus.draft || ddqStatus === DDQStatus.inspire) {
      return RequestTableStatus.inspire;
    } else if (
      ddqStatus === DDQStatus.chase ||
      ddqStatus === DDQStatus.requestSignoff ||
      ddqStatus === DDQStatus.open
    ) {
      return RequestTableStatus.distributor;
    }
  }

  getRequests(selectedTab, id?, isKyd?, dFilter?, isDName?, selectorValues?): Observable<RequestTable[]> {
    switch (selectedTab) {
      case 0:
        return this.getAllRequests(id, isKyd, dFilter, isDName, selectorValues);
      case 1:
        return this.getFilteredRequests(RequestTableStatus.inspire, id, isKyd, dFilter, isDName, selectorValues).pipe(
          tap((data) => {
            this.counts.inspire = data.length;
          })
        );
      case 2:
        return this.getFilteredRequests(
          RequestTableStatus.distributor,
          id,
          isKyd,
          dFilter,
          isDName,
          selectorValues
        ).pipe(
          tap((data) => {
            this.counts.distributor = data.length;
          })
        );
      case 3:
        return this.getFilteredRequests(RequestTableStatus.issuer, id, isKyd, dFilter, isDName, selectorValues).pipe(
          tap((data) => {
            this.counts.issuer = data.length;
          })
        );
      case 4:
        return this.getFilteredRequests(RequestTableStatus.complete, id, isKyd, dFilter, isDName, selectorValues).pipe(
          tap((data) => {
            this.counts.completed = data.length;
          })
        );
      case 5:
        return this.getUnsupportedRequests(id, isKyd, dFilter, isDName, selectorValues).pipe(
          tap((data) => {
            this.counts.offPlatform = data.length;
          })
        );
      // case 6:
      //   // previously case 0, before merge
      //   return this.getAllRequests().pipe(this.showSupportedRequestsOnly());
      // default:
      //   return this.getAllRequests(id);
    }
  }

  getFilteredRequests(
    requestTableStatus: RequestTableStatus,
    id?,
    isKyd?,
    dFilter?,
    isDName?,
    selectorValues?
  ): Observable<RequestTable[]> {
    return this.getAllRequests(id, isKyd, dFilter, isDName, selectorValues).pipe(
      switchMap((requestList) => {
        return of(...requestList).pipe(
          filter((request) => request.status === requestTableStatus),
          filter((request) => (id ? request.distributorKey == id : true)),
          filter((request) => (isKyd ? request.type === 'KYD' : true)),
          filter((request) =>
            dFilter && isDName ? request.distributorName.toLowerCase().includes(dFilter.toLowerCase()) : true
          ),
          filter((request) =>
            dFilter && !isDName ? this.makeString(request).includes(dFilter.toLocaleLowerCase()) : true
          ),

          // merge with offPlatformRequests hence comment out below
          // filter((request) => request.type === 'KYD'),
          toArray()
        );
      })
    );
  }

  getUnsupportedRequests(id?, isKyd?, dFilter?, isDName?, selectorValues?): Observable<RequestTable[]> {
    return this.getAllRequests(id, isKyd, dFilter, isDName).pipe(
      switchMap((requestList) => {
        return of(...requestList).pipe(
          filter(
            (request) =>
              !this.doWeHandleRequestOnPlatform(
                request.type,
                request.request.details.template.name,
                request.request.details.form.id,
                request.created
              )
          ),
          filter((request) => (id ? request.distributorKey == id : true)),
          filter((request) => (isKyd ? request.type === 'KYD' : true)),
          filter((request) =>
            dFilter && isDName ? request.distributorName.toLowerCase().includes(dFilter.toLowerCase()) : true
          ),
          filter((request) =>
            dFilter && !isDName ? this.makeString(request).includes(dFilter.toLocaleLowerCase()) : true
          ),
          filter((x) => (selectorValues?.region ? x.region === selectorValues.region : false)),
          filter((x) => (selectorValues?.status ? x.status === selectorValues.status : false)),
          filter((x) => (selectorValues?.distributorName ? x.region === selectorValues.distributorName : false)),
          toArray()
        );
      })
    );
  }

  makeString(obj: any): string {
    return (
      (obj.created || '') +
      (obj.distributorName || '') +
      (obj.distributorKey || '') +
      (obj.status || '') +
      (obj.type || '') +
      (obj.lei || '')
    ).toLowerCase();
  }

  getRequestSummary(request: Request, subscriber) {
    this.sub.add(
      this.distributorsService
        .getDistributor(request.details.distributor.name)
        .pipe(
          mergeMap((distributor: Distributor) =>
            this.issuersService.getIssuer(request.details.requester.name).pipe(
              // TODO - change from any
              mergeMap((issuer: Issuer) =>
                combineLatest(
                  request.details.distributor.contact.map((contact) =>
                    this.distributorsService.getDistributorContact(contact)
                  )
                ).pipe(
                  map((contacts: [Contact]) => {
                    return {
                      requestKey: request.id,
                      issuerName: issuer.name,
                      distributorKey: distributor.id,
                      distributorName: distributor.name,
                      distributorAddress: distributor?.legalentity?.attributes?.entity?.legalAddress
                        ? this.distributorsService.concatAddress(distributor.legalentity.attributes.entity.legalAddress)
                        : 'N/A',
                      contact: contacts,
                      ddqType: request.details.template.region,
                    } as KYDRequestSummary;
                  })
                )
              )
            )
          )
        )
        .subscribe(subscriber)
    );
  }

  createDirtyRequest(issuerRequestDetails): Observable<DirtyRequest> {
    return this.apiService.post<any, DirtyRequest>('ddq/dirty-requests', issuerRequestDetails);
  }

  // setDirtyRequestToHandled(dirtyRequestID: string) {
  //   return this.apiService.patch<any, DirtyRequest>(`ddq/dirty-requests/${dirtyRequestID}`, dirtyRequestID);
  // }

  setDirtyRequestToHandled(dirtyRequestID: string) {
    return this.apiService.patch<any, DirtyRequest>(`ddq/dirty-requests/${dirtyRequestID}`, {
      handled: 'TRUE',
    });
  }

  async createKYDRequest(kydRequestDetails: any, ddqConfig: any, subscriber) {
    const kydRequest = {
      createdby: this.currentUser,
      createdat: new Date().toISOString(),
      type: kydRequestDetails.requestType,
      status: RequestStatus.pending,
      details: {
        form: {
          id: ddqConfig.key.split('/')[1],
          status: DDQStatus.pending,
          progress: 0,
        },
        distributor: {
          name: kydRequestDetails.distributor,
          contact: kydRequestDetails.distributorContact,
          signedOffTimeStamp: null,
        },
        template: {
          region: Region[kydRequestDetails.template],
          name: Region[kydRequestDetails.template],
          versions: ['2023'],
        },
        requester: {
          name: [kydRequestDetails.issuer],
        },
      },
    } as KYDRequestData;
    this.sub.add(this.addRequest(kydRequest).subscribe(subscriber));
  }

  async createNonSupportedRequest(kydRequestDetails: any, subscriber) {
    const kydRequest = {
      createdby: this.currentUser,
      createdat: new Date().toISOString().slice(0, -1),
      type: kydRequestDetails.requestType,
      status: RequestStatus.pending,
      details: {
        form: {
          status: DDQStatus.pending,
        },
        distributor: {
          name: kydRequestDetails.distributor,
          // contact: kydRequestDetails.distributorContact,
          contact: [], // remove requirement of distributor contact for non-KYD
          signedOffTimeStamp: null,
        },
        template: {
          region: Region[kydRequestDetails.template],
          name: Region[kydRequestDetails.template],
        },
        requester: {
          name: [kydRequestDetails.issuer],
        },
      },
    } as KYDRequestData;
    this.sub.add(this.addRequest(kydRequest).subscribe(subscriber));
  }

  async getRequestAsync(requestKey: string) {
    return this.apiService.get<Request>(`ddq/requests/${requestKey}`).toPromise();
  }

  getRequestAPICall(requestKey: string): Observable<Request> {
    return this.apiService.get<Request>(`ddq/requests/${requestKey}`).pipe(take(1));
  }

  getRequest(requestKey: string, subscriber) {
    this.sub.add(this.getRequestAPICall(requestKey).subscribe(subscriber));
  }

  async getRequestByIdPromise(id: string) {
    return this.apiService.get<Request>(`ddq/requests/${id}`).toPromise();
  }

  async searchRequestIdsByDistributorIdPromise(distributorId) {
    return await this.apiService.get(`ddq/requests/distributor/${distributorId}`).toPromise();
  }

  async searchRequestIdsByDistributorIdAndIssuerIdPromise(distributorId, issuerId) {
    return await this.apiService.get(`ddq/requests/distributor/${distributorId}/issuer/${issuerId}`).toPromise();
  }

  getMyRequest(): Observable<Request> {
    return this.apiService.get<Request>('ddq/request');
  }

  getMyContributors(): Observable<Contact[]> {
    return this.getMyRequest().pipe(
      switchMap((request: Request) => {
        return of(...request.details.distributor.contact).pipe(
          mergeMap((contactID) => this.distributorsService.getDistributorContact(contactID)),
          toArray()
        );
      })
    );
  }

  async inviteUsersToPlatform(kydRequestRow: RequestTable) {
    await this.kydUserService.sendInviteEmails(kydRequestRow);
  }

  getActionButtonLogic(requestAction, row) {
    if (this.allActionsDisabled) {
      return true;
    }

    // Can always download, except when mid-downloading (triggered by check above)
    if (KYDRequestActions.download === requestAction) {
      return false;
    }

    const request = row.request as Request;
    const requestStatus = request?.status;
    const questionnaireStatus = row.questionnaireStatus;

    if (KYDRequestActions.enrich === requestAction || KYDRequestActions.publish === requestAction) {
      return requestStatus !== RequestStatus.pending;
    } else if (KYDRequestActions.view === requestAction) {
      return requestStatus !== RequestStatus.active && requestStatus !== RequestStatus.completed;
    } else if (KYDRequestActions.signoff === requestAction) {
      return (
        requestStatus !== RequestStatus.active || row?.ddqProgress !== 100 || questionnaireStatus !== DDQStatus.draft
      );
    } else if (KYDRequestActions.assign === requestAction) {
      return requestStatus !== RequestStatus.active || questionnaireStatus !== DDQStatus.signed;
    } else if (KYDRequestActions.reopen === requestAction) {
      return questionnaireStatus !== DDQStatus.issued && questionnaireStatus !== DDQStatus.completed;
    } else if (KYDRequestActions.completed === requestAction) {
      return requestStatus !== RequestStatus.active || questionnaireStatus !== DDQStatus.issued;
    } else if (KYDRequestActions.drafting === requestAction) {
      return (
        !['ACTIVE', 'SIGNOFF', 'COMPLETED'].includes(requestStatus) ||
        !['OPEN', 'REQUESTSIGNOFF', 'SIGNED', 'ISSUED', 'COMPLETED'].includes(questionnaireStatus)
      );
    } else if (KYDRequestActions.progress100 === requestAction) {
      return (
        requestStatus !== RequestStatus.active ||
        (request?.details?.form?.progress === 100 && request?.details?.form?.liveProgress === 100)
      );
    } else if (KYDRequestActions.manuallysignoff === requestAction) {
      return (
        requestStatus !== RequestStatus.active ||
        questionnaireStatus === DDQStatus.signed ||
        questionnaireStatus === DDQStatus.completed
      );
    } else {
      return true;
    }
  }

  updateRequestStatus(request, newStatus: RequestStatus) {
    this.allActionsDisabled = true;

    request.status = newStatus;
    this.sub.add(
      this.apiService.put<null, Request>(`ddq/requests/${request.id}/status/${newStatus}`, null).subscribe(() => {
        this.refreshSubject.next(true);
      })
    );
  }

  updateDDQStatus(request, newStatus: DDQStatus, subscriber = null) {
    this.allActionsDisabled = true;
    request.details.form.status = newStatus;

    if (subscriber) {
      this.sub.add(
        this.apiService
          .put<KYDRequestData, Request>(`ddq/requests/${request.id}/details`, request.details)
          .subscribe(subscriber)
      );
    } else {
      this.sub.add(
        this.apiService
          .put<KYDRequestData, Request>(`ddq/requests/${request.id}/details`, request.details)
          .subscribe(() => {
            this.refreshSubject.next(true);
          })
      );
    }
  }

  async updateRequestStatusAsync(request, newStatus: RequestStatus) {
    this.allActionsDisabled = true;
    request.status = newStatus;

    return this.apiService.put<null, Request>(`ddq/requests/${request.id}/status/${newStatus}`, null).toPromise();
  }

  async updateDDQStatusAsync(request, newStatus: DDQStatus) {
    this.allActionsDisabled = true;
    request.details.form.status = newStatus;

    return this.apiService
      .put<KYDRequestData, Request>(`ddq/requests/${request.id}/details`, request.details)
      .toPromise();
  }

  getRequestFromDistributorIdAndRequestType(distributorId: string, requestType: string): Observable<any> {
    return this.apiService.get(`ddq/request-from-distributor-id-and-type/${distributorId}/${requestType}`);
  }

  updateDDQProgress(request, newProgressPercentage, noRefresh = null) {
    request.details.form.progress = newProgressPercentage;
    this.sub.add(
      this.apiService
        .put<KYDRequestData, Request>(`ddq/requests/${request.id}/details`, request.details)
        .subscribe((res) => {
          if (!noRefresh) {
            this.refreshSubject.next(true);
          }
        })
    );
  }

  updateDDQDetails(request, newDetails: KYDRequestData) {
    return this.apiService.put<KYDRequestData, Request>(`ddq/requests/${request.id}/details`, newDetails);
  }

  updateRequestDetails(id, newDetails: kydRequestDetails) {
    return this.apiService.put<kydRequestDetails, Request>(`ddq/requests/${id}/details`, newDetails);
  }

  getRequestDetails(id) {
    return this.apiService.get<any>(`ddq/requests/${id}/details`);
  }

  reopenDDQ(requestID: string): Observable<any> {
    return this.apiService.post<any, any>(`ddq/requests/${requestID}/reopen`, null);
  }

  reopenDDQForDrafting(requestID: string): Observable<any> {
    return this.apiService.post<any, any>(`ddq/requests/${requestID}/draft`, null);
  }

  progressTo100(requestID: string): Observable<any> {
    return this.apiService.put<any, any>(`ddq/requests/${requestID}/progress100`, null);
  }

  signOffManually(requestID: string, date: string): Observable<any> {
    return this.apiService.put<any, any>(`ddq/requests/${requestID}/signoff/${date}`, null);
  }

  fetchAllRequests(): Observable<Request[]> {
    return this.apiService.get<Request[]>('ddq/requests');
  }

  fetchAllOffPlatformRequests(): Observable<Request[]> {
    return this.apiService.get<Request[]>('ddq/requests/offline');
  }

  fetchAllRequestsCombined(): Observable<Request[]> {
    return this.apiService.get<Request[]>('ddq/requests/combined');
  }

  getRequestIDsByDistributorId(distributorId: string): Observable<any[]> {
    return this.apiService.get<any[]>(`ddq/requests-by-distributor/${distributorId}`);
  }

  getRequestTypesByDistributorId(distributorId: string): Observable<any[]> {
    return this.apiService.get<any[]>(`ddq/requests-types-by-distributor/${distributorId}`);
  }

  getKydRequestByIssuerAndDistributor(issuerId: string, distributorId: string): Observable<any[]> {
    return this.apiService.get<any[]>(`ddq/requests/kyd/issuer/${issuerId}/distributor/${distributorId}`);
  }

  getRequestTypeById(requestId: string): Observable<any[]> {
    return this.apiService.get<any[]>(`ddq/requests/${requestId}/type`);
  }

  addRequest(request): Observable<Request> {
    return this.apiService.post<any, any>('ddq/requests', request);
  }

  fetchDirtyRequests(filters?): Observable<any> {
    return this.apiService.get<any[]>('ddq/dirty-requests').pipe(
      // we rename salesRegion key to be salesregion inline with issuerrequest DB fields to combine with non-dirty requests
      // then filter them by salesDesk and salesregion
      map((arr) => {
        return arr
          .map((request) => Object.assign({}, request, { salesregion: request.salesRegion }))
          .filter((x) => {
            if (x.handled) {
              return false;
            } else {
              if (filters) {
                return filters.salesdesk.includes(x.salesDesk) && filters.salesregion.includes(x.salesregion);
              } else return true;
            }
          });
      })
    );
  }

  getDirtyRequestByID(dirtyRequestID: string): Observable<any> {
    return this.apiService.get<any>(`ddq/dirty-requests/${dirtyRequestID}`);
  }

  resubmitDirtyRequest(postData: any): Observable<any> {
    return this.apiService.post(`ddq/issuer-portal-request`, postData);
  }

  fetchIssuerConnectionsRequests(filters): Observable<any> {
    let issuerRequestResponse;

    if (this.cacheService.getData().issuerRequests) {
      return of(this.cacheService.getData().issuerRequests);
    } else {
      if (this.cacheService.getData().contacts) {
        this.contacts = this.cacheService.getData().contacts;
        return combineLatest(
          this.apiService.get<any[]>('ddq/connections/issuer').pipe(
            map((arr) =>
              arr.filter((x) => {
                if (x.salesDesk === null && x.salesregion === null) {
                  return true;
                } else if (x.salesDesk === null) {
                  return filters.salesregion.includes(x.salesregion);
                } else if (x.salesregion === null) {
                  return filters.salesdesk.includes(x.salesDesk);
                } else {
                  return filters.salesdesk.includes(x.salesDesk) && filters.salesregion.includes(x.salesregion);
                }
              })
            ),

            switchMap((issuerRequests: any) => {
              issuerRequestResponse = issuerRequests.map((item) => {
                return {
                  ...item,
                  distributorAddress: { ...item.distributorAddress },
                  distributorContact: [
                    ...item.distributorContact.map((id) => {
                      return this.contacts.filter((contact) => contact.id === id).map((obj) => obj.name);
                    }),
                  ],
                };
              });

              return of(issuerRequestResponse);
              // .pipe(
              //   mergeMap((issuerRequest) =>
              //     combineLatest(
              //       issuerRequest.distributorContact.map((contact) =>
              //         this.distributorsService.getDistributorContact(contact)
              //       )
              //     ).pipe(
              //       map((distributorContacts: [Contact]) => {
              //         issuerRequest.distributorContact = distributorContacts
              //           .filter((distContact) => distContact !== null)
              //           .map((distContact) => distContact.name);
              //         return issuerRequest;
              //       })
              //     )
              //   ),
              //   toArray()
              // );
            })
          ),
          this.fetchDirtyRequests(filters)
        );
      } else {
        this.waitService.waitFor(
          this.distributorsService
            .getDistributorsContactsByIssuerUserID(filters.userID)
            .toPromise()
            .then((data) => {
              this.contacts = data;
              this.cacheService.addData({ contacts: data });
            })
            .catch((err) => console.error(err))
        );

        return combineLatest(
          this.apiService.get<any[]>('ddq/connections/issuer').pipe(
            map((arr) =>
              arr.filter((x) => {
                if (x.salesDesk === null && x.salesregion === null) {
                  return true;
                } else if (x.salesDesk === null) {
                  return filters.salesregion.includes(x.salesregion);
                } else if (x.salesregion === null) {
                  return filters.salesdesk.includes(x.salesDesk);
                } else {
                  return filters.salesdesk.includes(x.salesDesk) && filters.salesregion.includes(x.salesregion);
                }
              })
            ),
            switchMap((issuerRequests: any) => {
              issuerRequestResponse = issuerRequests.map((item) => {
                return {
                  ...item,
                  distributorAddress: { ...item.distributorAddress },
                  distributorContact: [
                    ...item.distributorContact.map((id) => {
                      if (this.contacts) {
                        return this.contacts.filter((contact) => contact.id === id).map((obj) => obj.name);
                      } else {
                        return [];
                      }
                    }),
                  ],
                };
              });
              // console.log(issuerRequestResponse, 'issuerRequestResponse!');
              return of(issuerRequestResponse);
            })
          ),
          this.fetchDirtyRequests(filters)
        );
      }
    }
  }

  reFetchIssuerConnectionsRequests(filters): Observable<any> {
    let issuerRequestResponse;
    this.sub.add(
      this.distributorsService.getDistributorsContactsByIssuerUserID(filters.userID).subscribe((data) => {
        // console.log(data, 'CONTACTS FILTERED!!!!!!');
        this.contacts = data;
        this.cacheService.addData({ contacts: data });
      })
    );
    return combineLatest(
      this.apiService.get<any[]>('ddq/connections/issuer').pipe(
        map((arr) =>
          arr.filter((x) => {
            if (x.salesDesk === null && x.salesregion === null) {
              return true;
            } else if (x.salesDesk === null) {
              return filters.salesregion.includes(x.salesregion);
            } else if (x.salesregion === null) {
              return filters.salesdesk.includes(x.salesDesk);
            } else {
              return filters.salesdesk.includes(x.salesDesk) && filters.salesregion.includes(x.salesregion);
            }
          })
        ),
        switchMap((issuerRequests: any) => {
          issuerRequestResponse = issuerRequests.map((item) => {
            return {
              ...item,
              distributorAddress: { ...item.distributorAddress },
              distributorContact: [
                ...item.distributorContact.map((id) => {
                  if (this.contacts) {
                    return this.contacts.filter((contact) => contact.id === id).map((obj) => obj.name);
                  } else {
                    return [];
                  }
                }),
              ],
            };
          });
          return of(issuerRequestResponse);
        })
      ),
      this.fetchDirtyRequests(filters)
    );
  }

  // Only used by devs
  deleteRequest(requestID: string) {
    this.getRequest(requestID, (request) => {
      this.sub.add(
        this.issuerRequestsService.deleteIssuerRequestsAgainstARequest(request.id).subscribe(() => {
          this.sub.add(
            this.apiService.delete(`ddq/requests/${requestID}`).subscribe((res) => {
              console.log(res);
              this.refreshSubject.next(true);
              // to refresh both requests and issuer-requests after reload, hard reload, dev only temp feature
              window.location.reload();
            })
          );
        })
      );
    });
  }

  deleteAllRequests() {
    this.sub.add(
      this.apiService.delete('ddq/requests').subscribe((res) => {
        console.log(res);
      })
    );
  }

  fetchAllUnresolvedComments(formID): Observable<any[]> {
    if (formID) {
      if (this.cacheService.getData().commentsCount) {
        return of(this.cacheService.getData().commentsCount.filter((comment) => comment.formid === formID));
      } else {
        return this.apiService
          .get<any>(`ddq/threads/count/open`)
          .pipe(map((res) => res.filter((x) => x.formid === formID)));
      }
    } else {
      return of(null);
    }
  }

  sendEmailToInspire(emailSubject, emailBody): Observable<any> {
    return this.apiService.post<any, any>(`ddq/ses/internal`, {
      emailSubject: emailSubject,
      emailBody: emailBody,
    });
  }

  getRequestInTableFormat(requestKey: string, subscriber) {
    this.sub.add(this.getRequestAPICall(requestKey).pipe(this.formatRequestForUITable()).subscribe(subscriber));
  }

  // This is a utility function that will likely not be used again!
  updateAllRequestsToMultiContact() {
    this.sub.add(
      this.getAllRequests().subscribe((requests) => {
        const allRequests = requests.map((request) => request.request);
        allRequests.map((request) => {
          request.details.distributor.contact = [request.details.distributor.contact];
          this.sub.add(this.updateDDQDetails(request, request.details).subscribe());
        });
      })
    );
  }

  // This is a utility function that will likely not be used again!
  updateAllRequestsToAllowMultipleIssuers() {
    this.sub.add(
      this.getAllRequests().subscribe((requests) => {
        const allRequests = requests.map((request) => request.request);
        allRequests.map((request) => {
          request.details.requester.name = [request.details.requester.name];
          this.sub.add(this.updateDDQDetails(request, request.details).subscribe());
        });
      })
    );
  }

  // This is a utility function that will likely not be used again!
  addIssuersToExistingRequestsThatAreMissing() {
    this.sub.add(
      this.getAllRequests().subscribe((requests) => {
        const allRequests = requests.map((request) => request.request);
        allRequests.map((request) => {
          this.connectionService
            .getConnectionsForDistributor(request.details.distributor.name)
            .subscribe((foundConnections) => {
              foundConnections.forEach((connection) => {
                if (!request.details.requester.name.includes(connection.issuerid)) {
                  request.details.requester.name.push(connection.issuerid);
                }
              });
              this.sub.add(this.updateDDQDetails(request, request.details).subscribe());
            });
        });
      })
    );
  }

  pollToSeeIfOtherPartyHasReturnedControl(requestKey: string, goalStatus: DDQStatus[]) {
    timer(15000)
      .pipe(takeWhile(() => this.alive))
      .subscribe((_) => {
        this.getRequest(requestKey, (request) => {
          const currentDDQStatus = (request as unknown as KYDRequestData).details.form.status;

          if (goalStatus.includes(currentDDQStatus)) {
            if (goalStatus.includes(DDQStatus.draft)) {
              this.draftReleased.next();
            } else if (goalStatus.includes(DDQStatus.open)) {
              this.reviewedDraftReturned.next();
            }
          } else {
            this.pollToSeeIfOtherPartyHasReturnedControl(requestKey, goalStatus);
          }
        });
      });
  }

  //   setTimeout(async () => {
  //     console.log('polling to see if other party has returned control of the request to us...')
  //     this.getRequest(requestKey, (request) => {
  //       const currentDDQStatus = (request as unknown as KYDRequestData).details.form.status;

  //       if (goalStatus.includes(currentDDQStatus)) {
  //         if (goalStatus.includes(DDQStatus.draft)) {
  //           this.draftReleased.next();
  //         } else if (goalStatus.includes(DDQStatus.open)) {
  //           this.reviewedDraftReturned.next();
  //         }
  //       } else {
  //         this.pollToSeeIfOtherPartyHasReturnedControl(requestKey, goalStatus);
  //       }
  //     });
  //   }, 15000);
  // }

  ddqIsPostSignoff(ddqStatus: DDQStatus) {
    return ddqStatus !== DDQStatus.open && ddqStatus !== DDQStatus.draft && ddqStatus !== DDQStatus.requestSignoff;
  }

  openReleaseDraftModal() {
    return this.modalService.openDialog({
      title: 'Release Draft',
      body: `
        <p>Are you sure you want to release this draft?</p>
        <p>InSPire will be able to access and review your answers and comments.</p>
        <p>The questionnaire will also be locked for editing upon releasing this draft. It will become editable again once InSPire releases the draft back to you.</p>
      `,
      buttons: {
        negative: 'Cancel',
        positive: 'Release Draft',
      },
      size: 'md',
      backdrop: true,
    });
  }

  openReleaseToDistributorModal() {
    return this.modalService.openDialog({
      title: 'Release to Distributor',
      body: `
        <p>Are you sure you want to release the DDQ back to the distributor?</p>
        <p>You will be unable to edit the DDQ until the distributor releases a draft again, and they will be able to view any changes you've made.</p>
      `,
      buttons: {
        negative: 'Cancel',
        positive: 'Release',
      },
      size: 'md',
      backdrop: true,
    });
  }

  openMakeChangesModal() {
    return this.modalService.openDialog({
      title: 'Make Changes',
      body: `
         <p>Are you sure you want to make changes to the questionnaire?</p>
         <p>
            If you make any changes a new draft will have to be released for review in order to start the sign off process
            again.
         </p>
      `,
      buttons: {
        negative: 'Cancel',
        positive: 'Make Changes',
      },
      size: 'md',
      backdrop: true,
    });
  }

  openConfirmSignoffModal() {
    return this.modalService.openDialog({
      title: 'Sign Off',
      body: `
         <p>Are you sure you want to sign off your due diligence questionnaire?</p>
         <p>Once you've signed off, you won't be able to make any changes to this version of the questionnaire.</p>
      `,
      buttons: {
        negative: 'Cancel',
        positive: 'Sign Off',
      },
      size: 'md',
      backdrop: true,
    });
  }

  openAfterSignOffModal() {
    return this.modalService.openDialog({
      title: 'Questionnaire Signed Off',
      body: `
        <div class="text-center">
          <img src="assets/green-tick.svg"/>
        </div>
        <br/>
        <h1 class="d-flex align-items-center justify-content-center mb-4">Questionnaire Signed Off</h1>
        <p class="d-flex align-items-center justify-content-center">Thank you for completing your Due Diligence check with InSPire.</p>

        <p class="d-flex align-items-center justify-content-center">
          If you would like to get in touch please reach out to:</p>
          <p><a
            class="d-flex align-items-center justify-content-center mt-1"
            href="mailto:inspirekyd@deltacapita.com"
            >inspirekyd@deltacapita.com</a
          >
        </p>
      `,
      buttons: {
        negative: 'Close',
      },
      size: 'md',
      backdrop: true,
    });
  }

  doWeHandleRequestOnPlatform(requestType, region, formId = false, createdAt) {
    if (!formId) {
      return false;
    } else {
      // return this.isEMEAOrAMERAndKYD(requestType, region, (createdAt = null));
      // temp returns true by removing cut off date
      return true;
    }
  }

  isEMEAOrAMERAndKYD(requestType, region, createdAt = null) {
    if (requestType !== 'KYD') {
      return false;
    } else if (region === 'APAC') {
      return false;
    } else if (region === 'EMEA') {
      return true;
    } else if (region === 'AMER') {
      if (createdAt) {
        return new Date(createdAt) > new Date('2023-10-31') && requestType === 'KYD';
      } else {
        return true;
      }
    }
  }

  setDownloadCsvSubject(value: string | any): void {
    this.csvDownloadSubject.next(value);
  }

  async getExitDate(requestID) {
    return await this.apiService.get(`ddq/requests/${requestID}/exit-signed`).toPromise();
  }

  startTimer(id) {
    return this.apiService.patch(`ddq/requests/${id}/start-timer`, null);
  }

  setActionChaserStartDate(id, date) {
    return this.apiService.patch(`ddq/requests/${id}/action-chaser-start-date/${date}`, null);
  }

  getRequestersByRequestId(requestId) {
    return this.apiService.get(`ddq/requests/${requestId}/requesters`);
  }
}
