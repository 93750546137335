import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dc-pane',
  templateUrl: './dc-pane.component.html',
  styleUrls: ['./dc-pane.component.scss'],
})
export class DcPaneComponent implements OnInit {
  constructor() {}
  @Input() noPadding: boolean;
  @Input() headerSearch: boolean;
  @Input() tableContainer: boolean;
  @Input() noHeader: boolean;
  @Input() filters: boolean;
  @Input() loading: boolean;
  @Input() noScrollBar: boolean;
  @Input() noScroll: boolean;
  ngOnInit(): void {}
  scroll(el: HTMLElement) {
    console.log('clicked')
    el.scrollIntoView();
}
}
