<nav class="navbar navbar-expand-sm navbar-dark bg-dark">
  <button
    id="navBar"
    class="navbar-toggler border-0 pl-0 pr-3"
    [class.open]="!navbarMenuCollapsed"
    type="button"
    data-toggle="collapse"
    data-target="#navbarOptions"
    aria-controls="navbarOptions"
    aria-expanded="false"
    aria-label="Toggle navigation"
    (click)="navbarMenuButton()"
  >
    <span class="line"></span>
    <span class="line"></span>
    <span class="line"></span>
  </button>
  <div class="navbar-brand logo navbar-brand logo"></div>
  <div class="collapse navbar-collapse" [ngbCollapse]="navbarMenuCollapsed" [animation]="false" id="navbarOptions">
    <ul ngbNav [activeId]="activeRoute" class="navbar-nav mr-auto flex-nowrap">
      <!-- <li class="nav-item" ngbNavItem="ddq" *ngIf="loggedInAsDistributor">
        <a ngbNavLink class="nav-link" routerLink="ddq">Profile</a>
      </li> -->
      <li class="nav-item" *ngIf="loggedInAsDistributor">
        <a class="nav-link" routerLinkActive="active" routerLink="profile">Profile</a>
      </li>
      <li class="nav-item" ngbNavItem="issuer-portal" *ngIf="loggedInAsIssuer">
        <a class="nav-link" routerLinkActive="active" routerLink="issuer-portal">Profile</a>
      </li>
      <li class="nav-item" *ngIf="loggedInAsInspire">
        <a class="nav-link" routerLinkActive="active" routerLink="requests">Requests</a>
      </li>
      <li class="nav-item" *ngIf="loggedInAsInspire">
        <a class="nav-link" routerLinkActive="active" routerLink="connections">Connections</a>
      </li>
      <li class="nav-item" *ngIf="loggedInAsInspire">
        <a class="nav-link" routerLinkActive="active" routerLink="issuer-requests">Issuer Requests</a>
      </li>
      <!-- <li class="nav-item"  *ngIf="loggedInAsInspire && activeRoute != 'files'">
        <a  class="nav-link" routerLinkActive="active" routerLink="distributors">Distributors</a>
      </li> -->
      <li class="nav-item" *ngIf="loggedInAsInspire">
        <a
          class="nav-link"
          [class.active1]="loggedInAsInspire && activeRoute == '/files'"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
          routerLink="distributors"
          >Distributors</a
        >
      </li>
      <!-- <li class="nav-item" ngbNavItem="distributor-contacts" *ngIf="loggedInAsInspire">
        <a ngbNavLink class="nav-link" routerLink="distributor-contacts">Distributor Contacts</a>
      </li> -->
      <li class="nav-item" routerLinkActive="active" *ngIf="loggedInAsInspire">
        <a class="nav-link" routerLinkActive="active" routerLink="issuers">Issuers</a>
      </li>
      <!-- <li class="nav-item" ngbNavItem="issuer-contacts" *ngIf="loggedInAsInspire">
        <a ngbNavLink class="nav-link" routerLink="issuer-contacts">Issuer Contacts</a>
      </li> -->
      <!-- <li class="nav-item" ngbNavItem="contacts" *ngIf="loggedInAsInspire">
        <a ngbNavLink class="nav-link" routerLink="contacts">Contacts</a>
      </li> -->
      <li class="nav-item" *ngIf="adminGuard.hasAccess && loggedInAsInspire">
        <a class="nav-link" routerLinkActive="active" routerLink="issuer-portal/review-requests">Review Requests</a>
      </li>
      <li class="nav-item" *ngIf="adminGuard.hasAccess && loggedInAsInspire">
        <a class="nav-link" routerLinkActive="active" routerLink="admin">Admin</a>
      </li>
    </ul>
  </div>
  <div
    *ngIf="email"
    class="userContainer d-flex cursor-pointer"
    (click)="userPanelCollapsed = !userPanelCollapsed; navbarMenuCollapsed = true"
    id="userPanel"
    #toggleButton
    data-toggle="collapse"
    data-target="#userPanelCollapsed"
    aria-controls="userPanelCollapsed"
    aria-expanded="false"
    aria-label="Toggle navigation"
    [attr.aria-expanded]="!userPanelCollapsed"
  >
    <div class="userName text-large d-none d-sm-block mr-1">{{ user }}</div>
    <div class="ml-2 userIcon"></div>
  </div>
  <div class="userPanel" #userMenu [ngbCollapse]="userPanelCollapsed" [animation]="false" *ngIf="email">
    <div class="p-3 border-bottom border-faded12">
      <div class="userContainer d-flex">
        <div class="userIcon"></div>
        <div class="userInfo">
          <div class="userName text-large text-truncate text-white pl-2">{{ user }}</div>
          <div class="userEmail text-small text-truncate pl-2">{{ email }}</div>
          <div class="userEmail text-small text-truncate pl-2">{{ role }}</div>
        </div>
      </div>
    </div>
    <div class="section p-3" style="display: flex; flex-flow: row wrap; justify-content: space-between">
      <button class="btn btn-link text-white p-0" (click)="signOut()">Sign Out</button>

      <button
        class="btn btn-link text-white p-0"
        *ngIf="role === 'Manager' || role === 'Sales'"
        (click)="openAddModal(issuerUserSettingsDataModal)"
      >
        Settings
      </button>
    </div>
  </div>

  <a href="/" *ngIf="!email" title="Login">Login</a>
</nav>

<ng-template #issuerUserSettingsDataModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Settings</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">Close</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="issuerUserSettingsForm" (ngSubmit)="submitSettings()">
      <div class="form-group icon">
        <app-dc-checkbox
          [id]="'checkbox1'"
          formControlName="newDocNotification"
          label="Receiving New Document Notification Email"
        ></app-dc-checkbox>
      </div>
      <div class="m-3 d-flex align-items-center justify-content-center">
        <button class="btn btn-primary btn-sm" type="submit" [disabled]="!issuerUserSettingsForm.valid">Confirm</button>
      </div>
    </form>
  </div>
</ng-template>
