import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

// in the config, when setting the groupArr validator for checkbox groups...
// if there is a single group of checkboxes, use a flat array,
// if there is two or more checkbox groups in the same question, the first group should be flat, the second and third etc should be nested
// eg. groupArr: ['80d9d37b-bb4b-4a50-b964-fd456dcf6b19',  '60c28e3b-1d76-42cd-8dc5-ea19d5360f94', ['8e3afd98-7c02-484b-a30f-933e5abd7815', 'b98b2f0e-4f36-49f2-93cf-7099f7c17c89']]

export function enableDisableValidator(field: any, allRadioFields: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const parent = control.parent;
    if (parent) {
      allRadioFields.forEach(element => {
        element?.options.forEach((option) => {
          if (control.value == option.label) {
            if (option.conditional && option.conditional.enable) {
              // enable here
              Object.keys(parent?.controls).forEach((key) => {
                const control = parent.controls[key];
                if (option.conditional.enable.includes(key)) {
                  control.enable();
                  control.updateValueAndValidity();
                }
              });
            }
            if (option.conditional && option.conditional.disable) {
              // disable here
              Object.keys(parent?.controls).forEach((key) => {
                const control = parent.controls[key];
                if (option.conditional.disable.includes(key)) {
                  control.disable();
                  control.reset();
                  control.updateValueAndValidity();
                }
              });
            }
          }
        });
      });
      

      if (true) {
        return null;
      }
    }
  };
}
