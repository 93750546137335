import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from '../auth/services/auth.service';
import { mergeMap, shareReplay, switchMap, take, toArray } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UserInfo } from '../admin/model/user';
import { GroupName } from '../admin/model/group';
import { AddUserRequest, DeleteUserRequest, UpdateUserRequest } from '../admin/model/auth-requests';
import { UserStatus } from '../kyd/model/contact';

interface CognitoUserInfo {
  user: {
    Username: string;
    Attributes: { Name: string; Value: string }[];
    Enabled: boolean;
    Name: string;
    Email: string;
    UserStatus: string;
  };
  groups: GroupName[];
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private apiService: ApiService, private authService: AuthService) {}

  users(): Observable<UserInfo[]> {
    return this.authService
      .administeredAccounts()
      .pipe(
        switchMap((accounts: string[]) => {
          return of(...accounts);
        })
      )
      .pipe(
        mergeMap((account) => {
          return this.apiService.get(`ddq/user/list`).pipe(
            switchMap((cognitoUsers: any[]) => {
              const users: UserInfo[] = cognitoUsers.map((user) => {
                const id = user.id;
                const email = user.email;
                const name = user.name;
                return {
                  id,
                  account,
                  username: email || name,
                  name,
                  email,
                  enabled: user.enabled ? true : false,
                  status: user.status,
                  groups: user.groups,
                };
              });
              return of(...users);
            })
          );
        })
      )
      .pipe(toArray())
      .pipe(shareReplay(1));
  }

  updateUser(account: string, email: string, username: string, groups: string[]) {
    const request: UpdateUserRequest = {
      groups: groups,
    };
    return this.apiService.post('auth/admin/' + account + '/user/' + username, request);
  }

  deleteUser(account: string, username: string) {
    return this.apiService.delete('auth/admin/' + account + '/user/' + username);
  }

  deactivateUser(account: string, username: string) {
    return this.apiService.delete('auth/admin/' + account + '/deactivate-user/' + username);
  }

  addUser(account: string, email: string, username: string, groups: string[]) {
    const request: AddUserRequest = {
      user: {
        user: username,
        email: email,
      },
      groups: groups,
    };
    return this.apiService.post('auth/admin/' + account + '/user', request);
  }

  addUserWithTemplate(account: string, email: string, username: string, groups: string[], metadataInfo, templateID) {
    const request: AddUserRequest = {
      user: {
        user: username,
        email: email,
        metadata: Object.assign(
          {},
          {
            templateId: templateID,
          },
          metadataInfo
        ),
      },
      groups: groups,
    };
    return this.apiService
      .post('auth/admin/' + account + '/user', request)
      .toPromise()
      .then(async (res: any) => {
        if (res.status === 'OK') {
          const userToBeAdd = {
            id: res.details.user.User.Username,
            name: (
              res.details.user.User.Attributes.find((x) => x.Name === 'name') ||
              res.details.user.User.Attributes.find((x) => x.Name === 'email')
            ).Value,
            email: res.details.user.User.Attributes.find((x) => x.Name === 'email').Value,
            enabled: res.details.user.User.Enabled ? 1 : 0,
            status: res.details.user.User.UserStatus,
            groups: res.details.groups,
          };
          return await this.uploadUserIntoCache(userToBeAdd, userToBeAdd.id)
            .then(() => {
              console.log('user added into cognito cache...');
            })
            .catch((err) => {
              console.log(err, 'Error adding user into cognito cache table...');
            });
        } else return res;
      });
  }

  async getUserDetails(userIDs: [string]): Promise<any> {
    return new Promise((resolve) => {
      const commaJoinedUserIDs = userIDs.join(',');
      this.apiService.get(`ddq/users?ids=${commaJoinedUserIDs}`).subscribe((userDetails) => {
        resolve(userDetails);
      });
    });
  }

  getLastLogin(username: string): Observable<any> {
    return this.apiService.get<any>(`ddq/user/last-login/${username}`);
  }

  getCognitoUserIdFromPool(allUsers: any[], email) {
    const targetUser = allUsers.find((userInfo) => userInfo.email === email);
    if (targetUser) {
      return targetUser.id;
    } else {
      return null;
    }
  }

  listUsersFromCognitoPool(): Observable<UserInfo[]> {
    return this.authService
      .administeredAccounts()
      .pipe(
        switchMap((accounts: string[]) => {
          return of(...accounts);
        })
      )
      .pipe(
        // old get users directly from cognito - very slow ...
        mergeMap((account) => {
          return this.apiService.get('auth/admin/' + account + '/user/list').pipe(
            switchMap((cognitoUsers: CognitoUserInfo[]) => {
              const users: UserInfo[] = cognitoUsers.map((cognitoUser) => {
                const user = cognitoUser.user;
                const id = user.Username;
                const sub = user.Attributes.find((a) => a.Name === 'sub')?.Value;
                const email = user.Attributes.find((a) => a.Name === 'email')?.Value;
                const name = user.Attributes.find((a) => a.Name === 'name')?.Value;
                const username = user.Username;
                return {
                  id,
                  account,
                  username: username === sub ? email : username,
                  name,
                  email,
                  enabled: user.Enabled,
                  status: user.UserStatus,
                  groups: cognitoUser.groups,
                };
              });
              return of(...users);
            })
          );
        })
      )
      .pipe(toArray())
      .pipe(shareReplay(1));
  }

  // routes start with 'ddq/user' below are all for the cognito users cache in sql db, not the actual aws cognito pool.
  async uploadUserIntoCache(user: any, id: string) {
    return await this.apiService.post<any, any>(`ddq/user/${id}`, user).toPromise();
  }

  getUserStatusFromCognitoPool(id: string) {
    return this.apiService.get<any>(`ddq/user/status/${id}`);
  }

  getUserGroupsFromCognitoPool(id: string) {
    return this.apiService.get<any>(`ddq/user/groups/${id}`);
  }

  async patchUserDetails(id: string, status: string, groups: string[]) {
    return await this.apiService.put<any, any>(`ddq/user/${id}/details`, { status, groups }).toPromise();
  }

  async patchUserStatus(id: string, status: string) {
    return await this.apiService.put<any, any>(`ddq/user/${id}/status`, { status }).toPromise();
  }

  async deleteUserInCache(id: string) {
    return await this.apiService.delete(`ddq/user/${id}`).toPromise();
  }

  mapStatus = (contact: any, status) => {
    if (contact.cognitoUserId) {
      if (status === 'CONFIRMED') {
        return UserStatus.active;
      } else return UserStatus.notLoggedOnYet;
    } else return UserStatus.notCreated;
  };

  async syncCacheWithCognitoPool() {
    let count = 0;
    let error = 0;
    let errorLogs = [];
    const cognitoUsers: any[] = await this.listUsersFromCognitoPool().toPromise();
    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
    const cachedUsers: any[] = await this.users().toPromise();

    if (cognitoUsers && Array.isArray(cognitoUsers) && cognitoUsers.length) {
      for (const user of cachedUsers) {
        if (!cognitoUsers.map((x) => x.id).includes(user.id)) {
          console.log(user.id, 'id to be deleted');
          await this.deleteUserInCache(user.id);
        }
      }

      for (let user of cognitoUsers) {
        await sleep(100);
        console.log(`caching user No. ${count}...`);
        await this.uploadUserIntoCache(user, user.id)
          .then(() => count++)
          .catch((e) => {
            errorLogs.push(e);
            error++;
          });
      }
    } else {
      console.log('error fetching users from cognito pool, please try again!');
    }
    console.log(
      'all cognito pool users count: ',
      cognitoUsers.length,
      'cached users count: ',
      count,
      'with errors count: ',
      error,
      'with error logs: ',
      errorLogs
    );
  }
}
