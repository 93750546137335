import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { DATAPROTECTIONADDENDUM } from './data-protection-addendum-text';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-data-protection-addendum',
  templateUrl: './data-protection-addendum.component.html',
  styleUrls: ['./data-protection-addendum.component.scss'],
  host: {'class': 'printDoc'}
})
export class DataProtectionAddendumComponent implements OnInit {
  text;
  public removeEventListener: () => void;
  constructor(private readonly sanitizer: DomSanitizer,private renderer: Renderer2, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.text = this.sanitizer.bypassSecurityTrustHtml(DATAPROTECTIONADDENDUM);
    this.removeEventListener = this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
      if (event.target instanceof HTMLAnchorElement) {
        this.handleAnchorClick(event);
      }
    });
    
  }
  public handleAnchorClick(event: Event) {
    event.preventDefault();
    const anchor = event.target as HTMLAnchorElement;
    this.scroll(anchor.hash)
  }

  public scroll(element: any) {
    const hash = element.split('#')[1]
    const el: HTMLElement|null = document.getElementById(hash);
    if (el) {
      setTimeout(() =>
        el.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'}), 0);
    }
  }
}
