import { isObservable, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

declare const Zone: any;

@Injectable({
  providedIn: 'root',
})
export class WaitService {
  async waitFor<T>(prom: Promise<T>): Promise<T> {
    const macroTask = Zone.current.scheduleMacroTask(
      `WAITFOR-${Math.random()}`,
      () => {},
      {},
      () => {}
    );
    return prom.then((p: T) => {
      macroTask.invoke();
      return p;
    });
  }
}
