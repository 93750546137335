<div class="container centre-screen" *ngIf="displayed">
  <div class="login-panel">
    <div class="login-logo">
      <!-- <img class="inspire-logo" src="assets/inspire-white-magenta.svg" /> -->
    </div>
  <form id="setupMfaForm" class="text-center">
    <div class="login-title">MFA Setup</div>
    <a [href]="sanitizedUrl" title="Click QR code for authenticator URL">
    <qrcode [qrdata]="qrCode" [width]="240" [errorCorrectionLevel]="'M'"></qrcode>
  </a>
    <div class="alert alert-info text-left">
      Scan the image above into an authenticator app (e.g. Google Authenticator) and enter the code below to verify the
      account.
    </div>
    <div class="form-group text-left">
      <label for="totpVerificationCode" class="d-none">Verification Code</label>
      <input class="form-control form-control-lg" id="totpVerificationCode" type="text"
             [formControl]="verificationCode" required
             name="totpVerificationCode" placeholder="Verification code">
    </div>
    <div [hidden]="!errorMessage" class="alert alert-danger">
      {{errorMessage}}
    </div>
    <button [disabled]="!canVerifyMfa()"
            class="btn btn-primary btn-lg"
            (click)="verifyMfa()">
      <ng-container *ngIf="submitted">
        <span class="sign-in-progress spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
        Verifying Code
      </ng-container>
      <ng-container *ngIf="!submitted">
        Verify Code
      </ng-container>
    </button>
  </form>
  <div class="need-help">
   
  </div>
</div></div>
