import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dc-loading',
  templateUrl: './dc-loading.component.html',
  styleUrls: ['./dc-loading.component.scss']
})
export class DcLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
