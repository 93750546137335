// TODO adjust mock data to use ddq-config

export const mockInputQuestion = {
  "created": "test-id-input",
  "externalRef": "Y2",
  "fields": [
    {
      "type": "input",
      "validate": {
        "required": true,
      },
    }
  ],
  "questionnaire": "Question",
  "text": "What is the full legal name of the Distributor firm?"
}

export const mockCheckboxQuestion = {
  "created": "test-id-checkbox",
  "externalRef": "C3",
  "fields": [
    {
      "options": [
        {
          "text": "Managing Conflicts of Interest"
        },
        {
          "text": "Anti-bribery / Anti-corruption"
        },
        {
          "text": "AML / KYC and Client Onboarding"
        },
        {
          "text": "Complaints Handling"
        },
        {
          "text": "Client Money Protection / Client Assets"
        },
        {
          "text": "Best Execution"
        },
        {
          "text": "Global Sanctions Lists"
        },
        {
          "text": "Conduct Risk / Code of Ethics"
        },
        {
          "fields": [
            {
              "type": "input",
              "validate": {
                "required": true,
              },
            }
          ],
          "text": "None of the above - please explain what steps are taken to ensure compliance with regulatory requirements:"
        }
      ],
      "type": "CheckboxGroup"
    }
  ],
  "questionnaire": "Question",
  "text": "Which of the following policies, procedures or guidelines does the distributor have in place?"
}

export const mockRadioButtonQuestion = {
  "created": "test-id-radio",
  "externalRef": "G4",
  "fields": [
    {
      "text": "Yes - please confirm if upon request or if there is a formal process:",
      "type": "RadioButtonOption",
      "validate": {
        "required": true,
      }
    },
    {
      "fields": [
        {
          "type": "Input",
          "validate": {
            "required": true,
          }
        }
      ],
      "text": "No - please provide the rationale below:",
      "type": "RadioButtonOption"
    }
  ],
  "questionnaire": "Question",
  "text": "Will the Distributor respond to issuer feedback requests to ensure Target Market remains appropriate and communicate any relevant product, investor feedback or complaints (if any)?",
  "type": "RadioButtonGroup"
}

export const mockRadioAndCheckboxQuestion = {
  "created": "test-id-complex-1",
  "externalRef": "E3",
  "fields": [
    {
      "options": [
        {
          "text": "N/A - distributor does not produce own marketing materials"
        },
        {
          "fields": [
            {
              "options": [
                {
                  "text": "Marketing Material Policy / Procedure / Guidance"
                },
                {
                  "text": "Materials reviewed by Legal / Compliance"
                },
                {
                  "text": "Materials approved by Legal / Compliance"
                },
                {
                  "fields": [
                    {
                      "type": "input",
                      "validate": {
                        "required": true,
                      }
                    }
                  ],
                  "text": "Other - please explain below:"
                }
              ],
              "type": "CheckboxGroup"
            }
          ],
          "text": "Yes - distributor produces own marketing materials"
        }
      ],
      "type": "RadioButtonGroup"
    }
  ],
  "questionnaire": "Question",
  "text": "If using Distributor’s own materials, what controls are in place to govern market material design and approval?"
}

export const mockRadioAndTextAreaQuestion = {
  "created": "test-id-complex-2",
  "externalRef": "B7",
  "fields": [
    {
      "options": [
        {
          "fields": [
            {
              "type": "TextArea",
              "validate": {
                "required": true,
              }
            }
          ],
          "Text": "Yes - confirm which entity is responsible and describe the limits/ thresholds below:"
        },
        {
          "fields": [
            {
              "type": "TextArea",
              "validate": {
                "required": true,
              }
            }
          ],
          "text": "No - please explain the rationale below:"
        }
      ],
      "type": "RadioButtonGroup"
    }
  ],
  "questionnaire": "Question",
  "text": "Does the entity responsible for the client’s portfolio have any policies regarding the level of diversification that must exist before investors can buy structured products?"
}

export const mockQuestionBank = [mockInputQuestion, mockCheckboxQuestion, mockRadioButtonQuestion, mockRadioAndCheckboxQuestion, mockRadioAndTextAreaQuestion];

export const mockEMEASkeleton = {
  "created": "0234-4543-0093-1342",
  "questionnaire": "Skeleton",
  "name": "EMEA_V1_SKELETON",
  "region": "EMEA",
  "sections": [
    {
      "questions": [
        "test-id-input"
      ],
      "text": "General Information"
    }
  ]
}

export const mockAMERSkeleton = {
  "created": "2355-3456-4443-2212",
  "questionnaire": "Skeleton",
  "name": "AMER_V2_SKELETON",
  "region": "AMER",
  "sections": [
    {
      "questions": [
        "test-id-input",
        "test-id-radio",
        "test-id-complex-2"
      ],
      "text": "General Information"
    }
  ]
}

export const mockAPACSkeleton = {
  "created": "1235-2364-6777-5545",
  "questionnaire": "Skeleton",
  "name": "APAC_V1_SKELETON",
  "region": "APAC",
  "sections": [
    {
      "questions": [
        "test-id-checkbox"
      ],
      "text": "General Information"
    }
  ]
}

export const mockSkeletonBank = [mockEMEASkeleton, mockAMERSkeleton, mockAPACSkeleton];
