import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDetails } from '../model/user';
import { map } from 'rxjs/operators';
import { UserPermission } from '../model/user-permission';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  currentUser: Observable<UserDetails | undefined> = this.authService.onUserSession.pipe(
    map((userDetails) => {
      const session = userDetails;
      const environment = userDetails?.environment;
      let result: UserDetails = undefined;

      if (session?.isValid()) {
        const email = session.idToken.payload.email;
        const groups = session.idToken.payload['cognito:groups'] || [];
        const permissions = JSON.parse(session.idToken.payload.permissions) as UserPermission;
        result = {
          id: session.accessToken.payload.username,
          name: session.idToken.payload.name,
          email,
          type: 'unknown',
          groups,
          clients: permissions.clients,
          distributors: permissions.distributors,
        };
        if (groups.includes('Administrator') && email.endsWith('deltacapita.com')) {
          result.type = 'inspire';
        } else {
          if (result.distributors.length > 0) {
            result.type = 'distributor';
          } else if (result.clients.length > 0) {
            result.type = 'issuer';
          } else {
            result.type = 'unknown';
          }
        }
        // if (environment === 'Prod' || environment === 'Demo' || environment === 'Test') {
        //   result.type = 'inspire'
        // } else {
        //   // if (email.endsWith('@deltacapita.com') || email.endsWith('@ext.deltacapita.com')) {
        //   //   // this.userType = 'distributor'
        //   //   // this.distributor$.next('distributor/06c52ebd-7001-4dbb-a31a-fb96e5001eff')
        //   //   // this.userType = 'client'
        //   //   // this.client$.next('client/d0fe0ad4-3a5e-40d2-8b88-2a34cfb44baa')
        //   //   result.type = 'inspire'
        //   //   // this.distributor$.next(undefined)
        //   //   // this.client$.next(undefined)
        //   // } else {
        //     if (permissions.clients.length === 0 && permissions.distributors.length === 1) {
        //         // TODO discuss with Paul
        //       if (groups.includes('ddqUser') || groups.includes('ddqAdministrator')) {
        //         result.type = 'distributor'
        //       } else {
        //         console.error('Unexpected user type: ')
        //         console.dir(session.idToken.payload)
        //         console.dir(permissions)
        //       }
        //     } else if (permissions.clients.length === 1 && permissions.distributors.length <= 1) {
        //       result.type = 'client'
        //     } else {
        //       result.type = 'unknown'
        //       console.error('Unexpected user type: ')
        //       console.dir(session.idToken.payload)
        //       console.dir(permissions)
        //     }
        //   // }
        // }
      }
      return result;
    })
  );

  constructor(private authService: AuthService) {}
}
