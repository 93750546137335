import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/ui-kit/dc-modal/modal.service';
import { PRIVACYPOLICY } from '../../../views/privacy-policy/privacy-policy-text';
import { TERMSOFUSE } from 'src/app/views/terms-of-use/terms-of-use-text';
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginFormComponent implements OnInit, OnDestroy {
  sub = new Subscription();

  displayed: boolean = false;
  submitted: boolean = false;

  faEyeSlash = faEyeSlash;
  faEye = faEye;

  username = new UntypedFormControl('', [Validators.required, Validators.email]);
  password = new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(64)]);

  showPassword: boolean;
  errorMessage: string;
  infoMessage: string;

  constructor(private authService: AuthService, private modalService: ModalService) {}

  ngOnInit(): void {
    this.sub.add(
      this.authService.onLoginRequired.subscribe((loginRequired) => {
        this.displayed = loginRequired;
        this.submitted = false;
      })
    );
    this.sub.add(
      this.authService.onAuthError.subscribe((err) => {
        this.errorMessage = err && err.message;
        this.submitted = false;
      })
    );

    this.username.reset();
    this.sub.add(
      this.username.valueChanges.subscribe(() => {
        this.errorMessage = undefined;
      })
    );

    this.password.reset();
    this.sub.add(
      this.password.valueChanges.subscribe(() => {
        this.errorMessage = undefined;
      })
    );
  }

  ngOnDestroy(): void {
    this.errorMessage = undefined;
    this.sub.unsubscribe();
  }

  canLogin() {
    return !this.submitted && this.username.valid && this.password.valid;
  }

  login() {
    this.submitted = true;
    this.authService.login(this.username.value, this.password.value.trim().replaceAll(/\n/g, ''));
  }
  forgotPassword() {
    this.authService.forgotPassword(this.username.value);
  }

  openPrivacyPolicy(text) {
    this.modalService
      .openDialog({
        title: text == 'privacy' ? 'Privacy Policy' : 'Terms of Use',
        body: text == 'privacy' ? PRIVACYPOLICY : TERMSOFUSE,
        buttons: {
          positive: 'Close',
          close: true,
        },
        size: 'xl',
        scrollable: true,
        windowClass: 'xlScrollableModal printDoc',
      })
      .then(
        (result) => {},
        (error) => {
          console.log(error);
        }
      );
  }
}
