import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListDistributorContactsComponent } from './list-distributor-contacts/list-distributor-contacts.component';
import { CreateDistributorContactComponent } from './create-distributor-contact/create-distributor-contact.component';
import { UiKitModule } from '../../ui-kit/ui-kit.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { GlobalComponentsModule } from '../global-components/global-components.module';
import { EditDistributorContactComponent } from './edit-distributor-contact/edit-distributor-contact.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ListDistributorContactsComponent, CreateDistributorContactComponent, EditDistributorContactComponent],
  imports: [
    CommonModule,
    UiKitModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbDropdownModule,
    NgbPopoverModule,
    GlobalComponentsModule,
    RouterModule
  ],
})
export class DistributorContactsModule {}
