import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransferService {
  public childInitCompleteSubject: Subject<void> = new Subject<void>();
  public isClicked: boolean = false;
  private data: any;
  private label: string;
  public notify = new BehaviorSubject<any>('');
  public toRefresh = new BehaviorSubject<any>('');
  public selectors = new BehaviorSubject<any>('');
  public filters = new BehaviorSubject<any>('');
  constructor(private router: Router) {}

  setData(data: any) {
    this.data = data;
  }

  setLabel(label: string) {
    this.label = label;
  }

  getData() {
    let temp = this.data;
    return temp;
  }

  getLabel() {
    let temp = this.label;
    return temp;
  }

  clearData() {
    this.data = undefined;
  }

  clearLabel() {
    this.label = undefined;
  }

  // for notify sibling component of any action
  notifyObservable$ = this.notify.asObservable();
  notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  // for notify sibling component to refresh / reload, please remember use completeRefresh() along with this function
  refreshObservable$ = this.toRefresh.asObservable();
  refreshOther(isToRefresh: boolean) {
    this.toRefresh.next(isToRefresh);
  }

  // for notify refreshing of other component
  notifyRefresh() {
    this.notifyOther({ refresh: true });
    this.refreshOther(true);
  }

  // after reloading use this function to reset flag! IMPORTANT!
  completeRefresh() {
    this.refreshOther(false);
  }

  // after notice to reload other sibling pages / tables! IMPORTANT!
  completeNotice() {
    this.notifyOther({ refresh: false });
  }

  // reserved for notify dropdown selectors in request page
  selectorsObservable$ = this.selectors.asObservable();
  updateSelectors(data: any) {
    if (data) {
      this.selectors.next(data);
    }
  }

  // reserved for notify dropdown filter values change from selectors in request page
  filtersObservable$ = this.filters.asObservable();
  updateFilters(data: any) {
    if (data) {
      this.filters.next(data);
    }
  }
}
