import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AnswersService {
  constructor(private apiService: ApiService) {}

  // saves all answers into flatten answer pool
  async dumpAnswers(id, structuredAnswers = {}) {
    return await this.apiService.post<any, any>(`ddq/answer-pool/dump/${id}`, structuredAnswers).toPromise();
  }

  async fetchAnswers(formID: string, region: string, version: string) {
    // fetch flattened answers from pool
    const answersFromPool = await this.getAnswersFromPool(formID);
    const answersTemplate = await this.rebuildDynamicTemplate(formID, region, version);
    // rebuild and return the structured answers
    return this.loadValues(answersTemplate, answersFromPool);
  }

  // stash structured answers into a cache in S3
  async cacheStructuredAnswers(id, region, version, obj) {
    return await this.apiService.post<any, any>(`ddq/answer-cache/upload/${id}/${region}/${version}`, obj).toPromise();
  }

  async getCachedStructuredAnswers(id, region, version) {
    return await this.apiService.get(`ddq/answer-cache/get/${id}/${region}/${version}`).toPromise();
  }

  // fetch flattened answers for a ddq
  async getAnswersFromPool(id: string) {
    return await this.apiService.get(`ddq/answer-pool/get/${id}`).toPromise();
  }

  // save to flattened answer pool
  async saveAnswersToPool(id: string, flattenedAnswers) {
    return await this.apiService.post<any, any>(`ddq/answer-pool/upload/${id}`, flattenedAnswers).toPromise();
  }

  // fetch one answer for a specific question
  async getAnswerByQuestionId(formId: string, questionId: string) {
    return await this.apiService.get(`ddq/answer/get/${formId}/${questionId}`).toPromise();
  }

  // this rebuild an empty answer skeleton template which match the dynamic fields length of the already filled answers.
  rebuildDynamicTemplate = async (id: string, region: string, version: string) => {
    return await this.apiService.get(`ddq/answer-skeleton/dynamic/${id}/${region}/${version}`).toPromise();
  };

  loadValues = (template, answers) => {
    if (template && typeof template === 'object') {
      for (let key of Object.keys(template)) {
        // console.log(template[key], 'template entry');
        if (typeof template[key] === 'object') {
          if (Array.isArray(template[key])) {
            let index = 0;
            for (let item of template[key]) {
              if (typeof item === 'object') {
                for (let innerKey of Object.keys(item)) {
                  //console.log('obj:', item, ' in array,', 'with key:', innerKey, 'and Index:', index);
                  if (answers && answers[innerKey]) {
                    item[innerKey] = answers[innerKey][index];
                  }
                }
              } else {
                if (answers && answers[key]) {
                  template[key] = answers[key];
                }
              }
              index++;
            }
          } else if (
            template[key] &&
            Object.keys(template[key]).length === 3 &&
            Object.keys(template[key]).includes('year') &&
            Object.keys(template[key]).includes('month') &&
            Object.keys(template[key]).includes('day')
          ) {
            if (answers && answers[key]) {
              template[key] = answers[key];
            }
          } else {
            this.loadValues(template[key], answers);
          }
        } else {
          if (answers && answers[key]) {
            template[key] = answers[key];
          }
        }
      }
      return template;
    } else {
      //  console.log('incorrect source template');
    }
  };
}
