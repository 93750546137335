import { Injectable } from '@angular/core';

import { NewMessageEntry, NewThreadEntry, UpdateMessageEntry } from '../model/comment';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import jp from 'jsonpath';
import { QuestionnaireService } from './questionnaire.service';
import { ApiService } from '../../services/api.service';
import { take } from 'rxjs/internal/operators/take';
import { filter, map, switchMap, toArray } from 'rxjs/operators';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CommentsService {
  questionSubject = new BehaviorSubject<any>([]);
  showResolvedSubject = new Subject<boolean>();
  commentSavedSubject = new Subject<boolean>();
  issuersSubject = new BehaviorSubject<any>(null);
  requestersSubject = new BehaviorSubject<any>(null);
  threadSubject = new BehaviorSubject<any>([]);
  commentsAuthor: string;

  private questionLinkSubject = new BehaviorSubject<any>(null);
  public questionLinkSubjectObservable$: Observable<string | any> = this.questionLinkSubject;
  public threadSubjectObservable$: Observable<any> = this.threadSubject;
  public updateDataObservable$ = new BehaviorSubject<any>(null);
  constructor(private apiService: ApiService) {}

  loadQuestionComments(question) {
    this.questionSubject.next(question);
  }

  getQuestion(questionID, ddqData) {
    return jp.query(ddqData, `$..questions[?(@.id=='${questionID}')]`)[0];
  }

  // new comments endpoints:
  resolveIssuerName(issuerId) {
    const issuer = this.issuersSubject.value.find((issuer) => issuer.id === issuerId);

    return issuer ? issuer.name : '';
  }

  getAllThreadsByForm(formId) {
    return this.apiService.get<any[]>(`ddq/threads/form/${formId}`);
  }

  getAllUnresolvedThreadsByForm(formId) {
    return this.apiService.get<any[]>(`ddq/threads/unresolved/form/${formId}`);
  }

  getAllThreadsByFormForDistributor(formId) {
    return this.apiService.get<any[]>(`ddq/threads/form/${formId}/distributor`);
  }

  getAllUnresolvedThreadsByFormForDistributor(formId) {
    return this.apiService.get<any[]>(`ddq/threads/unresolved/form/${formId}/distributor`);
  }

  getAllThreadsByFormForIssuer(formId, issuerId) {
    return this.apiService.get<any[]>(`ddq/threads/form/${formId}/issuer/${issuerId}`);
  }

  getAllUnresolvedThreadsByFormForIssuer(formId, issuerId) {
    return this.apiService.get<any[]>(`ddq/threads/unresolved/form/${formId}/issuer/${issuerId}`);
  }

  postNewThread(threadEntry: NewThreadEntry) {
    return this.apiService.post<any, any>(`ddq/threads`, threadEntry);
  }

  getAllMessagesByThread(threadId) {
    return this.apiService.get<any[]>(`ddq/messages/thread/${threadId}`);
  }

  getMessage(id) {
    return this.apiService.get<any>(`ddq/message/${id}`);
  }

  postMessage(messageEntry: NewMessageEntry) {
    return this.apiService.post<any, any>(`ddq/messages`, messageEntry);
  }

  updateMessage(updateEntry: UpdateMessageEntry) {
    return this.apiService.patch<any, any>(`ddq/message/${updateEntry.id}`, updateEntry);
  }

  deleteMessage(id: string) {
    return this.apiService.delete<any>(`ddq/message/${id}`);
  }
  closeThread(id: string) {
    return this.apiService.patch<any, any>(`ddq/threads/${id}/close`, null);
  }

  openThread(id: string) {
    return this.apiService.patch<any, any>(`ddq/threads/${id}/open`, null);
  }

  countOpenThreadsGlobally() {
    return this.apiService.get<any>(`ddq/threads/count/open`);
  }

  countOpenThreadsByIssuer(issuerId) {
    return this.apiService.get<any>(`ddq/threads/count/open/issuer/${issuerId}`);
  }

  deleteAllComments() {
    this.apiService.delete('ddq/comments').subscribe();
  }
  setQuestionLinkSubject(question: any): void {
    this.questionLinkSubject.next(question);
  }

  setThreadSubject(value: any): void {
    // console.log('setThreadSubject', value);
    this.threadSubject.next(value);
  }

  addToIssuerMsgCount(threadId: string) {
    return this.apiService.patch<any, any>(`ddq/threads/${threadId}/toissuer/add`, null);
  }

  addToDistributorMsgCount(threadId: string) {
    return this.apiService.patch<any, any>(`ddq/threads/${threadId}/todistributor/add`, null);
  }

  addToInspireMsgCount(threadId: string) {
    return this.apiService.patch<any, any>(`ddq/threads/${threadId}/toinspire/add`, null);
  }
}
