<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{ title }}</h5>
  <button *ngIf="close" type="button" class="close" aria-label="Close" (click)="modal.dismiss('Close')">Close</button>
</div>

<ng-container *ngIf="body">
  <div class="modal-body" ngbAutofocus [innerHtml]="body"></div>
</ng-container>

<div *ngIf="customComponent">
  <div [ngTemplateOutlet]="customComponent"></div>
</div>

<div class="modal-footer justify-content-between">
  <div>
    <button *ngIf="action" type="button" class="btn btn-link btn-sm pl-0 mr-3" (click)="onAction({ action: 1 })">
      {{ action }}
    </button>
  </div>
  <div>
    <button *ngIf="negative" type="button" class="btn btn-secondary btn-sm mr-3" (click)="onAction({ negative: 1 })">
      {{ negative }}
    </button>
    <button *ngIf="positive" type="button" class="btn btn-primary btn-sm" (click)="onAction({ positive: 1 })">
      {{ positive }}
    </button>
  </div>
</div>
