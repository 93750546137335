<div class="new-password-container" *ngIf="displayed">
  <form id="newPasswordForm" class="new-password-form">
    <div class="new-password-title">Reset Password</div>
    <div class="new-password-intro">
      <p>
        Your new password must be at least 8 characters long and includes the following:
      </p>
      <ul style="list-style-type: disc;">
        <li class="password-req-item">Numbers</li>
        <li class="password-req-item">Upper Case Letters</li>
        <li class="password-req-item">Lower Case Letters</li>
        <li class="password-req-item">Special Characters</li>
      </ul>
    </div>
    <br />
    <div class="form-group">
      <!-- <label for="name">Your Display Name</label> -->
      <input
        class="form-control form-control-lg"
        id="name"
        type="text"
        [formControl]="name"
        required
        name="name"
        placeholder="Your Display Name"
      />
      <div [hidden]="name.valid || name.pristine" class="alert alert-danger">Your display name is required</div>
    </div>
    <div class="form-group">
      <!-- <label for="password1">New Password</label> -->
      <div class="input-group input-group-lg">
        <input
          class="form-control form-control-lg"
          id="password1"
          [type]="showPassword ? 'text' : 'password'"
          [formControl]="password1"
          name="password1"
          placeholder="Password"
          required
        />
        <div class="input-group-append">
          <button *ngIf="!showPassword" (click)="showPassword = true" [attr.aria-label]="'Show password'">
            <fa-icon [icon]="faEyeSlash" title="Show Password"></fa-icon>
          </button>
          <button *ngIf="showPassword" (click)="showPassword = false" [attr.aria-label]="'Hide password'">
            <fa-icon [icon]="faEye" title="Hide Password"></fa-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group">
      <!-- <label for="password2">Confirm New Password</label> -->
      <div class="input-group input-group-lg">
        <input
          class="form-control form-control-lg"
          id="password2"
          [type]="showPassword ? 'text' : 'password'"
          [formControl]="password2"
          name="password2"
          placeholder="Confirm New Password"
          required
        />
        <div class="input-group-append">
          <button *ngIf="!showPassword" (click)="showPassword = true" [attr.aria-label]="'Show password'">
            <fa-icon [icon]="faEyeSlash" title="Show Password"></fa-icon>
          </button>
          <button *ngIf="showPassword" (click)="showPassword = false" [attr.aria-label]="'Hide password'">
            <fa-icon [icon]="faEye" title="Hide Password"></fa-icon>
          </button>
        </div>
      </div>
      
    </div>
    <div class="form-group">
      <div class="custom-control custom-checkbox mb-3" style="font-size: var(--text-label);">
        <input type="checkbox" class="custom-control-input" id="termsCheck" [formControl]="terms">
        <label class="custom-control-label" for="termsCheck">I Agree</label> to <a (click)="openPolicyModal('terms');" role="button" class="contact-team">Terms of Use</a> & <a (click)="openPolicyModal('data')" role="button" class="contact-team">Data Protection Addendum</a>
      </div>
    </div>
    <div [hidden]="!errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <button
      [disabled]="!canSaveNewPassword()"
      class="btn btn-primary btn-lg new-password-button"
      (click)="saveNewPassword()"
    >
      <ng-container *ngIf="submitted">
        <span class="sign-in-progress spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
        Changing Password
      </ng-container>
      <ng-container *ngIf="!submitted"> Change Password </ng-container>
    </button>
  </form>
</div>
