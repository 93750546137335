import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';
import { ApiService } from '../../services/api.service';
import { QuestionnaireService } from './questionnaire.service';
import { Request } from '../model/request';
import { CurrentUserService } from '../../services/current-user.service';
import { UsersService } from '../../services/users.service';
import { UserDetails, UserType } from '../../model/user';

@Injectable({
  providedIn: 'root',
})
export class KydUserSessionService {
  timedOut = false;
  loggedInUser;
  userInactiveTimer;
  inactive = false;
  loggedOut = false;
  userActivity;
  userInactive: Subject<any> = new Subject();
  inactivityTimer: Subject<any> = new Subject();
  sessionTimeout: Subject<any> = new Subject();
  ddqIsUnlocked: Subject<any> = new Subject();

  userType: UserType;

  userWhoIsLocking: UserDetails;

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private questionnaireService: QuestionnaireService,
    private currentUserService: CurrentUserService,
    private usersService: UsersService
  ) {
    this.userInactive.subscribe(() => {
      this.loggedOut = true;
      this.unlockDDQForm((lockInformation: any) => {
        console.log(lockInformation, 'logging out');
        if (lockInformation.lockedby === null) {
          if (this.timedOut) {
            console.log('timing out!');
            this.sessionTimeout.next();
            this.authService.sessionTimeout();
          } else {
            console.log('logging out!');
            this.authService.signOut();
          }
        } else {
          this.userInactive.next();
        }
      });
    });

    this.currentUserService.currentUser.subscribe((user) => {
      this.loggedInUser = user.id;
      this.userType = user.type;
    });
  }

  async nobodyElseIsEditing(): Promise<boolean> {
    return new Promise((resolve) => {
      try {
        this.apiService.get(`ddq/lock-ddq/${this.questionnaireService.requestKey}`).subscribe((lockInformation: any) => {
          try {
            const lockedByUser = lockInformation.lockedby;
            const logoutTime = lockInformation.lockeduntil;

            if (lockedByUser) {
              if (this.loggedInUser !== lockedByUser) {
                if (logoutTime && new Date(logoutTime * 1000) >= new Date()) {
                  this.getLockedByUser(lockedByUser);
                  resolve(false);
                }
              }
            }
            resolve(true);
          } catch {
            resolve(false);
          }
        });
      } catch {
        resolve(false);
      }
    });
  }

  async getLockedByUser(userID) {
    this.userWhoIsLocking = (await this.usersService.getUserDetails([userID]))[userID];
  }

  resetSessionTimer() {
    this.setTimeout();
    this.updateSessionLock();
  }

  updateSessionLock() {
    if (this.userType !== 'inspire') {
      this.apiService.put(`ddq/lock-ddq/${this.questionnaireService.requestKey}`, {}).subscribe()
    }
  }

  setTimeout() {
    this.userActivity = setTimeout(() => {
      this.timedOut = true;
      this.userInactive.next(undefined);
    }, 900000);
    this.userInactiveTimer = setTimeout(() => {
      this.inactive = true;
      this.inactivityTimer.next();
    }, 840000);
  }

  resetAllTimers() {
    if (!this.loggedOut) {
      this.inactive = false;
      this.timedOut = false;
      clearTimeout(this.userActivity);
      clearTimeout(this.userInactiveTimer);
      this.resetSessionTimer();
    }
  }

  unlockDDQForm(subscriber) {
    this.apiService.put(`ddq/unlock-ddq/${this.questionnaireService.requestKey}`, {}).subscribe(subscriber);
  }

  pollToSeeIfDDQStillLocked() {
    setTimeout(async () => {
      console.log('polling to see if ddq is still locked')
      const ddqIsEditable = await this.nobodyElseIsEditing()
      if (ddqIsEditable) {
        this.ddqIsUnlocked.next()
      } else {
        this.pollToSeeIfDDQStillLocked()
      }
    }, 15000)
  }

}


export function debounce(delay: number = 500): MethodDecorator {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const timeoutKey = Symbol();

    const original = descriptor.value;

    descriptor.value = function (...args) {
      clearTimeout(this[timeoutKey]);
      this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}
