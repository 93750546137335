import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  // use only first 10 chars of ISO date string as argument, i.e. "2023-12-15"
  getWorkingDays(date) {
    if (date) {
      let result = 0;
      const endDate = new Date();
      const currentDate = new Date(date);
      while (currentDate <= endDate) {
        const weekDay = currentDate.getDay();
        if (weekDay != 0 && weekDay != 6) result++;

        currentDate.setDate(currentDate.getDate() + 1);
      }

      return result > 0 ? result - 1 : result;
    } else return null;
  }

  toTwoDigits(n) {
    n = String(n);
    if (n.length == 1) n = '0' + n;
    return n;
  }

  addDays(date, days) {
    const result = new Date(date.slice(0, 19));
    result.setDate(result.getDate() + days);
    return result.toISOString();
  }
}
