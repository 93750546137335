import { EventEmitter } from '@angular/core';
import { Component, Injector, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '../controlvalue-accessor.connector';

@Component({
  selector: 'app-dc-checkbox',
  templateUrl: './dc-checkbox.component.html',
  styleUrls: ['./dc-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DcCheckboxComponent,
      multi: true,
    },
  ],
})
export class DcCheckboxComponent extends ControlValueAccessorConnector {
  @Input() disabled: boolean;
  @Input() id: string;
  @Input() label: string;
  @Output() changed = new EventEmitter();

  constructor(injector: Injector) {
    super(injector);
  }
}
