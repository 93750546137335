<div [class]="'form-' + themePosition + ' background' + ' slant-' + themeSlant">
  <div class="login-screen">
    <div class="login-component">
      <div class="login-display">
        <ng-container *ngIf="loginForm">
          <app-login-form></app-login-form>
        </ng-container>
        <ng-container *ngIf="newPasswordForm">
          <app-new-password-form></app-new-password-form>
        </ng-container>
        <ng-container *ngIf="setupMfaForm">
          <app-setup-mfa-form></app-setup-mfa-form>
        </ng-container>
        <ng-container *ngIf="confirmSignInForm">
          <app-confirm-signin-form></app-confirm-signin-form>
        </ng-container>
        <ng-container *ngIf="resetPasswordForm">
          <app-reset-password-form></app-reset-password-form>
        </ng-container>
      </div>
    </div>
  </div>
</div>