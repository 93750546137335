import { IssuersService } from './../../kyd/services/issuers.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AdminGuard } from '../../guards/admin-guard.service';
import {
  ActivationEnd,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { RoleService } from 'src/app/kyd/services/role.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DcAlertService } from '../dc-alert/dc-alert.service';

@Component({
  selector: 'app-dc-navbar',
  templateUrl: './dc-navbar.component.html',
  styleUrls: ['./dc-navbar.component.scss'],
})
export class DcNavbarComponent implements OnInit {
  @Input() user: string;
  @Input() email: string;
  @Input() loggedInAsInspire: boolean;
  @Input() loggedInAsDistributor: boolean;
  @Input() loggedInAsIssuer: boolean;
  @Input() navigating: boolean;
  @Output() signOutEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild('userMenu') userMenu: ElementRef;
  @ViewChild('toggleButton') toggleButton: ElementRef;

  public activeRoute: string;
  public userPanelCollapsed = true;
  public navbarMenuCollapsed;
  role;
  issuerUserSettingsForm: UntypedFormGroup;
  issuerContact;

  constructor(
    private router: Router,
    public adminGuard: AdminGuard,
    private renderer: Renderer2,
    private roleService: RoleService,
    private ngbModalService: NgbModal,
    private issuersService: IssuersService,
    private dcAlertService: DcAlertService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof RouterEvent) {
        this.navigationInterceptor(event);
      }
    });
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.user) {
        if (!this.userMenu.nativeElement.contains(e.target) && !this.toggleButton.nativeElement.contains(e.target)) {
          this.userPanelCollapsed = true;
        }
      }
    });
  }

  async ngOnInit() {
    this.role = (await this.roleService.getRole()).role;
    if (this.role === 'Manager' || this.role === 'Sales') {
      this.issuerContact = await this.issuersService.getIssuerContactByEmail(this.email!).toPromise();
      this.issuerUserSettingsForm = new UntypedFormGroup({
        newDocNotification: new UntypedFormControl(!this.issuerContact.optoutdocalert || true, Validators.required),
      });
    }
    this.navbarMenuCollapsed = true;
    this.userPanelCollapsed = true;
  }

  navbarMenuButton() {
    this.navbarMenuCollapsed = !this.navbarMenuCollapsed;
    this.userPanelCollapsed = true;
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.navigating = true;
      this.activeRoute = undefined;
      this.navbarMenuCollapsed = true;
      this.userPanelCollapsed = true;
    }
    if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      this.navigating = false;
      this.navbarMenuCollapsed = true;
      this.userPanelCollapsed = true;
      this.activeRoute = event.url;
    }
    if (event instanceof ActivationEnd) {
      this.navbarMenuCollapsed = true;
      this.userPanelCollapsed = true;
    }
  }

  signOut() {
    this.signOutEmitter.emit();
  }

  openAddModal(content) {
    const modalRef = this.ngbModalService.open(content, {
      scrollable: false,
      size: 'lg',
      centered: true,
      // keyboard: false,
      backdrop: 'static',
    });
    modalRef.result.then((result) => {}).catch((error) => {});
  }

  async submitSettings() {
    await this.issuersService
      .updateSalesContactNewDocAlert(this.issuerContact.id, !this.issuerUserSettingsForm.value.newDocNotification)
      .toPromise()
      .then(() => {
        this.dcAlertService.showAlert({
          type: 'success',
          message: `Successfully updated user settings`,
        });
        this.issuersService.refreshContactsSubject.next();
        this.ngbModalService.dismissAll();
      })
      .catch((e) => {
        console.log(e);
        this.dcAlertService.showAlert({
          type: 'danger',
          message: 'Error updating user settings!',
        });
        this.ngbModalService.dismissAll();
      });
  }
}
