import { DDQConfig } from '../model/ddq-config';

// currently only latest EMEA question template in here:
export const mockDDQConfig = new DDQConfig('Distributor One', '2022',  [
  {
    id: "f6229577-8dcb-40b4-b833-2232ee7ff76f",
    nav: "1",
    name: "Distributor Overview and Structured Products Experience",
    subsections: [
      {
        id: "fe49d2f9-4ba2-4985-9288-4c48060851ef",
        name: "Distributor Overview",
        nav: "1",
        questions: [
          {
            id: "40cfa473-70ed-4a2f-998a-783af3975feb",
            externalID: "1.1.1",
            nav: "1.1.1",
            text: "Please outline the Distributors ownership structure.",
            validate: {
              groupArr: [
                "5e6b3397-b032-471e-b07a-ec8c270bfb71",
                "cdbe1e1d-957e-4eb8-9d19-bcca2e2bba33",
              ],
            },
            fields: [
              {
                id: "617a27d9-b503-46c4-9a6b-23562fcad9d0",
                type: "input",
                width: 12,
                validate: {
                  required: true,
                },
                label: "Distributor Full Legal Name",
                value: false,
              },
              {
                id: "3e01a876-7190-4cb3-92f0-d830b859197a",
                type: "input",
                width: 12,
                validate: {
                  required: true,
                },
                label: "Ultimate parent Company",
                value: false,
              },
              {
                id: "e93ad73b-3eeb-45cf-9533-8af82a0f88e2",
                type: "input",
                width: 12,
                validate: {
                  required: true,
                },
                label: "Trading Names (if different to Full Legal Name)",
                value: false,
              },
              {
                id: "15e035e0-3a53-420d-939c-177688760415",
                type: "input",
                width: 12,
                validate: {
                  required: true,
                },
                label:
                  "Ownership structure - (Please email us a diagram if necessary and write the file name below)",
                value: false,
              },
              {
                id: "8e1824ba-58d4-4e2c-a80a-4922a5155113",
                type: "input",
                width: 12,
                validate: {
                  required: true,
                },
                label:
                  'Name of Ultimate Beneficial Owners "UBOs" - defined as a natural person holding 10% or more',
                value: false,
              },
              {
                id: "bccb0bb8-5a33-4c36-971e-50be13956cf1",
                type: "subHeadLabel",
                text: "Does the Distributor or any of its owners, majority shareholders, principals or directors have political exposure or sanction links:",
                width: 12,
              },
              {
                id: "08c1bf0b-e01b-5963-8ac6-3c50a518be45",
                type: "radio-list",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "61cc4a55-8295-566b-ba62-bf553756196b",
                    label:
                      "No political exposure or sanctions links have been identified",
                    conditional: {
                      disable: [
                        "5e6b3397-b032-471e-b07a-ec8c270bfb71",
                        "cdbe1e1d-957e-4eb8-9d19-bcca2e2bba33",
                      ],
                    },
                  },
                  {
                    id: "8fed7b9f-d764-5f15-ab42-311dfd805ceb",
                    label: "Yes",
                    conditional: {
                      enable: [
                        "5e6b3397-b032-471e-b07a-ec8c270bfb71",
                        "cdbe1e1d-957e-4eb8-9d19-bcca2e2bba33",
                      ],
                    },
                  },
                ],
              },
              {
                id: "5e6b3397-b032-471e-b07a-ec8c270bfb71",
                type: "checkbox",
                width: 12,
                indent: 1,
                label:
                  'Are Politically-Exposed Persons ("PEPs") or have any links to PEPs',
                value: false,
                disabled: true,
              },
              {
                id: "cdbe1e1d-957e-4eb8-9d19-bcca2e2bba33",
                type: "checkbox",
                width: 12,
                indent: 1,
                label:
                  "Have any links to sanctioned countries or sanctioned individuals",
                value: false,
                disabled: true,
              },
            ],
          },
          {
            id: "d2a44fdd-3c37-4fd9-8ba3-4fe787b0fbb9",
            externalID: "1.1.2",
            nav: "1.1.2",
            text: "What are the main business activities of the Distributor",
            fields: [
              {
                id: "ebad710a-a4c3-4c62-98c1-cc7cb8b351ce",
                type: "input",
                width: 3,
                placeholder: "YYYY",
                validate: {
                  required: true,
                },
                label: "Date legal entity was established / Earliest operating date",
              },
              {
                id: "ebad710a-a4c3-4c62-98c1-cc7cb8b351cex",
                type: "textArea",
                width: 12,
                validate: {
                  required: true,
                },
                label: "Description of main business activity",
              },
            ],
          },
          {
            id: "93def229-f7dd-4d33-8d6b-64e9077268f5",
            externalID: "1.1.3",
            nav: "1.1.3",
            text: "Distributor Type",
            fields: [
              {
                id: "2538fa35-fa57-4f3f-8660-9a357d7351bb",
                type: "dropdown",
                conditional: {
                  enable: ["0f5a6215-c979-4218-9382-46b28403702c"],
                },
                validate: {
                  required: true,
                },
                source: "distributorTypes",
                search: true,
                width: 6,
                value: null,
              },
              {
                id: "0f5a6215-c979-4218-9382-46b28403702c",
                type: "input",
                width: 6,
                disabled: true,
                validate: {
                  required: true,
                },
              },
            ],
          },
          {
            id: "269a3760-7188-4c31-a791-f0d05b46990a",
            externalID: "1.1.4",
            nav: "1.1.4",
            text: "Regulators",
            validate: {
              groupArr: [
                "da18e5fc-bf4e-4891-8aa9-d3c467bf72a0",
                "2aa355ef-bc40-48a7-a9aa-8ea2703314f2",
                "6c8b28ac-d957-471b-9484-db91ce1a06ee",
                "e3a73dc2-ccb8-43bb-9b46-70e83d50494e",
              ],
            },
            fields: [
              {
                id: "da18e5fc-bf4e-4891-8aa9-d3c467bf72a0",
                type: "checkbox",
                width: 12,
                conditional: {
                  enable: ["5dcaede0-0903-49eb-9207-864942ec7490"],
                },
                label: "Regulated",
                value: false,
              },
              {
                id: "5dcaede0-0903-49eb-9207-864942ec7490",
                type: "input",
                width: 12,
                indent: 1,
                validate: {
                  required: true,
                },
                placeholder: "Name of Regulator",
                value: "",
                disabled: true,
              },
              {
                id: "2aa355ef-bc40-48a7-a9aa-8ea2703314f2",
                type: "checkbox",
                width: 12,
                conditional: {
                  enable: ["2b788d31-0d13-4d8e-937e-fbe614680caf"],
                },
                label: "Self-regulated",
                value: false,
              },
              {
                id: "2b788d31-0d13-4d8e-937e-fbe614680caf",
                type: "input",
                width: 12,
                indent: 1,
                validate: {
                  required: true,
                },
                placeholder: "Name of Self-Regulatory Organisation (SRO)",
                value: "",
                disabled: true,
              },
              {
                id: "6c8b28ac-d957-471b-9484-db91ce1a06ee",
                type: "checkbox",
                width: 12,
                label: "Non-regulated",
                value: false,
              },
              {
                id: "e3a73dc2-ccb8-43bb-9b46-70e83d50494e",
                type: "checkbox",
                width: 12,
                conditional: {
                  enable: ["78cca5fe-29a3-480a-a083-ae8a6b447594"],
                },
                label: "Other - Please describe",
                value: false,
              },
              {
                id: "78cca5fe-29a3-480a-a083-ae8a6b447594",
                type: "input",
                width: 12,
                indent: 1,
                validate: {
                  required: true,
                },
                disabled: true,
                placeholder: "Name of Regulator",
                value: "",
              },
              {
                id: "13569b8d-31d2-40f6-bf02-bb8808abaa9c",
                type: "input",
                width: 4,
                validate: {
                  required: true,
                },
                label: "Year of Regulatory Approval",
                placeholder: "YYYY",
                value: "",
              },
            ],
          },
          {
            id: "c18d29ac-795f-4ae9-8458-a50f0389a003",
            externalID: "1.1.5",
            nav: "1.1.5",
            text: "Primary Business Address & Offices",
            fields: [
              {
                id: "517d7a55-ede3-4063-8b99-d16b1aab0eb0",
                type: "input",
                width: 12,
                validate: {
                  required: true,
                },
                label: "Primary Business / Headquarter Address",
                value: "",
              },
              {
                id: "c8f4d2d8-43c7-459a-9dc3-a7d6d45b927e",
                type: "input",
                width: 12,
                validate: {
                  required: true,
                },
                label: "Additional Office Country Locations",
                value: "",
              },
            ],
          },
          {
            id: "fdbcdf68-362b-4d21-94e3-bc1d6eea4751",
            externalID: "1.1.6",
            nav: "1.1.6",
            text: "Primary Website",
            fields: [
              {
                id: "05645c75-bdaa-4939-a33d-1ea797263021",
                type: "input",
                width: 12,
                validate: {
                  required: true,
                },
                value: "",
              },
            ],
          },
          {
            id: "6f43ae5a-7aab-4cd6-9eee-aa17ea64bc85",
            externalID: "1.1.7",
            nav: "1.1.7",
            text: "Please confirm the total number of staff involved in the design, production, or distribution of Structured Products",
            popover: {
              body: "<p>If the Distributor does not face the End Client, please confirm the number of front office staff dealing structured products; and the number of Sub-distributors / 3rd Party intermediaries / Custodians relationships they manage.</p><p> Please add a note defining the number provided as either the Distributor’s staff facing their direct clients or the number of Sub-distributors / 3rd Party intermediaries / Custodians’ facing their End Clients.</p>",
            },
            fields: [
              {
                id: "6541a8ae-1f92-4a41-b906-0fc55bce4e17",
                type: "subHead",
                text: "Total number of staff",
                width: 12,
              },
              {
                id: "cf19b747-9efe-4531-a076-d8b03635b547",
                type: "dropdown",
                width: 12,
                label: "Number of Staff",
                source: "range500",
                singleSelect: true,
                checkbox: false,
                validate: {
                  required: true,
                },
              },
              {
                id: "65f0cd95-8f1a-4036-a83d-b59d307af881",
                type: "subHead",
                text: "Total number of staff involved in the design, production, or distribution of Structured Products",
                width: 12,
              },
              {
                id: "65f0cd95-8f1a-4036-a83d-b59d307af882",
                type: "dropdown",
                width: 3,
                label: "Number of Staff",
                source: "range500",
                singleSelect: true,
                checkbox: false,
                validate: {
                  required: true,
                },
              },
              {
                id: "65f0cd95-8f1a-4036-a83d-b59d307af883",
                type: "dropdown",
                width: 3,
                label: "Outsourced",
                source: "yesno",
                singleSelect: true,
                checkbox: false,
                validate: {
                  required: true,
                },
              },
              {
                id: "65f0cd95-8f1a-4036-a83d-b59d307af884",
                type: "input",
                width: 6,
                label: "Outsourced Provider Name",
                value: "",
              },
              {
                id: "65f0cd95-8f1a-4036-a83d-b59d307af885",
                type: "input",
                width: 12,
                label: "Rational for outsourcing",
                value: "",
              },
              {
                id: "5686cca0-a82d-4aa5-b290-685709c6b4d1",
                type: "subHead",
                text: "Of which, the number of staff facing End Clients is:",
                width: 12,
              },
              {
                id: "5686cca0-a82d-4aa5-b290-685709c6b4d2",
                type: "dropdown",
                width: 3,
                label: "Number of Staff",
                source: "range500",
                singleSelect: true,
                checkbox: false,
                validate: {
                  required: true,
                },
              },
              {
                id: "5686cca0-a82d-4aa5-b290-685709c6b4d3",
                type: "dropdown",
                width: 3,
                label: "Outsourced",
                source: "yesno",
                singleSelect: true,
                checkbox: false,
                validate: {
                  required: true,
                },
              },
              {
                id: "5686cca0-a82d-4aa5-b290-685709c6b4d4",
                type: "input",
                width: 6,
                label: "Outsourced Provider Name",
                value: "",
              },
              {
                id: "5686cca0-a82d-4aa5-b290-685709c6b4d5",
                type: "input",
                width: 12,
                label: "Rational for outsourcing",
                value: "",
              },
              {
                id: "f528e2f1-91d5-4d19-b7f4-480425972911",
                type: "subHead",
                text: "Number of AML / Compliance staff",
                width: 12,
              },
              {
                id: "f528e2f1-91d5-4d19-b7f4-480425972912",
                type: "dropdown",
                width: 3,
                label: "Number of Staff",
                source: "range500",
                singleSelect: true,
                checkbox: false,
                value: "",
                validate: {
                  required: true,
                },
              },
              {
                id: "f528e2f1-91d5-4d19-b7f4-480425972913",
                type: "dropdown",
                width: 3,
                label: "Outsourced",
                source: "yesno",
                singleSelect: true,
                checkbox: false,
                validate: {
                  required: true,
                },
              },
              {
                id: "f528e2f1-91d5-4d19-b7f4-480425972914",
                type: "input",
                width: 6,
                label: "Outsourced Provider Name",
                value: "",
              },
              {
                id: "f528e2f1-91d5-4d19-b7f4-480425972915",
                type: "input",
                width: 12,
                label: "Rational for outsourcing",
                value: "",
              },
              {
                id: "0ff0b5a0-2949-528e-9748-a41e6415e7a4",
                type: "subHead",
                text: "",
                width: 12,
              },
              {
                id: "43687c4d-89fa-4223-9482-6205f3d297a1",
                type: "checkbox",
                width: 12,
                label:
                  "Filename of Organisation Chart / Supporting Document provided:",
                value: false,
                conditional: {
                  enable: ["43687c4d-89fa-4223-9482-6205f3d297a2"],
                },
              },
              {
                id: "43687c4d-89fa-4223-9482-6205f3d297a2",
                type: "input",
                width: 12,
                indent: 1,
                placeholder: "File name",
                value: "",
                disabled: true,
                validate: {
                  required: true,
                },
              },
            ],
          },
          {
            id: "b0062e4d-b04b-4be6-929c-d7b20ad84dc4",
            externalID: "1.1.8",
            nav: "1.1.8",
            text: "Please confirm the total number of End Clients held by the Distributor",
            fields: [
              {
                id: "865797c4-ac9d-458b-a604-71b9129b4000",
                type: "dropdown",
                width: 12,
                source: "range10000",
                value: "",
                singleSelect: true,
                checkbox: false,
                validate: {
                  required: true,
                },
              },
            ],
          },
          {
            id: "b0a66c47-421e-41c5-a8b4-47ec19b1d67d",
            externalID: "1.1.9",
            nav: "1.1.9",
            text: "Please confirm the Distributor's approximate Balance Sheet",
            fields: [
              {
                id: "965797c4-ac9d-458b-a604-71b9129b4001",
                type: "input",
                label: "Total Equity or Own Funds",
                width: 5,
                validate: {
                  required: true,
                },
                placeholder: "Mn",
                value: "",
                popover: {
                  body: "An approximate number of range is acceptable e.g. <$1m, <$10m, $10m - $100m, >=100-500m, >$500m; > $15,000million; $2,000-15,000m;",
                },
              },
              {
                id: "965797c4-ac9d-458b-a604-71b9129b4002",
                type: "dropdown",
                source: "currencies",
                label: "Currency",
                width: 3,
                search: true,
                singleSelect: true,
                checkbox: false,
                validate: {
                  required: true,
                },
              },
              {
                id: "965797c4-ac9d-458b-a604-71b9129b4003",
                label: "Date",
                type: "date",
                width: 4,
                validate: {
                  required: true,
                },
              },
            ],
          },
          {
            id: "7c62152e-7cf5-4651-8781-ba5ff2405700",
            externalID: "1.1.10",
            nav: "1.1.10",
            text: "Please confirm the Distributor's approximate Assets Under Management / investible assets or Structured Product notional sold",
            fields: [
              {
                id: "105797c4-ac9d-458b-a604-71b9129b4001",
                type: "input",
                width: 5,
                label: "AUM / investible assets or Structured Products",
                validate: {
                  required: true,
                },
                placeholder: "Mn",
                value: "",
                popover: {
                  body: "An approximate number of range is acceptable e.g.$<100m, <2,000m, 2,000-15,000m, >15,000 million;",
                },
              },
              {
                id: "105797c4-ac9d-458b-a604-71b9129b4002",
                type: "dropdown",
                source: "currencies",
                label: "Currency",
                width: 3,
                singleSelect: true,
                search: true,
                checkbox: false,
                validate: {
                  required: true,
                },
              },
              {
                id: "105797c4-ac9d-458b-a604-71b9129b4003",
                label: "Date",
                type: "date",
                width: 4,
                validate: {
                  required: true,
                },
              },
            ],
          },
        ],
      },
      {
        id: "4518eb51-cf08-4f21-9223-38c2c74b9fa9",
        name: "Structured Products Experience",
        nav: "2",
        questions: [
          {
            id: "69db2ef4-b7c9-4ec9-9eea-192e6dc8ecaf",
            externalID: "1.2.1",
            nav: "1.2.1",
            text: "What is the Distributor’s experience selling Structured Products within each Asset class?",
            validate: {
              groupArr: [
                "71502a59-5a14-4b55-94c5-2ac0fc283ae5",
                "3e52b4e0-bc04-46d8-83fa-06ecf0926fc6",
                "3e52b4e0-bc04-46d8-83fa-06ecf0926003",
                "3e52b4e0-bc04-46d8-83fa-06ecf0926004",
                "3e52b4e0-bc04-46d8-83fa-06ecf0926005",
                "3e52b4e0-bc04-46d8-83fa-06ecf0926006",
                "3e52b4e0-bc04-46d8-83fa-06ecf0926007",
              ],
            },
            fields: [
              {
                id: "111",
                type: "subHeadLabel",
                label: "test",
                width: 4,
              },
              {
                id: "222",
                type: "subHeadLabel",
                label: "Year of 1st distribution in asset class",
                width: 4,
              },
              {
                id: "333",
                type: "subHeadLabel",
                label: "Number of Products Launched (Last Year)",
                width: 4,
              },
              {
                id: "71502a59-5a14-4b55-94c5-2ac0fc283ae5",
                type: "checkbox",
                label: "Equities",
                width: 4,
                conditional: {
                  enable: [
                    "d7ac38fb-87b9-47d3-b91d-96402bb31a3d",
                    "fb11b3db-88df-4102-a2fb-a4361adc1bba",
                  ],
                },
                value: false,
              },
              {
                id: "d7ac38fb-87b9-47d3-b91d-96402bb31a3d",
                type: "input",
                label: "",
                width: 4,
                disabled: true,
                placeholder: "YYYY",
                validate: {
                  required: true,
                },
              },
              {
                id: "fb11b3db-88df-4102-a2fb-a4361adc1bba",
                type: "input",
                label: "",
                width: 4,
                disabled: true,
                validate: {
                  required: true,
                },
              },
              {
                id: "3e52b4e0-bc04-46d8-83fa-06ecf0926fc6",
                type: "checkbox",
                label: "Funds",
                width: 4,
                conditional: {
                  enable: [
                    "c24b4bd8-7010-413c-a864-ce452e9564ce",
                    "0ef778fc-e65d-4f04-9193-d1656886766f",
                  ],
                },
                value: false,
              },
              {
                id: "c24b4bd8-7010-413c-a864-ce452e9564ce",
                type: "input",
                width: 4,
                disabled: true,
                placeholder: "YYYY",
                validate: {
                  required: true,
                },
              },
              {
                id: "0ef778fc-e65d-4f04-9193-d1656886766f",
                type: "input",
                width: 4,
                disabled: true,
                validate: {
                  required: true,
                },
              },
              {
                id: "3e52b4e0-bc04-46d8-83fa-06ecf0926003",
                type: "checkbox",
                label: "Credit",
                width: 4,
                conditional: {
                  enable: [
                    "c24b4bd8-7010-413c-a864-ce452e956003",
                    "0ef778fc-e65d-4f04-9193-d16568867003",
                  ],
                },
                value: false,
              },
              {
                id: "c24b4bd8-7010-413c-a864-ce452e956003",
                type: "input",
                width: 4,
                disabled: true,
                placeholder: "YYYY",
                validate: {
                  required: true,
                },
              },
              {
                id: "0ef778fc-e65d-4f04-9193-d16568867003",
                type: "input",
                width: 4,
                disabled: true,
                validate: {
                  required: true,
                },
              },
              {
                id: "3e52b4e0-bc04-46d8-83fa-06ecf0926004",
                type: "checkbox",
                label: "Commodities",
                width: 4,
                conditional: {
                  enable: [
                    "c24b4bd8-7010-413c-a864-ce452e956004",
                    "0ef778fc-e65d-4f04-9193-d16568867004",
                  ],
                },
                value: false,
              },
              {
                id: "c24b4bd8-7010-413c-a864-ce452e956004",
                type: "input",
                width: 4,
                disabled: true,
                placeholder: "YYYY",
                validate: {
                  required: true,
                },
              },
              {
                id: "0ef778fc-e65d-4f04-9193-d16568867004",
                type: "input",
                width: 4,
                disabled: true,
                validate: {
                  required: true,
                },
              },
              {
                id: "3e52b4e0-bc04-46d8-83fa-06ecf0926005",
                type: "checkbox",
                label: "FX",
                width: 4,
                conditional: {
                  enable: [
                    "c24b4bd8-7010-413c-a864-ce452e956005",
                    "0ef778fc-e65d-4f04-9193-d16568867005",
                  ],
                },
                value: false,
              },
              {
                id: "c24b4bd8-7010-413c-a864-ce452e956005",
                type: "input",
                width: 4,
                disabled: true,
                placeholder: "YYYY",
                validate: {
                  required: true,
                },
              },
              {
                id: "0ef778fc-e65d-4f04-9193-d16568867005",
                type: "input",
                width: 4,
                disabled: true,
                validate: {
                  required: true,
                },
              },
              {
                id: "3e52b4e0-bc04-46d8-83fa-06ecf0926006",
                type: "checkbox",
                label: "Rates",
                width: 4,
                conditional: {
                  enable: [
                    "c24b4bd8-7010-413c-a864-ce452e956006",
                    "0ef778fc-e65d-4f04-9193-d16568867006",
                  ],
                },
                value: false,
              },
              {
                id: "c24b4bd8-7010-413c-a864-ce452e956006",
                type: "input",
                width: 4,
                disabled: true,
                placeholder: "YYYY",
                validate: {
                  required: true,
                },
              },
              {
                id: "0ef778fc-e65d-4f04-9193-d16568867006",
                type: "input",
                width: 4,
                disabled: true,
                validate: {
                  required: true,
                },
              },
              {
                id: "3e52b4e0-bc04-46d8-83fa-06ecf0926007",
                type: "checkbox",
                label: "Hybrids",
                width: 4,
                conditional: {
                  enable: [
                    "c24b4bd8-7010-413c-a864-ce452e956007",
                    "0ef778fc-e65d-4f04-9193-d16568867007",
                  ],
                },
                value: false,
              },
              {
                id: "c24b4bd8-7010-413c-a864-ce452e956007",
                type: "input",
                width: 4,
                disabled: true,
                placeholder: "YYYY",
                validate: {
                  required: true,
                },
              },
              {
                id: "0ef778fc-e65d-4f04-9193-d16568867007",
                type: "input",
                width: 4,
                disabled: true,
                validate: {
                  required: true,
                },
              },
            ],
          },
          {
            id: "f11fe727-471e-4f57-8a9b-991f423bb351",
            externalID: "1.2.2",
            nav: "1.2.2",
            text: "If the Credit asset class has been selected, does this experience include distributing Credit Linked Notes (CLNs)?",
            fields: [
              {
                id: "6ce35912-c571-4016-9d0a-4b1978712001",
                type: "radio-inline",
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "6ce35912-c571-4016-9d0a-4b1978712002",
                    label: "N/A",
                    conditional: {
                      disable: [
                        "6ce35912-c571-4016-9d0a-4b1978712005",
                        "6ce35912-c571-4016-9d0a-4b1978712006",
                        "6ce35912-c571-4016-9d0a-4b1978712007",
                        "6ce35912-c571-4016-9d0a-4b1978712008",
                      ],
                    },
                  },
                  {
                    id: "6ce35912-c571-4016-9d0a-4b1978712003",
                    label: "No",
                    conditional: {
                      disable: [
                        "6ce35912-c571-4016-9d0a-4b1978712005",
                        "6ce35912-c571-4016-9d0a-4b1978712006",
                        "6ce35912-c571-4016-9d0a-4b1978712007",
                        "6ce35912-c571-4016-9d0a-4b1978712008",
                      ],
                    },
                  },
                  {
                    id: "6ce35912-c571-4016-9d0a-4b1978712004",
                    label: "Yes - please confirm below",
                    conditional: {
                      enable: [
                        "6ce35912-c571-4016-9d0a-4b1978712005",
                        "6ce35912-c571-4016-9d0a-4b1978712006",
                        "6ce35912-c571-4016-9d0a-4b1978712007",
                        "6ce35912-c571-4016-9d0a-4b1978712008",
                      ],
                    },
                  },
                ],
                width: 12,
              },
              {
                id: "6ce35912-c571-4016-9d0a-4b1978712005",
                type: "input",
                label:
                  "Types of CLN - Vanilla, Tranches, Hybrids previously distributed",
                width: 12,
                disabled: true,
              },
              {
                id: "6ce35912-c571-4016-9d0a-4b1978712006",
                type: "input",
                label: "Reference-entity types you have experience of using:",
                width: 12,
                disabled: true,
              },
              {
                id: "6ce35912-c571-4016-9d0a-4b1978712007",
                type: "dropdown",
                source: "jurisdictions",
                label: "Jurisdictions you have distributed CLNs to previously:",
                search: true,
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
              },
              {
                id: "6ce35912-c571-4016-9d0a-4b1978712008",
                type: "input",
                label: "Key Personnel",
                width: 12,
                disabled: true,
              },
            ],
          },
        ],
      },
      {
        id: "1f81feac-d8ec-4c11-a970-eb3fc0d6b172",
        name: "Key Business Controllers, Officers & Contributor(s)",
        nav: "3",
        questions: [
          {
            id: "38d512f0-e4f1-41bd-ba7c-ded9a5592b54",
            externalID: "1.3.1",
            nav: "1.3.1",
            text: "Key Controllers, Officers and DDQ Contributor(s)",
            fields: [
              {
                id: "3ca34c46-6848-4994-a499-3cd0b3ac0330",
                type: "subHead",
                text: "Senior Manager Responsible for Business Unit",
                width: 12,
              },
              {
                id: "a5e2dd82-613f-4bf1-88e7-06eaa0a13001",
                type: "input",
                label: "Name",
                width: 6,
                validate: {
                  required: true,
                },
              },
              {
                id: "a5e2dd82-613f-4bf1-88e7-06eaa0a13002",
                type: "input",
                label: "Title",
                width: 6,
                validate: {
                  required: true,
                },
              },
              {
                id: "a5e2dd82-613f-4bf1-88e7-06eaa0a13003",
                type: "input",
                label: "Email",
                width: 6,
                validate: {
                  requiredEmail: true,
                },
              },
              {
                id: "a5e2dd82-613f-4bf1-88e7-06eaa0a13004",
                type: "input",
                label: "Tel",
                width: 6,
              },
              {
                id: "74fa9cea-debf-4bfd-ab32-7fa67b980b89",
                type: "subHead",
                text: "Lead Product Structuring Contact",
                width: 12,
              },
              {
                id: "a5e2dd82-613f-4bf1-88e7-06eaa0a13005",
                type: "input",
                label: "Name",
                width: 6,
              },
              {
                id: "a5e2dd82-613f-4bf1-88e7-06eaa0a13006",
                type: "input",
                label: "Title",
                width: 6,
              },
              {
                id: "a5e2dd82-613f-4bf1-88e7-06eaa0a13007",
                type: "input",
                label: "Email",
                width: 6,
                validate: {
                  email: true,
                },
              },
              {
                id: "a5e2dd82-613f-4bf1-88e7-06eaa0a13008",
                type: "input",
                label: "Tel",
                width: 6,
              },
              {
                id: "8ea25126-bb46-4078-8671-186b0e1a4ce4",
                type: "subHead",
                text: "Other Contributor",
                width: 12,
              },
              {
                id: "a5e2dd82-613f-4bf1-88e7-06eaa0a13009",
                type: "input",
                label: "Name",
                width: 6,
              },
              {
                id: "a5e2dd82-613f-4bf1-88e7-06eaa0a13010",
                type: "input",
                label: "Title",
                width: 6,
              },
              {
                id: "a5e2dd82-613f-4bf1-88e7-06eaa0a13011",
                type: "input",
                label: "Email",
                width: 6,
                validate: {
                  email: true,
                },
              },
              {
                id: "a5e2dd82-613f-4bf1-88e7-06eaa0a13012",
                type: "input",
                label: "Tel",
                width: 6,
              },
            ],
          },
          {
            id: "f3e0158d-f050-427b-b7b4-d2cf4fdd6704",
            externalID: "1.3.2",
            nav: "1.3.2",
            text: "Legal & Compliance Contact",
            fields: [
              {
                id: "6804a4db-9df6-443b-b732-46c97b3e0e36",
                type: "subHead",
                text: "Legal",
                width: 12,
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb001",
                type: "input",
                label: "Name",
                width: 6,
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb002",
                type: "input",
                label: "Title",
                width: 6,
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb003",
                type: "input",
                label: "Email",
                width: 6,
                validate: {
                  email: true,
                },
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb004",
                type: "input",
                label: "Tel",
                width: 6,
              },
              {
                id: "287796a6-0ea7-4151-80da-a06f2f81ddb1",
                type: "subHead",
                text: "Compliance",
                width: 12,
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb005",
                type: "input",
                label: "Name",
                width: 6,
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb006",
                type: "input",
                label: "Title",
                width: 6,
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb007",
                type: "input",
                label: "Email",
                width: 6,
                validate: {
                  email: true,
                },
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb008",
                type: "input",
                label: "Tel",
                width: 6,
              },
              {
                id: "d6f3c8d1-18ee-4db3-99bc-898a78ccfdf0",
                type: "subHead",
                text: "AML / MLRO Officer",
                width: 12,
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb009",
                type: "input",
                label: "Name",
                width: 6,
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb010",
                type: "input",
                label: "Title",
                width: 6,
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb011",
                type: "input",
                label: "Email",
                width: 6,
                validate: {
                  email: true,
                },
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb012",
                type: "input",
                label: "Tel",
                width: 6,
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb013",
                type: "checkbox",
                label:
                  "If no control functions contact, please explain rationale below and the steps taken to ensure compliance with relevant laws and regulation:",
                width: 12,
                conditional: {
                  enable: ["b4f36704-b9d5-4d0f-8bfa-f39cddfdb014"],
                },
                value: false,
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb014",
                type: "textArea",
                width: 12,
                indent: 1,
                disabled: true,
                validate: {
                  required: true,
                },
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb015",
                type: "checkbox",
                label:
                  "If control functions are outsourced, please specify to whom and provide a rationale for this:",
                width: 12,
                conditional: {
                  enable: ["b4f36704-b9d5-4d0f-8bfa-f39cddfdb016"],
                },
                value: false,
              },
              {
                id: "b4f36704-b9d5-4d0f-8bfa-f39cddfdb016",
                type: "textArea",
                width: 12,
                indent: 1,
                disabled: true,
                validate: {
                  required: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "68e7c198-b4cd-467b-87ca-c0fbe65697bd",
    name: "Distribution Strategy",
    nav: "2",
    subsections: [
      {
        id: "a617d9d4-b47b-490d-9f75-8edccf87add9",
        name: "General Information",
        nav: "1",
        questions: [
          {
            id: "c0298a69-ae1f-482f-9d95-69072e5433b5",
            externalID: "2.1.1",
            nav: "2.1.1",
            text: "What Structured Product / payoff types does the Distributor intend to distribute?",
            validate: {
              groupArr: [
                "de8bcada-72d7-45e0-9421-4780f52b1884",
                "670ac99e-cc2b-424d-9799-ef0e5e116a78",
                "eb9492ff-40df-4917-a30e-94600554e483",
                "2b2eba07-52fe-4ec8-adf1-f32d8a0d87f4",
                "66a40587-3974-43e1-aacc-626bbce1e7fc",
              ],
            },
            fields: [
              {
                id: "de8bcada-72d7-45e0-9421-4780f52b1884",
                type: "checkbox",
                label: "Capital Protection",
                value: false,
                width: 12,
              },
              {
                id: "670ac99e-cc2b-424d-9799-ef0e5e116a78",
                type: "checkbox",
                label: "Yield Enhancement",
                value: false,
                width: 12,
              },
              {
                id: "eb9492ff-40df-4917-a30e-94600554e483",
                type: "checkbox",
                label: "Participation",
                value: false,
                width: 12,
              },
              {
                id: "2b2eba07-52fe-4ec8-adf1-f32d8a0d87f4",
                type: "checkbox",
                label: "Leveraged Products / Warrants",
                value: false,
                width: 12,
              },
              {
                id: "66a40587-3974-43e1-aacc-626bbce1e7fc",
                type: "checkbox",
                label: "Specific payoff types - please specify below",
                value: false,
                width: 12,
                conditional: {
                  enable: ["1f5f0a0e-60c7-46dd-abe3-126de0aba2a1"],
                },
              },
              {
                id: "1f5f0a0e-60c7-46dd-abe3-126de0aba2a1",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "95ac5b7f-ada9-40c1-829a-bb9e60ebe458",
            externalID: "2.1.2",
            nav: "2.1.2",
            text: "How does the Distributor intend to repackage the structured product in any of the following forms?",
            validate: {
              groupArr: [
                "2d513e81-20d2-4ed9-9c2e-b3e66da61d79",
                "98d436dd-bf41-4036-a2ac-19a0e56c7816",
                "c1f4dff4-24bf-455e-8ff3-1c3a9f25f70c",
                "4c50f202-0fc5-52e5-853c-cb51b33d58fd",
                "be86eef5-c4ac-44e5-90aa-f5c0d755767b",
              ],
            },
            fields: [
              {
                id: "2d513e81-20d2-4ed9-9c2e-b3e66da61d79",
                type: "checkbox",
                label: "Notes held in security accounts",
                value: false,
                width: 12,
              },
              {
                id: "98d436dd-bf41-4036-a2ac-19a0e56c7816",
                type: "checkbox",
                label: "Insurance policies",
                value: false,
                width: 12,
              },
              {
                id: "c1f4dff4-24bf-455e-8ff3-1c3a9f25f70c",
                type: "checkbox",
                label: "Pension plans",
                value: false,
                width: 12,
              },
              {
                id: "4c50f202-0fc5-52e5-853c-cb51b33d58fd",
                type: "checkbox",
                label: "Deposit",
                value: false,
                width: 12,
              },
              {
                id: "be86eef5-c4ac-44e5-90aa-f5c0d755767b",
                type: "checkbox",
                label: "Other - please specify below",
                value: false,
                width: 12,
                conditional: {
                  enable: ["21200005"],
                },
              },
              {
                id: "21200005",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "36948136-7cde-4fb7-8789-b88ac33cfbc9",
            externalID: "2.1.3",
            nav: "2.1.3",
            text: "Does the Distributor (or any Sub-distributor / 3rd-Party involved), provide lending for investment in Structured Products?",
            fields: [
              {
                id: "384850c8-8d25-4782-9587-de9bfea2fe31",
                type: "radio-inline",
                width: 12,
                value: "",
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "9f6a4ff7-312c-48a8-a6b0-3974f3b0af03",
                    label: "No",
                    conditional: {
                      disable: ["22bca5af-bd7f-4737-92dd-ce836041d480"],
                    },
                  },
                  {
                    id: "9cc61c47-4961-4491-a41e-a0b3dd7b4218",
                    label:
                      "Yes - please describe below what policies or controls govern this activity",
                    conditional: {
                      enable: ["22bca5af-bd7f-4737-92dd-ce836041d480"],
                    },
                  },
                ],
              },
              {
                id: "22bca5af-bd7f-4737-92dd-ce836041d480",
                type: "textArea",
                label: "",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
              },
            ],
          },
          {
            id: "59bb07bb-f6bc-475f-83df-9b7dfabb0e4c",
            externalID: "2.1.4",
            nav: "2.1.4",
            text: "Distribution strategy to the End Client (select all applicable):",
            popover: {
              body: "<p>Notes for DDQ Completion: </p><ul><li>Distributor and Distributor via Affiliate under separate legal agreement - 2.1.5-2.1.9: Direct distributor questions completed.<br> 2.2.1-2.2.3 Not applicable. References to Intermediaries not ticked.<br>Section 5: Not applicable.</li><li>Distributor via Affiliate under separate legal agreement - 2.1.5-2.1.9: Direct distributor questions completed. <br>2.2.1-2..2.3 Not applicable Section 5: Answers completed with respect to Affiliated distribution channels only.<br> References to Intermediaries not ticked.</li><li>Sub-distributor / 3rd-Party Intermediary (all) – 2.1.5-2.1.6: Intermediary roles ticked. <br>2.2.1-2.2.3: Intermediary completed Section 5: Intermediaries section completed.</li></ul>",
            },
            validate: {
              groupArr: [
                "3d70612e-74e9-4569-94cd-ee756347a3c1",
                "b477106f-5d43-4238-bbf3-a910e101fe99",
                "8a574fdc-fd1e-4fe6-bd10-842f7629f70c",
                "1fe99def-f03c-4647-82f6-7b8ad270f109",
                "2e44df7b-c085-4ce2-b9dc-cd30cbcf2047",
                "f5439368-ad54-4855-a242-6fabc9b02db3",
                "27027295-557d-49fa-9774-632d20da8e7b",
              ],
            },
            fields: [
              {
                id: "3d70612e-74e9-4569-94cd-ee756347a3c1",
                type: "checkbox",
                label:
                  "Distributor distributes directly to the End Client (including Discretionary Asset Management (Pooled investment) where the End Client buys a fund and benefits from the growth of the pooled assets",
                value: false,
                width: 12,
              },
              {
                id: "b477106f-5d43-4238-bbf3-a910e101fe99",
                type: "checkbox",
                label:
                  "Distributor distributes via Affiliate under <strong>same</strong> legal agreement – e.g. Global / regional wealth manager subsidiaries, retail / private banking network ",
                value: false,
                width: 12,
              },
              {
                id: "8a574fdc-fd1e-4fe6-bd10-842f7629f70c",
                type: "checkbox",
                label:
                  "Distributor distributes via Affiliate under <strong>separate</strong> legal agreement – e.g. Global / regional wealth manager subsidiaries, retail / private banking network, has tied IFAs/agents with a formal legal relationship",
                value: false,
                width: 12,
              },
              {
                id: "1fe99def-f03c-4647-82f6-7b8ad270f109",
                type: "checkbox",
                label:
                  "Sub-distributor / 3rd-Party Intermediary – Distributor’s direct clients have advised mandates for their End Clients e.g. private banks, retail banks",
                value: false,
                width: 12,
              },
              {
                id: "2e44df7b-c085-4ce2-b9dc-cd30cbcf2047",
                type: "checkbox",
                label:
                  "Sub-distributor / 3rd-Party Intermediary – Discretionary Fund Manager where the Structured Products are booked to an individuals named account of the End Client",
                value: false,
                width: 12,
              },
              {
                id: "f5439368-ad54-4855-a242-6fabc9b02db3",
                type: "checkbox",
                label:
                  "Sub-distributor / 3rd-Party Intermediary – IFA Aggregators, products are sold via IFAs/agents that are independent of the Distributor",
                value: false,
                width: 12,
              },
              {
                id: "27027295-557d-49fa-9774-632d20da8e7b",
                type: "checkbox",
                label: "Other - please specify below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["def2bf44-19b9-4294-9e96-0923675809fa"],
                },
              },
              {
                id: "def2bf44-19b9-4294-9e96-0923675809fa",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "84f0ee41-3b89-4dbb-a5b8-77d92f30321a",
            externalID: "2.1.5",
            nav: "2.1.5",
            text: "What is the split in responsibilities between the type of legal entities involved in the distribution chain? Please select all those that are applicable:",
            fields: [
              {
                id: "51f328a1-3fb3-49c8-a7f0-c18c4c9fa12d",
                type: "dropdown",
                width: 12,
                label: "Distributor",
                source: "investorJurisdiction",
                validate: {
                  required: true,
                },
              },
              {
                id: "e471e15b-71ca-46aa-9948-10f5781fa4a6",
                type: "dropdown",
                width: 12,
                label: "Sub-Distributors/3rd Party Intermediaries",
                source: "investorJurisdiction",
              },
              {
                id: "ba512561-e2f9-47a8-a48a-bff075abcbf3",
                type: "dropdown",
                width: 12,
                label: "Custodian",
                source: "investorJurisdiction",
              },
            ],
          },
          {
            id: "d2ccb2d9-679d-459b-98b0-bc9a698d2905",
            externalID: "2.1.6",
            nav: "2.1.6",
            text: "Please confirm which entity will send orders to and settle with the Issuer ",
            validate: {
              groupArr: [
                "d5b99d43-7c9f-49d5-b05b-007e9b591173",
                [
                  "120a2d1a-5445-55ac-b24c-a96c2b075d02",
                  "f9942ad5-1277-5a46-bed6-cdb8f4295aa0",
                  "785467cc-6881-5fc2-8df8-e5dff9739558",
                ],
                "36c8f975-096c-4647-adce-38c610fb6ea0",
                [
                  "ebb3529c-4aee-5022-94c3-37146d91b772",
                  "646c6ec7-b942-5e8f-bd67-9450087d2d22",
                  "28db2d0a-81e3-51a4-81dc-4b8765257918",
                ],
                "2849992b-f7d3-4eb4-bb52-e7752f04424e",
                "19c1ca75-1da8-425b-a363-903ddd694c48",
                [
                  "d9f6d87c-967b-5307-a88f-5e69a3029969",
                  "adc1b06f-1267-5525-b584-2b3598f1afc7",
                  "b38a61c5-45b6-54fa-8d5a-015f5d6ccaf5",
                  "8ba3fd19-42c6-53f5-aa38-189790e45f6e",
                ],
                [
                  "3ef65810-2c43-5f80-b594-1486a35e63ac",
                  "956d15fb-b3cb-5aa9-a398-e37693cd5968",
                  "4ebf4fe3-dabd-564f-9c76-07e9cca56a68",
                ],
                [
                  "2f0c6cec-a976-50f3-89be-ee7e16f0cba6",
                  "a426b875-f9e8-5db3-bbb1-56a228b46d8c",
                  "ff36302b-07f2-5628-ade7-40708f462d43",
                ],
              ],
            },
            fields: [
              {
                id: "d66b9759-2edb-5535-b053-5268b27c5875",
                type: "subHead",
                text: "Entities sending orders to the Issuer",
                width: 12,
              },
              {
                id: "d5b99d43-7c9f-49d5-b05b-007e9b591173",
                type: "checkbox",
                label: "Distributor",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "120a2d1a-5445-55ac-b24c-a96c2b075d02",
                    "f9942ad5-1277-5a46-bed6-cdb8f4295aa0",
                    "785467cc-6881-5fc2-8df8-e5dff9739558",
                  ],
                },
              },
              {
                id: "120a2d1a-5445-55ac-b24c-a96c2b075d02",
                type: "checkbox",
                label: "Direct",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "f9942ad5-1277-5a46-bed6-cdb8f4295aa0",
                type: "checkbox",
                label: "Via Affiliated Custodian",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "785467cc-6881-5fc2-8df8-e5dff9739558",
                type: "checkbox",
                label: "Via appointed 3rd Party Custodian",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "36c8f975-096c-4647-adce-38c610fb6ea0",
                type: "checkbox",
                label: "Sub-Distributor/3rd Party Intermediary",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "ebb3529c-4aee-5022-94c3-37146d91b772",
                    "646c6ec7-b942-5e8f-bd67-9450087d2d22",
                    "28db2d0a-81e3-51a4-81dc-4b8765257918",
                  ],
                },
              },
              {
                id: "ebb3529c-4aee-5022-94c3-37146d91b772",
                type: "checkbox",
                label: "Direct",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "646c6ec7-b942-5e8f-bd67-9450087d2d22",
                type: "checkbox",
                label: "Via Affiliated Custodian",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "28db2d0a-81e3-51a4-81dc-4b8765257918",
                type: "checkbox",
                label: "Via appointed 3rd Party Custodian",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "2849992b-f7d3-4eb4-bb52-e7752f04424e",
                type: "checkbox",
                label: "End Client Custodian",
                value: false,
                width: 12,
              },
              {
                id: "19c1ca75-1da8-425b-a363-903ddd694c48",
                type: "checkbox",
                label: "Other - please explain below",
                value: false,
                width: 12,
                conditional: {
                  enable: ["fbd3ce5f-b4dc-452c-b605-5be31c87a77c"],
                },
              },
              {
                id: "fbd3ce5f-b4dc-452c-b605-5be31c87a77c",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
              {
                id: "78e5e368-52e7-5738-b2ad-3c809fd9fbee",
                type: "subHead",
                text: "Entities settling with the Issuer",
                width: 12,
              },
              {
                id: "d9f6d87c-967b-5307-a88f-5e69a3029969",
                type: "checkbox",
                label: "Distributor",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "3ef65810-2c43-5f80-b594-1486a35e63ac",
                    "956d15fb-b3cb-5aa9-a398-e37693cd5968",
                    "4ebf4fe3-dabd-564f-9c76-07e9cca56a68",
                  ],
                },
              },
              {
                id: "3ef65810-2c43-5f80-b594-1486a35e63ac",
                type: "checkbox",
                label: "Direct",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "956d15fb-b3cb-5aa9-a398-e37693cd5968",
                type: "checkbox",
                label: "Via Affiliated Custodian",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "4ebf4fe3-dabd-564f-9c76-07e9cca56a68",
                type: "checkbox",
                label: "Via appointed 3rd Party Custodian",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "adc1b06f-1267-5525-b584-2b3598f1afc7",
                type: "checkbox",
                label: "Sub-Distributor/3rd Party Intermediary",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "2f0c6cec-a976-50f3-89be-ee7e16f0cba6",
                    "a426b875-f9e8-5db3-bbb1-56a228b46d8c",
                    "ff36302b-07f2-5628-ade7-40708f462d43",
                  ],
                },
              },
              {
                id: "2f0c6cec-a976-50f3-89be-ee7e16f0cba6",
                type: "checkbox",
                label: "Direct",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "a426b875-f9e8-5db3-bbb1-56a228b46d8c",
                type: "checkbox",
                label: "Via Affiliated Custodian",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "ff36302b-07f2-5628-ade7-40708f462d43",
                type: "checkbox",
                label: "Via appointed 3rd Party Custodian",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "b38a61c5-45b6-54fa-8d5a-015f5d6ccaf5",
                type: "checkbox",
                label: "End Client Custodian",
                value: false,
                width: 12,
              },
              {
                id: "8ba3fd19-42c6-53f5-aa38-189790e45f6e",
                type: "checkbox",
                label: "Other - please explain below",
                value: false,
                width: 12,
                conditional: {
                  enable: ["08013d38-cb6a-50cc-b118-786428511f88"],
                },
              },
              {
                id: "08013d38-cb6a-50cc-b118-786428511f88",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "909247e5-8266-4cb5-b116-2e0950deac18",
            externalID: "2.1.7",
            nav: "2.1.7",
            text: "Please confirm the countries and channels the Distributor uses to offer / sell Structured Products directly to the End Clients",
            popover: {
              body: "<p>Direct distribution is where the Distributor’s counterparty is the end client booking the Structured Product in to their own account and not on-selling the product to another entity or ultimate end client.</p><p>If on the other hand the structured product is purchased into the account of your direct clients’ end client (i.e. your indirect client), we consider this to be sold via an Intermediary. For example, a private bank/ financial advisor offering structured products to their advised clients or an Independent Asset Manager responsible for individually accounts managed on a discretionary basis. If so please answer the ‘Intermediary Usage’ questions.</p>",
            },
            dynamic: true,
            dynamicDisable: "N/A - No Direct Distribution",
            dynamicDisableObject: {
              name: "N/A - No Direct Distribution",
              id: "482c5bf2-a0c9-42b6-adcc-6072464ff467",
              arrayId: "c1862b7a-f6da-4b22-ba4f-76c5fb61d11a",
              conditional: {
                parent: {
                  disable: ["367ae060-0677-47b5-b448-179dc3561489"],
                  enable: ["367ae060-0677-47b5-b448-179dc3561489"],
                },
                disable: ["c1862b7a-f6da-4b22-ba4f-76c5fb61d11a"],
                enable: ["c1862b7a-f6da-4b22-ba4f-76c5fb61d11a"],
                disablePriority: true,
              },
            },
            dynamicDisableSubQuestion: ["367ae060-0677-47b5-b448-179dc3561489"],
            dynamicButtonText: "Add Location",
            fields: [
              [
                {
                  id: "00afb768-96d0-4c26-a6b3-00608c44a255",
                  type: "dropdown",
                  label: "Distributor Sales Force Location(s)",
                  value: "",
                  source: "jurisdictions",
                  search: true,
                  singleSelect: true,
                  checkbox: false,
                  width: 12,
                  validate: {
                    required: true,
                  },
                  popover: {
                    body: "<strong>Distributor Sales Force Location:</strong> i.e. the offices where your sales force are based and where the distribution activity takes place from.",
                  },
                },
                {
                  id: "a64f7700-3cb3-447e-9ffd-bf0ce31092d0",
                  type: "dropdown",
                  label: "Country of Distribution",
                  value: "",
                  source: "jurisdictions",
                  search: true,
                  singleSelect: true,
                  checkbox: false,
                  width: 12,
                  validate: {
                    required: true,
                  },
                  popover: {
                    body: "<strong>Country of Distribution:</strong> The location where the structured product is offered / made available for purchase.",
                  },
                },
                {
                  id: "90b35183-6eea-47fe-a26a-45f110e474c2",
                  type: "dropdown",
                  width: 6,
                  label: "Local Regulatory Adherence Basis",
                  source: "localRegAdherence",
                  singleSelect: true,
                  checkbox: false,
                  validate: {
                    required: true,
                  },
                  popover: {
                    body: "<strong>Local Regulatory Adherence Basis:</strong> How the firm is regulated in the Country of Distribution.",
                  },
                },
                {
                  id: "ce1d22e4-482e-48d5-a452-b08f1afd0b01",
                  type: "dropdown",
                  width: 6,
                  label: "Offer Type",
                  source: "offerTypes",
                  singleSelect: true,
                  checkbox: false,
                  validate: {
                    required: true,
                  },
                },
                {
                  id: "83b5cd3f-fe83-4b6c-8fbd-87535683ad5a",
                  type: "dropdown",
                  width: 3,
                  label: "Advised",
                  popover: {
                    body: "<p><strong>Advised:</strong> Where the Distributor makes personal recommendations on how their direct client should proceed.</p><p>All rows should equal 100%.</p>",
                  },
                  source: "percentage",
                  singleSelect: true,
                  checkbox: false,
                  validate: {
                    percent: [
                      "83b5cd3f-fe83-4b6c-8fbd-87535683ad5a",
                      "c2405d39-7027-4a04-b3cd-e702af93cff2",
                      "0491a3b1-a7b9-4f95-8e06-50d0f051ab18",
                      "09babc53-0b0a-4e9e-927b-7b43863fff00",
                    ],
                  },
                },
                {
                  id: "c2405d39-7027-4a04-b3cd-e702af93cff2",
                  type: "dropdown",
                  width: 3,
                  label: "Non-Advised",
                  popover: {
                    body: "<p><strong>Non-Advised:</strong> Where the Distributor does not make any personal recommendation, but merely provides generic information and leaves their direct client to decide how they wish to proceed. Appropriateness considerations may be required for complex products.</p><p>All rows should equal 100%.</p>",
                  },
                  source: "percentage",
                  singleSelect: true,
                  checkbox: false,
                  validate: {
                    percent: [
                      "83b5cd3f-fe83-4b6c-8fbd-87535683ad5a",
                      "c2405d39-7027-4a04-b3cd-e702af93cff2",
                      "0491a3b1-a7b9-4f95-8e06-50d0f051ab18",
                      "09babc53-0b0a-4e9e-927b-7b43863fff00",
                    ],
                  },
                },
                {
                  id: "0491a3b1-a7b9-4f95-8e06-50d0f051ab18",
                  type: "dropdown",
                  width: 3,
                  label: "Execution Only",
                  popover: {
                    body: "<p><strong>Execution Only:</strong> where the Distributor executes transaction upon the specific instructions of a direct client where the Distributor does not give advice on investments relating to the merits or suitability of the transaction. Appropriateness considerations may be required for complex products.</p><p>All rows should equal 100%.</p>",
                  },
                  source: "percentage",
                  singleSelect: true,
                  checkbox: false,
                  validate: {
                    percent: [
                      "83b5cd3f-fe83-4b6c-8fbd-87535683ad5a",
                      "c2405d39-7027-4a04-b3cd-e702af93cff2",
                      "0491a3b1-a7b9-4f95-8e06-50d0f051ab18",
                      "09babc53-0b0a-4e9e-927b-7b43863fff00",
                    ],
                  },
                },
                {
                  id: "09babc53-0b0a-4e9e-927b-7b43863fff00",
                  type: "dropdown",
                  width: 3,
                  label: "Discretionary",
                  popover: {
                    body: "<p><strong>Discretionary – Pooled Mandates:</strong> where the Distributor’s direct clients run pooled discretionary mandates where the structured products are booked to an account in the direct client’s name who operates a pooled investment. i.e. Discretionary Asset Management, Discretionary Fund Managers or portfolio managers where the end clients buys a fund and benefits from the growth of the pooled assets as a fund unit holder.</p><p><strong>Discretionary – Unpooled Mandates:</strong> where the Distributor’s direct client is a discretionary manager with a mandate to use his/her discretion to book the Structured Product to individual named accounts of their end client.</p><p>All rows should equal 100%.</p>",
                  },
                  source: "percentage",
                  singleSelect: true,
                  checkbox: false,
                  validate: {
                    percent: [
                      "83b5cd3f-fe83-4b6c-8fbd-87535683ad5a",
                      "c2405d39-7027-4a04-b3cd-e702af93cff2",
                      "0491a3b1-a7b9-4f95-8e06-50d0f051ab18",
                      "09babc53-0b0a-4e9e-927b-7b43863fff00",
                    ],
                  },
                },
              ],
            ],
          },
          {
            id: "367ae060-0677-47b5-b448-179dc3561489",
            externalID: "2.1.7b",
            nav: "",
            text: " ",
            validate: {
              groupArr: ["06e18744-8961-4068-8d4e-cfec6e1587f2"],
            },
            fields: [
              {
                id: "95d746aa-45c3-42e1-b297-c9681b9e871b",
                type: "textArea",
                label: "Additional Notes (optional)",
                width: 12,
              },
              {
                id: "06e18744-8961-4068-8d4e-cfec6e1587f2",
                type: "checkbox",
                label:
                  "The Distributor has confirmed that he/ she understands and complies with the legal and regulatory requirements applicable to the distribution and marketing of securities including the undertaking and completion of regulatory filings required under any local laws (a) in the above jurisdictions and (b) to U.S. Persons globally",
                value: false,
                width: 12,
              },
            ],
          },
          {
            id: "18cfede6-8699-486e-93d3-9025a578ad94",
            externalID: "2.1.8",
            nav: "2.1.8",
            text: "Please provide an approximate breakdown of the Distributor’s direct investor base and the channels they use to access Structured Products",
            popover: {
              body: "<p>Please describe the investor type of your direct clients and how they access Structured Products offered by the Distributor:</p><ul><li><strong>Advised:</strong> Where the Distributor makes personal recommendations on how their direct client should proceed.</li><li><strong>Non-Advised:</strong> Where the Distributor does not make any personal recommendation, but merely provides generic information and leaves their direct client to decide how they wish to proceed. Appropriateness considerations may be required for complex products.</li><li><strong>Execution Only:</strong> where the Distributor executes transaction upon the specific instructions of a direct client where the Distributor does not give advice on investments relating to the merits or suitability of the transaction. Appropriateness considerations may be required for complex products.</li><li><strong>Discretionary – Pooled Mandates:</strong> where the Distributor’s direct clients run pooled discretionary mandates where the structured products are booked to an account in the direct client’s name who operates a pooled investment.    i.e. Discretionary Asset Management, Discretionary Fund Managers or portfolio managers where the end clients buys a fund and benefits from the growth of the pooled assets as a fund unit holder.</li><li>Discretionary – Unpooled Mandates: where the Distributor’s direct client is a discretionary manager with a mandate to use his/her discretion to book the Structured Product to individual named accounts of their end client.</li></ul>",
            },
            validate: {
              groupArr: [
                "616574ad-fe91-4e90-908d-b89a9ee1e7b1",
                "e659ae94-9bdd-4e9f-a4a2-a5b345a8d954",
                "26a5529e-52d0-40de-ba63-69de8b4a6275",
                "cdec8pws-95f4-4556-9993-0c2a019bcpws",
                "e0ccf404-8166-4820-8d6b-f6fd4ec16c38",
                "144e57ff-dfba-4388-98da-a64b553e0b98",
              ],
            },
            fields: [
              {
                id: "616574ad-fe91-4e90-908d-b89a9ee1e7b1",
                type: "checkbox",
                label: "N/A - No Direct Distribution",
                value: false,
                width: 12,
                conditional: {
                  disablePriority: true,
                  disable: [
                    "e659ae94-9bdd-4e9f-a4a2-a5b345a8d954",
                    "3316582d-32a2-4f14-8b63-6c4da24f4ea5",
                    "f44c934f-df3e-4ddf-99bc-f917cab71a85",
                    "86e0fd2a-3fa8-4394-95fe-59b50aa20019",
                    "8b42e600-8bac-4a6a-8cf3-48ece3d2f6f1",
                    "4cacd6c7-e15f-4815-a4e0-851aef356d5e",
                    "26a5529e-52d0-40de-ba63-69de8b4a6275",
                    "6a292d68-0ded-4063-92cd-4d21d2714d72",
                    "78a5de9b-9790-41d3-937b-b91698d65c77",
                    "638bb257-7193-44c8-af8e-2b9f026b8a4f",
                    "59292884-31dd-4cf6-8b90-649038a94a30",
                    "f70d0ce1-f8f0-42de-8e67-68a1c09f7f99",
                    "cdec8pws-95f4-4556-9993-0c2a019bcpws",
                    "cfe1df3c-c8bf-4322-0001-000000000001",
                    "6f33b397-c23d-4550-0002-000000000001",
                    "872e765c-e111-4390-0002-000000000002",
                    "0566fe30-327a-4795-0002-000000000003",
                    "f3755c68-fbf3-446a-0002-000000000004",
                    "e0ccf404-8166-4820-8d6b-f6fd4ec16c38",
                    "75ea3682-76e3-40c8-9a8f-e78b905bcf45",
                    "da025a04-2b55-4169-ac9f-cccd0523223f",
                    "7027dc52-5b3b-449e-abf4-d938291ecab9",
                    "d01d2117-de8c-4c91-8849-d91d2955967c",
                    "18c199fd-fdee-479e-bdce-c04f19e435cb",
                    "144e57ff-dfba-4388-98da-a64b553e0b98",
                    "9cbcc3e9-bfe2-45c5-8a20-b8484da67834",
                    "c22c2086-1e4f-463a-a023-f438d17a9254",
                    "c73b7130-b953-4de9-a072-b989e7dce656",
                    "69024cae-cc63-4ab6-8d5e-0611b8f546fa",
                    "33d45b1f-a092-4568-9d7a-edbfe4d5f8ac",
                  ],
                  enable: [
                    "e659ae94-9bdd-4e9f-a4a2-a5b345a8d954",
                    "26a5529e-52d0-40de-ba63-69de8b4a6275",
                    "cdec8pws-95f4-4556-9993-0c2a019bcpws",
                    "e0ccf404-8166-4820-8d6b-f6fd4ec16c38",
                    "144e57ff-dfba-4388-98da-a64b553e0b98",
                  ],
                },
              },
              {
                id: "4cbb9d57-6268-4ac4-b46d-fee2d59d0c24",
                type: "subHead",
                text: "",
                width: 12,
              },
              {
                id: "e659ae94-9bdd-4e9f-a4a2-a5b345a8d954",
                type: "checkbox",
                label: "Retail",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "3316582d-32a2-4f14-8b63-6c4da24f4ea5",
                    "f44c934f-df3e-4ddf-99bc-f917cab71a85",
                    "86e0fd2a-3fa8-4394-95fe-59b50aa20019",
                    "8b42e600-8bac-4a6a-8cf3-48ece3d2f6f1",
                    "4cacd6c7-e15f-4815-a4e0-851aef356d5e",
                  ],
                },
              },
              {
                id: "3316582d-32a2-4f14-8b63-6c4da24f4ea5",
                type: "input",
                label:
                  "Please confirm any additional definitions used e.g. Basic, Informed, Advanced, Expert",
                width: 12,
                validate: {
                  required: true,
                },
                disabled: true,
              },
              {
                id: "f44c934f-df3e-4ddf-99bc-f917cab71a85",
                type: "dropdown",
                width: 3,
                label: "Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "f44c934f-df3e-4ddf-99bc-f917cab71a85",
                    "86e0fd2a-3fa8-4394-95fe-59b50aa20019",
                    "8b42e600-8bac-4a6a-8cf3-48ece3d2f6f1",
                    "4cacd6c7-e15f-4815-a4e0-851aef356d5e",
                  ],
                },
                disabled: true,
              },
              {
                id: "86e0fd2a-3fa8-4394-95fe-59b50aa20019",
                type: "dropdown",
                width: 3,
                label: "Non-Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "f44c934f-df3e-4ddf-99bc-f917cab71a85",
                    "86e0fd2a-3fa8-4394-95fe-59b50aa20019",
                    "8b42e600-8bac-4a6a-8cf3-48ece3d2f6f1",
                    "4cacd6c7-e15f-4815-a4e0-851aef356d5e",
                  ],
                },
                disabled: true,
              },
              {
                id: "8b42e600-8bac-4a6a-8cf3-48ece3d2f6f1",
                type: "dropdown",
                width: 3,
                label: "Execution Only",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "f44c934f-df3e-4ddf-99bc-f917cab71a85",
                    "86e0fd2a-3fa8-4394-95fe-59b50aa20019",
                    "8b42e600-8bac-4a6a-8cf3-48ece3d2f6f1",
                    "4cacd6c7-e15f-4815-a4e0-851aef356d5e",
                  ],
                },
                disabled: true,
              },
              {
                id: "4cacd6c7-e15f-4815-a4e0-851aef356d5e",
                type: "dropdown",
                width: 3,
                label: "Discretionary",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "f44c934f-df3e-4ddf-99bc-f917cab71a85",
                    "86e0fd2a-3fa8-4394-95fe-59b50aa20019",
                    "8b42e600-8bac-4a6a-8cf3-48ece3d2f6f1",
                    "4cacd6c7-e15f-4815-a4e0-851aef356d5e",
                  ],
                },
                disabled: true,
              },
              {
                id: "18e254ed-9f42-4581-a738-92615d2c9602",
                type: "subHead",
                text: "",
                width: 12,
              },
              {
                id: "26a5529e-52d0-40de-ba63-69de8b4a6275",
                type: "checkbox",
                label: "Elective /  Opt Up / Qualified Investor",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "6a292d68-0ded-4063-92cd-4d21d2714d72",
                    "78a5de9b-9790-41d3-937b-b91698d65c77",
                    "638bb257-7193-44c8-af8e-2b9f026b8a4f",
                    "59292884-31dd-4cf6-8b90-649038a94a30",
                    "f70d0ce1-f8f0-42de-8e67-68a1c09f7f99",
                  ],
                },
              },
              {
                id: "6a292d68-0ded-4063-92cd-4d21d2714d72",
                type: "input",
                label: "",
                width: 12,
                validate: {
                  required: true,
                },
                disabled: true,
              },
              {
                id: "78a5de9b-9790-41d3-937b-b91698d65c77",
                type: "dropdown",
                width: 3,
                label: "Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "78a5de9b-9790-41d3-937b-b91698d65c77",
                    "638bb257-7193-44c8-af8e-2b9f026b8a4f",
                    "59292884-31dd-4cf6-8b90-649038a94a30",
                    "f70d0ce1-f8f0-42de-8e67-68a1c09f7f99",
                  ],
                },
                disabled: true,
              },
              {
                id: "638bb257-7193-44c8-af8e-2b9f026b8a4f",
                type: "dropdown",
                width: 3,
                label: "Non-Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "78a5de9b-9790-41d3-937b-b91698d65c77",
                    "638bb257-7193-44c8-af8e-2b9f026b8a4f",
                    "59292884-31dd-4cf6-8b90-649038a94a30",
                    "f70d0ce1-f8f0-42de-8e67-68a1c09f7f99",
                  ],
                },
                disabled: true,
              },
              {
                id: "59292884-31dd-4cf6-8b90-649038a94a30",
                type: "dropdown",
                width: 3,
                label: "Execution Only",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "78a5de9b-9790-41d3-937b-b91698d65c77",
                    "638bb257-7193-44c8-af8e-2b9f026b8a4f",
                    "59292884-31dd-4cf6-8b90-649038a94a30",
                    "f70d0ce1-f8f0-42de-8e67-68a1c09f7f99",
                  ],
                },
                disabled: true,
              },
              {
                id: "f70d0ce1-f8f0-42de-8e67-68a1c09f7f99",
                type: "dropdown",
                width: 3,
                label: "Discretionary",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "78a5de9b-9790-41d3-937b-b91698d65c77",
                    "638bb257-7193-44c8-af8e-2b9f026b8a4f",
                    "59292884-31dd-4cf6-8b90-649038a94a30",
                    "f70d0ce1-f8f0-42de-8e67-68a1c09f7f99",
                  ],
                },
                disabled: true,
              },
              {
                id: "de6d3f56-0221-4d2a-afff-4917bd033e3b",
                type: "subHead",
                text: "",
                width: 12,
              },
              {
                id: "cdec8pws-95f4-4556-9993-0c2a019bcpws",
                type: "checkbox",
                label: "Per Se Professional",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "cfe1df3c-c8bf-4322-0001-000000000001",
                    "6f33b397-c23d-4550-0002-000000000001",
                    "872e765c-e111-4390-0002-000000000002",
                    "0566fe30-327a-4795-0002-000000000003",
                    "f3755c68-fbf3-446a-0002-000000000004",
                  ],
                },
              },
              {
                id: "cfe1df3c-c8bf-4322-0001-000000000001",
                type: "input",
                label: "Typical client types:",
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                disabled: true,
              },
              {
                id: "6f33b397-c23d-4550-0002-000000000001",
                type: "dropdown",
                width: 3,
                label: "Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "6f33b397-c23d-4550-0002-000000000001",
                    "872e765c-e111-4390-0002-000000000002",
                    "0566fe30-327a-4795-0002-000000000003",
                    "f3755c68-fbf3-446a-0002-000000000004",
                  ],
                },
                disabled: true,
              },
              {
                id: "872e765c-e111-4390-0002-000000000002",
                type: "dropdown",
                width: 3,
                label: "Non-Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "6f33b397-c23d-4550-0002-000000000001",
                    "872e765c-e111-4390-0002-000000000002",
                    "0566fe30-327a-4795-0002-000000000003",
                    "f3755c68-fbf3-446a-0002-000000000004",
                  ],
                },
                disabled: true,
              },
              {
                id: "0566fe30-327a-4795-0002-000000000003",
                type: "dropdown",
                width: 3,
                label: "Execution Only",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "6f33b397-c23d-4550-0002-000000000001",
                    "872e765c-e111-4390-0002-000000000002",
                    "0566fe30-327a-4795-0002-000000000003",
                    "f3755c68-fbf3-446a-0002-000000000004",
                  ],
                },
                disabled: true,
              },
              {
                id: "f3755c68-fbf3-446a-0002-000000000004",
                type: "dropdown",
                width: 3,
                label: "Discretionary",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "6f33b397-c23d-4550-0002-000000000001",
                    "872e765c-e111-4390-0002-000000000002",
                    "0566fe30-327a-4795-0002-000000000003",
                    "f3755c68-fbf3-446a-0002-000000000004",
                  ],
                },
                disabled: true,
              },
              {
                id: "a1a0439f-f87b-4d0f-bcda-36fd09d90b23",
                type: "subHead",
                text: "",
                width: 12,
              },
              {
                id: "e0ccf404-8166-4820-8d6b-f6fd4ec16c38",
                type: "checkbox",
                label: "Eligible Counterparty",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "75ea3682-76e3-40c8-9a8f-e78b905bcf45",
                    "da025a04-2b55-4169-ac9f-cccd0523223f",
                    "7027dc52-5b3b-449e-abf4-d938291ecab9",
                    "d01d2117-de8c-4c91-8849-d91d2955967c",
                    "18c199fd-fdee-479e-bdce-c04f19e435cb",
                  ],
                },
              },
              {
                id: "75ea3682-76e3-40c8-9a8f-e78b905bcf45",
                type: "input",
                label: "",
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                disabled: true,
              },
              {
                id: "da025a04-2b55-4169-ac9f-cccd0523223f",
                type: "dropdown",
                width: 3,
                label: "Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "da025a04-2b55-4169-ac9f-cccd0523223f",
                    "7027dc52-5b3b-449e-abf4-d938291ecab9",
                    "d01d2117-de8c-4c91-8849-d91d2955967c",
                    "18c199fd-fdee-479e-bdce-c04f19e435cb",
                  ],
                },
                disabled: true,
              },
              {
                id: "7027dc52-5b3b-449e-abf4-d938291ecab9",
                type: "dropdown",
                width: 3,
                label: "Non-Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "da025a04-2b55-4169-ac9f-cccd0523223f",
                    "7027dc52-5b3b-449e-abf4-d938291ecab9",
                    "d01d2117-de8c-4c91-8849-d91d2955967c",
                    "18c199fd-fdee-479e-bdce-c04f19e435cb",
                  ],
                },
                disabled: true,
              },
              {
                id: "d01d2117-de8c-4c91-8849-d91d2955967c",
                type: "dropdown",
                width: 3,
                label: "Execution Only",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "da025a04-2b55-4169-ac9f-cccd0523223f",
                    "7027dc52-5b3b-449e-abf4-d938291ecab9",
                    "d01d2117-de8c-4c91-8849-d91d2955967c",
                    "18c199fd-fdee-479e-bdce-c04f19e435cb",
                  ],
                },
                disabled: true,
              },
              {
                id: "18c199fd-fdee-479e-bdce-c04f19e435cb",
                type: "dropdown",
                width: 3,
                label: "Discretionary",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "da025a04-2b55-4169-ac9f-cccd0523223f",
                    "7027dc52-5b3b-449e-abf4-d938291ecab9",
                    "d01d2117-de8c-4c91-8849-d91d2955967c",
                    "18c199fd-fdee-479e-bdce-c04f19e435cb",
                  ],
                },
                disabled: true,
              },
              {
                id: "fa6898db-175b-4a36-bbd5-fe24c2b8089b",
                type: "subHead",
                text: "",
                width: 12,
              },
              {
                id: "144e57ff-dfba-4388-98da-a64b553e0b98",
                type: "checkbox",
                label: "Other",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "9cbcc3e9-bfe2-45c5-8a20-b8484da67834",
                    "c22c2086-1e4f-463a-a023-f438d17a9254",
                    "c73b7130-b953-4de9-a072-b989e7dce656",
                    "69024cae-cc63-4ab6-8d5e-0611b8f546fa",
                    "33d45b1f-a092-4568-9d7a-edbfe4d5f8ac",
                  ],
                },
              },
              {
                id: "9cbcc3e9-bfe2-45c5-8a20-b8484da67834",
                type: "input",
                label: "Please confirm deifnition used:",
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                disabled: true,
              },
              {
                id: "c22c2086-1e4f-463a-a023-f438d17a9254",
                type: "dropdown",
                width: 3,
                label: "Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "c22c2086-1e4f-463a-a023-f438d17a9254",
                    "c73b7130-b953-4de9-a072-b989e7dce656",
                    "69024cae-cc63-4ab6-8d5e-0611b8f546fa",
                    "33d45b1f-a092-4568-9d7a-edbfe4d5f8ac",
                  ],
                },
                disabled: true,
              },
              {
                id: "c73b7130-b953-4de9-a072-b989e7dce656",
                type: "dropdown",
                width: 3,
                label: "Non-Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "c22c2086-1e4f-463a-a023-f438d17a9254",
                    "c73b7130-b953-4de9-a072-b989e7dce656",
                    "69024cae-cc63-4ab6-8d5e-0611b8f546fa",
                    "33d45b1f-a092-4568-9d7a-edbfe4d5f8ac",
                  ],
                },
                disabled: true,
              },
              {
                id: "69024cae-cc63-4ab6-8d5e-0611b8f546fa",
                type: "dropdown",
                width: 3,
                label: "Execution Only",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "c22c2086-1e4f-463a-a023-f438d17a9254",
                    "c73b7130-b953-4de9-a072-b989e7dce656",
                    "69024cae-cc63-4ab6-8d5e-0611b8f546fa",
                    "33d45b1f-a092-4568-9d7a-edbfe4d5f8ac",
                  ],
                },
                disabled: true,
              },
              {
                id: "33d45b1f-a092-4568-9d7a-edbfe4d5f8ac",
                type: "dropdown",
                width: 3,
                label: "Discretionary",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "c22c2086-1e4f-463a-a023-f438d17a9254",
                    "c73b7130-b953-4de9-a072-b989e7dce656",
                    "69024cae-cc63-4ab6-8d5e-0611b8f546fa",
                    "33d45b1f-a092-4568-9d7a-edbfe4d5f8ac",
                  ],
                },
                disabled: true,
              },
            ],
          },
          {
            id: "11054d2a-3bc2-4608-a107-a011bcbb81fd",
            externalID: "2.1.9",
            nav: "2.1.9",
            text: "How is the Distributor compensated for their role in Structured Product transactions?",
            validate: {
              groupArr: [
                "53849ce7-cf0d-403c-8389-e9799a52079b",
                "5f5efdd5-5db8-4667-995e-889618f94b18",
                "c8867379-76f4-422e-8615-0e1515d32d95",
                "4219d503-7611-4d2c-9827-73ac583b7c9b",
                "9d8adf70-09d8-4666-9a0a-071ef5e53108",
                ["9ccae2fc-2ca3-4c7c-84b7-2e48dd519b16"],
              ],
            },
            fields: [
              {
                id: "53849ce7-cf0d-403c-8389-e9799a52079b",
                type: "checkbox",
                label: "Via the Issuer - Buy at a discount and reoffer",
                value: false,
                width: 12,
              },
              {
                id: "5f5efdd5-5db8-4667-995e-889618f94b18",
                type: "checkbox",
                label: "Via the Issuer - Separate payment via wire",
                value: false,
                width: 12,
              },
              {
                id: "c8867379-76f4-422e-8615-0e1515d32d95",
                type: "checkbox",
                label: "Via the Client - Separate payment via wire",
                value: false,
                width: 12,
              },
              {
                id: "4219d503-7611-4d2c-9827-73ac583b7c9b",
                type: "checkbox",
                label: "Via the Client - Fees on AUM and no mark-up",
                value: false,
                width: 12,
              },
              {
                id: "9d8adf70-09d8-4666-9a0a-071ef5e53108",
                type: "checkbox",
                label: "Other - please specify below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["95a9b3c2-dc5f-44da-b958-0b8f9aa4c5ce"],
                },
              },
              {
                id: "95a9b3c2-dc5f-44da-b958-0b8f9aa4c5ce",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
              {
                id: "a91ca0d6-4d7d-4b6f-b327-3cdb21e7a8fd",
                type: "subHead",
                text: "",
                width: 12,
              },
              {
                id: "9ccae2fc-2ca3-4c7c-84b7-2e48dd519b16",
                type: "checkbox",
                label:
                  "Distributor confirms that payment will be to a bank account in the name of the distributor held in the main jurisdiction of operation",
                value: false,
                width: 12,
              },
            ],
          },
        ],
      },
      {
        id: "feb86ebd-fece-4e81-a301-4c6f65a1fc3d",
        name: "Sub-distributor/ 3rd-Party Intermediary Usage ",
        nav: "2",
        questions: [
          {
            id: "83386224-3148-45b1-81eb-e2517344ecfd",
            externalID: "2.2.1",
            nav: "2.2.1",
            text: "Please confirm the countries and channels where the Sub-distributor/ 3rd-Party intermediary offer/ sell Structured Products directly to the End Clients in each country",
            dynamic: true,
            dynamicDisable:
              "N/A - No Sub-distributor/ 3rd-Party intermediary are used",
            dynamicDisableObject: {
              name: "N/A - No Sub-distributor/ 3rd-Party intermediary are used",
              id: "309bbbb1-d76d-4cff-b6f4-d6357b7c6df7",
              arrayId: "64d952b9-74e5-4f9d-9ac0-81295df4bf9a",
              conditional: {
                parent: {
                  disable: ["2989fa7e-8a2c-4544-87a2-ac9ec5d0999c"],
                  enable: ["2989fa7e-8a2c-4544-87a2-ac9ec5d0999c"],
                },
                disable: ["64d952b9-74e5-4f9d-9ac0-81295df4bf9a"],
                enable: ["64d952b9-74e5-4f9d-9ac0-81295df4bf9a"],
                disablePriority: true,
              },
            },
            dynamicDisableSubQuestion: ["2989fa7e-8a2c-4544-87a2-ac9ec5d0999c"],
            dynamicButtonText: "Add Location",
            fields: [
              [
                {
                  id: "180123af-d325-4b99-b5b1-1416b1655dd7",
                  type: "dropdown",
                  label: "Sub-Distributor/3rd-Party Intermediary Sales force Location",
                  width: 12,
                  source: "jurisdictions",
                  value: null,
                  search: true,
                  singleSelect: true,
                  checkbox: false,
                  validate: {
                    required: true,
                  },
                  popover: {
                    body: "<strong>Sub-Distributor Sales Force Location:</strong> i.e. the offices where your sales force are based and where the distribution activity takes place from.",
                  },
                },
                {
                  id: "08278aa3-b8f9-431e-81ba-c88faba7cc8d",
                  type: "dropdown",
                  label: "Country of Distribution",
                  source: "jurisdictions",
                  value: null,
                  search: true,
                  singleSelect: true,
                  checkbox: false,
                  width: 12,
                  validate: {
                    required: true,
                  },
                  popover: {
                    body: "<strong>Country of Distribution:</strong> The location where the structured product is offered / made available for purchase.",
                  },
                },
                {
                  id: "ea9a363c-dc46-4814-9d46-8edf184a89fd",
                  type: "dropdown",
                  width: 6,
                  label: "Local Regulatory Adherence Basis",
                  source: "localRegAdherence",
                  value: null,
                  singleSelect: true,
                  checkbox: false,
                  validate: {
                    required: true,
                  },
                  popover: {
                    body: "<strong>Local Regulatory Adherence Basis:</strong> How the firm is regulated in the Country of Distribution.",
                  },
                },
                {
                  id: "39cdf83b-9dde-47e7-9a6e-3a23c1a000f6",
                  type: "dropdown",
                  width: 6,
                  label: "Offer Type",
                  source: "offerTypes",
                  value: null,
                  singleSelect: true,
                  checkbox: false,
                  validate: {
                    required: true,
                  },
                },
                {
                  id: "59a9eb61-83bb-4cf7-b544-ceeeddd4a800",
                  type: "dropdown",
                  width: 3,
                  label: "Advised",
                  popover: {
                    body: "<p><strong>Advised:</strong> Where the Distributor makes personal recommendations on how their direct client should proceed.</p><p>All rows should equal 100%.</p>",
                  },
                  source: "percentage",
                  value: null,
                  singleSelect: true,
                  checkbox: false,
                  validate: {
                    percent: [
                      "59a9eb61-83bb-4cf7-b544-ceeeddd4a800",
                      "68ad51cb-dca1-480a-b929-2b0f6e5e3bfa",
                      "e5cea8e1-b3c3-433d-97ac-793b019b596e",
                      "e3fb8ba6-aba3-4bec-974a-f77a0e0a5f03",
                    ],
                  },
                },
                {
                  id: "68ad51cb-dca1-480a-b929-2b0f6e5e3bfa",
                  type: "dropdown",
                  width: 3,
                  label: "Non-Advised",
                  popover: {
                    body: "<p><strong>Non-Advised:</strong> Where the Distributor does not make any personal recommendation, but merely provides generic information and leaves their direct client to decide how they wish to proceed. Appropriateness considerations may be required for complex products.</p><p>All rows should equal 100%.</p>",
                  },
                  source: "percentage",
                  value: null,
                  singleSelect: true,
                  checkbox: false,
                  validate: {
                    percent: [
                      "59a9eb61-83bb-4cf7-b544-ceeeddd4a800",
                      "68ad51cb-dca1-480a-b929-2b0f6e5e3bfa",
                      "e5cea8e1-b3c3-433d-97ac-793b019b596e",
                      "e3fb8ba6-aba3-4bec-974a-f77a0e0a5f03",
                    ],
                  },
                },
                {
                  id: "e5cea8e1-b3c3-433d-97ac-793b019b596e",
                  type: "dropdown",
                  width: 3,
                  label: "Execution Only",
                  popover: {
                    body: "<p><strong>Execution Only:</strong> where the Distributor executes transaction upon the specific instructions of a direct client where the Distributor does not give advice on investments relating to the merits or suitability of the transaction. Appropriateness considerations may be required for complex products.</p><p>All rows should equal 100%.</p>",
                  },
                  source: "percentage",
                  value: null,
                  singleSelect: true,
                  checkbox: false,
                  validate: {
                    percent: [
                      "59a9eb61-83bb-4cf7-b544-ceeeddd4a800",
                      "68ad51cb-dca1-480a-b929-2b0f6e5e3bfa",
                      "e5cea8e1-b3c3-433d-97ac-793b019b596e",
                      "e3fb8ba6-aba3-4bec-974a-f77a0e0a5f03",
                    ],
                  },
                },
                {
                  id: "e3fb8ba6-aba3-4bec-974a-f77a0e0a5f03",
                  type: "dropdown",
                  width: 3,
                  label: "Discretionary",
                  popover: {
                    body: "<p><strong>Discretionary – Pooled Mandates:</strong> where the Distributor’s direct clients run pooled discretionary mandates where the structured products are booked to an account in the direct client’s name who operates a pooled investment. i.e. Discretionary Asset Management, Discretionary Fund Managers or portfolio managers where the end clients buys a fund and benefits from the growth of the pooled assets as a fund unit holder.</p><p><strong>Discretionary – Unpooled Mandates:</strong> where the Distributor’s direct client is a discretionary manager with a mandate to use his/her discretion to book the Structured Product to individual named accounts of their end client.</p><p>All rows should equal 100%.</p>",
                  },
                  source: "percentage",
                  value: null,
                  singleSelect: true,
                  checkbox: false,
                  validate: {
                    percent: [
                      "59a9eb61-83bb-4cf7-b544-ceeeddd4a800",
                      "68ad51cb-dca1-480a-b929-2b0f6e5e3bfa",
                      "e5cea8e1-b3c3-433d-97ac-793b019b596e",
                      "e3fb8ba6-aba3-4bec-974a-f77a0e0a5f03",
                    ],
                  },
                },
              ],
            ],
          },
          {
            id: "2989fa7e-8a2c-4544-87a2-ac9ec5d0999c",
            externalID: "2.2.1b",
            nav: "",
            text: " ",
            validate: {
              groupArr: ["fcec60a7-da17-4765-9896-c2ae78e75265"],
            },
            fields: [
              {
                id: "fcec60a7-da17-4765-9896-c2ae78e75265",
                type: "checkbox",
                label:
                  "The Distributor has confirmed that the intermediaries / wholesalers used have the required licenses in place and comply with the legal and regulatory requirements applicable to the distribution and marketing of securities including the undertaking and completion of regulatory  filings required under any local laws (a) in the above jurisdictions and (b) to U.S. Persons globally",
                value: false,
                width: 12,
              },
            ],
          },
          {
            id: "cccabe65-609d-44c3-855d-f2f9f74f6e20",
            externalID: "2.2.2",
            nav: "2.2.2",
            text: "Please provide an approximate breakdown of the Distributor’s indirect client base (i.e. look through to any 3rd-Party intermediary’s end clients) and the channels they use to access Structured Products",
            popover: {
              body: "Please describe the investor type of your indirect clients and how they access Structured Products offered by the Distributor.",
            },
            validate: {
              groupArr: [
                "8d91d2fe-940e-4dfe-b96d-ae1a0a2df990",
                "c627c2e1-c18a-4893-a947-f2a769a731f5",
                "209f5ffe-8d05-43c0-a604-7b6b2cf821ee",
                "222c8pws-95f4-4556-9993-0c2aam9bcpws",
                "aa878978-f33c-4af8-b52f-95e2bf198b67",
                "73f4d017-db17-4394-8538-637f2a8414f2",
              ],
            },
            fields: [
              {
                id: "8d91d2fe-940e-4dfe-b96d-ae1a0a2df990",
                type: "checkbox",
                label: "N/A – No Sub-distributor/ 3rd-Party intermediary are used",
                value: false,
                width: 12,
                conditional: {
                  disablePriority: true,
                  disable: [
                    "1942548c-b22b-46c5-a2b7-b65f929e8cfb",
                    "c627c2e1-c18a-4893-a947-f2a769a731f5",
                    "5494ed61-7d8a-434c-952b-0f2c88e75586",
                    "a7453885-6bbe-4d08-b1a2-fafb6d01ffcd",
                    "4e1b91b4-0086-4c92-ae6e-ebd1ffdc1e10",
                    "8654104d-7426-4070-abfd-eefc1242c297",
                    "209f5ffe-8d05-43c0-a604-7b6b2cf821ee",
                    "19cd943b-4118-43bb-b309-4522182eaca2",
                    "e4554f9a-1261-4150-a9c1-68e367f997f0",
                    "81e410cd-f1f6-4a4f-844c-45db6b170545",
                    "b8f06d86-ac13-41c3-b076-1ca632ee41ad",
                    "4bb6a959-aba9-4a7b-9c3b-afe6513cd0c5",
                    "222c8pws-95f4-4556-9993-0c2aam9bcpws",
                    "2221df3c-c8bf-4322-0001-000000000001",
                    "2223b397-c23d-4550-0002-000000000001",
                    "222e765c-e111-4390-0002-000000000002",
                    "2226fe30-327a-4795-0002-000000000003",
                    "22255c68-fbf3-446a-0002-000000000004",
                    "aa878978-f33c-4af8-b52f-95e2bf198b67",
                    "5330fad5-6eb8-4b61-8bd1-306248caae53",
                    "b2eca7e4-ca95-4501-92cb-6aa29e6230e9",
                    "7bafa2ac-bd39-4946-b512-70f28ffc03ea",
                    "f5b7bd3e-8012-4713-9127-31f4e2265324",
                    "f4f792e4-078a-4f2c-8ac1-1b6a228536d4",
                    "73f4d017-db17-4394-8538-637f2a8414f2",
                    "e1f218ad-ce55-4513-8be6-9c368682d3f8",
                    "e1a6be08-fcb4-4313-bb57-8dadcd085b99",
                    "a357df09-2aeb-4234-9c97-bb6e27b8ba16",
                    "a5f83b3e-10c0-4752-abc1-560b2a73705e",
                    "5cdf37b0-eb1f-40a6-998d-77374e53f9e4",
                  ],
                  enable: [
                    "1942548c-b22b-46c5-a2b7-b65f929e8cfb",
                    "209f5ffe-8d05-43c0-a604-7b6b2cf821ee",
                    "222c8pws-95f4-4556-9993-0c2aam9bcpws",
                    "aa878978-f33c-4af8-b52f-95e2bf198b67",
                    "73f4d017-db17-4394-8538-637f2a8414f2",
                  ],
                },
              },
              {
                id: "ee825116-e25a-5c03-a5a4-b8e5fef3cf0a",
                type: "subHead",
                text: "",
                width: 12,
              },
              {
                id: "1942548c-b22b-46c5-a2b7-b65f929e8cfb",
                type: "checkbox",
                label: "Retail",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "c627c2e1-c18a-4893-a947-f2a769a731f5",
                    "5494ed61-7d8a-434c-952b-0f2c88e75586",
                    "a7453885-6bbe-4d08-b1a2-fafb6d01ffcd",
                    "4e1b91b4-0086-4c92-ae6e-ebd1ffdc1e10",
                    "8654104d-7426-4070-abfd-eefc1242c297",
                  ],
                },
              },
              {
                id: "c627c2e1-c18a-4893-a947-f2a769a731f5",
                type: "input",
                width: 12,
                label:
                  "Please confirm any additional definitions used e.g. Basic, Informed, Advanced, Expert",
                validate: {
                  required: true,
                },
                disabled: true,
              },
              {
                id: "5494ed61-7d8a-434c-952b-0f2c88e75586",
                type: "dropdown",
                width: 3,
                label: "Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "5494ed61-7d8a-434c-952b-0f2c88e75586",
                    "a7453885-6bbe-4d08-b1a2-fafb6d01ffcd",
                    "4e1b91b4-0086-4c92-ae6e-ebd1ffdc1e10",
                    "8654104d-7426-4070-abfd-eefc1242c297",
                  ],
                },
                disabled: true,
              },
              {
                id: "a7453885-6bbe-4d08-b1a2-fafb6d01ffcd",
                type: "dropdown",
                width: 3,
                label: "Non-Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "5494ed61-7d8a-434c-952b-0f2c88e75586",
                    "a7453885-6bbe-4d08-b1a2-fafb6d01ffcd",
                    "4e1b91b4-0086-4c92-ae6e-ebd1ffdc1e10",
                    "8654104d-7426-4070-abfd-eefc1242c297",
                  ],
                },
                disabled: true,
              },
              {
                id: "4e1b91b4-0086-4c92-ae6e-ebd1ffdc1e10",
                type: "dropdown",
                width: 3,
                label: "Execution Only",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "5494ed61-7d8a-434c-952b-0f2c88e75586",
                    "a7453885-6bbe-4d08-b1a2-fafb6d01ffcd",
                    "4e1b91b4-0086-4c92-ae6e-ebd1ffdc1e10",
                    "8654104d-7426-4070-abfd-eefc1242c297",
                  ],
                },
                disabled: true,
              },
              {
                id: "8654104d-7426-4070-abfd-eefc1242c297",
                type: "dropdown",
                width: 3,
                label: "Discretionary",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "5494ed61-7d8a-434c-952b-0f2c88e75586",
                    "a7453885-6bbe-4d08-b1a2-fafb6d01ffcd",
                    "4e1b91b4-0086-4c92-ae6e-ebd1ffdc1e10",
                    "8654104d-7426-4070-abfd-eefc1242c297",
                  ],
                },
                disabled: true,
              },
              {
                id: "2825dc5f-a8ff-4842-8802-851ccd309b5d",
                type: "subHead",
                text: "",
                width: 12,
              },
              {
                id: "209f5ffe-8d05-43c0-a604-7b6b2cf821ee",
                type: "checkbox",
                label: "Elective /  Opt Up / Qualified Investor",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "19cd943b-4118-43bb-b309-4522182eaca2",
                    "e4554f9a-1261-4150-a9c1-68e367f997f0",
                    "81e410cd-f1f6-4a4f-844c-45db6b170545",
                    "b8f06d86-ac13-41c3-b076-1ca632ee41ad",
                    "4bb6a959-aba9-4a7b-9c3b-afe6513cd0c5",
                  ],
                },
              },
              {
                id: "19cd943b-4118-43bb-b309-4522182eaca2",
                type: "input",
                width: 12,
                label: "",
                validate: {
                  required: true,
                },
                disabled: true,
              },
              {
                id: "e4554f9a-1261-4150-a9c1-68e367f997f0",
                type: "dropdown",
                width: 3,
                label: "Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "e4554f9a-1261-4150-a9c1-68e367f997f0",
                    "81e410cd-f1f6-4a4f-844c-45db6b170545",
                    "b8f06d86-ac13-41c3-b076-1ca632ee41ad",
                    "4bb6a959-aba9-4a7b-9c3b-afe6513cd0c5",
                  ],
                },
                disabled: true,
              },
              {
                id: "81e410cd-f1f6-4a4f-844c-45db6b170545",
                type: "dropdown",
                width: 3,
                label: "Non-Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "e4554f9a-1261-4150-a9c1-68e367f997f0",
                    "81e410cd-f1f6-4a4f-844c-45db6b170545",
                    "b8f06d86-ac13-41c3-b076-1ca632ee41ad",
                    "4bb6a959-aba9-4a7b-9c3b-afe6513cd0c5",
                  ],
                },
                disabled: true,
              },
              {
                id: "b8f06d86-ac13-41c3-b076-1ca632ee41ad",
                type: "dropdown",
                width: 3,
                label: "Execution Only",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "e4554f9a-1261-4150-a9c1-68e367f997f0",
                    "81e410cd-f1f6-4a4f-844c-45db6b170545",
                    "b8f06d86-ac13-41c3-b076-1ca632ee41ad",
                    "4bb6a959-aba9-4a7b-9c3b-afe6513cd0c5",
                  ],
                },
                disabled: true,
              },
              {
                id: "4bb6a959-aba9-4a7b-9c3b-afe6513cd0c5",
                type: "dropdown",
                width: 3,
                label: "Discretionary",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "e4554f9a-1261-4150-a9c1-68e367f997f0",
                    "81e410cd-f1f6-4a4f-844c-45db6b170545",
                    "b8f06d86-ac13-41c3-b076-1ca632ee41ad",
                    "4bb6a959-aba9-4a7b-9c3b-afe6513cd0c5",
                  ],
                },
                disabled: true,
              },
              {
                id: "dd424597-c277-4125-8c22-3f36ddee4d05",
                type: "subHead",
                text: "",
                width: 12,
              },
              {
                id: "222c8pws-95f4-4556-9993-0c2aam9bcpws",
                type: "checkbox",
                label: "Per Se Professional",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "2221df3c-c8bf-4322-0001-000000000001",
                    "2223b397-c23d-4550-0002-000000000001",
                    "222e765c-e111-4390-0002-000000000002",
                    "2226fe30-327a-4795-0002-000000000003",
                    "22255c68-fbf3-446a-0002-000000000004",
                  ],
                },
              },
              {
                id: "2221df3c-c8bf-4322-0001-000000000001",
                type: "input",
                label: "Typical client types:",
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                disabled: true,
              },
              {
                id: "2223b397-c23d-4550-0002-000000000001",
                type: "dropdown",
                width: 3,
                label: "Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "2223b397-c23d-4550-0002-000000000001",
                    "222e765c-e111-4390-0002-000000000002",
                    "2226fe30-327a-4795-0002-000000000003",
                    "22255c68-fbf3-446a-0002-000000000004",
                  ],
                },
                disabled: true,
              },
              {
                id: "222e765c-e111-4390-0002-000000000002",
                type: "dropdown",
                width: 3,
                label: "Non-Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "2223b397-c23d-4550-0002-000000000001",
                    "222e765c-e111-4390-0002-000000000002",
                    "2226fe30-327a-4795-0002-000000000003",
                    "22255c68-fbf3-446a-0002-000000000004",
                  ],
                },
                disabled: true,
              },
              {
                id: "2226fe30-327a-4795-0002-000000000003",
                type: "dropdown",
                width: 3,
                label: "Execution Only",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "2223b397-c23d-4550-0002-000000000001",
                    "222e765c-e111-4390-0002-000000000002",
                    "2226fe30-327a-4795-0002-000000000003",
                    "22255c68-fbf3-446a-0002-000000000004",
                  ],
                },
                disabled: true,
              },
              {
                id: "22255c68-fbf3-446a-0002-000000000004",
                type: "dropdown",
                width: 3,
                label: "Discretionary",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "2223b397-c23d-4550-0002-000000000001",
                    "222e765c-e111-4390-0002-000000000002",
                    "2226fe30-327a-4795-0002-000000000003",
                    "22255c68-fbf3-446a-0002-000000000004",
                  ],
                },
                disabled: true,
              },
              {
                id: "330e9f78-79b2-59c3-9210-50ad00013588",
                type: "subHead",
                text: "",
                width: 12,
              },
              {
                id: "aa878978-f33c-4af8-b52f-95e2bf198b67",
                type: "checkbox",
                label: "Eligible Counterparty",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "5330fad5-6eb8-4b61-8bd1-306248caae53",
                    "b2eca7e4-ca95-4501-92cb-6aa29e6230e9",
                    "7bafa2ac-bd39-4946-b512-70f28ffc03ea",
                    "f5b7bd3e-8012-4713-9127-31f4e2265324",
                    "f4f792e4-078a-4f2c-8ac1-1b6a228536d4",
                  ],
                },
              },
              {
                id: "5330fad5-6eb8-4b61-8bd1-306248caae53",
                type: "input",
                value: "",
                label: "",
                width: 12,
                validate: {
                  required: true,
                },
                disabled: true,
              },
              {
                id: "b2eca7e4-ca95-4501-92cb-6aa29e6230e9",
                type: "dropdown",
                width: 3,
                label: "Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "b2eca7e4-ca95-4501-92cb-6aa29e6230e9",
                    "7bafa2ac-bd39-4946-b512-70f28ffc03ea",
                    "f5b7bd3e-8012-4713-9127-31f4e2265324",
                    "f4f792e4-078a-4f2c-8ac1-1b6a228536d4",
                  ],
                },
                disabled: true,
              },
              {
                id: "7bafa2ac-bd39-4946-b512-70f28ffc03ea",
                type: "dropdown",
                width: 3,
                label: "Non-Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "b2eca7e4-ca95-4501-92cb-6aa29e6230e9",
                    "7bafa2ac-bd39-4946-b512-70f28ffc03ea",
                    "f5b7bd3e-8012-4713-9127-31f4e2265324",
                    "f4f792e4-078a-4f2c-8ac1-1b6a228536d4",
                  ],
                },
                disabled: true,
              },
              {
                id: "f5b7bd3e-8012-4713-9127-31f4e2265324",
                type: "dropdown",
                width: 3,
                label: "Execution Only",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "b2eca7e4-ca95-4501-92cb-6aa29e6230e9",
                    "7bafa2ac-bd39-4946-b512-70f28ffc03ea",
                    "f5b7bd3e-8012-4713-9127-31f4e2265324",
                    "f4f792e4-078a-4f2c-8ac1-1b6a228536d4",
                  ],
                },
                disabled: true,
              },
              {
                id: "f4f792e4-078a-4f2c-8ac1-1b6a228536d4",
                type: "dropdown",
                width: 3,
                label: "Discretionary",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "b2eca7e4-ca95-4501-92cb-6aa29e6230e9",
                    "7bafa2ac-bd39-4946-b512-70f28ffc03ea",
                    "f5b7bd3e-8012-4713-9127-31f4e2265324",
                    "f4f792e4-078a-4f2c-8ac1-1b6a228536d4",
                  ],
                },
                disabled: true,
              },
              {
                id: "1ba539b6-fb8f-4ba6-81e4-cdfb6b59eefd",
                type: "subHead",
                text: "",
                width: 12,
              },
              {
                id: "73f4d017-db17-4394-8538-637f2a8414f2",
                type: "checkbox",
                label: "Other",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "e1f218ad-ce55-4513-8be6-9c368682d3f8",
                    "e1a6be08-fcb4-4313-bb57-8dadcd085b99",
                    "a357df09-2aeb-4234-9c97-bb6e27b8ba16",
                    "a5f83b3e-10c0-4752-abc1-560b2a73705e",
                    "5cdf37b0-eb1f-40a6-998d-77374e53f9e4",
                  ],
                },
              },
              {
                id: "e1f218ad-ce55-4513-8be6-9c368682d3f8",
                type: "input",
                label: "Please confirm deifnition used:",
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                disabled: true,
              },
              {
                id: "e1a6be08-fcb4-4313-bb57-8dadcd085b99",
                type: "dropdown",
                width: 3,
                label: "Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "e1a6be08-fcb4-4313-bb57-8dadcd085b99",
                    "a357df09-2aeb-4234-9c97-bb6e27b8ba16",
                    "a5f83b3e-10c0-4752-abc1-560b2a73705e",
                    "5cdf37b0-eb1f-40a6-998d-77374e53f9e4",
                  ],
                },
                disabled: true,
              },
              {
                id: "a357df09-2aeb-4234-9c97-bb6e27b8ba16",
                type: "dropdown",
                width: 3,
                label: "Non-Advised",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "e1a6be08-fcb4-4313-bb57-8dadcd085b99",
                    "a357df09-2aeb-4234-9c97-bb6e27b8ba16",
                    "a5f83b3e-10c0-4752-abc1-560b2a73705e",
                    "5cdf37b0-eb1f-40a6-998d-77374e53f9e4",
                  ],
                },
                disabled: true,
              },
              {
                id: "a5f83b3e-10c0-4752-abc1-560b2a73705e",
                type: "dropdown",
                width: 3,
                label: "Execution Only",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "e1a6be08-fcb4-4313-bb57-8dadcd085b99",
                    "a357df09-2aeb-4234-9c97-bb6e27b8ba16",
                    "a5f83b3e-10c0-4752-abc1-560b2a73705e",
                    "5cdf37b0-eb1f-40a6-998d-77374e53f9e4",
                  ],
                },
                disabled: true,
              },
              {
                id: "5cdf37b0-eb1f-40a6-998d-77374e53f9e4",
                type: "dropdown",
                width: 3,
                label: "Discretionary",
                source: "percentage",
                singleSelect: true,
                checkbox: false,
                validate: {
                  percent: [
                    "e1a6be08-fcb4-4313-bb57-8dadcd085b99",
                    "a357df09-2aeb-4234-9c97-bb6e27b8ba16",
                    "a5f83b3e-10c0-4752-abc1-560b2a73705e",
                    "5cdf37b0-eb1f-40a6-998d-77374e53f9e4",
                  ],
                },
                disabled: true,
              },
            ],
          },
          {
            id: "b57540a3-cc03-490d-aa23-ebd761d80a29",
            externalID: "2.2.3",
            nav: "2.2.3",
            text: "How and by whom are fees paid to the Sub-distributor/ 3rd-Party intermediary?",
            fields: [
              {
                id: "c5b86afc-13f0-4bbe-97d9-93086788f1a7",
                type: "checkbox",
                label:
                  "N/A – no Sub-distributor/ 3rd-Party intermediary Distributors are used",
                value: false,
                width: 12,
                conditional: {
                  disablePriority: true,
                  disable: [
                    "babb1b62-8ca1-44ec-83b8-807edb11e4a6",
                    "19182168-ba7c-463d-9607-8fb437871640",
                  ],
                  enable: [
                    "babb1b62-8ca1-44ec-83b8-807edb11e4a6",
                    "19182168-ba7c-463d-9607-8fb437871640",
                  ],
                },
              },
              {
                id: "babb1b62-8ca1-44ec-83b8-807edb11e4a6",
                type: "textArea",
                disabled: false,
                label: "How are fees paid to Sub-distributor/ 3rd-Party intermediary?",
                width: 12,
                validate: {
                  required: true,
                },
              },
              {
                id: "19182168-ba7c-463d-9607-8fb437871640",
                type: "textArea",
                disabled: false,
                label: "Who pays the fees to Sub-distributor/ 3rd-Party intermediary?",
                width: 12,
                validate: {
                  required: true,
                },
              },
            ],
          },
        ],
      },
      {
        id: "0ef76f0c-1b87-4b3c-96f1-68731f9d30fc",
        name: "Regulatory Adherence & FinCrime",
        nav: "3",
        questions: [
          {
            id: "aa13b225-3513-4090-8506-0333c1a9f6a8",
            externalID: "2.3.1",
            nav: "2.3.1",
            text: "Please describe the MIFID – Elective Professional procedures or other local jurisdiction opt-up professional procedures.",
            validate: {
              groupArr: [
                "128e965c-0cad-4b34-80b4-5d6224a70fec",
                "ea42ce5c-0977-4dd3-963d-e54f44d87d00",
                "e7c4289c-993d-4542-b647-211b972d3422",
              ],
            },
            fields: [
              {
                id: "128e965c-0cad-4b34-80b4-5d6224a70fec",
                type: "checkbox",
                label: "N/A",
                value: false,
                width: 12,
              },
              {
                id: "ea42ce5c-0977-4dd3-963d-e54f44d87d00",
                type: "checkbox",
                label:
                  "Client Driven requests - Please describe the process including evidence collected and oversight:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["46b48b65-cff3-4f6d-97db-1385abb7210e"],
                },
              },
              {
                id: "46b48b65-cff3-4f6d-97db-1385abb7210e",
                type: "textArea",
                disabled: true,
                label: "",
                width: 12,
                indent: 1,
                validate: {
                  required: true,
                },
              },
              {
                id: "e7c4289c-993d-4542-b647-211b972d3422",
                type: "checkbox",
                label:
                  "Sales Driven Request - Please describe this process and any differences between a client driven request:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["0f028c83-5bb4-4003-97bc-43b72b77c99d"],
                },
              },
              {
                id: "0f028c83-5bb4-4003-97bc-43b72b77c99d",
                type: "textArea",
                disabled: true,
                label: "",
                width: 12,
                indent: 1,
                validate: {
                  required: true,
                },
              },
            ],
          },
          {
            id: "e26c59f0-8bc7-469d-a05a-863ea999d4ac",
            externalID: "2.3.2",
            nav: "2.3.2",
            text: "If relevant with respect to the Prospectus regulation 2017/1129 (and retained in UK Law), what exemptions are relied on for the provision of a prospectus for public offers:",
            popover: {
              body: "*“Offer” means a communication to persons in any form and by any means, presenting sufficient information on the terms of the offer and the securities to be offered, so as to enable an investor to decide to purchase or subscribe for those securities. This definition also applies to the placing of securities through financial intermediaries. Therefore the control described above should ensure there is no risk of offering the product to more than 150 natural or legal persons and should not be operated as a products subscription control.",
            },
            validate: {
              groupArr: [
                "a81f9c16-7834-4dde-83fc-6edf8670f459",
                "6801fc91-187d-474e-8735-c115a90901ed",
                "37e9f84a-dc87-4f47-b859-e523651deaca",
                "5cfa082a-3022-4ffb-88e1-853ee9e615d0",
                "9094a09d-18b9-40b2-814c-4d98b574fc64",
                "9b067745-e1f1-4412-b661-c5bbb8948728",
                "2174d520-f5e3-4eeb-9223-d91c8c987600",
                "ca1e40fb-063b-4d1c-904c-ccedaf9c04c8",
                "e1aabacd-3fc0-4efc-b6d8-61b0aad71bb0",
                "8670c3d0-4232-4ebb-a8a5-ef2f9799b138",
              ],
            },
            fields: [
              {
                id: "263be2d5-f9a4-4038-9d44-bcde632c8b9a",
                type: "subHead",
                text: "Within the EU and UK",
                width: 12,
              },
              {
                id: "a81f9c16-7834-4dde-83fc-6edf8670f459",
                type: "checkbox",
                label:
                  "Securities denomination of at least EUR 100,000 (or equivalent)",
                value: false,
                width: 12,
              },
              {
                id: "6801fc91-187d-474e-8735-c115a90901ed",
                type: "checkbox",
                label:
                  "Minimum investment amount of at least EUR 100,000 (or equivalent)",
                value: false,
                width: 12,
              },
              {
                id: "37e9f84a-dc87-4f47-b859-e523651deaca",
                type: "checkbox",
                label:
                  "An offer of securities addressed solely to Qualified Investors",
                value: false,
                width: 12,
              },
              {
                id: "5cfa082a-3022-4ffb-88e1-853ee9e615d0",
                type: "checkbox",
                label:
                  "An offer of securities addressed to fewer than 150 natural or legal persons per Member State other than qualified investors:– If so, provide details below of the controls in place and records kept to ensure the threshold is complied with across the distribution network (including any Sub-distributors / 3rd-Party Intermediaries used):",
                value: false,
                width: 12,
                conditional: {
                  enable: ["1be3ad3e-1573-4080-bae6-e20afa7bd918"],
                },
              },
              {
                id: "1be3ad3e-1573-4080-bae6-e20afa7bd918",
                type: "textArea",
                label: "",
                width: 12,
                indent: 1,
                disabled: true,
                validate: {
                  required: true,
                },
              },
              {
                id: "6f6c064e-0e15-48bb-83d7-2a36d0a0b7e1",
                type: "subHead",
                text: "Within Switzerland",
                width: 12,
              },
              {
                id: "9094a09d-18b9-40b2-814c-4d98b574fc64",
                type: "checkbox",
                label: "The offering is directed only to professional clients.",
                value: false,
                width: 12,
              },
              {
                id: "9b067745-e1f1-4412-b661-c5bbb8948728",
                type: "checkbox",
                label: "The offering is directed to less than 500 investors.",
                value: false,
                width: 12,
              },
              {
                id: "2174d520-f5e3-4eeb-9223-d91c8c987600",
                type: "checkbox",
                label:
                  "The offering is directed to investors which acquire securities in an amount of at least CHF 100,000.",
                value: false,
                width: 12,
              },
              {
                id: "ca1e40fb-063b-4d1c-904c-ccedaf9c04c8",
                type: "checkbox",
                label:
                  "The offering consists of securities with a denomination of at least CHF 100,000.",
                value: false,
                width: 12,
              },
              {
                id: "e1aabacd-3fc0-4efc-b6d8-61b0aad71bb0",
                type: "checkbox",
                label:
                  "The offering(s) are not in excess of CHF 8 million over a period of 12 months.",
                value: false,
                width: 12,
              },
              {
                id: "9cbf28ec-0a7b-4350-a6ed-34ac237722cf",
                type: "subHead",
                text: "Outside of the EU",
                width: 12,
              },
              {
                id: "8670c3d0-4232-4ebb-a8a5-ef2f9799b138",
                type: "checkbox",
                label: "Please specify below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["983597fd-9312-4d13-a50a-b3c8d52f39b5"],
                },
              },
              {
                id: "983597fd-9312-4d13-a50a-b3c8d52f39b5",
                type: "textArea",
                label: "",
                width: 12,
                indent: 1,
                disabled: true,
                validate: {
                  required: true,
                },
              },
            ],
          },
          {
            id: "f8d25e47-3fc7-4b83-b225-7e0eebff06b7",
            externalID: "2.3.3",
            nav: "2.3.3",
            text: "Does the legal entity who is responsible for the end client sell / intend to sell into any sanctioned countries e.g. OFAC, HMT, EU, UNSC sanctions or any other applicable sanctions program(s)?",
            validate: {
              groupArr: [
                "0d0b7b43-c28f-4297-9230-a62420d7e649",
                "55493ffd-56b2-4edf-bdaa-a9be79bb3b83",
                "0d0b7b43-c28f-4297-9230-a62420d7e649",
              ],
            },
            fields: [
              {
                id: "08605096-6b0b-4371-bdba-d4284154c1b4",
                type: "radio-list",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "6ec1c482-4b9c-4d41-9e0f-03009480fdd7",
                    label: "No",
                    conditional: {
                      disable: [
                        "0d0b7b43-c28f-4297-9230-a62420d7e649",
                        "22622eb8-9f2b-4db7-8fba-4cdc2842afa1",
                        "55493ffd-56b2-4edf-bdaa-a9be79bb3b83",
                        "023a7640-8bcf-45f8-b8d9-c6d1570ee6b4",
                      ],
                    },
                  },
                  {
                    id: "166b04da-d1cd-4d75-a86b-4767e4a9c61e",
                    label: "Yes",
                    conditional: {
                      enable: [
                        "0d0b7b43-c28f-4297-9230-a62420d7e649",
                        "55493ffd-56b2-4edf-bdaa-a9be79bb3b83",
                      ],
                    },
                  },
                ],
              },
              {
                id: "0d0b7b43-c28f-4297-9230-a62420d7e649",
                type: "checkbox",
                label: "Distributor - Please specify below:",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
                conditional: {
                  enable: ["22622eb8-9f2b-4db7-8fba-4cdc2842afa1"],
                },
              },
              {
                id: "22622eb8-9f2b-4db7-8fba-4cdc2842afa1",
                type: "textArea",
                label: "",
                width: 12,
                indent: 2,
                disabled: true,
                validate: {
                  required: true,
                },
              },
              {
                id: "55493ffd-56b2-4edf-bdaa-a9be79bb3b83",
                type: "checkbox",
                label:
                  "Yes, Sub-Distributor/3rd-Party Intermediary - Please specify below",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
                conditional: {
                  enable: ["023a7640-8bcf-45f8-b8d9-c6d1570ee6b4"],
                },
              },
              {
                id: "023a7640-8bcf-45f8-b8d9-c6d1570ee6b4",
                type: "textArea",
                label: "",
                width: 12,
                indent: 2,
                disabled: true,
                validate: {
                  required: true,
                },
              },
            ],
          },
          {
            id: "acad4dac-7f57-41b5-a516-a7f5c7a3927b",
            externalID: "2.3.4",
            nav: "2.3.4",
            text: "Have there been any regulatory investigations, sanctions, fines or warnings either for the Distributor or Sub-distributors / 3rd-Party intermediaries or key personnel received in the last 3 years (whether from the domestic regulator or overseas)?",
            fields: [
              {
                id: "5d9d0623-e8f0-49a5-9aa0-06f901fb628e",
                type: "radio-inline",
                width: 12,
                value: "",
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "26d27644-0d2a-4b92-b320-361e22aefd0f",
                    label: "No",
                    conditional: {
                      disable: ["953bcae9-abd9-40b2-8e30-8e320d03df12"],
                    },
                  },
                  {
                    id: "1fb3f5f9-ef8a-420b-88ff-de7c20acc0d4",
                    label:
                      "Yes - please summarise the event(s) and explain the remediation status below:",
                    conditional: {
                      enable: ["953bcae9-abd9-40b2-8e30-8e320d03df12"],
                    },
                  },
                ],
              },
              {
                id: "953bcae9-abd9-40b2-8e30-8e320d03df12",
                type: "textArea",
                label: "",
                width: 12,
                indent: 0,
                disabled: true,
                validate: {
                  required: true,
                },
              },
            ],
          },
          {
            id: "f4788f40-c191-4cb1-b3b9-6a19ffaa0733",
            externalID: "2.3.5",
            nav: "2.3.5",
            text: "Have there been any adverse or negative news either for the Distributor or Sub-distributors / 3rd-Party intermediaries or any of the key personnel for the Distributor’s structured products activities in the last 3 years.",
            fields: [
              {
                id: "7441ca8d-67e1-4b9a-b87f-f90378697141",
                type: "radio-inline",
                width: 12,
                value: "",
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "1e01fda8-12c7-483a-8261-ff31aef431e1",
                    label: "No",
                    conditional: {
                      disable: ["674bcf7b-a297-41c5-8c7a-d3e953ae3c4d"],
                    },
                  },
                  {
                    id: "5afe4641-f1ed-4e38-a3f0-f79ad88f5efe",
                    label:
                      "Yes - please summarise the event(s) and explain the remediation status below:",
                    conditional: {
                      enable: ["674bcf7b-a297-41c5-8c7a-d3e953ae3c4d"],
                    },
                  },
                ],
              },
              {
                id: "674bcf7b-a297-41c5-8c7a-d3e953ae3c4d",
                type: "textArea",
                label: "",
                width: 12,
                indent: 0,
                disabled: true,
                validate: {
                  required: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "f9224ed5-fa12-44df-8d66-a20e670e3702",
    name: "Distributor’s Product Design & Governance ",
    nav: "3",
    subsections: [
      {
        id: "b2449dac-2f49-4eb0-a271-13850bdff43a",
        name: "General Information",
        nav: "1",
        questions: [
          {
            id: "4167904c-d913-4bc7-b7b6-c8a96d5babcf",
            externalID: "3.1.1",
            nav: "3.1.1",
            text: "Please describe how products are approved and any relevant governance committees/ forums (including who attends and votes for product approvals).",
            popover: {
              body: "Please outline the Committee responsibilities for reviewing, rejecting/ approving and monitoring products.",
            },
            validate: {
              groupArr: [
                "f5463179-1afc-4728-90ed-65ac41b575ab",
                "020d45ac-7af8-4685-adda-daf763a119c8",
                "9c4fa2b9-9d24-40a8-9c1b-0c1ea2db5cb9",
                "f34fb9bf-681c-4aa2-84b8-9065d11983c0",
                "235a9b29-37a7-46b7-991e-32d258e617c0",
                "3378b18f-0c45-4a35-a415-477fbcc4d532",
                "f69d59d0-391b-4de3-ae96-7232adbd9a6b",
                "5dd0e8f7-1d83-493f-a174-2b55c1e8f2fb",
                "5da7d39e-a136-4cb3-bff5-17c41678b103",
                "39c4ebd5-45d7-45a4-9025-68389dbdbbc2",
                "14d2a84e-7e50-4e7a-ae15-c8e8e77c2a3b",
                "43f01526-4265-47f6-9e74-5c6c3aefd117",
                "ab331835-d25b-45e3-993b-1bcf8fed10be",
                "7fa58062-aaa3-4157-b4d1-106c1426234a",
                "c5d3e257-c5cc-4832-ba08-1bd2e8dbcc19",
                "f8fde137-8942-48f0-b670-6f37a0faa42e",
                "9612ef0c-3917-4207-a3db-fad937adfc67",
                "0a1f5fc3-2ebd-4f97-a5b4-4d6f4c35f374",
                "4b019c50-2ef0-42eb-a4a9-ef098a86bca7",
                "1be5c4f9-0a84-482f-a460-aee46b8249fe",
              ],
            },
            fields: [
              {
                id: "e61dc451-acb6-4f3d-ab5a-fcb3618d9402",
                type: "input",
                label: "Forum Name",
                width: 8,
                validate: {
                  required: true,
                },
              },
              {
                id: "16e988a9-9708-4ca6-a43e-b98ccccfe0c8",
                type: "input",
                label: "Meeting Frequency",
                width: 4,
                validate: {
                  required: true,
                },
              },
              {
                id: "0cc08bda-b7b9-40c0-b032-9ae22b6baffb",
                type: "textArea",
                label: "Product Approval & Monitoring Responsibilities",
                width: 12,
                validate: {
                  required: true,
                },
              },
              {
                id: "d6154600-df92-4b9c-b305-1d549461cd6c",
                type: "subHeadLabel",
                text: "Product Approval Basis",
                width: 12,
              },
              {
                id: "191d6d4d-c40d-438b-9f4d-66f82b1b5a11",
                type: "radio-inline",
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "d53e550b-fb25-420e-856d-1bbb9d6c574a",
                    label: "Trade by Trade",
                  },
                  {
                    id: "4e208e01-e8ec-42de-b4bb-bd2e61d22846",
                    label: "Product Group / Families / Cluster",
                  },
                ],
                value: false,
                width: 12,
              },
              {
                id: "c982d290-efd7-4e12-ab94-e078079b9252",
                type: "subHeadLabel",
                text: "When designing and launching a product does your governance framework consider the following:",
                width: 12,
              },
              {
                id: "f5463179-1afc-4728-90ed-65ac41b575ab",
                type: "checkbox",
                label: "Identified demand/need of the Target Market ",
                value: false,
                width: 12,
              },
              {
                id: "020d45ac-7af8-4685-adda-daf763a119c8",
                type: "checkbox",
                label: "Intended Target Market Profile",
                value: false,
                width: 12,
              },
              {
                id: "9c4fa2b9-9d24-40a8-9c1b-0c1ea2db5cb9",
                type: "checkbox",
                label: "Product features/payoffs",
                value: false,
                width: 12,
              },
              {
                id: "f34fb9bf-681c-4aa2-84b8-9065d11983c0",
                type: "checkbox",
                label: "Product testing",
                value: false,
                width: 12,
              },
              {
                id: "235a9b29-37a7-46b7-991e-32d258e617c0",
                type: "checkbox",
                label: "Risks to the End Clients",
                value: false,
                width: 12,
              },
              {
                id: "3378b18f-0c45-4a35-a415-477fbcc4d532",
                type: "checkbox",
                label: "Distribution strategy",
                value: false,
                width: 12,
              },
              {
                id: "f69d59d0-391b-4de3-ae96-7232adbd9a6b",
                type: "checkbox",
                label: "Environmental, Social, Governance (ESG) Criteria",
                value: false,
                width: 12,
              },
              {
                id: "9612ef0c-3917-4207-a3db-fad937adfc67",
                type: "checkbox",
                label:
                  "Results of post sales monitoring from previously distributed products",
                value: false,
                width: 12,
              },
              {
                id: "0a1f5fc3-2ebd-4f97-a5b4-4d6f4c35f374",
                type: "checkbox",
                label:
                  "Comparison with current product offering to allow opportunity for removal / replacement of structures that are no longer value for money",
                value: false,
                width: 12,
              },
              {
                id: "4b019c50-2ef0-42eb-a4a9-ef098a86bca7",
                type: "checkbox",
                label: "Outcome Monitoring Results for previously launched products",
                value: false,
                width: 12,
              },
              {
                id: "1be5c4f9-0a84-482f-a460-aee46b8249fe",
                type: "checkbox",
                label: "Vulnerable Customer Characteristics and Needs",
                value: false,
                width: 12,
              },
              {
                id: "5dd0e8f7-1d83-493f-a174-2b55c1e8f2fb",
                type: "checkbox",
                label: "Other - Please specify below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["3cd7fa0b-074c-4cb3-b474-6b98de31d34d"],
                },
              },
              {
                id: "3cd7fa0b-074c-4cb3-b474-6b98de31d34d",
                type: "input",
                validate: {
                  required: true,
                },
                value: "",
                disabled: true,
                width: 12,
                indent: 1,
              },
            ],
          },
          {
            id: "5b36c4b4-aff2-49e5-a4ab-391602cef0a4",
            externalID: "3.1.1A",
            nav: " ",
            text: "Who attends and Approves products? ",
            validate: {
              groupArr: [
                "5da7d39e-a136-4cb3-bff5-17c41678b103",
                "39c4ebd5-45d7-45a4-9025-68389dbdbbc2",
                "14d2a84e-7e50-4e7a-ae15-c8e8e77c2a3b",
                "43f01526-4265-47f6-9e74-5c6c3aefd117",
                "ab331835-d25b-45e3-993b-1bcf8fed10be",
                "7fa58062-aaa3-4157-b4d1-106c1426234a",
                "c5d3e257-c5cc-4832-ba08-1bd2e8dbcc19",
                "f8fde137-8942-48f0-b670-6f37a0faa42e",
              ],
            },
            fields: [
              {
                id: "33bccb0bb8-5a33-4c36-971e-50be13956cf2",
                type: "subHeadLabel",
                text: "Voting Member",
                width: 12,
              },
              {
                id: "5da7d39e-a136-4cb3-bff5-17c41678b103",
                type: "checkbox",
                label: "CEO/COO",
                value: false,
                width: 12,
              },
              {
                id: "39c4ebd5-45d7-45a4-9025-68389dbdbbc2",
                type: "checkbox",
                label: "Sales",
                value: false,
                width: 12,
              },
              {
                id: "14d2a84e-7e50-4e7a-ae15-c8e8e77c2a3b",
                type: "checkbox",
                label: "Relation Managers (RMs)",
                value: false,
                width: 12,
              },
              {
                id: "43f01526-4265-47f6-9e74-5c6c3aefd117",
                type: "checkbox",
                label: "Legal",
                value: false,
                width: 12,
              },
              {
                id: "ab331835-d25b-45e3-993b-1bcf8fed10be",
                type: "checkbox",
                label: "Compliance",
                value: false,
                width: 12,
              },
              {
                id: "7fa58062-aaa3-4157-b4d1-106c1426234a",
                type: "checkbox",
                label: "Audit",
                value: false,
                width: 12,
              },
              {
                id: "c5d3e257-c5cc-4832-ba08-1bd2e8dbcc19",
                type: "checkbox",
                label: "Risk",
                value: false,
                width: 12,
              },
              {
                id: "f8fde137-8942-48f0-b670-6f37a0faa42e",
                type: "checkbox",
                label: "Other",
                value: false,
                width: 12,
                conditional: {
                  enable: ["df50a7de-0788-47ba-8a92-cdbd39f01cc8"],
                },
              },
              {
                id: "df50a7de-0788-47ba-8a92-cdbd39f01cc8",
                type: "input",
                validate: {
                  required: true,
                },
                value: "",
                disabled: true,
                width: 12,
                indent: 1,
              },
            ],
          },
          {
            id: "76f6ef94-64f3-46fb-9545-52cb5eff6049",
            externalID: "3.1.1B",
            nav: " ",
            text: " ",
            fields: [
              {
                id: "33bccb0bb8-5a33-4c36-971e-50be13956cf3",
                type: "subHeadLabel",
                text: "Non-voting Member",
                width: 12,
              },
              {
                id: "74090b02-158b-4b59-a4ca-9d694a95864e",
                type: "checkbox",
                label: "CEO/COO",
                value: false,
                width: 12,
              },
              {
                id: "e05a87cd-cf26-49ec-853f-e6c1be3a5ab9",
                type: "checkbox",
                label: "Sales",
                value: false,
                width: 12,
              },
              {
                id: "ba433cf6-c952-46ee-9334-1df2cbb6ad90",
                type: "checkbox",
                label: "Relation Managers (RMs)",
                value: false,
                width: 12,
              },
              {
                id: "a807d398-ba8a-4386-a271-192020286edf",
                type: "checkbox",
                label: "Legal",
                value: false,
                width: 12,
              },
              {
                id: "578d548f-1fdf-4ee7-8e01-919438f2c706",
                type: "checkbox",
                label: "Compliance",
                value: false,
                width: 12,
              },
              {
                id: "b5f36876-a702-4d4a-948b-084b79f6c1e3",
                type: "checkbox",
                label: "Audit",
                value: false,
                width: 12,
              },
              {
                id: "e4f4544d-beb9-4c9b-abe8-cee530edfc41",
                type: "checkbox",
                label: "Risk",
                value: false,
                width: 12,
              },
              {
                id: "c3e7dac6-c814-4492-bcef-1e4cfa42c0fc",
                type: "checkbox",
                label: "Other",
                value: false,
                width: 12,
                conditional: {
                  enable: ["da7b7862-64f2-4cd7-8f99-3e5b242e4db8"],
                },
              },
              {
                id: "da7b7862-64f2-4cd7-8f99-3e5b242e4db8",
                type: "input",
                validate: {
                  required: true,
                },
                value: "",
                disabled: true,
                width: 12,
                indent: 1,
              },
              {
                id: "3b667c20-cc0d-4092-8a63-8f896737a13d",
                type: "textArea",
                label: "Additional Notes (Optional)",
                disabled: false,
                width: 12,
              },
            ],
          },
          {
            id: "bdc7cbe1-4beb-4e71-8c8c-551c74153581",
            externalID: "3.1.2",
            nav: "3.1.2",
            text: "How does the Distributor review the Issuer’s potential Target Market?",
            validate: {
              groupArr: [
                "ad89c558-c04e-44c6-b918-aa4d9cf8d4ac",
                "a5bedf95-9a1f-4119-8768-e5c7026aa7d6",
                "82919963-8cff-44ae-ae6e-066a81750b0d",
                "7fa94bfa-8231-4818-bde7-0bd96a2af193",
                "7faa63d0-9b85-4fbe-9842-ec5223edb276",
                "3a4860af-fbf5-49cc-a635-8bb67272c0ff",
              ],
            },
            fields: [
              {
                id: "ad89c558-c04e-44c6-b918-aa4d9cf8d4ac",
                type: "checkbox",
                label:
                  "Direct engagement with Issuer to understand rationale for their Target Market Assessment",
                value: false,
                width: 12,
              },
              {
                id: "a5bedf95-9a1f-4119-8768-e5c7026aa7d6",
                type: "checkbox",
                label:
                  "Review of information supplied by the Issuer as defined in distribution agreements in relation to the Target Market Assessment",
                value: false,
                width: 12,
              },
              {
                id: "82919963-8cff-44ae-ae6e-066a81750b0d",
                type: "checkbox",
                label:
                  "Challenge of decisions made by the Issuer in relation to their Target Market Assessment including distribution strategy",
                value: false,
                width: 12,
              },
              {
                id: "7fa94bfa-8231-4818-bde7-0bd96a2af193",
                type: "checkbox",
                label:
                  "Detailed review of Distributor’s existing and proposed client base to ensure alignment with Issuer’s Target Market ",
                value: false,
                width: 12,
              },
              {
                id: "7faa63d0-9b85-4fbe-9842-ec5223edb276",
                type: "checkbox",
                label:
                  "Independent desk research to complement the Issuer’s Target Market Assessment ",
                value: false,
                width: 12,
              },
              {
                id: "3a4860af-fbf5-49cc-a635-8bb67272c0ff",
                type: "checkbox",
                label: "Other – please explain below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["2862f147-d9e0-45d7-bc9d-621c22af85c4"],
                },
              },
              {
                id: "2862f147-d9e0-45d7-bc9d-621c22af85c4",
                type: "input",
                validate: {
                  required: true,
                },
                value: "",
                disabled: true,
                width: 12,
                indent: 1,
              },
            ],
          },
          {
            id: "c3a127d9-e308-4050-922f-3d77a9b97cf8",
            externalID: "3.1.3",
            nav: "3.1.3",
            text: "When defining the actual Target Market for a product which factors are considered by the Distributor?",
            popover: {
              body: "If any of the below are not considered, please explain the rationale. Responses should also reflect obligations of any Sub-distributors / 3rd Party intermediaries and the target market profiling they do for their End Client    ",
            },
            validate: {
              groupArr: [
                "c280b74c-1371-4c0e-841a-175ba473ed91",
                "ad137163-dde5-46e5-9972-db1c81b3a908",
                "b766a1a2-e71a-4741-846c-5ce618c7015f",
                "4ec7b35e-a338-40c0-a463-66d65d34a64f",
                "4436e6d4-0056-495a-b3b8-7f575f3b4997",
                "4436e6d4-0056-495a-b3b8-7f575f3b4997",
                "57bbc1ef-1a24-4c12-9721-6b016bb85501",
                "01183407-097e-42b8-a3ff-5515849dc957",
                "d53939d6-e6f2-4045-a904-d303d5358ec3",
              ],
            },
            fields: [
              {
                id: "c280b74c-1371-4c0e-841a-175ba473ed91",
                type: "checkbox",
                label:
                  "Client Type – e.g. Retail, Professional, Eligible Counterparty",
                value: false,
                width: 12,
              },
              {
                id: "ad137163-dde5-46e5-9972-db1c81b3a908",
                type: "checkbox",
                label:
                  "Client Objectives & Needs – (i) client’s investment objectives, e.g. growth, income or hedging, (ii) client’s investment horizon, e.g. willing to hold to maturity, willing for product to mature early",
                value: false,
                width: 12,
              },
              {
                id: "d53939d6-e6f2-4045-a904-d303d5358ec3",
                type: "checkbox",
                label:
                  "Client’s Objectives, Characteristics & Needs in terms of sustainability - financial instrument is consistent with target client’s specific sustainability-related profile.",
                value: false,
                width: 12,
              },
              {
                id: "b766a1a2-e71a-4741-846c-5ce618c7015f",
                type: "checkbox",
                label:
                  "Risk profile - the target clients risk profile and whether the product risk or categorisation is appropriate i.e. capital protected, single index products, credit linked products",
                value: false,
                width: 12,
              },
              {
                id: "4ec7b35e-a338-40c0-a463-66d65d34a64f",
                type: "checkbox",
                label:
                  "Sophistication - Product complexity and whether it could be understood by the target clients",
                value: false,
                width: 12,
              },
              {
                id: "4436e6d4-0056-495a-b3b8-7f575f3b4997",
                type: "checkbox",
                label:
                  "Investment experience - Level of knowledge and experience the target clients might have about the underlying or the market view expressed by the product",
                value: false,
                width: 12,
              },
              {
                id: "57bbc1ef-1a24-4c12-9721-6b016bb85501",
                type: "checkbox",
                label:
                  "AUM, diversification and ability to sustain the potential loss of capital associated with the product",
                value: false,
                width: 12,
              },
              {
                id: "01183407-097e-42b8-a3ff-5515849dc957",
                type: "checkbox",
                label: "Other – Please specify:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["a35fe9f1-5bf5-4d3b-b87b-a42c157710e8"],
                },
              },
              {
                id: "a35fe9f1-5bf5-4d3b-b87b-a42c157710e8",
                type: "input",
                validate: {
                  required: true,
                },
                value: "",
                disabled: true,
                width: 12,
                indent: 1,
              },
            ],
          },
          {
            id: "fdc0d45d-d0c9-4d99-b290-fe8e70474058",
            externalID: "3.1.4",
            nav: "3.1.4",
            text: "In which scenarios would the Distributor refine the Issuer’s Target Market?",
            validate: {
              groupArr: [
                "6ba8e587-f5c8-475f-9698-97e13910d71a",
                [
                  "1ca18059-8912-4378-a440-f9c62d2ed285",
                  "8fe731ee-2582-48db-adf5-cd6194d63628",
                  "e1ca0e24-1363-495a-96d1-94c6bde4fb7a",
                  "5a257c5c-8536-402a-b606-9c46e3a8605c",
                ],
                "1fff00b8-3930-40c7-9c9e-5493dbc49f42",
                [
                  "6a0f0c43-dc18-4821-8d99-8192ac244eca",
                  "d4eceef4-9a6f-4452-8a03-3c7122486c5d",
                  "058bc81e-12d1-4dda-9ff8-ebf30a44af2e",
                ],
              ],
            },
            fields: [
              {
                id: "6ba8e587-f5c8-475f-9698-97e13910d71a",
                type: "checkbox",
                label: "More Restrictive",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "1ca18059-8912-4378-a440-f9c62d2ed285",
                    "8fe731ee-2582-48db-adf5-cd6194d63628",
                    "e1ca0e24-1363-495a-96d1-94c6bde4fb7a",
                    "5a257c5c-8536-402a-b606-9c46e3a8605c",
                  ],
                  disable: [
                    "1ca18059-8912-4378-a440-f9c62d2ed285",
                    "8fe731ee-2582-48db-adf5-cd6194d63628",
                    "e1ca0e24-1363-495a-96d1-94c6bde4fb7a",
                    "5a257c5c-8536-402a-b606-9c46e3a8605c",
                    "2911bd8a-d794-45b2-bae5-ecfe5db0dd00",
                  ],
                },
              },
              {
                id: "1ca18059-8912-4378-a440-f9c62d2ed285",
                type: "checkbox",
                label:
                  "Distributor identifies that greater knowledge and experience is required to understand the product for their client base",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "8fe731ee-2582-48db-adf5-cd6194d63628",
                type: "checkbox",
                label:
                  "Distributor Identifies that the proposed distribution method is inappropriate for their client base",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "e1ca0e24-1363-495a-96d1-94c6bde4fb7a",
                type: "checkbox",
                label:
                  "Distributor identifies that the product is more complex during review",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "5a257c5c-8536-402a-b606-9c46e3a8605c",
                type: "checkbox",
                label: "Other – please explain below:",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
                conditional: {
                  enable: ["2911bd8a-d794-45b2-bae5-ecfe5db0dd00"],
                },
              },
              {
                id: "2911bd8a-d794-45b2-bae5-ecfe5db0dd00",
                type: "input",
                value: "",
                width: 12,
                indent: 2,
                disabled: true,
                validate: {
                  required: true,
                },
              },
              {
                id: "1fff00b8-3930-40c7-9c9e-5493dbc49f42",
                type: "checkbox",
                label: "Less Restrictive",
                value: false,
                width: 12,
                conditional: {
                  enable: [
                    "6a0f0c43-dc18-4821-8d99-8192ac244eca",
                    "d4eceef4-9a6f-4452-8a03-3c7122486c5d",
                    "058bc81e-12d1-4dda-9ff8-ebf30a44af2e",
                  ],
                  disable: [
                    "6a0f0c43-dc18-4821-8d99-8192ac244eca",
                    "d4eceef4-9a6f-4452-8a03-3c7122486c5d",
                    "058bc81e-12d1-4dda-9ff8-ebf30a44af2e",
                    "fb4fefad-0608-4cdd-900b-ab1ee6713561",
                  ],
                },
              },
              {
                id: "6a0f0c43-dc18-4821-8d99-8192ac244eca",
                type: "checkbox",
                label:
                  "Distributor utilises the product for hedging / diversification purposes within a wider portfolio",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "d4eceef4-9a6f-4452-8a03-3c7122486c5d",
                type: "checkbox",
                label:
                  "Distributor Identifies that the proposed distribution method is too narrow for their client base",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "058bc81e-12d1-4dda-9ff8-ebf30a44af2e",
                type: "checkbox",
                label: "Other – please explain below:",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
                conditional: {
                  enable: ["fb4fefad-0608-4cdd-900b-ab1ee6713561"],
                },
              },
              {
                id: "fb4fefad-0608-4cdd-900b-ab1ee6713561",
                type: "input",
                value: "",
                width: 12,
                indent: 2,
                disabled: true,
                validate: {
                  required: true,
                },
              },
            ],
          },
        ],
      },
      {
        id: "07e68f5f-8bd4-45e9-8a6c-2a2487f7b8e8",
        name: "Product Testing",
        nav: "2",
        questions: [
          {
            id: "4cc43f23-42f7-47c2-8f17-430ffd859671",
            externalID: "3.2.1",
            nav: "3.2.1",
            text: "Does the Distributor carry out any kind of product testing (such as back testing or forward-looking stress testing) as part of the product design or approval process?",
            validate: {
              groupArr: [
                "a45e5ce3-8fc5-4069-bc7e-3cc366c09740",
                "38f40015-9169-40cf-b473-fb8751839287",
                "2c1e80a1-61a6-482a-9b0e-3baaef04cb3c",
                "707b1c5b-6e67-413e-9751-12ea8c0e386c",
                "c21d8e11-b165-41b7-9a2d-8e46ea262c8a",
                "5cfaccef-e000-46b7-843a-3a067445fc02",
                "d4b8ddf2-22e1-497d-ae41-9f89e347ec21",
                "2b9f02fa-97c8-4e31-8917-b842a64d1c1d",
                "6ccb5fe4-128d-449d-abce-dc0822c9973c",
              ],
            },
            fields: [
              {
                id: "c7597b12-09ab-4de8-923e-ff7cf11df540",
                type: "radio-list",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "ab62176f-1085-4e07-8bc4-4ad62018c759",
                    label: "Yes",
                    conditional: {
                      enable: [
                        "a45e5ce3-8fc5-4069-bc7e-3cc366c09740",
                        "38f40015-9169-40cf-b473-fb8751839287",
                        "2c1e80a1-61a6-482a-9b0e-3baaef04cb3c",
                        "707b1c5b-6e67-413e-9751-12ea8c0e386c",
                        "c21d8e11-b165-41b7-9a2d-8e46ea262c8a",
                        "5cfaccef-e000-46b7-843a-3a067445fc02",
                        "6ccb5fe4-128d-449d-abce-dc0822c9973c",
                      ],
                      disable: [
                        "d4b8ddf2-22e1-497d-ae41-9f89e347ec21",
                        "2b9f02fa-97c8-4e31-8917-b842a64d1c1d",
                        "6095d09d-e22a-4424-aa31-0245e984c4aa",
                      ],
                    },
                  },
                ],
              },
              {
                id: "a45e5ce3-8fc5-4069-bc7e-3cc366c09740",
                type: "checkbox",
                label: "Back testing",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "38f40015-9169-40cf-b473-fb8751839287",
                type: "checkbox",
                label: "Forward looking stress testing",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "2c1e80a1-61a6-482a-9b0e-3baaef04cb3c",
                type: "checkbox",
                label: "Value for money testing / Fair Value Testing",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "6ccb5fe4-128d-449d-abce-dc0822c9973c",
                type: "checkbox",
                label: "Product’s sustainability factors analysis",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "707b1c5b-6e67-413e-9751-12ea8c0e386c",
                type: "checkbox",
                label: "Scenario analysis including KID",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "c21d8e11-b165-41b7-9a2d-8e46ea262c8a",
                type: "checkbox",
                label: "Risk/Reward testing",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "5cfaccef-e000-46b7-843a-3a067445fc02",
                type: "checkbox",
                label: "Other - please specify below:",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
                conditional: {
                  enable: ["cbc9f2ba-c97b-42ad-b820-7abeacd0bfa0"],
                },
              },
              {
                id: "cbc9f2ba-c97b-42ad-b820-7abeacd0bfa0",
                type: "input",
                value: "",
                width: 12,
                indent: 2,
                disabled: true,
                validate: {
                  required: true,
                },
              },
              {
                id: "c7597b12-09ab-4de8-923e-ff7cf11df540",
                type: "radio-list",
                width: 12,
                options: [
                  {
                    id: "a27c346b-1415-472b-b4af-e26787007ba4",
                    label: "No",
                    conditional: {
                      disable: [
                        "a45e5ce3-8fc5-4069-bc7e-3cc366c09740",
                        "38f40015-9169-40cf-b473-fb8751839287",
                        "2c1e80a1-61a6-482a-9b0e-3baaef04cb3c",
                        "707b1c5b-6e67-413e-9751-12ea8c0e386c",
                        "c21d8e11-b165-41b7-9a2d-8e46ea262c8a",
                        "5cfaccef-e000-46b7-843a-3a067445fc02",
                        "cbc9f2ba-c97b-42ad-b820-7abeacd0bfa0",
                        "6ccb5fe4-128d-449d-abce-dc0822c9973c",
                      ],
                      enable: [
                        "d4b8ddf2-22e1-497d-ae41-9f89e347ec21",
                        "2b9f02fa-97c8-4e31-8917-b842a64d1c1d",
                      ],
                    },
                  },
                ],
              },
              {
                id: "d4b8ddf2-22e1-497d-ae41-9f89e347ec21",
                type: "checkbox",
                label: "We rely on Issuer provided product testing",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
              },
              {
                id: "2b9f02fa-97c8-4e31-8917-b842a64d1c1d",
                type: "checkbox",
                label: "Please explain below why this is not required",
                value: false,
                width: 12,
                indent: 1,
                disabled: true,
                conditional: {
                  enable: ["6095d09d-e22a-4424-aa31-0245e984c4aa"],
                },
              },
              {
                id: "6095d09d-e22a-4424-aa31-0245e984c4aa",
                type: "input",
                value: "",
                width: 12,
                indent: 2,
                disabled: true,
                validate: {
                  required: true,
                },
              },
            ],
          },
          {
            id: "817d80f3-7f1b-43f9-a696-e8ed83e568a5",
            externalID: "3.2.2",
            nav: "3.2.2",
            text: "Who defined the product testing policy or guidelines followed?",
            validate: {
              groupArr: [
                "c435d8f3-c5a4-5b15-9db6-0a8c43dfcbc4",
                "baeccd96-0397-466d-84df-47651b77d98f",
                "3b0472c4-7aad-4851-b9b9-5b9db5a2064e",
              ],
            },
            fields: [
              {
                id: "c435d8f3-c5a4-5b15-9db6-0a8c43dfcbc4",
                type: "checkbox",
                label:
                  "N/A – The Distributor relies on issuer provided product testing",
                value: false,
                width: 12,
                indent: 0,
              },
              {
                id: "baeccd96-0397-466d-84df-47651b77d98f",
                type: "checkbox",
                label: "Regulatory defined method. Please describe below:",
                value: false,
                width: 12,
                indent: 0,
                conditional: {
                  enable: ["f60b4246-91b9-4bc9-b1ae-f6369490673d"],
                },
              },
              {
                id: "f60b4246-91b9-4bc9-b1ae-f6369490673d",
                type: "input",
                value: "",
                width: 12,
                indent: 1,
                disabled: true,
                validate: {
                  required: true,
                },
              },
              {
                id: "3b0472c4-7aad-4851-b9b9-5b9db5a2064e",
                type: "checkbox",
                label: "In house defined method. Please describe below:",
                value: false,
                width: 12,
                indent: 0,
                conditional: {
                  enable: ["ba81d660-c53c-4750-a302-44417db35912"],
                },
              },
              {
                id: "ba81d660-c53c-4750-a302-44417db35912",
                type: "input",
                value: "",
                width: 12,
                indent: 1,
                disabled: true,
                validate: {
                  required: true,
                },
              },
            ],
          },
          {
            id: "780f9b2a-8f19-40b5-ae0a-0cc52c350a61",
            externalID: "3.2.3",
            nav: "3.2.3",
            text: "If the Distributor does carry out product testing, how do these results impact the product approval or distribution process?",
            fields: [
              {
                id: "38586c93-00c8-4bf8-b319-fcc990d2819c",
                type: "textArea",
                label: "",
                width: 12,
                validate: {
                  required: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "291701d2-cf9f-4616-a093-3592b19c893e",
    name: "Suitability, Appropriateness and Distribution Controls",
    nav: "4",
    subsections: [
      {
        id: "a257945f-aac4-4d07-a838-9826a7db6dd1",
        name: "General Information",
        nav: "1",
        questions: [
          {
            id: "0ce351b5-d223-42a6-b285-b8f8196e92e4",
            externalID: "4.1.1",
            nav: "4.1.1",
            text: "Are suitability checks (if applicable) performed by the legal entity who is responsible for the End Client?",
            validate: {
              groupArr: [
                "89474bca-0e67-4021-a397-ef812281c4bc",
                "24a6941b-1da5-4fec-88fc-08405d88f092",
                "fc08f103-b5ca-4d30-953f-f31f1e1cabcd",
              ],
            },
            fields: [
              {
                id: "65716483-7fd2-49e2-b1b5-6ed2fb954d47",
                type: "radio-list",
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "e31c5001-bc20-4d14-a0f6-332f567cdd67",
                    label:
                      "N/A - no regulatory requirement to perform suitability assessment",
                    conditional: {
                      disable: [
                        "89474bca-0e67-4021-a397-ef812281c4bc",
                        "24a6941b-1da5-4fec-88fc-08405d88f092",
                        "fc08f103-b5ca-4d30-953f-f31f1e1cabcd",
                        "717e281a-3705-4060-a6e9-e5e50d06a122",
                      ],
                    },
                  },
                  {
                    id: "fa8ae865-3e87-4038-aa82-560f47a3c896",
                    label: "Yes",
                    conditional: {
                      enable: [
                        "89474bca-0e67-4021-a397-ef812281c4bc",
                        "24a6941b-1da5-4fec-88fc-08405d88f092",
                        "fc08f103-b5ca-4d30-953f-f31f1e1cabcd",
                      ],
                      disable: ["717e281a-3705-4060-a6e9-e5e50d06a122"],
                    },
                  },
                ],
              },
              {
                id: "89474bca-0e67-4021-a397-ef812281c4bc",
                type: "checkbox",
                label: "By the Distributor",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
              },
              {
                id: "24a6941b-1da5-4fec-88fc-08405d88f092",
                type: "checkbox",
                label: "By Sub-distributors / 3rd-Party intermediaries",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
              },
              {
                id: "fc08f103-b5ca-4d30-953f-f31f1e1cabcd",
                type: "checkbox",
                label: "By Custodians",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
              },
              {
                id: "65716483-7fd2-49e2-b1b5-6ed2fb954d47",
                type: "radio-list",
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "b53598c3-1f6c-41ec-9d9a-bd3477212aab",
                    label: "No - Please explain the rationale for this below:",
                    conditional: {
                      enable: ["717e281a-3705-4060-a6e9-e5e50d06a122"],
                      disable: [
                        "89474bca-0e67-4021-a397-ef812281c4bc",
                        "24a6941b-1da5-4fec-88fc-08405d88f092",
                        "fc08f103-b5ca-4d30-953f-f31f1e1cabcd",
                      ],
                    },
                  },
                ],
              },
              {
                id: "717e281a-3705-4060-a6e9-e5e50d06a122",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 1,
              },
            ],
          },
          {
            id: "58ca157a-9023-4caa-9303-20fab3d4bf1f",
            externalID: "4.1.2",
            nav: "4.1.2",
            text: "Are appropriateness checks (if applicable) performed by the legal entity who is responsible for the End Client?",
            validate: {
              groupArr: [
                "78296937-0943-4fd4-9e27-702a61d8e31a",
                "300c4281-0706-4c74-986e-514afcdc1ced",
                "895b655d-eb27-4448-80da-862d92d222d4",
              ],
            },
            fields: [
              {
                id: "ba34f9c5-f6ac-4975-bf32-9740217e630c",
                type: "radio-list",
                width: 12,
                value: "",
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "f7a6c600-8dfb-4fdf-acc7-ec4d06591176",
                    label:
                      "N/A - no regulatory requirement to perform appropriateness assessment",
                    conditional: {
                      disable: [
                        "78296937-0943-4fd4-9e27-702a61d8e31a",
                        "300c4281-0706-4c74-986e-514afcdc1ced",
                        "895b655d-eb27-4448-80da-862d92d222d4",
                        "7cfb6092-b64e-405e-a964-8206cec2b13f",
                      ],
                    },
                  },
                  {
                    id: "4b813afb-6440-47f6-a3ab-61b66656dd28",
                    label: "Yes",
                    conditional: {
                      enable: [
                        "78296937-0943-4fd4-9e27-702a61d8e31a",
                        "300c4281-0706-4c74-986e-514afcdc1ced",
                        "895b655d-eb27-4448-80da-862d92d222d4",
                      ],
                      disable: ["7cfb6092-b64e-405e-a964-8206cec2b13f"],
                    },
                  },
                ],
              },
              {
                id: "78296937-0943-4fd4-9e27-702a61d8e31a",
                type: "checkbox",
                label: "By the Distributor",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
              },
              {
                id: "300c4281-0706-4c74-986e-514afcdc1ced",
                type: "checkbox",
                label: "By Sub-distributors / 3rd-Party intermediaries",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
              },
              {
                id: "895b655d-eb27-4448-80da-862d92d222d4",
                type: "checkbox",
                label: "By Custodians",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
              },
              {
                id: "ba34f9c5-f6ac-4975-bf32-9740217e630c",
                type: "radio-list",
                width: 12,
                value: "",
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "e6100d4a-c9f3-44ad-b07c-0c8be0cba398",
                    label: "No - Please explain the rationale for this below:",
                    conditional: {
                      enable: ["7cfb6092-b64e-405e-a964-8206cec2b13f"],
                      disable: [
                        "78296937-0943-4fd4-9e27-702a61d8e31a",
                        "300c4281-0706-4c74-986e-514afcdc1ced",
                        "895b655d-eb27-4448-80da-862d92d222d4",
                      ],
                    },
                  },
                ],
              },
              {
                id: "7cfb6092-b64e-405e-a964-8206cec2b13f",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 1,
              },
            ],
          },
          {
            id: "1fb1a1fa-f190-42b3-b83c-9539bdb661de",
            externalID: "4.1.3",
            nav: "4.1.3",
            text: "Does the legal entity responsible for the End Client conduct ongoing client suitability, / appropriateness / client categorisation reviews?",
            validate: {
              groupArr: [
                "7936fad5-cee6-40df-9b38-96d1b3a73487",
                "020e5a37-e926-416f-9008-5fac23dbb73c",
                "c62cad21-c80f-467b-93ae-66b7fe76526a",
                "ee03d13a-10b3-49c1-b65f-133ec607cdb8",
              ],
            },
            fields: [
              {
                id: "76cafdc7-ae1f-4306-9b64-83071b6b29b0",
                type: "radio-list",
                width: 12,
                value: "",
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "111dc701-19ee-4c92-b3d7-5d1c47563eda",
                    label:
                      "N/A – no regulatory requirement to review suitability/ appropriateness/ categorisation",
                    conditional: {
                      disable: [
                        "7936fad5-cee6-40df-9b38-96d1b3a73487",
                        "020e5a37-e926-416f-9008-5fac23dbb73c",
                        "c62cad21-c80f-467b-93ae-66b7fe76526a",
                        "ee03d13a-10b3-49c1-b65f-133ec607cdb8",
                        "6cf613fa-a706-4a1a-ba13-33ea0f95e03d",
                        "1ff28461-18a0-4a9f-b41e-c34a3434afeb",
                      ],
                    },
                  },
                  {
                    id: "e3004057-a942-4342-a08e-4fc4d5a9b57d",
                    label: "No - Client onboarding only",
                    conditional: {
                      disable: [
                        "7936fad5-cee6-40df-9b38-96d1b3a73487",
                        "020e5a37-e926-416f-9008-5fac23dbb73c",
                        "c62cad21-c80f-467b-93ae-66b7fe76526a",
                        "ee03d13a-10b3-49c1-b65f-133ec607cdb8",
                        "6cf613fa-a706-4a1a-ba13-33ea0f95e03d",
                        "1ff28461-18a0-4a9f-b41e-c34a3434afeb",
                      ],
                    },
                  },
                  {
                    id: "e87e9607-edc8-4a16-925c-802d0afba097",
                    label: "Yes",
                    conditional: {
                      enable: [
                        "7936fad5-cee6-40df-9b38-96d1b3a73487",
                        "020e5a37-e926-416f-9008-5fac23dbb73c",
                        "c62cad21-c80f-467b-93ae-66b7fe76526a",
                        "ee03d13a-10b3-49c1-b65f-133ec607cdb8",
                      ],
                    },
                  },
                ],
              },
              {
                id: "7936fad5-cee6-40df-9b38-96d1b3a73487",
                type: "checkbox",
                label: "Trade by trade basis",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
              },
              {
                id: "020e5a37-e926-416f-9008-5fac23dbb73c",
                type: "checkbox",
                label:
                  "When notified of change to client circumstances during client meetings/interactions",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
              },
              {
                id: "c62cad21-c80f-467b-93ae-66b7fe76526a",
                type: "checkbox",
                label:
                  "Periodic Review (for example formal quarterly or annually review) please specify frequency below:",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
                conditional: {
                  enable: ["6cf613fa-a706-4a1a-ba13-33ea0f95e03d"],
                },
              },
              {
                id: "6cf613fa-a706-4a1a-ba13-33ea0f95e03d",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 2,
              },
              {
                id: "ee03d13a-10b3-49c1-b65f-133ec607cdb8",
                type: "checkbox",
                label: "Other - Please specify below:",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
                conditional: {
                  enable: ["1ff28461-18a0-4a9f-b41e-c34a3434afeb"],
                },
              },
              {
                id: "1ff28461-18a0-4a9f-b41e-c34a3434afeb",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 2,
              },
            ],
          },
          {
            id: "9d8f348a-8145-4bc1-af22-8fc8a1428c30",
            externalID: "4.1.4",
            nav: "4.1.4",
            text: "How does the Distributor obtain the information required to assess their needs and investment objectives?",
            validate: {
              groupArr: [
                "a0103690-ce2d-4609-9454-eb9ee48ce10c",
                "5027e18a-edd7-402d-9580-a8ae27be1f05",
                "42ef7d82-e183-4885-9d7c-1bdd0042528f",
                "ce05a540-d5e6-4a7e-a07d-068821a9c78b",
                "95be94b3-db65-45d7-867f-39e63c337c99",
                "02f6db6d-c2f4-4434-99ab-738b2baa445a",
                "ded4211f-f99f-4bdf-8b93-18220ff18419",
              ],
            },
            fields: [
              {
                id: "a0103690-ce2d-4609-9454-eb9ee48ce10c",
                type: "checkbox",
                label:
                  "Client onboarding process including an assessment of the client’s personal circumstances, risk appetite, objectives, investment knowledge and experience, Sustainability (ESG) preferences",
                value: false,
                width: 12,
              },
              {
                id: "5027e18a-edd7-402d-9580-a8ae27be1f05",
                type: "checkbox",
                label:
                  "Regular client meetings and ongoing monitoring of client’s portfolio (e.g. performance, concentration limits, downgrades, fees/sales commissions, turnover, early termination and/or trading near observation dates for structured products) and personal circumstances, including changes to their Sustainability (ESG) preferences",
                value: false,
                width: 12,
              },
              {
                id: "42ef7d82-e183-4885-9d7c-1bdd0042528f",
                type: "checkbox",
                label: "Consumer research",
                value: false,
                width: 12,
              },
              {
                id: "ce05a540-d5e6-4a7e-a07d-068821a9c78b",
                type: "checkbox",
                label:
                  "Requirement for end client review and sign-off of term sheet prior to trading",
                value: false,
                width: 12,
              },
              {
                id: "95be94b3-db65-45d7-867f-39e63c337c99",
                type: "checkbox",
                label:
                  "Distributor does not face the End Client; Sub-distributors / 3rd-Party intermediary is responsible",
                value: false,
                width: 12,
              },
              {
                id: "ded4211f-f99f-4bdf-8b93-18220ff18419",
                type: "checkbox",
                label: "Other - Please specify details below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["ec5a9f0c-d2a8-44c3-88e9-4b5bda0afb2c"],
                },
              },
              {
                id: "ec5a9f0c-d2a8-44c3-88e9-4b5bda0afb2c",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 1,
              },
              {
                id: "02f6db6d-c2f4-4434-99ab-738b2baa445a",
                type: "checkbox",
                label: "No such information is collected by the Distributor",
                value: false,
                width: 12,
              },
            ],
          },
          {
            id: "aae379ab-d1cc-4698-8d69-0e115c05524a",
            externalID: "4.1.5",
            nav: "4.1.5",
            text: "How does the Distributor reasonably ensure that End Clients have sufficient knowledge and experience to understand the payoffs, risks and/or strategies underlying the Structured Products that it offers?",
            validate: {
              groupArr: [
                "6eaf7ce3-e4f5-4bd7-a288-d72b65ece307",
                "6d7a1727-ac00-40ab-974d-aaa45b685ab7",
                "76e4e464-27ab-4deb-bf04-083e265d177c",
                "16be47fc-5681-4588-a26f-89a612514be4",
                "f01cf849-f7d7-4faf-96b6-7528e534e266",
                "3e845e52-825f-436e-856a-1a186ca7e288",
                "a0950832-17be-4e78-a525-7ef3fd8bec9a",
              ],
            },
            fields: [
              {
                id: "6eaf7ce3-e4f5-4bd7-a288-d72b65ece307",
                type: "checkbox",
                label: "Provision of full and fair disclosure of product information",
                value: false,
                width: 12,
              },
              {
                id: "6d7a1727-ac00-40ab-974d-aaa45b685ab7",
                type: "checkbox",
                label: "Provision of suitably alternative investment options",
                value: false,
                width: 12,
              },
              {
                id: "76e4e464-27ab-4deb-bf04-083e265d177c",
                type: "checkbox",
                label: "Heightened first line sales supervision",
                value: false,
                width: 12,
              },
              {
                id: "16be47fc-5681-4588-a26f-89a612514be4",
                type: "checkbox",
                label: "Enhanced first line controls and non-compliance remediation",
                value: false,
                width: 12,
              },
              {
                id: "f01cf849-f7d7-4faf-96b6-7528e534e266",
                type: "checkbox",
                label:
                  "Distributor does not face the End Client; Sub-distributors / 3rd-Party intermediary is responsible",
                value: false,
                width: 12,
              },
              {
                id: "3e845e52-825f-436e-856a-1a186ca7e288",
                type: "checkbox",
                label:
                  "Specific CLN disclosures, including product information and key risks i.e. the structure of the product, different types of credit event and the potential resulting impact to CLN payoffs and potential loss off capital.",
                value: false,
                width: 12,
              },
              {
                id: "a0950832-17be-4e78-a525-7ef3fd8bec9a",
                type: "checkbox",
                label: "Other - Please specify details below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["b84a98ee-9634-4fed-811c-4ae7aeb15cbc"],
                },
              },
              {
                id: "b84a98ee-9634-4fed-811c-4ae7aeb15cbc",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 1,
              },
            ],
          },
          {
            id: "5ee74bca-7baf-42ab-9f7f-3e3dff0ab557",
            externalID: "4.1.6",
            nav: "4.1.6",
            text: "Does the Distributor have restrictions in place in relation to the distribution of Structured Products?",
            validate: {
              groupArr: [
                "7825823d-5601-44c1-9de5-58eecbba9795",
                "28d943a5-9caa-4876-9fbf-e0e1c6698bc0",
                "1cb20612-9aec-4821-b0e0-f78c30106714",
                "ca5c2804-edfa-403b-8b10-10422c4b673a",
                "cea42d76-6238-4e88-81ae-41d4a4bfde7c",
                "f0910019-0d4f-4452-8244-5e0515cd7c8d",
              ],
            },
            fields: [
              {
                id: "ad2e4592-52b0-40e2-84ab-d91c360536ad",
                type: "radio-list",
                width: 12,
                value: "",
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "c8dcc36f-783d-4e35-a586-e3370ca7b952",
                    label: "No",
                    conditional: {
                      disable: [
                        "1cb20612-9aec-4821-b0e0-f78c30106714",
                        "ca5c2804-edfa-403b-8b10-10422c4b673a",
                        "cea42d76-6238-4e88-81ae-41d4a4bfde7c",
                        "dc3502ee-d313-4e20-a6b7-a758d7f2ad1a",
                        "d7fe2a1e-9682-451b-9c7e-9a8281616158",
                        "35cc352d-9416-4a35-aa2f-841f830d6501",
                        "f0910019-0d4f-4452-8244-5e0515cd7c8d",
                        "fbb389bc-8ee2-4821-8e8f-c12dcecad890",
                      ],
                      enable: [
                        "7825823d-5601-44c1-9de5-58eecbba9795",
                        "28d943a5-9caa-4876-9fbf-e0e1c6698bc0",
                      ],
                    },
                  },
                ],
              },
              {
                id: "7825823d-5601-44c1-9de5-58eecbba9795",
                type: "checkbox",
                label:
                  "Distributor does not face the End Client; Sub-distributors / 3rd-Party intermediary is responsible",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
              },
              {
                id: "28d943a5-9caa-4876-9fbf-e0e1c6698bc0",
                type: "checkbox",
                label: "Other - Please explain the rationale below",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
                conditional: {
                  enable: ["0eda0363-b845-40ba-841c-b1844c943ca7"],
                },
              },
              {
                id: "0eda0363-b845-40ba-841c-b1844c943ca7",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 2,
              },
              {
                id: "ad2e4592-52b0-40e2-84ab-d91c360536ad",
                type: "radio-list",
                width: 12,
                value: "",
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "31769849-5cfa-49b8-b39f-61d428d3fa6a",
                    label: "Yes - please describe what restrictions are applied",
                    conditional: {
                      disable: [
                        "7825823d-5601-44c1-9de5-58eecbba9795",
                        "28d943a5-9caa-4876-9fbf-e0e1c6698bc0",
                        "0eda0363-b845-40ba-841c-b1844c943ca7",
                      ],
                      enable: [
                        "1cb20612-9aec-4821-b0e0-f78c30106714",
                        "ca5c2804-edfa-403b-8b10-10422c4b673a",
                        "cea42d76-6238-4e88-81ae-41d4a4bfde7c",
                        "f0910019-0d4f-4452-8244-5e0515cd7c8d",
                      ],
                    },
                  },
                ],
              },
              {
                id: "1cb20612-9aec-4821-b0e0-f78c30106714",
                type: "checkbox",
                label: "Risk Factors",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
                conditional: {
                  enable: ["dc3502ee-d313-4e20-a6b7-a758d7f2ad1a"],
                },
                popover: {
                  body: "Detail if the distribution of products is restricted so that the risk factors / rating of the product must align with the risk appetite of the End Client",
                },
              },
              {
                id: "dc3502ee-d313-4e20-a6b7-a758d7f2ad1a",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 2,
              },
              {
                id: "ca5c2804-edfa-403b-8b10-10422c4b673a",
                type: "checkbox",
                label: "Sales Team",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
                conditional: {
                  enable: ["d7fe2a1e-9682-451b-9c7e-9a8281616158"],
                },
                popover: {
                  body: "Detail if the distribution of structured products is restricted to a specific team of individuals within the firm with the prerequisite experience and training i.e. specialised CLN training.",
                },
              },
              {
                id: "d7fe2a1e-9682-451b-9c7e-9a8281616158",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 2,
              },
              {
                id: "cea42d76-6238-4e88-81ae-41d4a4bfde7c",
                type: "checkbox",
                label: "Product Type",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
                conditional: {
                  enable: ["35cc352d-9416-4a35-aa2f-841f830d6501"],
                },
                popover: {
                  body: "Detail if certain product structures are restricted for distribution and how this is controlled i.e. Supervisor sign off, system controls or post trade sample checks.",
                },
              },
              {
                id: "35cc352d-9416-4a35-aa2f-841f830d6501",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 2,
              },
              {
                id: "f0910019-0d4f-4452-8244-5e0515cd7c8d",
                type: "checkbox",
                label: "Other - please specify below",
                value: false,
                width: 12,
                disabled: true,
                indent: 1,
                conditional: {
                  enable: ["fbb389bc-8ee2-4821-8e8f-c12dcecad890"],
                },
              },
              {
                id: "fbb389bc-8ee2-4821-8e8f-c12dcecad890",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 2,
              },
            ],
          },
          {
            id: "1988d47f-baa6-4996-bb79-eca6e9b0a7eb",
            externalID: "4.1.7",
            nav: "4.1.7",
            text: "How does the Distributor ensure these restrictions are adequately controlled and monitored?",
            validate: {
              groupArr: [
                "8ae12276-6197-4d24-8640-ce2f186ac2cf",
                "b33939ad-1fb6-44f5-ab50-b07cd1381b89",
                "28aa640c-7029-423f-9314-14c5d32d0226",
                "b2187aa1-2ca9-494f-a175-770d5435a5fe",
                "5e8033e5-3754-4b59-91d2-e11568cbd328",
                "fa418831-1f97-4b5b-b84b-78c00980b445",
              ],
            },
            fields: [
              {
                id: "8ae12276-6197-4d24-8640-ce2f186ac2cf",
                type: "checkbox",
                label: "N/A – No restrictions are in place",
                value: false,
                width: 12,
              },
              {
                id: "b33939ad-1fb6-44f5-ab50-b07cd1381b89",
                type: "checkbox",
                label:
                  "N/A – Distributor does not face the End Client; Sub-distributors / 3rd-Party intermediary is responsible",
                value: false,
                width: 12,
              },
              {
                id: "28aa640c-7029-423f-9314-14c5d32d0226",
                type: "checkbox",
                label:
                  "System Control / Blocking Mechanisms - please explain the control below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["fc2cb85f-488c-433c-a80e-80ac4babd7f8"],
                },
              },
              {
                id: "fc2cb85f-488c-433c-a80e-80ac4babd7f8",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 1,
              },
              {
                id: "b2187aa1-2ca9-494f-a175-770d5435a5fe",
                type: "checkbox",
                label:
                  "Compliance and Risk sample checking and sign off - please explain the control below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["e2d7ed39-199c-4628-a22e-19069c6895b0"],
                },
              },
              {
                id: "e2d7ed39-199c-4628-a22e-19069c6895b0",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 1,
              },
              {
                id: "5e8033e5-3754-4b59-91d2-e11568cbd328",
                type: "checkbox",
                label:
                  "Supervisory/Managerial sign off - please explain the control below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["93954200-b943-4d71-aec9-f8a3164c4452"],
                },
              },
              {
                id: "93954200-b943-4d71-aec9-f8a3164c4452",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 1,
              },
              {
                id: "fa418831-1f97-4b5b-b84b-78c00980b445",
                type: "checkbox",
                label: "Other - please explain the control below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["87b05dd9-9d49-4f69-b225-f70f7c5a7594"],
                },
              },
              {
                id: "87b05dd9-9d49-4f69-b225-f70f7c5a7594",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 1,
              },
            ],
          },
          {
            id: "8de291d3-7eb8-4aa1-ad12-64778c56aafa",
            externalID: "4.1.8",
            nav: "4.1.8",
            text: "Does the Distributor / Sub-distributors / 3rd-Party intermediaries have any policies regarding the level of diversification that must exist in a client’s portfolio before investors can buy Structured Products?",
            validate: {
              groupArr: [],
            },
            fields: [
              {
                id: "a6d88e0b-069b-464e-b728-acac1fea35c8",
                type: "radio-list",
                width: 12,
                value: "",
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "a867f09d-a597-49af-bcdf-584e1c035c9e",
                    label:
                      "Yes – Please describe the diversification limits/ thresholds below e.g. % diversification by geography, issuer, complexity, risk:",
                    conditional: {
                      disable: ["bcba4e70-9983-4696-ace3-3be74904f512"],
                      enable: ["452e13a1-f4bf-4c25-95ef-e7bdf1a1d405"],
                    },
                  },
                ],
              },
              {
                id: "452e13a1-f4bf-4c25-95ef-e7bdf1a1d405",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 1,
              },
              {
                id: "a6d88e0b-069b-464e-b728-acac1fea35c8",
                type: "radio-list",
                width: 12,
                value: "",
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "0e5ff1dc-5450-4de2-9a51-3344cd06301d",
                    label: "No – Please explain rationale below:",
                    conditional: {
                      disable: ["452e13a1-f4bf-4c25-95ef-e7bdf1a1d405"],
                      enable: ["bcba4e70-9983-4696-ace3-3be74904f512"],
                    },
                  },
                ],
              },
              {
                id: "bcba4e70-9983-4696-ace3-3be74904f512",
                type: "input",
                width: 12,
                disabled: true,
                validate: {
                  required: true,
                },
                value: "",
                indent: 1,
              },
            ],
          },
        ],
      },
      {
        id: "117035bb-32d1-4ef2-9bff-8b55277b0b48",
        name: "Fees",
        nav: "2",
        questions: [
          {
            id: "ff584d51-bd47-4532-829b-f430eb4df0ff",
            externalID: "4.2.1",
            nav: "4.2.1",
            text: "What fee information is disclosed to the End Client?",
            validate: {
              groupArr: [
                "e099cac8-27f2-4821-be37-9db3e5ee5508",
                "28f5e956-9867-4a0e-a1f5-f854a06470b7",
                "22f29060-8702-4849-bfee-498ed7d8731e",
                "518647c8-aa36-491d-8187-89c4073d00c2",
                "837f99af-de20-4386-b993-ee26383d425f",
              ],
            },
            fields: [
              {
                id: "e099cac8-27f2-4821-be37-9db3e5ee5508",
                type: "checkbox",
                label: "Fee structure disclosed",
                value: false,
                width: 12,
              },
              {
                id: "28f5e956-9867-4a0e-a1f5-f854a06470b7",
                type: "checkbox",
                label: "Fee levels disclosed",
                value: false,
                width: 12,
              },
              {
                id: "22f29060-8702-4849-bfee-498ed7d8731e",
                type: "checkbox",
                label: "Min/Max fee ranges",
                value: false,
                width: 12,
              },
              {
                id: "518647c8-aa36-491d-8187-89c4073d00c2",
                type: "checkbox",
                label: "Absolute/Exact fee amount",
                value: false,
                width: 12,
              },
              {
                id: "837f99af-de20-4386-b993-ee26383d425f",
                type: "checkbox",
                label: "Other - please specify below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["b2c2884f-d718-432d-ad7f-70d3fe317018"],
                },
              },
              {
                id: "b2c2884f-d718-432d-ad7f-70d3fe317018",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "deae2380-09e1-44e7-834d-a7c9157d1e3f",
            externalID: "4.2.2",
            nav: "4.2.2",
            text: "Does the distributor comply with the legal, regulatory, and good practice requirements applicable to disclosure of fees to End Clients?",
            fields: [
              {
                id: "0f35df22-95cc-4815-ad3c-335969f3ce72",
                type: "radio-list",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "868ce3bc-5a20-48c0-b4c5-b6ae874d8f21",
                    label:
                      "Yes – the Distributor confirms that they disclose fees in good time prior to the investment decision being made; and the distributor understands and complies with the legal and regulatory requirements applicable to the disclosure of fees to End Clients in all jurisdictions that they distribute Structured Products in.",
                    conditional: {
                      disable: ["dc1a63ad-a1e9-4ad8-b326-d9d9b927e404"],
                    },
                  },
                  {
                    id: "c320ecd9-2b07-490b-8aa2-96265baa9a42",
                    label:
                      "No – please explain to whom the fees are disclosed and when: ",
                    conditional: {
                      enable: ["dc1a63ad-a1e9-4ad8-b326-d9d9b927e404"],
                    },
                  },
                ],
              },
              {
                id: "dc1a63ad-a1e9-4ad8-b326-d9d9b927e404",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "7d0e6bc6-314e-488b-a5fd-17c75d3a2ad1",
            externalID: "4.2.3",
            nav: "4.2.3",
            text: "How are fees disclosed to the End Client?",
            validate: {
              groupArr: [
                "0fb1447f-38d9-4b6b-83cb-a0d04b46bb9a",
                "631a7f8f-a2ce-4d62-ad0b-41a75c6f7b71",
                "3c9d126f-ac9a-4b2d-96b4-68dfde7dd4fc",
                "1f9862eb-6214-40d3-87d2-7a449eaca0d7",
              ],
            },
            fields: [
              {
                id: "0fb1447f-38d9-4b6b-83cb-a0d04b46bb9a",
                type: "checkbox",
                label:
                  "In the offering or marketing documents – please specify below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["6d9fbb00-4af3-4f7d-90e8-50406c3f4f89"],
                },
              },
              {
                id: "6d9fbb00-4af3-4f7d-90e8-50406c3f4f89",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
              {
                id: "631a7f8f-a2ce-4d62-ad0b-41a75c6f7b71",
                type: "checkbox",
                label: "Upon investor’s request",
                value: false,
                width: 12,
              },
              {
                id: "3c9d126f-ac9a-4b2d-96b4-68dfde7dd4fc",
                type: "checkbox",
                label: "By means of an agreement",
                value: false,
                width: 12,
              },
              {
                id: "1f9862eb-6214-40d3-87d2-7a449eaca0d7",
                type: "checkbox",
                label: "Other - please specify below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["03ac5084-88df-4cfd-b4e3-7ba13cb1d183"],
                },
              },
              {
                id: "03ac5084-88df-4cfd-b4e3-7ba13cb1d183",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "0ee42187-a02c-49e9-ad3f-712dabf59ee9",
            externalID: "4.2.4",
            nav: "4.2.4",
            text: "Does the legal entity who is responsible for the end client have any clients and or services in the UK that UK Retail Distribution Review Rules apply to? ",
            fields: [
              {
                id: "3cb42621-d99c-4599-96af-ebd562a421b6",
                type: "radio-list",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "71c03976-db4f-488e-99ee-0e6325954f2a",
                    label: "No",
                    conditional: {
                      disable: ["1994e6e1-a977-415f-9b1e-d7dee837fadf"],
                    },
                  },
                  {
                    id: "cc40bb16-3ffb-4ba5-8517-bdb00e4769ca",
                    label:
                      "Yes – please describe below what arrangements are in place to conduct impacted UK clients / services appropriately?",
                    conditional: {
                      enable: ["1994e6e1-a977-415f-9b1e-d7dee837fadf"],
                    },
                  },
                ],
              },
              {
                id: "1994e6e1-a977-415f-9b1e-d7dee837fadf",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
        ],
      },
      {
        id: "f5c9abb1-37aa-4bee-b403-a012c7a3c694",
        name: "Marketing Material",
        nav: "3",
        questions: [
          {
            id: "6a998b42-5ee7-4dbb-9cb3-033625e3e549",
            externalID: "4.3.1",
            nav: "4.3.1",
            text: "What channels are used to drive demand for structured product from End Clients?",
            validate: {
              groupArr: [
                "923bd615-f727-47ed-9c2f-589fb4a0f234",
                "c52746be-6498-45d2-94d8-b24ca30da6d3",
                "2b3a095e-6b9f-4a4d-9ccd-ad916eb3dfd8",
                "5b43a1b6-ffca-4832-a936-9d0861436dbc",
                "8546cef5-eb5f-429d-bfd9-9e8099d30493",
                "a7f48420-218c-4c00-9e4b-201ceadb29b9",
                "92cf7f86-cf44-4ede-8924-22f0dbe80c48",
              ],
            },
            fields: [
              {
                id: "923bd615-f727-47ed-9c2f-589fb4a0f234",
                type: "checkbox",
                label:
                  "Reverse Inquiry (where the End Clients asks for a specific product without having been solicited by the Issuer nor the Distributor)",
                value: false,
                width: 12,
              },
              {
                id: "c52746be-6498-45d2-94d8-b24ca30da6d3",
                type: "checkbox",
                label: "Targeted communications to advisors and existing client base",
                value: false,
                width: 12,
              },
              {
                id: "2b3a095e-6b9f-4a4d-9ccd-ad916eb3dfd8",
                type: "checkbox",
                label: "Newly targeted prospects",
                value: false,
                width: 12,
              },
              {
                id: "5b43a1b6-ffca-4832-a936-9d0861436dbc",
                type: "checkbox",
                label: "3rd-Party Intermediary/Sub distribution network",
                value: false,
                width: 12,
              },
              {
                id: "8546cef5-eb5f-429d-bfd9-9e8099d30493",
                type: "checkbox",
                label: "Branches",
                value: false,
                width: 12,
              },
              {
                id: "a7f48420-218c-4c00-9e4b-201ceadb29b9",
                type: "checkbox",
                label: "Internet / website based marketing",
                value: false,
                width: 12,
              },
              {
                id: "92cf7f86-cf44-4ede-8924-22f0dbe80c48",
                type: "checkbox",
                label: "Other - please explain below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["3891c33b-30a4-4c77-ac14-10b5e6b4dd05"],
                },
              },
              {
                id: "3891c33b-30a4-4c77-ac14-10b5e6b4dd05",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "29f2f4c7-0c3a-4815-8aa0-e7c163cc712b",
            externalID: "4.3.2",
            nav: "4.3.2",
            text: "What document types will be used to market products?",
            popover: {
              body: "Issuer’s term sheet or documentation should not be used for marketing purposes by the Sub-distributors / 3rd-Party intermediaries unless such use is duly approved by the relevant Issuer.",
            },
            validate: {
              groupArr: [
                "401a6a09-1f8d-432b-b3d9-f615e811bbb6",
                "24b99e18-dde7-4e97-b863-e2b594fc4d68",
                "bc1a7cdc-3ccf-4fd4-a5b7-6f335bc48bcf",
                "4dfc07f0-97ee-4bc6-bce7-e4afe587be8d",
                "60a64b71-9a5f-4e67-80e5-10618503d46a",
                "2654f971-d04d-4e09-860a-99dd4e748e11",
                "b8dd312f-348f-4e8c-a7cc-1fc2187dc7aa",
                "6d066756-e8f3-4e47-9043-5dc1efeb439c",
                "83e460a2-a70c-47a9-8088-97ae23a67279",
                "af687b5e-aa1e-4a44-9692-404f13b260f1",
                "420c8f77-e5e2-4e86-86ae-bc5e2a1fb48f",
              ],
            },
            fields: [
              {
                id: "401a6a09-1f8d-432b-b3d9-f615e811bbb6",
                type: "checkbox",
                label: "Prospectus",
                value: false,
                width: 12,
              },
              {
                id: "24b99e18-dde7-4e97-b863-e2b594fc4d68",
                type: "checkbox",
                label: "KIDS - EU",
                value: false,
                width: 12,
              },
              {
                id: "bc1a7cdc-3ccf-4fd4-a5b7-6f335bc48bcf",
                type: "checkbox",
                label: "KIDS - UK",
                value: false,
                width: 12,
              },
              {
                id: "4dfc07f0-97ee-4bc6-bce7-e4afe587be8d",
                type: "checkbox",
                label: "KIDS - Swiss",
                value: false,
                width: 12,
              },
              {
                id: "60a64b71-9a5f-4e67-80e5-10618503d46a",
                type: "checkbox",
                label: "Swiss Simplified Prospectus / Basisinformationsblatt (BIS)",
                value: false,
                width: 12,
              },
              {
                id: "2654f971-d04d-4e09-860a-99dd4e748e11",
                type: "checkbox",
                label:
                  "Issuer provided promotional marketing documents (e.g. factsheets Sustainability factor of the financial instrument)",
                value: false,
                width: 12,
              },
              {
                id: "b8dd312f-348f-4e8c-a7cc-1fc2187dc7aa",
                type: "checkbox",
                label:
                  "Issuer Term Sheet, which is not shared with retail investors prior to making the investment decision without the Issuer’s approval",
                value: false,
                width: 12,
              },
              {
                id: "6d066756-e8f3-4e47-9043-5dc1efeb439c",
                type: "checkbox",
                label:
                  "Distributor’s own Term Sheet written for Retail audience (if required)",
                value: false,
                width: 12,
              },
              {
                id: "83e460a2-a70c-47a9-8088-97ae23a67279",
                type: "checkbox",
                label: "Distributor’s own promotional marketing materials",
                value: false,
                width: 12,
              },
              {
                id: "af687b5e-aa1e-4a44-9692-404f13b260f1",
                type: "checkbox",
                label:
                  "Sub-distributor / 3rd-Party intermediary’s own promotional marketing materials",
                value: false,
                width: 12,
              },
              {
                id: "420c8f77-e5e2-4e86-86ae-bc5e2a1fb48f",
                type: "checkbox",
                label: "Other - please explain below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["f3a6572c-655d-431e-9a9b-e9b4123486ab"],
                },
              },
              {
                id: "f3a6572c-655d-431e-9a9b-e9b4123486ab",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "801fafdb-e2a6-4bdd-b2bb-6c8cae00f2c1",
            externalID: "4.3.3",
            nav: "4.3.3",
            text: "If the Distributor, Sub-distributor / 3rd-Party Intermediary are producing promotional marketing materials, what controls are in place to govern the marketing/promotional material design and approval?",
            validate: {
              groupArr: [
                "7af6bd7d-19a1-4683-8bb4-d68efd116cfe",
                "d9a2b078-421f-4fa0-a86c-a16a39fe41f7",
                "a1adee3e-5bff-46fb-9408-427e03d54e23",
                "1d5a5a54-0dc6-44d0-b082-73f2c8afc317",
                "9566c413-49d4-486d-a048-a21dc068e255",
                "f2aeb748-fc02-48e0-bbfc-4e3247ffe064",
              ],
            },
            fields: [
              {
                id: "7af6bd7d-19a1-4683-8bb4-d68efd116cfe",
                type: "checkbox",
                label:
                  "N/A – Distributor does not produce own promotional marketing materials",
                value: false,
                width: 12,
              },
              {
                id: "d9a2b078-421f-4fa0-a86c-a16a39fe41f7",
                type: "checkbox",
                label:
                  "N/A – Sub-distributor / 3rd-Party Intermediary does not produce own promotional marketing materials",
                value: false,
                width: 12,
              },
              {
                id: "a1adee3e-5bff-46fb-9408-427e03d54e23",
                type: "checkbox",
                label: "Promotional marketing materials Policy / Procedure / Guidance",
                value: false,
                width: 12,
              },
              {
                id: "1d5a5a54-0dc6-44d0-b082-73f2c8afc317",
                type: "checkbox",
                label: "Materials reviewed by Legal / Compliance",
                value: false,
                width: 12,
              },
              {
                id: "9566c413-49d4-486d-a048-a21dc068e255",
                type: "checkbox",
                label: "Materials approved by Legal / Compliance",
                value: false,
                width: 12,
              },
              {
                id: "f2aeb748-fc02-48e0-bbfc-4e3247ffe064",
                type: "checkbox",
                label: "Other - please explain below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["5ec3b433-d921-44e1-b35b-8d0db0f192ea"],
                },
              },
              {
                id: "5ec3b433-d921-44e1-b35b-8d0db0f192ea",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "1252cbf6-3ff4-4062-9f4a-5945fa1bfa94",
            externalID: "4.3.4",
            nav: "4.3.4",
            text: "Please provide a representative sample of any Distributor, Sub-distributor / 3rd-Party Intermediary produced promotional marketing materials used that can be shared with Issuers. This should be emailed to inspirekyd@deltacapita.com",
            fields: [
              {
                id: "e603f5a0-8179-4d59-9917-addb52b55ca1",
                type: "radio-list",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "e5ad2dcf-9a2b-4421-953d-1bbc2607cea5",
                    label:
                      "N/A – Distributor, Sub-distributor / 3rd-Party Intermediary does not produce own marketing materials",
                    conditional: {
                      disable: ["381ca728-8963-447c-8a65-deb3105b4c46"],
                    },
                  },
                  {
                    id: "f4a35954-2305-4ee3-8dcf-8fd063316a34",
                    label: "Yes – Email sent to inspirekyd@deltacapita.com",
                    conditional: {
                      disable: ["381ca728-8963-447c-8a65-deb3105b4c46"],
                    },
                  },
                  {
                    id: "e499cc75-3616-4253-87f7-7a3ba13784ec",
                    label: "No – please explain why this cannot be shared below:",
                    conditional: {
                      enable: ["381ca728-8963-447c-8a65-deb3105b4c46"],
                    },
                  },
                ],
              },
              {
                id: "381ca728-8963-447c-8a65-deb3105b4c46",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "71323df4-8c67-4951-9c02-b1c373a818d6",
            externalID: "4.3.5",
            nav: "4.3.5",
            text: "Does the Distributor expect the Issuer to prepare and issue the marketing materials it uses for distribution?",
            fields: [
              {
                id: "710b2c87-d19c-457b-9075-a5c0ceaacbe2",
                type: "radio-list",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "b6e64138-9793-4569-addc-c75c033afabf",
                    label: "No",
                    conditional: {
                      disable: ["646d5ad7-1572-48fb-9d72-a84c7f3fa5cf"],
                    },
                  },
                  {
                    id: "f87ed623-4a1b-4fec-9f16-161f053a6f66",
                    label: "Yes – Please specify the jurisdictions below",
                    conditional: {
                      enable: ["646d5ad7-1572-48fb-9d72-a84c7f3fa5cf"],
                    },
                  },
                ],
              },
              {
                id: "646d5ad7-1572-48fb-9d72-a84c7f3fa5cf",
                type: "dropdown",
                disabled: true,
                source: "jurisdictions",
                search: true,
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "de474bbf-a649-4d94-9c01-4609e0654a0f",
            externalID: "4.3.6",
            nav: "4.3.6",
            text: "Does the Distributor, Sub-distributor / 3rd-Party Intermediary’s own marketing material, use any of the following Issuer information?",
            validate: {
              groupArr: [
                "9f336934-b092-40e0-977b-789181170fb8",
                "4b31af02-b8be-44e5-be42-1ff2b391e203",
                "e6e98337-f9d8-4803-a433-b05899953d87",
                "09a0d11c-47a4-4bed-87f6-5f2c91167d53",
                "58dfd005-8cfb-4ac0-8339-12876fea2806",
                "105bdbfd-cdfd-4241-b5ce-e7afd1de796a",
              ],
            },
            fields: [
              {
                id: "9f336934-b092-40e0-977b-789181170fb8",
                type: "checkbox",
                label:
                  "N/A – Distributor, Sub-distributor / 3rd-Party Intermediary does not produce own marketing materials",
                value: false,
                width: 12,
              },
              {
                id: "4b31af02-b8be-44e5-be42-1ff2b391e203",
                type: "checkbox",
                label: "Issuer Name",
                value: false,
                width: 12,
              },
              {
                id: "e6e98337-f9d8-4803-a433-b05899953d87",
                type: "checkbox",
                label: "Issuer Logo",
                value: false,
                width: 12,
              },
              {
                id: "09a0d11c-47a4-4bed-87f6-5f2c91167d53",
                type: "checkbox",
                label: "Issuer Credit Rating",
                value: false,
                width: 12,
              },
              {
                id: "58dfd005-8cfb-4ac0-8339-12876fea2806",
                type: "checkbox",
                label: "Issuer Role",
                value: false,
                width: 12,
              },
              {
                id: "105bdbfd-cdfd-4241-b5ce-e7afd1de796a",
                type: "checkbox",
                label: "Other - please explain below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["2a68d4f9-18c7-4987-bb2f-23cc3a03b861"],
                },
              },
              {
                id: "2a68d4f9-18c7-4987-bb2f-23cc3a03b861",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
        ],
      },
      {
        id: "4e505fa1-6966-468b-9288-304c18ddd8f2",
        name: "Post-Sale Governance",
        nav: "4",
        questions: [
          {
            id: "7a42145a-4717-4d8e-9244-1f1158b157bb",
            externalID: "4.4.1",
            nav: "4.4.1",
            text: "Does the Distributor have a supervisory processes and post-trade controls in place to ensure that Structured Products meet an actual demand from End Client?",
            validate: {
              groupArr: [
                "bb554efa-194e-4e15-bdac-94eb68cfca2a",
                "90c63996-9dd1-41af-bc3d-44b15cd78430",
                "94c464ee-0936-436f-9875-be9b1ae21465",
                "9f58336d-741d-4321-8758-57fc5c61d2b2",
                "88892779-34d1-42f2-8466-6aa852ed75ab",
                "2caf0ad4-c06b-4d4c-8ef2-a614e9c690eb",
              ],
            },
            fields: [
              {
                id: "13e4a10d-f3aa-4118-af2d-4e56620ca1f8",
                type: "radio-list",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "516c03e2-b552-4537-9223-3c6885b20f94",
                    label: "Yes",
                    conditional: {
                      enable: [
                        "bb554efa-194e-4e15-bdac-94eb68cfca2a",
                        "90c63996-9dd1-41af-bc3d-44b15cd78430",
                        "94c464ee-0936-436f-9875-be9b1ae21465",
                        "9f58336d-741d-4321-8758-57fc5c61d2b2",
                        "88892779-34d1-42f2-8466-6aa852ed75ab",
                        "2caf0ad4-c06b-4d4c-8ef2-a614e9c690eb",
                      ],
                      disable: ["ce1e611f-7354-4bbf-a900-84aa9a4231b6"],
                    },
                  },
                ],
              },
              {
                id: "bb554efa-194e-4e15-bdac-94eb68cfca2a",
                type: "checkbox",
                label:
                  "Sales supervisory policy and framework - Please describe policy and framework below",
                disabled: true,
                value: false,
                width: 12,
                indent: 1,
                conditional: {
                  enable: ["084d69df-6076-4435-a5cc-8e434b65764e"],
                },
              },
              {
                id: "084d69df-6076-4435-a5cc-8e434b65764e",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 2,
              },
              {
                id: "90c63996-9dd1-41af-bc3d-44b15cd78430",
                type: "checkbox",
                label:
                  "Advisers have supervisors who monitor investment advice and recommendations – Please describe the process below",
                disabled: true,
                value: false,
                width: 12,
                indent: 1,
                conditional: {
                  enable: ["7651c304-c94c-4719-a113-dc2071837220"],
                },
              },
              {
                id: "7651c304-c94c-4719-a113-dc2071837220",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 2,
              },
              {
                id: "94c464ee-0936-436f-9875-be9b1ae21465",
                type: "checkbox",
                label: "Target Market and suitability tests controlled in a system",
                disabled: true,
                value: false,
                width: 12,
                indent: 1,
              },
              {
                id: "9f58336d-741d-4321-8758-57fc5c61d2b2",
                type: "checkbox",
                label:
                  "Compliance and Risk sample checking - Please describe and detail the frequency below",
                disabled: true,
                value: false,
                width: 12,
                conditional: {
                  enable: ["e61e2ec2-406e-4d3b-8287-b4987d96d213"],
                },
                indent: 1,
              },
              {
                id: "e61e2ec2-406e-4d3b-8287-b4987d96d213",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 2,
              },
              {
                id: "88892779-34d1-42f2-8466-6aa852ed75ab",
                type: "checkbox",
                label: "Internal or External audit - please explain frequency below",
                disabled: true,
                value: false,
                width: 12,
                indent: 1,
                conditional: {
                  enable: ["4c51b3ee-c90a-49c3-a970-56ffd68264ba"],
                },
              },
              {
                id: "4c51b3ee-c90a-49c3-a970-56ffd68264ba",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 2,
              },
              {
                id: "2caf0ad4-c06b-4d4c-8ef2-a614e9c690eb",
                type: "checkbox",
                label: "Other - please specify details below:",
                disabled: true,
                value: false,
                width: 12,
                conditional: {
                  enable: ["f204b1c3-8f39-47a3-a747-e6348cf15f26"],
                },
                indent: 1,
              },
              {
                id: "f204b1c3-8f39-47a3-a747-e6348cf15f26",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 2,
              },
              {
                id: "13e4a10d-f3aa-4118-af2d-4e56620ca1f8",
                type: "radio-list",
                width: 12,
                options: [
                  {
                    id: "441000011",
                    label: "No – Please explain rationale below:",
                    conditional: {
                      disable: [
                        "bb554efa-194e-4e15-bdac-94eb68cfca2a",
                        "90c63996-9dd1-41af-bc3d-44b15cd78430",
                        "94c464ee-0936-436f-9875-be9b1ae21465",
                        "9f58336d-741d-4321-8758-57fc5c61d2b2",
                        "e61e2ec2-406e-4d3b-8287-b4987d96d213",
                        "88892779-34d1-42f2-8466-6aa852ed75ab",
                        "4c51b3ee-c90a-49c3-a970-56ffd68264ba",
                        "2caf0ad4-c06b-4d4c-8ef2-a614e9c690eb",
                        "f204b1c3-8f39-47a3-a747-e6348cf15f26",
                        "084d69df-6076-4435-a5cc-8e434b65764e",
                        "7651c304-c94c-4719-a113-dc2071837220",
                      ],
                      enable: ["ce1e611f-7354-4bbf-a900-84aa9a4231b6"],
                    },
                  },
                ],
              },
              {
                id: "ce1e611f-7354-4bbf-a900-84aa9a4231b6",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "52fde8dd-d6d9-4678-8ce1-b4cfeb0ab318",
            externalID: "4.4.2",
            nav: "4.4.2",
            text: "How does the Distributor monitor and become aware that products have not been sold to the Negative Target Market?",
            popover: {
              body: "Please describe the processes, controls, regularity, and teams involved. What is the process to inform Issuers of the outcome?",
            },
            validate: {
              groupArr: [
                "119381de-6628-4593-83dc-3db0b7331416",
                "725a3860-de4d-4e02-8d04-2a8888d8c92a",
                "66aeca28-785e-4dba-804d-c08465a73aae",
              ],
            },
            fields: [
              {
                id: "119381de-6628-4593-83dc-3db0b7331416",
                type: "checkbox",
                label:
                  "N/A - Distributor does not face the End Client; Sub-distributors / 3rd-Party intermediary is responsible",
                value: false,
                width: 12,
              },
              {
                id: "725a3860-de4d-4e02-8d04-2a8888d8c92a",
                type: "checkbox",
                label: "For direct sales",
                value: false,
                width: 12,
                conditional: {
                  enable: ["e24ddd08-753a-4d22-b309-0dccf2e73fa0"],
                },
              },
              {
                id: "e24ddd08-753a-4d22-b309-0dccf2e73fa0",
                type: "textArea",
                width: 12,
                validate: {
                  required: true,
                },
                disabled: true,
              },
              {
                id: "66aeca28-785e-4dba-804d-c08465a73aae",
                type: "checkbox",
                label:
                  "For sales via Sub-distributors/3rd-Party intermediaries (if applicable)",
                value: false,
                width: 12,
                conditional: {
                  enable: ["a71d018c-bca4-4047-b8bf-1bb3b524b18a"],
                },
              },
              {
                id: "a71d018c-bca4-4047-b8bf-1bb3b524b18a",
                type: "textArea",
                width: 12,
                validate: {
                  required: true,
                },
                disabled: true,
              },
            ],
          },
          {
            id: "5547952d-93e3-4a80-8981-b407b3bbd31d",
            externalID: "4.4.3",
            nav: "4.4.3",
            text: "How does the Distributor monitor to ensure that: post-trade, the product continues to meet the general needs of the Target Market and the specific needs of the End Clients?",
            popover: {
              body: "The Issuer wants to understand what data points might trigger a product review. For example, would an existing product be reviewed in the instance where there has been an impact on the risk or return expectations of the product i.e. a crucial event, as identified by the above data points?",
            },
            validate: {
              groupArr: [
                "c589e563-27bf-4d11-9338-d5cb23ac2a12",
                "c9c3ede7-e0a8-4f19-9049-6708c587bd4a",
                "966314f3-02c2-44db-b108-f5dcb3f51963",
                "c468d3ef-753d-4332-a9e5-b3441796c570",
                "093fb64c-f8e6-4d95-b4e8-b1c10bb3560c",
                "e76a657c-e15f-45bf-8063-d95623f32687",
                "1de5468b-5520-4b01-ab9f-38e4552a11a8",
                "1515abef-7da5-4699-8449-e1ee7600eee3",
                "9430aab5-9ad6-44e6-ac0f-9a0043f8fbe4",
                "b11bb0b6-f8f2-4c4c-910e-21f771bb09bc",
                "2d02a3c6-3355-4e5b-9e54-42266018df95",
                "403d925c-11d5-4cf6-8d6b-2e1c5519c2e5",
                "e8ce88d6-ec14-449e-add1-3124d475ef7c",
              ],
            },
            fields: [
              {
                id: "c589e563-27bf-4d11-9338-d5cb23ac2a12",
                type: "checkbox",
                label: "Customer surveys",
                value: false,
                width: 12,
              },
              {
                id: "c9c3ede7-e0a8-4f19-9049-6708c587bd4a",
                type: "checkbox",
                label: "Monitoring secondary market activity",
                value: false,
                width: 12,
              },
              {
                id: "966314f3-02c2-44db-b108-f5dcb3f51963",
                type: "checkbox",
                label: "Cancellation Rates",
                value: false,
                width: 12,
              },
              {
                id: "c468d3ef-753d-4332-a9e5-b3441796c570",
                type: "checkbox",
                label: "Churn Rates",
                value: false,
                width: 12,
              },
              {
                id: "093fb64c-f8e6-4d95-b4e8-b1c10bb3560c",
                type: "checkbox",
                label:
                  "Unwind Rates - advised selling back of structured products just prior to their natural maturity",
                value: false,
                width: 12,
              },
              {
                id: "e76a657c-e15f-45bf-8063-d95623f32687",
                type: "checkbox",
                label: "Customer Complaints",
                value: false,
                width: 12,
              },
              {
                id: "1de5468b-5520-4b01-ab9f-38e4552a11a8",
                type: "checkbox",
                label: "Product Performance Reporting & Monitoring",
                value: false,
                width: 12,
              },
              {
                id: "1515abef-7da5-4699-8449-e1ee7600eee3",
                type: "checkbox",
                label: "Valuation Monitoring and Verification",
                value: false,
                width: 12,
              },
              {
                id: "9430aab5-9ad6-44e6-ac0f-9a0043f8fbe4",
                type: "checkbox",
                label: "Credit Event Monitoring (CLNs)",
                value: false,
                width: 12,
              },
              {
                id: "e8ce88d6-ec14-449e-add1-3124d475ef7c",
                type: "checkbox",
                label:
                  "Events that could materially affect the potential risk to the Target Market including ESG factors",
                value: false,
                width: 12,
              },
              {
                id: "b11bb0b6-f8f2-4c4c-910e-21f771bb09bc",
                type: "checkbox",
                label: "Reviewing the above information in regular meetings",
                value: false,
                width: 12,
              },
              {
                id: "2d02a3c6-3355-4e5b-9e54-42266018df95",
                type: "checkbox",
                label:
                  "N/A - Distributor does not face the End Client; Sub-distributors / 3rd-Party intermediary is responsible",
                value: false,
                width: 12,
              },
              {
                id: "403d925c-11d5-4cf6-8d6b-2e1c5519c2e5",
                type: "checkbox",
                label: "Other - please explain below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["3adf9f8b-bf8c-4e43-9531-757262b16113"],
                },
              },
              {
                id: "3adf9f8b-bf8c-4e43-9531-757262b16113",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "9417e4bb-16c9-4b17-a83f-b8eb8a93a215",
            externalID: "4.4.4",
            nav: "4.4.4",
            text: "Have there been any Credit Events in relation to CLNs?",
            fields: [
              {
                id: "d423faf7-8bf8-429b-b545-9d568115a711",
                type: "radio-list",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "d89175c4-78ed-48fe-bbd2-304c6768657b",
                    label: "N/A",
                    conditional: {
                      disable: ["1f21f0d3-aef8-4073-9984-f6473c389ff2"],
                    },
                  },
                  {
                    id: "3ab14d3c-08ae-4cbb-a4cf-f6b69d734173",
                    label: "No",
                    conditional: {
                      disable: ["1f21f0d3-aef8-4073-9984-f6473c389ff2"],
                    },
                  },
                  {
                    id: "f1437cdc-c553-4dae-a4bb-29632b7db11e",
                    label: "Yes – Please summarise the impact on the End Client",
                    conditional: {
                      enable: ["1f21f0d3-aef8-4073-9984-f6473c389ff2"],
                    },
                  },
                ],
              },
              {
                id: "1f21f0d3-aef8-4073-9984-f6473c389ff2",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
        ],
      },
      {
        id: "8bc1aa35-48f8-4ee9-aa3e-0f7a10190871",
        name: "Feedback & Complaints",
        nav: "5",
        questions: [
          {
            id: "63462272-95d8-4a36-a48b-a484706f2dea",
            externalID: "4.5.1",
            nav: "4.5.1",
            text: "Will the Distributor respond to Issuer feedback requests to ensure Target Market remains appropriate and communicate any relevant product, investor feedback or complaints (if any)?",
            validate: {
              groupArr: [
                "fb5c6ff8-a1b2-4e45-b4ee-c2def6e1ba21",
                "fc904072-001d-4dee-92ea-593ebcd86c16",
              ],
            },
            fields: [
              {
                id: "b42cc2f7-aca6-476f-b7ac-3daa734433b4",
                type: "radio-list",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "0f3fb2dd-37ad-4938-b71d-0ab2cf08a049",
                    label: "Yes",
                    conditional: {
                      enable: [
                        "fb5c6ff8-a1b2-4e45-b4ee-c2def6e1ba21",
                        "fc904072-001d-4dee-92ea-593ebcd86c16",
                      ],
                      disable: ["27f22614-a974-4782-83b9-ae48b10a400a"],
                    },
                  },
                ],
              },
              {
                id: "fb5c6ff8-a1b2-4e45-b4ee-c2def6e1ba21",
                type: "checkbox",
                label: "Via regular meetings with Issuers",
                disabled: true,
                value: false,
                width: 12,
                indent: 1,
              },
              {
                id: "fc904072-001d-4dee-92ea-593ebcd86c16",
                type: "checkbox",
                label: "Other - Please explain approach taken below:",
                disabled: true,
                value: false,
                width: 12,
                conditional: {
                  enable: ["4b09f8db-49c0-4194-aaec-02e3b995b6f0"],
                },
                indent: 1,
              },
              {
                id: "4b09f8db-49c0-4194-aaec-02e3b995b6f0",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
              {
                id: "b42cc2f7-aca6-476f-b7ac-3daa734433b4",
                type: "radio-list",
                width: 12,
                options: [
                  {
                    id: "c40e41f5-0a5f-4c59-aca9-ab264017aed0",
                    label: "No - Please provide the rationale below:",
                    conditional: {
                      disable: [
                        "fb5c6ff8-a1b2-4e45-b4ee-c2def6e1ba21",
                        "fc904072-001d-4dee-92ea-593ebcd86c16",
                        "4b09f8db-49c0-4194-aaec-02e3b995b6f0",
                      ],
                      enable: ["27f22614-a974-4782-83b9-ae48b10a400a"],
                    },
                  },
                ],
              },
              {
                id: "27f22614-a974-4782-83b9-ae48b10a400a",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "6539f487-614a-4c2e-918b-c434424fd453",
            externalID: "4.5.2",
            nav: "4.5.2",
            text: "Describe the procedure for handling complaints made by the End Clients, including the involvement of Legal and/or Compliance",
            popover: {
              body: "Please explain the Distributors complaint handling process in detail confirming procedures for monitoring, acknowledging, responding and reporting customer complaints, including the involvement of Legal and/or Compliance? Provide web page link to procedure if available",
            },
            fields: [
              {
                id: "c664f2e4-6610-4cbc-8717-3b1497c61f90",
                type: "textArea",
                width: 12,
                validate: {
                  required: true,
                },
              },
            ],
          },
          {
            id: "86f34ba1-db1e-45e4-b1db-dba2b892da47",
            externalID: "4.5.3",
            nav: "4.5.3",
            text: "Has the Distributor received any Complaints in the last 3 years, related to structured products distribution in either: General Administration / Customer Service; Information, Sums / Charges or Product Performance; Advising, Selling or Arranging; Other?",
            fields: [
              {
                id: "b0cfe67d-d132-4e81-9e64-ca1bde55343c",
                type: "radio-list",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "d197dc25-6d91-4b31-a99b-205894cd2e3f",
                    label: "No",
                    conditional: {
                      disable: ["cc2bbb9e-8225-4313-b5db-f9b8d2148a25"],
                    },
                  },
                  {
                    id: "907507f6-1b36-4c07-8ac4-94de47472be9",
                    label:
                      "Yes - please provide and explain the remediation status here:",
                    conditional: {
                      enable: ["cc2bbb9e-8225-4313-b5db-f9b8d2148a25"],
                    },
                  },
                ],
              },
              {
                id: "cc2bbb9e-8225-4313-b5db-f9b8d2148a25",
                type: "textArea",
                disabled: true,
                width: 12,
                validate: {
                  required: true,
                },
              },
            ],
          },
        ],
      },
      {
        id: "a33bc7cb-171b-42de-a358-38c076476994",
        name: "Policies & Training",
        nav: "6",
        questions: [
          {
            id: "837029f6-ae1d-4197-afa8-6ce4098823c4",
            externalID: "4.6.1",
            nav: "4.6.1",
            text: "Which of the following policies, procedures or guidelines does the Distributor have in place?",
            validate: {
              groupArr: [
                "b29173ca-c06c-47c9-9a0b-53e5bb42d0cf",
                "0a57c21f-cee0-4ac7-98f9-a2fa8b0dfcb3",
                "f20c13b5-d308-447b-bcea-897d27713faf",
                "7fd2e4fe-ec9d-4e2e-92a5-2242997caec7",
                "707ce4a7-b235-462f-9cfd-605960a15870",
                "5abc94d9-80c3-4048-8e0d-b92ac9da21b2",
                "b55d972b-d00b-48ba-8553-63aceb271dad",
                "8d1e8519-c3e7-4b3d-8f6f-de9c36535ff2",
                "896a15d2-a8fe-48d0-89ac-1be78099a804",
                "099297fd-d332-4f79-a3e8-87985b31e3da",
                "34322f19-6756-4e80-abe5-e64f6c6f4c68",
                "a4faa108-7a63-42c2-9b98-b98091260edd",
                "62176b31-bce0-4b1e-a90d-9ca96b54cfcb",
                "95c21058-5562-4859-8bd6-2406086a30c1",
                "e86b88e9-c33f-4128-ae60-0dd00498b3c8",
                "5a2501aa-f56f-41e3-b0a5-4623a17c2e6d",
                "b17fdf3f-95af-41ed-ae62-f7cdfb532025",
                "8584a4be-fac3-4c43-b2d9-32c31c9a7cb0",
                "0cc3df58-d3e3-4f91-8646-b82b9bdf4d01",
                "577c6462-3c6b-4bf9-97f5-9e70d424ffe3",
              ],
            },
            fields: [
              {
                id: "b29173ca-c06c-47c9-9a0b-53e5bb42d0cf",
                type: "checkbox",
                label: "Managing Conflicts of Interest",
                value: false,
                width: 12,
              },
              {
                id: "0a57c21f-cee0-4ac7-98f9-a2fa8b0dfcb3",
                type: "checkbox",
                label: "Anti-Bribery / Anti-Corruption",
                value: false,
                width: 12,
              },
              {
                id: "f20c13b5-d308-447b-bcea-897d27713faf",
                type: "checkbox",
                label:
                  "AML and Financial Crime (to detect, prevent and report suspicious activity)",
                value: false,
                width: 12,
              },
              {
                id: "7fd2e4fe-ec9d-4e2e-92a5-2242997caec7",
                type: "checkbox",
                label:
                  "KYC and Client Onboarding (including UBOs and PEPs identification)  ",
                value: false,
                width: 12,
              },
              {
                id: "707ce4a7-b235-462f-9cfd-605960a15870",
                type: "checkbox",
                label: "Complaints Handling",
                value: false,
                width: 12,
              },
              {
                id: "5abc94d9-80c3-4048-8e0d-b92ac9da21b2",
                type: "checkbox",
                label: "Client Money Protection / Client Assets",
                value: false,
                width: 12,
              },
              {
                id: "b55d972b-d00b-48ba-8553-63aceb271dad",
                type: "checkbox",
                label: "Best Execution",
                value: false,
                width: 12,
              },
              {
                id: "8d1e8519-c3e7-4b3d-8f6f-de9c36535ff2",
                type: "checkbox",
                label:
                  "Global Sanctions Monitoring (from authorities including OFAC, HMT, EU, UNSC)",
                value: false,
                width: 12,
              },
              {
                id: "896a15d2-a8fe-48d0-89ac-1be78099a804",
                type: "checkbox",
                label: "Conduct risk / code of ethics",
                value: false,
                width: 12,
              },
              {
                id: "099297fd-d332-4f79-a3e8-87985b31e3da",
                type: "checkbox",
                label: "Suitability / appropriateness",
                value: false,
                width: 12,
              },
              {
                id: "34322f19-6756-4e80-abe5-e64f6c6f4c68",
                type: "checkbox",
                label:
                  "Supervision (covering the sales and marketing of Structured Products)",
                value: false,
                width: 12,
              },
              {
                id: "a4faa108-7a63-42c2-9b98-b98091260edd",
                type: "checkbox",
                label: "Independent Audit (internal / external) & Oversight ",
                value: false,
                width: 12,
              },
              {
                id: "62176b31-bce0-4b1e-a90d-9ca96b54cfcb",
                type: "checkbox",
                label: "Business continuity plan and disaster recovery plan",
                value: false,
                width: 12,
              },
              {
                id: "95c21058-5562-4859-8bd6-2406086a30c1",
                type: "checkbox",
                label: "Prevention/ Anti-facilitation of Tax Evasion",
                value: false,
                width: 12,
              },
              {
                id: "b17fdf3f-95af-41ed-ae62-f7cdfb532025",
                type: "checkbox",
                label: "Environmental, Social, Governance (ESG)",
                value: false,
                width: 12,
              },
              {
                id: "0cc3df58-d3e3-4f91-8646-b82b9bdf4d01",
                type: "checkbox",
                label: "Retail Structured Products ESG Governance",
                value: false,
                width: 12,
              },
              {
                id: "e86b88e9-c33f-4128-ae60-0dd00498b3c8",
                type: "checkbox",
                label:
                  "Vulnerable customers & Accessibility (related to health, life events, resilience, and capability)",
                value: false,
                width: 12,
              },
              {
                id: "8584a4be-fac3-4c43-b2d9-32c31c9a7cb0",
                type: "checkbox",
                label: "Product Governance",
                value: false,
                width: 12,
              },
              {
                id: "577c6462-3c6b-4bf9-97f5-9e70d424ffe3",
                type: "checkbox",
                label:
                  "Consumer Duty (including compliance with consumer outcomes; products and services, price and value, consumer understanding, consumer support)",
                value: false,
                width: 12,
              },
              {
                id: "5a2501aa-f56f-41e3-b0a5-4623a17c2e6d",
                type: "checkbox",
                label:
                  "No - what steps are taken to ensure compliance with regulatory requirements? ",
                value: false,
                width: 12,
                conditional: {
                  enable: ["39744de3-17a7-4035-bfcc-fca2d8e75e26"],
                },
              },
              {
                id: "39744de3-17a7-4035-bfcc-fca2d8e75e26",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "04b0963a-c229-4ef8-903c-2bcdc0aedd25",
            externalID: "4.6.2",
            nav: "4.6.2",
            text: "Are background checks and screening completed by the Distributor on new hires?",
            fields: [
              {
                id: "7a76738d-1a0c-453a-b676-235a05257bc9",
                type: "radio-list",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "9381a699-52b2-4b05-942a-8f48ded52f7c",
                    label: "Yes",
                    conditional: {
                      disable: ["dfa2b69a-1393-41f8-862c-347fa1167020"],
                    },
                  },
                  {
                    id: "406d1f03-cf93-4085-964c-8fe555c154b0",
                    label: "No - Please explain the rationale below:",
                    conditional: {
                      enable: ["dfa2b69a-1393-41f8-862c-347fa1167020"],
                    },
                  },
                ],
              },
              {
                id: "dfa2b69a-1393-41f8-862c-347fa1167020",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "9b457332-fac7-4f3e-ad78-b84df7f2ece4",
            externalID: "4.6.3",
            nav: "4.6.3",
            text: "What licenses, exams or accreditations does the sales team at the Distributor have to attain before they can sell Structured Products?",
            fields: [
              {
                id: "411855dc-e11d-4718-83b8-9c5e8f7bc820",
                type: "textArea",
                width: 12,
                validate: {
                  required: true,
                },
              },
            ],
          },
          {
            id: "c28be33c-b392-4037-a370-90813b313001",
            externalID: "4.6.4",
            nav: "4.6.4",
            text: "What training or education, if any, does the sales team at the Distributor receive prior to being approved to sell Structured Products?",
            validate: {
              groupArr: [
                "45a2451b-c284-4385-91bd-54dcc137395b",
                "b063489c-19a5-4c86-86f0-99b1b59e3863",
                "f76764bd-633f-453e-88c2-135aeb2aca20",
                "6e7358ca-281f-4250-b345-617b680339b5",
                "01d65f19-0670-4049-8029-584b0450e43c",
                "ba2c9e24-e42b-4dee-8451-3c8ef9d2bcf7",
                "9d516504-43e0-4e74-adf6-d9d9762a401d",
                "9d516504-43e0-4e74-adf6-d9d9762a401d",
                "f6d23b52-1b57-4558-b06c-dbd997d38188",
                "d4bb6f2d-bb32-4670-aa04-dc68f7209e63",
                "b8046bb3-43e0-4f6b-9095-3da1e52433c4",
                "c9385b40-4d11-4582-bc8e-d8acddece579",
                "ea7c9d9c-b431-43f7-bee1-1ffd8883fa2c",
                "3584675b-9117-44fb-82d6-fa9bce639562",
                "45f86375-8787-400a-9bc4-bea20cd5155b",
                "f28463a8-b49f-4210-9b5e-d1af2b7327c6",
                "f0b39ae5-3e82-4dba-a2d0-52bb2467405f",
                "fe6d9a13-1d1c-4af9-921f-007195c95be1",
                "4c46fddb-aaf7-47a8-914b-d72a102e7eef",
                "c3959aea-fbee-49f4-88af-61ba66e3a8fd",
                "445739de-0840-4bdf-b59a-0a86ef3f5887",
              ],
            },
            fields: [
              {
                id: "45a2451b-c284-4385-91bd-54dcc137395b",
                type: "checkbox",
                label: "No Training is provided - please explain rationale below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["2088a836-609f-4c15-b517-9a3426068065"],
                },
              },
              {
                id: "2088a836-609f-4c15-b517-9a3426068065",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
              {
                id: "951f9a9a-942c-4d08-9a9b-18fd8fd827a5",
                type: "subHead",
                text: "Prior to being approved to sell structured products:",
                width: 12,
              },
              {
                id: "b063489c-19a5-4c86-86f0-99b1b59e3863",
                type: "checkbox",
                label: "Individual Training",
                value: false,
                width: 12,
              },
              {
                id: "f76764bd-633f-453e-88c2-135aeb2aca20",
                type: "checkbox",
                label: "Provision of selling guidelines",
                value: false,
                width: 12,
              },
              {
                id: "6e7358ca-281f-4250-b345-617b680339b5",
                type: "checkbox",
                label: "Factsheet",
                value: false,
                width: 12,
              },
              {
                id: "01d65f19-0670-4049-8029-584b0450e43c",
                type: "checkbox",
                label: "Online educational materials",
                value: false,
                width: 12,
              },
              {
                id: "ba2c9e24-e42b-4dee-8451-3c8ef9d2bcf7",
                type: "checkbox",
                label: "Supervisor specific training",
                value: false,
                width: 12,
              },
              {
                id: "9d516504-43e0-4e74-adf6-d9d9762a401d",
                type: "checkbox",
                label: "Structured Products in general",
                value: false,
                width: 12,
              },
              {
                id: "e6772c9d-8738-5177-90c1-39680c7e1dd6",
                type: "checkbox",
                label: "Structured Products that have been onboarded for investment",
                value: false,
                width: 12,
              },
              {
                id: "f6d23b52-1b57-4558-b06c-dbd997d38188",
                type: "checkbox",
                label:
                  "CLN Structured Product Training (Including credit default swap mechanics including the different types of credit event, ISDA Determination Committee, auction processes, recovery rates and settlement types, as well as the potential resulting impact to CLN payoffs)",
                value: false,
                width: 12,
              },
              {
                id: "d4bb6f2d-bb32-4670-aa04-dc68f7209e63",
                type: "checkbox",
                label: "Consumer Duty Training",
                value: false,
                width: 12,
              },
              {
                id: "b8046bb3-43e0-4f6b-9095-3da1e52433c4",
                type: "checkbox",
                label: "Other - please provide details below",
                value: false,
                width: 12,
                conditional: {
                  enable: ["3c06d99a-c67b-4c46-b13d-836d4445ee29"],
                },
              },
              {
                id: "3c06d99a-c67b-4c46-b13d-836d4445ee29",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
              {
                id: "49f51137-4e0a-4d97-b574-e2e06cd86cbb",
                type: "subHead",
                text: "On an ongoing basis:",
                width: 12,
              },
              {
                id: "c9385b40-4d11-4582-bc8e-d8acddece579",
                type: "checkbox",
                label: "Individual Training",
                value: false,
                width: 12,
              },
              {
                id: "ea7c9d9c-b431-43f7-bee1-1ffd8883fa2c",
                type: "checkbox",
                label: "Provision of selling guidelines",
                value: false,
                width: 12,
              },
              {
                id: "3584675b-9117-44fb-82d6-fa9bce639562",
                type: "checkbox",
                label: "Factsheet",
                value: false,
                width: 12,
              },
              {
                id: "45f86375-8787-400a-9bc4-bea20cd5155b",
                type: "checkbox",
                label: "Online educational materials",
                value: false,
                width: 12,
              },
              {
                id: "f28463a8-b49f-4210-9b5e-d1af2b7327c6",
                type: "checkbox",
                label: "Supervisor specific training",
                value: false,
                width: 12,
              },
              {
                id: "f0b39ae5-3e82-4dba-a2d0-52bb2467405f",
                type: "checkbox",
                label: "Structured Products in general",
                value: false,
                width: 12,
              },
              {
                id: "fe6d9a13-1d1c-4af9-921f-007195c95be1",
                type: "checkbox",
                label: "Structured Products that have been onboarded for investment",
                value: false,
                width: 12,
              },
              {
                id: "4c46fddb-aaf7-47a8-914b-d72a102e7eef",
                type: "checkbox",
                label:
                  "CLN Structured Product Training (Including credit default swap mechanics including the different types of credit event, ISDA Determination Committee, auction processes, recovery rates and settlement types, as well as the potential resulting impact to CLN payoffs)",
                value: false,
                width: 12,
              },
              {
                id: "c3959aea-fbee-49f4-88af-61ba66e3a8fd",
                type: "checkbox",
                label: "Consumer Duty Training",
                value: false,
                width: 12,
              },
              {
                id: "445739de-0840-4bdf-b59a-0a86ef3f5887",
                type: "checkbox",
                label: "Other - please provide details below",
                value: false,
                width: 12,
                conditional: {
                  enable: ["57c16a89-ef4b-4a65-9041-c2bc0cdbdccb"],
                },
              },
              {
                id: "57c16a89-ef4b-4a65-9041-c2bc0cdbdccb",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "1a6cb939-3419-405b-981d-fdcf52ee38d4",
            externalID: "4.6.5",
            nav: "4.6.5",
            text: "How does the Distributor ensure the individual responsible for the End Client is in ‘good standing’ and has completed the required training? (Select all that apply)",
            validate: {
              groupArr: [
                "368e190b-71a5-4307-959a-c147c000850a",
                "b4fd3e3c-04ce-40a7-8990-2edc3c34978b",
                "ab3ec9a5-2277-4b6d-9e67-8f590270f56c",
                "a5583c16-7497-4142-8a65-1a421bc9b969",
                "5e96817a-064a-4817-9d9e-bc6da94012d2",
                "c880e59d-3954-4f0b-8da9-51619e8f1927",
              ],
            },
            fields: [
              {
                id: "368e190b-71a5-4307-959a-c147c000850a",
                type: "checkbox",
                label: "Compliance Monitoring",
                value: false,
                width: 12,
              },
              {
                id: "b4fd3e3c-04ce-40a7-8990-2edc3c34978b",
                type: "checkbox",
                label: "HR Monitoring",
                value: false,
                width: 12,
              },
              {
                id: "ab3ec9a5-2277-4b6d-9e67-8f590270f56c",
                type: "checkbox",
                label: "Audit",
                value: false,
                width: 12,
              },
              {
                id: "a5583c16-7497-4142-8a65-1a421bc9b969",
                type: "checkbox",
                label:
                  "Training provided to Sub-distributor / 3rd-Party intermediary where necessary",
                value: false,
                width: 12,
              },
              {
                id: "5e96817a-064a-4817-9d9e-bc6da94012d2",
                type: "checkbox",
                label: "Delegation of responsibility via Sub-distributor contract",
                value: false,
                width: 12,
              },
              {
                id: "c880e59d-3954-4f0b-8da9-51619e8f1927",
                type: "checkbox",
                label: "Other - Please provide details:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["001ca754-1f77-4f37-aa24-0ad02e3ce66c"],
                },
              },
              {
                id: "001ca754-1f77-4f37-aa24-0ad02e3ce66c",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "860ea728-d19b-43b3-883b-73f39f497710",
    name: "Sub-distributors / 3rd Party intermediaries – Selection and Oversight",
    nav: "5",
    subsections: [
      {
        id: "a38cb890-95db-43b3-aa49-cfb14b2c8d0b",
        name: "General Information",
        nav: "1",
        questions: [
          {
            id: "27a7ca21-0147-49a3-b29c-2c53e596eb1a",
            externalID: "5.1.1",
            nav: "5.1.1",
            text: "If the Distributor only sells through affiliated distributors, are the affiliated distributors covered by the same group authorized governance framework?",
            popover: {
              body: "Affiliate distributor is a part of the same group that is directly or indirectly controlled by the Distributor named in Q1.1.1, this could be a parent or subsidiary company.",
            },
            fields: [
              {
                id: "5a3b54de-9430-4287-8229-c71face9333b",
                type: "radio-list",
                width: 12,
                options: [
                  {
                    id: "a59286de-0422-46e1-be30-bb96d4eaf45d",
                    label: "N/A",
                    conditional: {
                      disable: ["d9e96bf4-8cf2-4dc8-b26e-74241ecd3301"],
                    },
                  },
                  {
                    id: "cf189bc6-99b4-4509-b1c3-6a38e51fc4a3",
                    label: "Yes",
                    conditional: {
                      disable: ["d9e96bf4-8cf2-4dc8-b26e-74241ecd3301"],
                    },
                  },
                  {
                    id: "511000003",
                    label:
                      "No – Please explain below any differences between the governance frameworks:",
                    conditional: {
                      enable: ["d9e96bf4-8cf2-4dc8-b26e-74241ecd3301"],
                    },
                  },
                ],
                validate: {
                  required: true,
                },
              },
              {
                id: "d9e96bf4-8cf2-4dc8-b26e-74241ecd3301",
                type: "textArea",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "a500a4e3-7cf2-404f-b790-8a1548a52b8f",
            externalID: "5.1.2",
            nav: "5.1.2",
            text: "How does the Distributor assess, select, and onboard Sub-distributors / 3rd-Party intermediaries (Select all Applicable)?",
            validate: {
              groupArr: [
                "dac7ae82-32e0-42cd-b51b-a1e60bc880d3",
                "270e01ac-b896-4eeb-be9c-3a1fa5e1c522",
                "e0d87898-d9ff-4d03-a48f-0cbf42ce57c6",
                "d0f5b6d0-7aaa-4b03-9c88-1323c6c43f4a",
              ],
            },
            fields: [
              {
                id: "dac7ae82-32e0-42cd-b51b-a1e60bc880d3",
                type: "checkbox",
                label:
                  "KYC on Sub-distributor / 3rd-Party intermediary – please describe criteria considered e.g. regulatory status, ownership structure, news screening:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["688216a4-9f4d-4cc7-ab39-bdf942bebb38"],
                },
              },
              {
                id: "688216a4-9f4d-4cc7-ab39-bdf942bebb38",
                type: "textArea",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
              {
                id: "270e01ac-b896-4eeb-be9c-3a1fa5e1c522",
                type: "checkbox",
                label:
                  "Due Diligence on Sub-distributor / 3rd-Party intermediary - please describe criteria considered e.g. assessment of client base, distribution channels, sales force structured products experience etc:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["754a3bda-822f-432b-967a-70a5d6b2dbb0"],
                },
              },
              {
                id: "754a3bda-822f-432b-967a-70a5d6b2dbb0",
                type: "textArea",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
              {
                id: "e0d87898-d9ff-4d03-a48f-0cbf42ce57c6",
                type: "checkbox",
                label:
                  "Approval to onboard Sub-distributor / 3rd-Party intermediary – please describe who is involved in the decision Business? Legal? Compliance?",
                value: false,
                width: 12,
                conditional: {
                  enable: ["fd20560b-ad1e-4651-9f1f-40e7d4be2ba1"],
                },
              },
              {
                id: "fd20560b-ad1e-4651-9f1f-40e7d4be2ba1",
                type: "textArea",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
              {
                id: "d0f5b6d0-7aaa-4b03-9c88-1323c6c43f4a",
                type: "checkbox",
                label: "Other – Please provide details:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["2aa81305-e97b-4818-b14c-758f932d4fc1"],
                },
              },
              {
                id: "2aa81305-e97b-4818-b14c-758f932d4fc1",
                type: "textArea",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "bb350f31-bd56-4f55-a81f-8a5e8ca6905b",
            externalID: "5.1.3",
            nav: "5.1.3",
            text: "Does the Distributor document the relationship between the Distributor and any Sub-distributors / 3rd-Party intermediaries?",
            fields: [
              {
                id: "aec78441-2b36-487a-8505-658a5729d119",
                type: "radio-list",
                width: 12,
                options: [
                  {
                    id: "1e245092-a96a-4243-aaa0-41d650aabc06",
                    label:
                      "Yes - please describe below what form this document takes (distribution agreement, terms of business etc) and confirm if it mirrors the distributor’s obligations to the Issuer.",
                    conditional: {
                      enable: ["643764a6-d2c0-4fc9-b5f9-7a7b54017682"],
                      disable: ["a54c1ebd-fd72-4eb2-835c-c0044599a17d"],
                    },
                  },
                ],
                validate: {
                  required: true,
                },
              },
              {
                id: "643764a6-d2c0-4fc9-b5f9-7a7b54017682",
                type: "textArea",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
              {
                id: "aec78441-2b36-487a-8505-658a5729d119",
                type: "radio-list",
                width: 12,
                options: [
                  {
                    id: "dcb222d3-3fff-4df5-a90b-82baef1b5423",
                    label: "No – please provide rationale below:",
                    conditional: {
                      enable: ["a54c1ebd-fd72-4eb2-835c-c0044599a17d"],
                      disable: ["643764a6-d2c0-4fc9-b5f9-7a7b54017682"],
                    },
                  },
                ],
              },
              {
                id: "a54c1ebd-fd72-4eb2-835c-c0044599a17d",
                type: "textArea",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "074092bc-1df8-401d-8155-f33ab0dde204",
            externalID: "5.1.4",
            nav: "5.1.4",
            text: "Does the Distributor restrict which products a Sub-distributor / 3rd-Party Intermediary can sell?",
            fields: [
              {
                id: "ee468eeb-a3a5-449a-b318-92b118e41a9a",
                type: "radio-list",
                width: 12,
                options: [
                  {
                    id: "0a408b7a-5b34-44db-aaba-4e7bd0ebb805",
                    label:
                      "Yes – please describe below what restrictions are applied and how they’re controlled:",
                    conditional: {
                      enable: ["9c07ef0e-5b2c-4cb4-8600-8efb27547e11"],
                      disable: ["b97cee33-6c3c-42dc-8506-a1ca791e5e95"],
                    },
                  },
                ],
                validate: {
                  required: true,
                },
              },
              {
                id: "9c07ef0e-5b2c-4cb4-8600-8efb27547e11",
                type: "textArea",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
              {
                id: "ee468eeb-a3a5-449a-b318-92b118e41a9a",
                type: "radio-list",
                width: 12,
                options: [
                  {
                    id: "514000003",
                    label: "No - Please provide rationale below:",
                    conditional: {
                      enable: ["b97cee33-6c3c-42dc-8506-a1ca791e5e95"],
                      disable: ["9c07ef0e-5b2c-4cb4-8600-8efb27547e11"],
                    },
                  },
                ],
              },
              {
                id: "b97cee33-6c3c-42dc-8506-a1ca791e5e95",
                type: "textArea",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "42eea99c-f218-41a6-90b1-14a2d65d6b81",
            externalID: "5.1.5",
            nav: "5.1.5",
            text: "After initial approval and onboarding, how does the Distributor monitor the ongoing performance of Sub-distributors / 3rd-Party intermediaries to ensure it continues to be satisfactory and meets the minimum standards?",
            validate: {
              groupArr: [
                "d6bf7df5-156c-4e05-b95c-5d71b54cf737",
                "4970a3e7-a587-4412-a43e-5c970bc94522",
                "25dd240e-fd95-41ba-98c1-3cab639086bf",
                "b43cd79f-cc2e-4c22-9dd6-70d7627362da",
              ],
            },
            fields: [
              {
                id: "d6bf7df5-156c-4e05-b95c-5d71b54cf737",
                type: "checkbox",
                label:
                  "KYC Refresh on Sub-distributor / 3rd-Party intermediary – please describe criteria considered e.g. regulatory status, ownership structure, news screening:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["afc0bf68-49f0-494c-a7b6-a10ecb46d154"],
                },
              },
              {
                id: "afc0bf68-49f0-494c-a7b6-a10ecb46d154",
                type: "textArea",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
              {
                id: "4970a3e7-a587-4412-a43e-5c970bc94522",
                type: "checkbox",
                label:
                  "Due Diligence Refresh on Sub-distributor / 3rd-Party intermediary - please describe criteria considered e.g. assessment of client base, distribution channels, sales force structured products experience etc:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["102007cd-bfe1-40ff-9d63-6e3550b6e4d5"],
                },
              },
              {
                id: "102007cd-bfe1-40ff-9d63-6e3550b6e4d5",
                type: "textArea",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
              {
                id: "25dd240e-fd95-41ba-98c1-3cab639086bf",
                type: "checkbox",
                label:
                  "Re-Approval of Sub-distributor / 3rd-Party intermediary – please describe who is involved in the decision Business? Legal? Compliance?",
                value: false,
                width: 12,
                conditional: {
                  enable: ["f36f4968-adf0-4299-a95d-9bcbc5d31a96"],
                },
              },
              {
                id: "f36f4968-adf0-4299-a95d-9bcbc5d31a96",
                type: "textArea",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
              {
                id: "b43cd79f-cc2e-4c22-9dd6-70d7627362da",
                type: "checkbox",
                label:
                  "Other criteria considering periodically – please provide details:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["281a093a-dee2-4dbb-843e-7e5a2d651a3f"],
                },
              },
              {
                id: "281a093a-dee2-4dbb-843e-7e5a2d651a3f",
                type: "textArea",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "4a9db58e-9720-47e4-bf9a-62cd0d85b567",
            externalID: "5.1.6",
            nav: "5.1.6",
            text: "What documentation does the Distributor pass-through to Sub-distributors/ 3rd-Party intermediaries to enable them to comply with their obligations?",
            validate: {
              groupArr: [
                "e5e27c3e-94ff-426c-8336-c71a64095dca",
                "ab9eb289-a492-435e-9941-fb69bab5c377",
                "114a789c-8661-466c-9eea-704fb3119bcc",
                "65d7d5af-0f06-4b1b-8a33-d99e10d820da",
              ],
            },
            fields: [
              {
                id: "e5e27c3e-94ff-426c-8336-c71a64095dca",
                type: "checkbox",
                label: "Potential Target Market (designed by the Issuer)",
                value: false,
                width: 12,
              },
              {
                id: "ab9eb289-a492-435e-9941-fb69bab5c377",
                type: "checkbox",
                label: "Actual Target Market (designed by the Distributor)",
                value: false,
                width: 12,
              },
              {
                id: "114a789c-8661-466c-9eea-704fb3119bcc",
                type: "checkbox",
                label: "Promotional marketing materials",
                value: false,
                width: 12,
              },
              {
                id: "65d7d5af-0f06-4b1b-8a33-d99e10d820da",
                type: "checkbox",
                label: "Other - please specify below:",
                value: false,
                width: 12,
                conditional: {
                  enable: ["412239cf-a7b0-4c2a-b675-539766ca013a"],
                },
              },
              {
                id: "412239cf-a7b0-4c2a-b675-539766ca013a",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
            ],
          },
          {
            id: "a7476006-b0eb-4b1b-a04c-98bfaedac1b8",
            externalID: "5.1.7",
            nav: "5.1.7",
            text: "Have any Sub-distributors / 3rd-Party intermediaries been removed from the Distributor panel within the past three years, or do they all continue to meet the minimum standards and criteria?",
            validate: {
              groupArr: [
                "f26a6c97-603f-4f52-bc5c-718b018b016d",
                "7b159d59-26d6-49ac-abf5-bcb1c6f768ff",
                "43fde6d8-c715-4967-868f-e551405e78a3",
              ],
            },
            fields: [
              {
                id: "5fbf70f0-07e4-481c-9de4-092e1e7e09c4",
                type: "radio-list",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "d3260322-0a22-4f1b-800e-f23aaf47f6f4",
                    label: "No",
                    conditional: {
                      disable: [
                        "f26a6c97-603f-4f52-bc5c-718b018b016d",
                        "7b159d59-26d6-49ac-abf5-bcb1c6f768ff",
                        "43fde6d8-c715-4967-868f-e551405e78a3",
                        "e892c0e8-ae7a-4b0a-a3ad-91eccb27c952",
                      ],
                    },
                  },
                  {
                    id: "75834415-f8a3-47b6-9c58-d194b7d56ddb",
                    label: "Yes - please select the reasons for removal below:",
                    conditional: {
                      enable: [
                        "f26a6c97-603f-4f52-bc5c-718b018b016d",
                        "7b159d59-26d6-49ac-abf5-bcb1c6f768ff",
                        "43fde6d8-c715-4967-868f-e551405e78a3",
                      ],
                    },
                  },
                ],
              },
              {
                id: "f26a6c97-603f-4f52-bc5c-718b018b016d",
                type: "checkbox",
                label:
                  "Suitability, mis-selling, distribution, sale or marketing of Structured Products",
                disabled: true,
                value: false,
                width: 12,
                indent: 1,
              },
              {
                id: "7b159d59-26d6-49ac-abf5-bcb1c6f768ff",
                type: "checkbox",
                label: "AML, Financial crime failings",
                disabled: true,
                value: false,
                width: 12,
                indent: 1,
              },
              {
                id: "43fde6d8-c715-4967-868f-e551405e78a3",
                type: "checkbox",
                label: "Other - please explain below:",
                disabled: true,
                value: false,
                width: 12,
                indent: 1,
                conditional: {
                  enable: ["e892c0e8-ae7a-4b0a-a3ad-91eccb27c952"],
                },
              },
              {
                id: "e892c0e8-ae7a-4b0a-a3ad-91eccb27c952",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 2,
              },
            ],
          },
          {
            id: "158e13d3-c22b-433e-a99d-854f839575e1",
            externalID: "5.1.8",
            nav: "5.1.8",
            text: "Does the Distributor provide training or documentation to Sub-distributors / 3rd-Party intermediaries to ensure their on-going competence?",
            validate: {
              groupArr: [
                "fd722099-cff0-44c2-838f-54d0177796d9",
                "113fd285-d9f4-4c3e-8c1a-5bfc507efcf0",
                "a7491aca-d983-430f-8b05-9ee3e9689a27",
                "984c0a95-70b7-4ea7-84eb-ed04704bd28d",
              ],
            },
            fields: [
              {
                id: "99072de9-83e3-441e-88f6-b085fa9f6717",
                type: "radio-list",
                width: 12,
                validate: {
                  required: true,
                },
                options: [
                  {
                    id: "932550ca-f56b-43d0-ae80-c6bf6808158c",
                    label: "No - please explain rationale below:",
                    conditional: {
                      enable: ["bdb7aa4d-8b22-403f-a8ce-adc037d8f8ac"],
                      disable: [
                        "fd722099-cff0-44c2-838f-54d0177796d9",
                        "113fd285-d9f4-4c3e-8c1a-5bfc507efcf0",
                        "a7491aca-d983-430f-8b05-9ee3e9689a27",
                        "984c0a95-70b7-4ea7-84eb-ed04704bd28d",
                        "60cd3230-e375-4ee7-bb5d-81c9fbe0a779",
                      ],
                    },
                  },
                ],
              },
              {
                id: "bdb7aa4d-8b22-403f-a8ce-adc037d8f8ac",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 1,
              },
              {
                id: "99072de9-83e3-441e-88f6-b085fa9f6717",
                type: "radio-list",
                width: 12,
                options: [
                  {
                    id: "3fedae65-c6ad-4573-8667-77f52fca7b53",
                    label: "Yes - please confirm what continuous provisions are made:",
                    conditional: {
                      enable: [
                        "fd722099-cff0-44c2-838f-54d0177796d9",
                        "113fd285-d9f4-4c3e-8c1a-5bfc507efcf0",
                        "a7491aca-d983-430f-8b05-9ee3e9689a27",
                        "984c0a95-70b7-4ea7-84eb-ed04704bd28d",
                      ],
                      disable: ["bdb7aa4d-8b22-403f-a8ce-adc037d8f8ac"],
                    },
                  },
                ],
              },
              {
                id: "fd722099-cff0-44c2-838f-54d0177796d9",
                type: "checkbox",
                label: "Training on Structured Products in general",
                disabled: true,
                value: false,
                width: 12,
                indent: 1,
              },
              {
                id: "113fd285-d9f4-4c3e-8c1a-5bfc507efcf0",
                type: "checkbox",
                label: "Training on specific Structured Products offerings/issuances",
                disabled: true,
                value: false,
                width: 12,
                indent: 1,
              },
              {
                id: "a7491aca-d983-430f-8b05-9ee3e9689a27",
                type: "checkbox",
                label:
                  "Pass-through issuer/distributor documentation e.g. Prospectus, KIDs, Factsheet etc",
                disabled: true,
                value: false,
                width: 12,
                indent: 1,
              },
              {
                id: "984c0a95-70b7-4ea7-84eb-ed04704bd28d",
                type: "checkbox",
                label: "Other",
                disabled: true,
                value: false,
                width: 12,
                indent: 1,
                conditional: {
                  enable: ["60cd3230-e375-4ee7-bb5d-81c9fbe0a779"],
                },
              },
              {
                id: "60cd3230-e375-4ee7-bb5d-81c9fbe0a779",
                type: "input",
                disabled: true,
                value: "",
                width: 12,
                validate: {
                  required: true,
                },
                indent: 2,
              },
            ],
          },
          {
            id: "98e38faa-5846-491a-9c1f-b587fd4311c7",
            externalID: "5.1.9",
            nav: "5.1.9",
            text: "How are customer complaints managed between the Distributor and the Sub-distributors / 3rd-Party intermediaries?",
            popover: {
              body: "Please explain the process in place for Sub-distributors / 3rd Party intermediaries to communicate and respond / remediate relevant complaints with the Distributor.",
            },
            fields: [
              {
                id: "e5aa85a5-4bfc-4455-af09-1e73d42e5570",
                type: "textArea",
                width: 12,
                validate: {
                  required: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "2eba960e-cc13-4c19-85f9-c5d574b39ce7",
    name: "Terms & Conditions",
    nav: "6",
    subsections: [
      {
        id: "2d1c4be6-306c-4be4-8bc0-2751cc626fe9",
        name: "Terms & Conditions",
        nav: "1",
        questions: [
          {
            id: "23e080ea-5f9c-4860-bf88-120318463d8b",
            externalID: "6.1.1",
            nav: "6.1.1",
            text: "Representation for accuracy",
            validate: {
              groupArr: ["f918422d-38c9-499b-9d7e-360cc4b0cd14"],
            },
            fields: [
              {
                id: "f918422d-38c9-499b-9d7e-360cc4b0cd14",
                type: "checkbox",
                label:
                  "The undersigned acknowledges that as at the date of this questionnaire, and each subsequent date on which the questionnaire is reviewed and updated the information provided herein is a true, accurate and complete representation of their organization and the internal controls and processes in place; and Issuers using the inSPire Due Diligence service will place reliance on the answers contained herein in determining whether or not to enter into a distribution relationship with your Firm. ",
                value: false,
                width: 12,
              },
            ],
          },
          {
            id: "d4ab4c07-d7cb-4309-b17d-a6cbf50fd1f4",
            externalID: "6.1.2",
            nav: "6.1.2",
            text: "Obligation to notify upon material change",
            validate: {
              groupArr: ["8cf500e7-afd2-4b7c-8028-b857341aa52c"],
            },
            fields: [
              {
                id: "8cf500e7-afd2-4b7c-8028-b857341aa52c",
                type: "checkbox",
                label:
                  "The Distributor undertakes to promptly and bi-laterally notify inSPire Due Diligence and each of its Issuers individually if, at any time, any statement provided by it in this questionnaire becomes inaccurate, false or misleading or omits any fact which is materially relevant to the assessment of their organization and the internal controls and processes in place.",
                value: false,
                width: 12,
              },
            ],
          },
          {
            id: "3c2809f1-940d-41b3-baee-47927172cbc6",
            externalID: "6.1.3",
            nav: "6.1.3",
            text: "Consent to share",
            validate: {
              groupArr: ["795b1116-7bc6-4f45-9689-d0624698164e"],
            },
            fields: [
              {
                id: "795b1116-7bc6-4f45-9689-d0624698164e",
                type: "checkbox",
                label:
                  "The Distributor provides consent for inSPire Due Diligence to share the DDQ with the Issuers specifically identified and confirmed in writing by the Distributor to inSPire Due Diligence and for the purposes of Structured Product Distributor Due Diligence processes.  ",
                value: false,
                width: 12,
              },
            ],
          },
          {
            id: "2234f5de-9eaa-49d1-8249-5e2863b7e189",
            externalID: "6.1.4",
            nav: "6.1.4",
            text: "Consent to disclose distributor name upon issuer request (optional)",
            fields: [
              {
                id: "4bb9bc91-5ab0-4486-ae7f-8c3c81272070",
                type: "checkbox",
                label:
                  "The Distributor provides consent for DeltaCapita - inSPire Due Diligence to share the Distributor’s legal entity name only with the Issuers using the inSPire Due Diligence who are looking to onboard new structured product distributors. ",
                value: false,
                width: 12,
              },
              {
                id: "17635b30-69fd-4692-944b-273bc72b6719",
                type: "subHeadLabel",
                text: "<ul><li>Apart from the Distributor’s name, no other information will be provided to an Issuer under this consent.</li><li>If an Issuer then requests access to the distributor’s DDQ, this will still require <b>the distributor’s express prior approval before the DDQ can be released to the issuer</b>.</li></ul>",
                width: 12,
                indent: 1,
              },
            ],
          },
          {
            id: "e439a741-f8ad-4cb6-9bbd-9e1c8a71166f",
            externalID: "6.1.5",
            nav: "6.1.5",
            text: "Completed by",
            fields: [
              {
                id: "73c74466-b50d-4849-8c66-dfd1935eac65",
                type: "input",
                label: "Name of Firm Officer",
                value: "",
                width: 6,
                validate: {
                  required: true,
                },
              },
              {
                id: "ed407de9-a77c-44a1-a712-e86bcc391e29",
                type: "input",
                label: "Title",
                value: "",
                width: 6,
                validate: {
                  required: true,
                },
              },
              {
                id: "9a941fb6-68eb-4f6f-be75-bd63a93ce9a7",
                type: "input",
                label: "Email",
                value: "",
                width: 6,
                validate: {
                  requiredEmail: true,
                },
              },
              {
                id: "39d105dc-b0ea-4f93-b74e-ab376a142177",
                type: "input",
                label: "Phone",
                value: "",
                width: 6,
              },
              {
                id: "b543aa08-f43a-45e1-8aca-005e6d24e5e4",
                label: "Date",
                type: "date",
                width: 6,
                validate: {
                  required: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
],);
