import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { CompanyInfo, Issuer, NewIssuerConfig } from '../model/issuer';
import { IssuerContactTableItem, IssuerSalesContact, IssuerSalesContactDetails } from '../model/issuer-sales-contact';
import { ConnectionService } from './connection.service';
import { filter, map, mergeMap, switchMap, toArray } from 'rxjs/operators';
import { Connection } from '../model/connection';
import { Contact, ContactTableItem, UserStatus } from '../model/contact';
import { UserInfo } from '../../admin/model/user';
import { Distributor } from '../model/distributor';
import { UsersService } from '../../services/users.service';
import { isUsernamePasswordOpts } from '@aws-amplify/auth/lib-esm/types';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class IssuersService {
  refreshContactsSubject: Subject<void> = new Subject<void>();
  refreshIssuersSubject: Subject<void> = new Subject<void>();

  issuerContacts: IssuerSalesContact[] = [];
  users: UserInfo[] = [];
  private csvDownloadSubject = new Subject<string | any>();
  public csvDownloadObservable$: Observable<string | any> = this.csvDownloadSubject;
  constructor(
    private apiService: ApiService,
    private connectionService: ConnectionService,
    private usersService: UsersService,
    private cacheService: CacheService
  ) {}

  getAllIssuers(): Observable<Issuer[]> {
    if (this.cacheService.getData().issuers) {
      return of(this.cacheService.getData().issuers);
    } else {
      return this.apiService.get<Issuer[]>('ddq/issuers');
    }
  }

  getIssuer(issuerID: string): Observable<Issuer> {
    return this.apiService.get<Issuer>(`ddq/issuers/${issuerID}`);
  }

  getDeskListFromContacts(): Observable<any[]> {
    return this.apiService.get<any>(`ddq/issuers/contacts/desklist`);
  }

  getMyIssuerDetails(): Observable<CompanyInfo> {
    return this.apiService.get<CompanyInfo>(`ddq/issuer`);
  }

  getIssuerIDByName(issuerName: string): Observable<string> {
    return this.apiService.get<any>(`ddq/issuer/${issuerName}`);
  }

  addIssuer(issuer: NewIssuerConfig): Observable<Issuer> {
    return this.apiService.post<NewIssuerConfig, Issuer>('ddq/issuers', issuer);
  }

  getAllIssuerSalesContacts(): Observable<IssuerSalesContact[]> {
    return this.apiService.get<IssuerSalesContact[]>('ddq/issuers/contacts');
  }

  getIssuerContactByEmail(email: string): Observable<any> {
    return this.apiService.get<IssuerSalesContact[]>(`ddq/issuer/contact/by-email/${email}`);
  }

  searchReplaceAllContacts(toReplaceWith: string, toBeReplaced: string): Observable<any> {
    return this.apiService.patch<any, any>(`ddq/issuers/replace-contacts/${toReplaceWith}/${toBeReplaced}`, null);
  }

  getAllIssuerContactsForTable(): Observable<any[]> {
    if (this.cacheService.getData().issuerContacts && this.cacheService.getData().users) {
      this.issuerContacts = this.cacheService.getData().issuerContacts;
      this.users = this.cacheService.getData().users;

      return of(this.issuerContacts).pipe(
        switchMap((issuerContactsList: IssuerSalesContact[]) =>
          of(this.users).pipe(
            switchMap((allUsers: UserInfo[]) => {
              // console.log(issuerContactsList, 'issuerContactsList');
              const allNonDCUsers = allUsers.filter((user: UserInfo) => !user.email.endsWith('deltacapita.com'));

              return issuerContactsList.map((issuerContact: IssuerSalesContact) => {
                const IssuerContactTableItem: IssuerContactTableItem = {
                  ...issuerContact,
                  issuer: issuerContact.issuername,
                  userStatus: this.getUserStatus(allNonDCUsers, issuerContact.email),
                  cognitoUserId: this.usersService.getCognitoUserIdFromPool(allNonDCUsers, issuerContact.email),
                };
                return IssuerContactTableItem;
              });
            }),
            toArray()
          )
        )
      );
    } else {
      return this.getAllIssuerSalesContacts().pipe(
        switchMap((issuerContactsList: IssuerSalesContact[]) =>
          this.usersService.users().pipe(
            switchMap((allUsers: UserInfo[]) => {
              // console.log(issuerContactsList, 'issuerContactsList');
              const allNonDCUsers = allUsers.filter((user: UserInfo) => !user.email.endsWith('deltacapita.com'));

              return issuerContactsList.map((issuerContact: IssuerSalesContact) => {
                const IssuerContactTableItem: IssuerContactTableItem = {
                  ...issuerContact,
                  issuer: issuerContact.issuername,
                  userStatus: this.getUserStatus(allNonDCUsers, issuerContact.email),
                  cognitoUserId: this.usersService.getCognitoUserIdFromPool(allNonDCUsers, issuerContact.email),
                };
                return IssuerContactTableItem;
              });
            }),
            toArray()
          )
        )
      );
    }
  }

  getUserStatus(allNonDCUsers: UserInfo[], issuerEmail) {
    const issuerUser = allNonDCUsers.find((issuerUserInfo: UserInfo) => issuerUserInfo.email === issuerEmail);
    if (issuerUser) {
      if (issuerUser.status === 'CONFIRMED') {
        return UserStatus.active;
      } else {
        return UserStatus.notLoggedOnYet;
      }
    } else {
      return UserStatus.notCreated;
    }
  }

  getIssuerSalesContact(contactID): Observable<IssuerSalesContact> {
    return this.apiService.get<IssuerSalesContact>(`ddq/issuers/contacts/${contactID}`);
  }

  getContactsByIssuer(issuerID): Observable<IssuerSalesContact[]> {
    return this.apiService.get<IssuerSalesContact[]>(`ddq/issuers/${issuerID}/contacts`);
  }

  getSalesRegionsByIssuer(issuerID): Observable<IssuerSalesContact[]> {
    return this.apiService.get<IssuerSalesContact[]>(`ddq/issuers/${issuerID}/salesregion`);
  }

  getSalesDesksByIssuer(issuerID): Observable<IssuerSalesContact[]> {
    return this.apiService.get<IssuerSalesContact[]>(`ddq/issuers/${issuerID}/salesdesks`);
  }

  getSalesRegionsByIssuerContact(contactID): Observable<any[]> {
    return this.apiService.get<any[]>(`ddq/issuers/salescontact/${contactID}/salesregions`);
  }

  getSalesDesksByIssuerContact(contactID): Observable<any[]> {
    return this.apiService.get<any[]>(`ddq/issuers/salescontact/${contactID}/salesdesks`);
  }

  addIssuerSalesContact(issuerSalesContact, subscriber): void {
    this.apiService
      .post<IssuerSalesContactDetails, IssuerSalesContact>(`ddq/issuers/contacts`, issuerSalesContact)
      .subscribe(subscriber);
  }

  updateIssuerSalesContact(contactID, contact): Observable<IssuerSalesContactDetails> {
    return this.apiService.patch<IssuerSalesContactDetails, IssuerSalesContactDetails>(
      `ddq/issuers/contacts/${contactID}`,
      contact
    );
  }

  setIssuerSalesContactToInvited(contactID: string) {
    return this.apiService.patch<any, any>(`ddq/issuers/contacts/${contactID}/invited`, null);
  }

  setIssuerSalesContactToNotInvited(contactID: string) {
    return this.apiService.patch<any, any>(`ddq/issuers/contacts/${contactID}/notinvited`, null);
  }

  deleteContact(contactID: string) {
    return this.apiService.delete<any>(`ddq/issuers/contacts/${contactID}`);
  }

  getAllUnconnectedIssuers(distributorID: string): Observable<Issuer[]> {
    return combineLatest(this.getAllIssuers(), this.connectionService.getConnectionsForDistributor(distributorID)).pipe(
      switchMap((issuersAndConnections: [Issuer[], Connection[]]) => {
        const allIssuers = issuersAndConnections[0];
        return of(...allIssuers).pipe(
          filter((issuer: Issuer) => {
            const distributorsConnections = issuersAndConnections[1] as Connection[];
            const connectedIssuers = distributorsConnections.map((connection) => connection.issuerid);
            return !connectedIssuers.includes(issuer.id);
          }),
          toArray()
        );
      })
    );
  }

  updateSalesRegions(issuerID: string, updatedRegions) {
    return this.apiService.patch<any, any>(`ddq/issuers/${issuerID}/regions`, updatedRegions);
  }

  updateSalesDesks(issuerID: string, updatedDesks) {
    return this.apiService.patch<any, any>(`ddq/issuers/${issuerID}/desks`, updatedDesks);
  }

  restructureDesk(contactID: string, restructuredDesk) {
    return this.apiService.patch(`ddq/issuers/${contactID}/restructureDesk`, restructuredDesk);
  }

  updateSalesContactNewDocAlert(contactID: string, isOptOut: boolean) {
    return this.apiService.patch<any, any>(`ddq/issuers/contacts/${contactID}/optOutNotification`, {
      optoutdocalert: isOptOut,
    });
  }

  setDownloadCsvSubject(value: string | any): void {
    this.csvDownloadSubject.next(value);
  }
}
