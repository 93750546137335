import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from "@angular/router";
import {Observable, of} from "rxjs";
import {CurrentUserService} from "../services/current-user.service";
import {switchMap} from "rxjs/operators";
import {UserType} from "../model/user";

@Injectable({
  providedIn: 'root'
})
export class IssuerGuard  {

  hasAccess: Observable<boolean>;

  constructor(private currentUsersService: CurrentUserService, private router: Router) {
    this.hasAccess = this.currentUsersService.currentUser.pipe(
        switchMap((currentUser) => of(currentUser.type)),
        switchMap((currentType: UserType) => {
          switch(currentType) {
            case "inspire":
              return of(true);
              break;
            case "issuer":
              return of(true);
              break;
            case "distributor":
              this.router.navigate(['/profile'])
              return of(false);
              break;
            default:
              return of(false);
              break;
          }
        })
    );
  }

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAccess;
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.hasAccess;
  }
}
