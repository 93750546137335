import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoadingSpinnerComponent} from "./loading-spinner/loading-spinner.component";
import { DdqEditAlertComponent } from './ddq-edit-alert/ddq-edit-alert.component';
import { ToastAlertComponent } from './toast-alert/toast-alert.component';
import {NgbModule, NgbToastModule} from "@ng-bootstrap/ng-bootstrap";
import { NotifyAlertComponent } from './notify-alert/notify-alert.component';
import { DdqLockedAlertComponent } from './ddq-locked-alert/ddq-locked-alert.component';
import { DdqUnlockedAlertComponent } from './ddq-unlocked-alert/ddq-unlocked-alert.component';
import { ContentLoaderComponent } from './content-loader/content-loader.component';
import { DraftHeldByDistributorAlertComponent } from './draft-held-by-distributor-alert/draft-held-by-distributor-alert.component';



@NgModule({
  declarations: [LoadingSpinnerComponent, DdqEditAlertComponent, ToastAlertComponent, NotifyAlertComponent, DdqLockedAlertComponent, DdqUnlockedAlertComponent, ContentLoaderComponent, DraftHeldByDistributorAlertComponent],
    exports: [
        LoadingSpinnerComponent,
        ContentLoaderComponent,
        DdqEditAlertComponent,
        ToastAlertComponent,
        NotifyAlertComponent,
        DdqLockedAlertComponent,
        DdqUnlockedAlertComponent,
        DraftHeldByDistributorAlertComponent,
    ],
    imports: [
        CommonModule,
        NgbToastModule,
        NgbModule,
    ]
})
export class GlobalComponentsModule { }
