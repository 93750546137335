import {Injectable} from '@angular/core'
import {AuthService} from '../auth/services/auth.service'
import {SectionGuard} from './section-guard.service'
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class AdminGuard extends SectionGuard {
  constructor(authService: AuthService, router: Router) {
    super(authService, router,['Administrator'])
  }
}
