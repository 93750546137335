import { Region } from './request';

export enum UserStatus {
  notCreated = 'NOT CREATED',
  notLoggedOnYet = 'NOT LOGGED ON YET',
  active = 'ACTIVE',
  notAvailable = 'N/A',
}

export interface Contact {
  id?: string;
  clientid: string;
  name: string;
  email: string;
  mobile: string;
  tel: string;
  region: [Region];
  invitesentat?: string;
  distributorname: string;
}

// export type ContactTableItem = Contact & {
//   distributor: string;
//   userStatus: UserStatus;
// };

export interface ContactTableItem {
  id?: string;
  clientid: string;
  name: string;
  email: string;
  mobile: string;
  tel: string;
  region: string;
  invitesentat?: string;
  distributorname: string;
  distributor: string;
  userStatus: UserStatus;
  cognitoUserId?: string;
  lastLogin?: string;
}
