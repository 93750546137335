import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Observable, Subject, of } from 'rxjs';
import { IssuerRequest, IssuerRequestTableItem } from '../model/issuer-request';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class IssuerRequestsService {
  private csvDownloadSubject = new Subject<string | any>();
  public csvDownloadObservable$: Observable<string | any> = this.csvDownloadSubject;
  constructor(private apiService: ApiService, private cacheService: CacheService) {}

  getAllIssuerRequests(): Observable<IssuerRequestTableItem[]> {
    if (this.cacheService.getData().allIssuerRequests) {
      return of(this.cacheService.getData().allIssuerRequests);
    } else {
      return this.apiService.get<IssuerRequestTableItem[]>('ddq/issuer-requests');
    }
  }

  getListWithDesks(): Observable<any[]> {
    return this.apiService.get(`ddq/issuer-requests/list-desk`);
  }

  getIssuerRequestID(issuerID, requestID): Observable<any> {
    return this.apiService.get(`ddq/issuer-request-id/${issuerID}/${requestID}`);
  }

  getIssuerRequestIDByIssuerDistributorAndRequestType(issuerID, distributorID, requestType): Observable<any> {
    return this.apiService.get(`ddq/issuer-requests/${issuerID}/${distributorID}/${requestType}`);
  }

  createIssuerRequest(issuerRequest: IssuerRequest): Observable<IssuerRequest> {
    return this.apiService.post<IssuerRequest, IssuerRequest>('ddq/issuer-requests', issuerRequest);
  }

  updateContactInIssuerRequest(newContactID: string, issuerRequestID: string) {
    return this.apiService.patch<any, any>(`ddq/issuer-request/${issuerRequestID}/contact/${newContactID}`, null);
  }

  UpdateRequest(comment, age, chase, commentary, newsalert, salesdesk, salesregion, id): Observable<IssuerRequest> {
    return this.apiService.patch('ddq/issuer-request/update', {
      comment,
      age,
      chase,
      commentary,
      newsalert,
      salesdesk,
      salesregion,
      id,
    });
  }

  UpdateToOnPlatformRequest(
    comment,
    issuersalescontactid,
    purpose,
    ref,
    salesdesk,
    salesregion,
    id
  ): Observable<IssuerRequest> {
    return this.apiService.patch('ddq/issuer-request/update/on-platform', {
      comment,
      issuersalescontactid,
      purpose,
      ref,
      salesdesk,
      salesregion,
      id,
    });
  }

  updateDeskAndRegion(salesdesk, salesregion, id): Observable<IssuerRequest> {
    return this.apiService.patch(`ddq/issuer-request/update-desks`, {
      salesdesk,
      salesregion,
      id,
    });
  }

  completeOffPlatformRequest(id, date): Observable<IssuerRequest> {
    return this.apiService.patch(`ddq/issuer-request/complete/${id}`, { completionDate: date });
  }

  expireOffPlatformRequest(id): Observable<IssuerRequest> {
    return this.apiService.patch(`ddq/issuer-request/expire/${id}`, null);
  }

  upgradeOffPlatformRequest(id): Observable<IssuerRequest> {
    return this.apiService.patch(`ddq/issuer-request/upgrade/${id}`, null);
  }

  getCompletionDates(id): Observable<any> {
    return this.apiService.get(`ddq/issuer-request/${id}/completion-dates`);
  }

  getExpDate(id): Observable<any> {
    return this.apiService.get(`ddq/issuer-request/${id}/expiration-date`);
  }

  getLogs(id): Observable<any> {
    return this.apiService.get(`ddq/issuer-request/${id}/logs`);
  }

  renewExpDate(id): Observable<IssuerRequest> {
    return this.apiService.patch(`ddq/issuer-request/${id}/expiration-renew`, null);
  }

  updateExpDate(id, date): Observable<IssuerRequest> {
    return this.apiService.patch(`ddq/issuer-request/${id}/expiration-date/${date}`, null);
  }

  progressOffPlatformRequest(id): Observable<IssuerRequest> {
    return this.apiService.patch(`ddq/issuer-request/progress/${id}`, null);
  }

  activateOffPlatformRequest(id): Observable<IssuerRequest> {
    return this.apiService.patch(`ddq/issuer-request/activate/${id}`, null);
  }

  reRequestOffPlatformRequest(id): Observable<IssuerRequest> {
    return this.apiService.patch(`ddq/issuer-request/re-request/${id}`, null);
  }

  isThereAnExistingIssuerRequest(issuerID, requestID): Observable<boolean> {
    return this.apiService.patch<{ issuerID: string; requestID: string }, boolean>(
      'ddq/issuer-requests/existence-check',
      {
        issuerID,
        requestID,
      }
    );
  }

  // FOR DEV USE ONLY
  deleteIssuerRequestsAgainstARequest(requestID): Observable<any> {
    return this.apiService.delete<any>(`ddq/issuer-requests/${requestID}`);
  }

  deleteIssuerRequest(id): Observable<any> {
    return this.apiService.delete<any>(`ddq/issuer-request/${id}`);
  }

  convertToISOTime(createdDate, createdTime) {
    return new Date(createdDate.year, createdDate.month - 1, createdDate.day, createdTime.hour, createdTime.minute)
      .toISOString()
      .slice(0, -1);
  }

  convertToISODate(createdDate) {
    return new Date(createdDate.year, createdDate.month - 1, createdDate.day).toISOString().slice(0, -1);
  }

  requestIdToEdit: string;
  issuerIdToEdit: string;
  commentToEdit: string;
  purposeToEdit: string;
  referecneToEdit: string;
  salesContactIdToEdit: string;

  editIssuerRequestFields(
    requestId: string,
    issuerId: string,
    comment: string,
    purpose: string,
    reference: string,
    salesContactId: string
  ): void {
    this.requestIdToEdit = requestId;
    this.issuerIdToEdit = issuerId;
    this.commentToEdit = comment;
    this.purposeToEdit = purpose;
    this.referecneToEdit = reference;
    this.salesContactIdToEdit = salesContactId;
  }

  setDownloadCsvSubject(value: string | any): void {
    this.csvDownloadSubject.next(value);
  }

  async getIssuerRequestByIdPromise(id: string) {
    return this.apiService.get<any>(`ddq/issuer-request/${id}`).toPromise();
  }

  async getActiveIssContactsByRequestIdFromIssRequestsPromise(requestId): Promise<any[]> {
    return await this.apiService.get<any>(`ddq/issuer-requests/issuer-contacts/${requestId}`).toPromise();
  }
}
