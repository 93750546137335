<app-dc-navbar
  *ngIf="(signedIn && !loading) || !signedIn && activeRoute == 'privacy-policy' || !signedIn && activeRoute == 'terms-of-use' || !signedIn && activeRoute == 'data-protection-addendum'"
  (signOutEmitter)="signOut($event)"
  [user]="user"
  [email]="email"
  [loggedInAsInspire]="loggedInAsInspire"
  [loggedInAsDistributor]="loggedInAsDistributor"
  [loggedInAsIssuer]="loggedInAsIssuer"
></app-dc-navbar>
<app-dc-alert></app-dc-alert>
<ng-container *ngIf="!signedIn && (activeRoute !== 'privacy-policy') && (activeRoute !== 'terms-of-use') && (activeRoute !== 'data-protection-addendum')">
  <app-login-screen></app-login-screen>
</ng-container>

<ng-container *ngIf="(signedIn || activeRoute == 'privacy-policy') || activeRoute == 'terms-of-use' || activeRoute == 'data-protection-addendum'">
  <router-outlet></router-outlet>
</ng-container>

<div class="app-version">
  <div class="environment">
    {{ environment }}
  </div>
  Build Number: {{ appBuildNumber }}<br />
  Build Date: {{ appBuildDateString }}<br />
  Version: {{ appVersion.substring(0, 10) }}<br />
</div>
