<div class="alertContainer">
  <ngb-alert
    #staticAlert
    *ngIf="alertText"
    (closed)="alertText = ''"
    [animation]="true"
    [dismissible]="dismissible"
    [type]="alertText.type"
  >
    <button type="button" class="customClose close" data-dismiss="alert" aria-label="Close" (click)="close()">
      OK
    </button>
    <div class="customH5 alert-heading">
      <span class="svgIcon {{ alertText.type }}" alt="alert icon" [src]="alertIcon"></span>
      <span class="alertMessage">{{ alertText.message }}</span>
    </div>
  </ngb-alert>
</div>
