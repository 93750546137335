import { UntypedFormArray, UntypedFormGroup, ValidatorFn } from '@angular/forms';

export function requirePercentFormArrayValidator(validateId: any): ValidatorFn {
  return (formArray: UntypedFormArray) => {
    if (validateId == null || validateId == undefined) {
      return null;
    }
    const percentId = validateId[0];
    let percentGroup = [];
    let sumtotal = 0;
    formArray.value.forEach((x, index) => {
      let obj = {};
      if (x.hasOwnProperty(percentId)) {
        if (x[percentId] == null || x[percentId] === '') {
          percentGroup.push(0);
        } else {
          if (Number(x[percentId]) > 0) {
            percentGroup.push(Number(x[percentId]) * 100);
          }
        }
      }
      let sum = percentGroup.reduce((a, b) => a + b, 0);
      sumtotal = sum;
    });

    if (sumtotal == 100) {
      formArray.value.forEach((x, index) => {
        const control = formArray.controls[index] as UntypedFormGroup;
        Object.keys(control.controls).forEach((key) => {
          formArray.at(index).get(percentId).setErrors(null);
        });
      });

      return null;
    } else if (sumtotal !== 100) {
      formArray.value.forEach((x, index) => {
        const control = formArray.controls[index] as UntypedFormGroup;
        Object.keys(control.controls).forEach((key) => {
          if (key == percentId) {
            formArray.at(index).get(percentId).setErrors({ percentInvalid: true });
          }
        });
      });

      return {
        requirePercentControlValidator: true,
      };
    }
  };
}
