export const DATAPROTECTIONADDENDUM = `
<div class="termsText data">
<div class="wrapper">
    <h2><span>1.</span> Definitions And Interpretation</h2>
        <p><span>1.1</span> The following words and expressions shall, unless the context otherwise requires, have the meaning assigned to them in the Agreement or as follows:</p>
    <p><strong>“Admissible Purpose”</strong> has the meaning assigned to the term in clause <a href="#2.1">2.1</a>;</p>
<p><strong>“Affiliate”</strong> means any legal entity directly or indirectly controlling or controlled by or under direct or indirect common control with the specified entity. “Control”, for the purposes of this definition, means the power to direct the management and policies of such entity, directly or indirectly, whether through the ownership of voting securities, by contract or otherwise;</p>
<p><strong>“Authority Enquiries”</strong> has the meaning assigned to the term in clause <a href="#11">11</a>;</p>
<p><strong>“Business Day”</strong> means a day (excluding Saturdays) on which banks generally are open in the location of a recipient of a notice for the transaction of normal banking business;</p>
<p><strong>"Client Personal Data"</strong> means any Personal Data processed by Delta Capita on behalf of the Client pursuant to or in connection with the provision of the Services;</p>
<p><strong>"Data Export"</strong> means any transfer of Client Personal Data to a Third Country by any of the Parties or a Sub-Processor;</p>
<p><strong>"Data Protection Law"</strong> means the EU GDPR and the UK GDPR as amended from time to time, and any other applicable law, statute, subordinate legislation, regulation, order, mandatory guidance, code of practice, or directives or requirements of any regulatory or supervisory body which relates to the protection of individuals with regard to the processing, privacy and/or use of personal data;</p>
<p><strong>"Data Security Incident"</strong> means technical, organisational or other incidents (including incidents at Sub-Processors) which have resulted or may result in a Personal Data Breach as defined under EU and UK GDPR;</p>
<p><strong>"Data Security Standards"</strong> has the meaning assigned to the term in clause <a href="#3.2">3.2</a>;</p>
<p><strong>"Data Subject Requests"</strong> has the meaning assigned to the term in clause <a href="#4.1">4.1</a>;</p>
<p><strong>"DPIA"</strong> has the meaning given to it in clause <a href="#5.2">5.2</a>;</p>
<p><strong>"EU GDPR"</strong> means the EU General Data Protection Regulation 2016/679“ </p>
<p><strong>"ICO"</strong> means the UK Information Commissioner’s Office“ </p>
<p><strong>"Sub-Processor"</strong> has the meaning assigned to the term in clause <a href="#7.1">7.1</a>“ </p>
<p><strong>"Third Country"</strong> means non-EEA and non-UK countries which have not been recognised by the European Commission or the ICO (as applicable) as providing an adequate level of Personal Data protection; and </p>
<p><strong>"UK GDPR"</strong> means the EU GDPR as it forms part of domestic law in the United Kingdom by virtue of section 3 of the European Union (Withdrawal) Act 2018 and the Data Protection Act 2018. </p>
<p>Unless otherwise defined herein, terms that appear in the EU and UK GDPR shall be defined in accordance with that legislation, in particular the following terms: <strong>"Controller“</strong>; <strong>"Data Subject“</strong>; <strong>"Member State“</strong>; <strong>"Personal Data“</strong>; <strong>"Personal Data Breach“</strong>; <strong>"Processing“</strong>; <strong>"Processor"</strong>; and <strong>"Supervisory Authority"</strong>.</p>
        <p><span id="1.2">1.2</span> In this DPA unless otherwise specified, reference to:</p>
        <ol class="alpha">
            <li>a person includes any person, individual, company, firm, corporation, government, state or agency of a state or any undertaking or organisation (whether or not having separate legal personality and irrespective of the jurisdiction in or under the law of which it was incorporated or exists);</li>
        <li>a party is to a party to this DPA and includes its successors in title and permitted assignees;</li>
    <li>   a <strong>"statute"</strong> or <strong>"statutory instrument"</strong> or any of their provisions is to be construed as a reference to that statute or statutory instrument or such provision as the same may have been amended or re-enacted before the date of this DPA;</li>
<li>recitals, clauses, paragraphs or schedules are to recitals, clauses of and paragraphs of and schedules to this DPA.  The Schedules form part of the operative provisions of this DPA and references to this DPA shall, unless the context otherwise requires, include references to the Schedules; and</li>
<li>           words denoting the singular shall include the plural and vice versa and words denoting any gender shall include all genders.</li>
        </ol>
    <h2><span>2.</span> Subject Matter And Terms Of Agreement</h2>
<p><span id="2.1">2.1</span> To the extent that the Services involves the processing of Client Personal Data (the <strong>"Admissible Purpose"</strong>) this DPA shall apply. Delta Capita shall not use or disclose Client Personal Data for any other purpose than the Admissible Purpose or as required by applicable law.</p>
<p><span>2.2</span> <a href="#schedule1">schedule 1</a> sets out details of:</p>
<ol class="alpha">
<li>the activities of Processing Personal Data carried out by Delta Capita on behalf of the Client;</li>
<li>the categories of Client Personal Data; and</li>
<li>the categories of Data Subjects whose Personal Data is the subject of the Processing.</li>
        </ol>
        <p><span>2.3</span> The Client may make amendments to the details set out in <a href="#schedule1">Schedule 1</a> by written notice to Delta Capita from time to time as such Client reasonably considers necessary to meet its statutory obligations.</p>
    <p><span>2.4</span> Delta Capita shall be permitted to transfer to and process Client Personal Data in a Third Country provided that any such transfer complies with the applicable laws of such Third Country and the requirements set out at clause <a href="#8">8</a>.</p>
<p><span>2.5</span> Delta Capita shall process Client Personal Data only on behalf of the Client and in strict accordance with the Client’s written instructions unless required to do so otherwise by EU member state or UK law (as applicable) to which Delta Capita is subject.  In such a case, Delta Capita shall inform the Client of that legal requirement before carrying out such Processing, unless that law prohibits Delta Capita from providing such information under applicable law.  For the avoidance of doubt, whenever this DPA or the Agreement includes provisions relating to the Processing of Client Personal Data such Processing shall be a written instruction of the Client given pursuant to this DPA.</p>
<p><span>2.6</span> Delta Capita shall immediately inform the Client if, in its opinion, an instruction of such Client infringes Data Protection Law.</p>
<p><span>2.7</span> The duration of this DPA is equal to the duration of the Agreement.  This DPA shall terminate automatically upon termination of the Agreement, with the exception of any provisions intended to survive termination hereof or the Agreement.  Any right to terminate this DPA separately prior to such termination date shall be excluded to the extent permitted by applicable law.</p>
<p><span>2.8</span> In the event that changes in Data Protection Law or if required by a Supervisory Authority to which a Party is subject, require the amendment of the terms of this DPA, the Parties will upon either Party’s request agree to make required amendments.</p>
<h2 id="3"><span>3.</span> Required Technical And Organisational Measures</h2>
<p><span>3.1</span> Delta Capita shall ensure the ongoing confidentiality, integrity, availability and resilience of its processing systems and services as well as the ability to restore the availability and access to Client Personal Data in a prompt manner in the event of a physical or technical incident, as required under Data Protection Law.</p>
<p><span id="3.2">3.2</span> Delta Capita shall implement appropriate technical and organisational measures in order to:</p>
<ol class="alpha">
            <li>prevent (i) unauthorised or unlawful processing of the Client Personal Data; and (ii) the accidental loss or destruction of, or damage to, the Client Personal Data; and</li>
        <li>ensure a level of security appropriate to (i) the harm that might result from such unauthorised or unlawful processing or accidental loss, destruction or damage; and (ii) the nature of the Client Personal Data to be protected;</li>
            </ol>
        <p>including the measures referred to in Article 32 of EU and UK GDPR (<strong>"Data Security Standards"</strong>). </p>
    <p><span>3.3</span> Delta Capita warrants that it has implemented the Data Security Standards and that it will maintain such Data Security Standards during the term hereof.  Delta Capita shall ensure by means of appropriate protective mechanisms that access to Client Personal Data is strictly limited to those personnel of Delta Capita who require access in order to fulfil the Admissible Purpose or to comply with applicable law.</p>
<p><span>3.4</span> Acknowledging that the Data Security Standards are subject to technical progress and development, the Parties agree that Delta Capita shall be authorised to implement adequate alternative technical and organisational measures provided, however, that such measures shall not fall short of the level of security provided by the Data Security Standards and comply with the requirements under Data Protection Law.  Delta Capita shall without delay notify the Client of any material changes to the Data Security Standards.  Delta Capita shall regularly test and audit the Data Security Standards for adequateness and compliance with Data Protection Law and without delay take any corrective action required.</p>
    <h2><span>4.</span> Data Subject Rights </h2>
    <p><span id="4.1">4.1</span> The Parties acknowledge and agree that the Client is legally responsible for dealing with any requests or enquiries from Data Subjects in relation to their rights under Data Protection Law in relation to the Processing of Client Personal Data (<strong>"Data Subject Requests"</strong>). Delta Capita shall not respond to any such Data Subject Requests unless required by the Client in documented instructions. </p>
<p><span>4.2</span> Where the Client is obliged to provide information to a Data Subject in relation to the Processing of Personal Data or take any other action required in its reasonable discretion to comply with Data Subject Requests, Delta Capita shall take all other action reasonably requested by the Client in order to comply with such Data Subject Requests.  </p>
<p><span>4.3</span> Delta Capita shall: </p>
<ol class="alpha">
            <li>without delay notify the Client of any Data Subject Requests or other enquiries relating to this DPA received by it or any Sub-Processor without responding to such requests or enquiries unless expressly otherwise instructed by such Client; and </li>
        <li>ensure that any Sub-Processors do not respond to any Data Subject Requests unless expressly otherwise instructed by the Client or required by Data Protection Law, in which case Delta Capita shall to the extent permitted by Data Protection Law inform the Client of that legal requirement before the Sub-Processor responds to the request.</li>
        </ol>
        <p><span>4.4</span> In the event of a dispute with or other claims brought by a Data Subject concerning the Processing of Client Personal Data against Delta Capita, Delta Capita shall promptly notify and inform the Client and shall cooperate and coordinate with a view to effectively defending against such claims or settling them amicably in a timely fashion.  In the event of such disputes or claims brought against  the Client, Delta Capita shall cooperate and provide such Client with any information and take any action reasonably required by such Client. </p>
    <h2>5. Further Data Protection Obligations </h2>
<p><span>5.1</span> Delta Capita shall maintain, and promptly make available to the competent Supervisory Authority and/or the Client on request, a proper documented (electronic form sufficient) record of all categories of processing activities carried out on behalf of the Client in accordance with Article 30 paragraph 2 EU and UK GDPR, which contains at least the following information: </p>
<ol class="alpha">
    <li>The name and contact details of Delta Capita, Sub-Processors and such Client and, where applicable, of such Client’s or Delta Capita’s representative, and the data protection officer;</li>
<li>The categories of processing carried out on behalf of such Client;</li>
        <li>Where applicable, transfers of Personal Data to a non-EEA country or an international organisation, including the identification of that non-EEA country or international organisation and the documentation of suitable safeguards (if required);</li>
        <li>a general description of the Data Security Standards.</li>
        </ol>
        <p><span id="5.2">5.2</span> Delta Capita shall provide the Client with reasonable assistance in ensuring compliance with the obligations in respect of data protection impact assessments, taking account of the nature of the processing and the information available to Delta Capita.</p>
    <h2><span>6.</span> Supplier Personnel/data Protection Officer</h2>
<p><span>6.1</span> Delta Capita shall ensure that any personnel undertaking or involved in the Processing under this DPA are properly qualified and trained, including specific and appropriate data protection training, and have committed themselves to keep Client Personal Data confidential or are under an appropriate statutory obligation of confidentiality.</p>
<p><span>6.2</span> Delta Capita shall appoint a data protection officer in accordance with Data Protection Law or, if appointment of a data protection officer is not required under the Data Protection Law, another data protection official responsible for the data protection aspects of the Processing of Client Personal Data and shall immediately provide the Client with relevant up to date contact details of such data protection officer or person.</p>
<h2><span>7.</span> Sub Processing</h2>
<p><span id="7.1">7.1</span> Delta Capita may engage other Processors in relation to the processing of Client Personal Data (<strong>"Sub-Processor"</strong>) in accordance with Data Protection Law, the Agreement and this DPA. </p>
<p><span>7.2</span> Delta Capita shall give the Client prior written notice of the intended appointment of any new Sub-Processor not listed in Schedule 3, including full details of the Processing to be undertaken by the Sub-Processor.  If, within three weeks of receipt of that notice, the Client notifies Delta Capita in writing of any objections to the proposed appointment for legitimate reasons, Delta Capita shall work with the Client in good faith to take reasonable measures to address the objections raised by  the Client and where such a measures cannot be agreed within three (3) weeks from Delta Capita's receipt of the Client’s notice, notwithstanding anything in the Agreement: (i) Delta Capita shall not appoint (nor disclose any Client Personal Data to) the proposed Sub-Processor except with the prior written consent of such Client or (ii) the Client may by written notice to Delta Capita with immediate effect terminate the Agreement to the extent that it relates to Services that require the use of the proposed Sub-Processor.</p>
<p id="7.3"><span>7.3</span> With respect to each Sub-Processor, Delta Capita shall:</p>
<ol class="alpha">
<li>before any Client Personal Data are transferred to the Sub-Processor, carry out adequate due diligence to ensure that the Sub-Processor is capable of providing the level of protection for Client Personal Data required by this DPA and the Agreement;</li>
<li>enter into a written agreement with the Sub-Processor which is governed by an EU member state or UK law (as applicable) and imposes the same obligations on the Sub-Processor in relation to the protection of Client Personal Data as are imposed on Delta Capita under this DPA.  This shall apply in particular, but shall not be limited to, the confidentiality obligations and the Data Security Standards to be observed pursuant to clause <a href="#3">3</a>;</li>
        <li>upon request provide the Client with the sub-contracting agreement and any other documentation reasonably requested by the Client (it being understood that Delta Capita shall be permitted to redact any commercial terms which are confidential and not required by such Client to assess compliance of Delta Capita with its obligations under this DPA);</li>
        <li>conduct regular audits as required to ensure that the Sub-Processor complies with the Data Security Standards and its other contractual obligations and shall without delay notify the Client in accordance with clause <a href="#10">10</a> on becoming aware of any breach of a Sub-Processor’s obligations.</li>
        </ol>
        <p><span>7.4</span> Delta Capita shall at all times and without being so requested provide the Client with an up to date list of all Sub-Processors engaged by Delta Capita for carrying out specific processing activities on behalf of such Client, as set out at Schedule 3, detailing company name, address and contact details, the specific area of Client Personal Data Processing operations outsourced and the location of the Processing. As of the date of this DPA, Delta Capita engages exclusively the Sub-Processors listed in Schedule 3 which Delta Capita may continue to use, provided that the obligations set out in clause <a href="#7.3">7.3</a> are met.</p>
    <p><span>7.5</span> In case of non-compliance of the Sub-Processor with its contractual obligations: </p>
    <ol class="alpha">
    <li>Delta Capita shall remain fully liable to the Client for any damages caused by such non-compliance; and</li>
<li>the Client shall be entitled to withdraw its consent to the engagement of such Sub-Processor in which case Delta Capita shall promptly stop engaging such Sub-Processor in connection with the Processing and clause <a href="#12">12</a> shall apply with respect to such Sub-Processor.</li>
        </ol>
    <h2 id="8"><span>8.</span> Data Export</h2>
<p><span>8.1</span> Delta Capita shall, before it carries out any Data Export, (i) enter into the standard clauses published by the European Commission or the ICO (as applicable); and/or (ii) enter into or establish any other appropriate instruments or undertakings required under Data Protection Law to effect such Data Export without breach of such applicable law.  If so required by Data Protection Law or requested by the Client, Delta Capita shall cause any Sub-Processor to enter into such instruments or undertakings directly with each relevant Client.</p>
<p><span>8.2</span> Where the Client provides Delta Capita with Personal Data with the Client’s knowledge and intention that the Personal Data will be transferred or made available to a third-party data receiver, the Client acknowledges it is initiating and agreeing to the transfer and therefore:</p>
<ol class="alpha">
<li>the Client is solely responsible for ensuring the transfer of the Personal Data to the data receiver, and the data receiver’s Processing of the Personal Data, complies with Data Protection Law; and</li>
<li>the Client shall enter into any contractual arrangements directly with the data receiver, including where applicable the EU and UK standard contractual clauses for international transfers of Personal Data, and carry out any transfer risk assessments, to ensure the transfer of the Personal Data to the data receiver complies with Data Protection Law.</li>
        </ol>
        <p><span>8.3</span> The Client shall indemnify Delta Capita against any claims, costs, damages and expenses (including reasonable legal expenses) suffered or incurred by Delta Capita as a result of the Client’s breach of this clause 8.</p>
    <p><span>8.4</span> Subject to clause 8.2, when processing Client Personal Data outside of the territory of the EEA or the UK or engaging in any act or practice regarding Client Personal Data where that act or practice is subject to data protection laws in jurisdictions outside the territory of the EEA or the UK Delta Capita shall also comply with those applicable laws.  Except where clause 8.2 applies, Delta Capita shall provide appropriate safeguards to ensure an adequate level of data protection in accordance with Article 44 EU and UK GDPR.</p>
    <h2><span>9.</span> Inspections And Audits</h2>
<p><span>9.1</span> Delta Capita shall make available to the Client on request all information necessary to demonstrate compliance with this DPA and with Data Protection Law.  </p>
<p><span>9.2</span> The Client shall give Delta Capita reasonable notice of any audit or inspection to be conducted and shall (and ensure that each of its mandated auditors shall) avoid causing any damage, injury or disruption to Delta Capita’s premises, equipment, personnel and business in the course of such an audit or inspection.  Audits or inspections shall not be conducted outside normal business hours and shall not be conducted more than once every 12 months.</p>
<p><span>9.3</span> If the Client notifies Delta Capita about any deficiencies or irregularities related to the Processing of Client Personal Data discovered during such audits or inspections, Delta Capita shall promptly take any action reasonably requested by the Client to remedy such findings and provide the Client with written documentation evidencing that the audit findings have been remedied as required by Data Protection Law and this DPA.</p>
<p><span>9.4</span> The Client agrees that Delta Capita may satisfy the audit requirements in this clause 9 by providing the Client with copies of the reports produced by its independent third party data security auditors or as part of its data security accreditations.  </p>
    <h2 id="10"><span>10.</span> Personal Data Breaches And Incidents</h2>
<p><span>10.1</span> The Parties are aware that Data Protection Law may impose a duty on the Client to inform the competent Supervisory Authority and the relevant Data Subjects in the event of a Data Security Incident.  Delta Capita shall without undue delay (and within 48 hours of becoming aware) notify the Client of any actual or suspected Data Security Incident.</p>
<p><span>10.2</span> Data Security Incidents include the following:</p>
<ol class="alpha">
<li>Any actual or suspected unauthorised access, disclosure, loss, download, theft, blocking, encryption or deletion by malware or other unauthorised action in relation to Client Personal Data by unauthorised third parties; </li>
<li>any actual or suspected operational incidents which may have an impact on the Processing of Client Personal Data;</li>
        <li>any actual or suspected breach of this DPA or Data Protection Law by Delta Capita, its employees or other vicarious agents to the extent that such breach relates to Client Personal Data or Delta Capita's obligations under this DPA; or</li>
        <li>any legally binding request for disclosure or seizure of Client Personal Data by a law enforcement or other public authority unless Delta Capita is prohibited by applicable law to notify such incident to the Client (in which case, Delta Capita must notify the Client immediately upon such prohibition having ceased).</li>
        </ol>
        <p><span>10.3</span> Delta Capita’s notification to the Client must include any information such Client specifically requests or which may reasonably be expected to be required or appropriate in order to comply with its legal obligations in relation to the Data Security Incident.  This includes in particular any information required under Article 33(3) EU and UK GDPR and as a minimum the following:</p>
        <ol class="alpha">
        <li>the nature of the Data Security Incident, the categories and numbers of Data Subjects concerned, and the categories and numbers of Personal Data records concerned;</li>
    <li>the name and contact details of Supplier's data protection officer or other relevant contact from whom more information may be obtained;</li>
        <li> the likely consequences of the Data Security Incident; and</li>
        <li>the measures taken or proposed to be taken to address the Data Security Incident.</li>
        </ol>
        <p><span>10.4</span> Delta Capita is not authorised to notify a Data Security Incident to a Supervisory Authority (or any other authority), the Data Subjects concerned or any other third parties unless Delta Capita is required to do so under Data Protection Law (e.g. if the Data Security Incident results in a Personal Data Breach for which Delta Capita is itself responsible as Controller). In such event, Delta Capita shall, to the extent permitted under applicable law, liaise and coordinate with the Client prior to making such a notification.  The Client and Delta Capita shall co-operate in good faith to agree on a joint approach with a view to prevent any contradicting or inconclusive notifications.  This includes providing each other with the details of any notification and the date and time on which notification will be made.</p>
    <p><span>10.5</span> In the event of a Data Security Incident, Delta Capita shall without delay and in coordination with the Client take appropriate and available measures to restore the confidentiality, integrity and availability of the Client Personal Data and the resilience of the processing systems and services and to mitigate the risk of harm and/or any detrimental consequences for the Data Subjects affected or potentially affected by the Data Security Incident.  Delta Capita shall promptly provide the Client with a written incident report detailing such measures taken and specific measures taken to prevent similar incidents in the future.</p>
    <h2><span id="11">11.</span> Communication With Authorities</h2>
<p>To the extent permitted under Data Protection Law: </p>
<ol class="alpha">
<li>Delta Capita shall without delay notify the Client in the event of any audits, enquiries, investigations, requests, orders or other proceedings or matters which may relate to Client Personal Data or Delta Capita's obligations under this DPA by a Supervisory Authority or any other public body in relation to the Processing of Client Personal Data (<strong>"Authority Enquiries"</strong>).  The Client and Delta Capita shall co-operate in good faith to support each other and to ensure an aligned and coordinated communication with the authority in relation to any Authority Enquiries.  </li>
<li>in the event of a dispute with, orders or fines imposed or other claims brought by a Supervisory Authority or other competent authority concerning the Processing of Client Personal Data against either or both of the Client and Delta Capita, the Client and Delta Capita shall promptly notify and inform each other and shall cooperate and coordinate with a view to effectively defending themselves against such claims or settling them amicably in a timely fashion.</li>
        </ol>
    <h2 id="12"><span>12.</span> Return And Deletion Of Client Personal Data</h2>
<p id="12.1"><span>12.1</span> Upon termination of the Agreement or anytime upon request of the Client, Delta Capita shall promptly delete and procure the deletion of all copies of Client Personal Data that has been provided to Delta Capita in relation to the Agreement.  Such Client may in its absolute discretion by written notice require Delta Capita to return a complete copy of all Client Personal Data to the Client by secure file transfer in such format as is reasonably notified by the Client to Delta Capita.  Delta Capita shall comply with any such written request in a timely fashion.</p>
<p><span>12.2</span> Notwithstanding clause <a href="#12.1">12.1</a>, Delta Capita may retain Client Personal Data to the extent required by EU member state or UK laws (as applicable) and only to the extent and for such period as required by EU member state or UK laws (as applicable) and always provided that Delta Capita shall ensure that such retained Client Personal Data is:</p>
<ol class="alpha">
<li>kept confidential and protected against unauthorised access, disclosure or use; and</li>
<li>only Processed as necessary for the purpose(s) specified in such EU member state or UK laws (as applicable) requiring its storage and for no other purpose.</li>
        </ol>
        <p><span>12.3</span> Delta Capita shall promptly ensure that all Sub-Processors comply with this clause <a href="#12">12</a>.</p>
    <p><span>12.4</span> Upon the written request of the Client, Delta Capita shall provide written certification to such Client that it has fully complied with its obligations under this clause. </p>
    <h2><span>13.</span> Order Of Precedence</h2>
<p>This DPA varies the terms of the Agreement and the provisions of this DPA are incorporated into and form part of the Agreement as if set out in the Agreement in full.  In the event of any conflict or inconsistency, the provisions of this DPA shall take precedence over the provisions of the Agreement.  Save as aforesaid, the provisions of the Agreement shall remain in full force.</p>
<h2><span>14.</span> Severance</h2>
<p>Should any provision of this DPA be invalid or unenforceable, then the remainder of this DPA shall remain valid and in force. The invalid or unenforceable provision shall be either:</p>
<ol class="alpha">
<li>amended as necessary to ensure its validity and enforceability, while preserving the parties’ intentions as closely as possible or, if this is not possible; or</li>
<li>construed in a manner as if the invalid or unenforceable part had never been contained therein.  This shall apply accordingly in the event of any unintended gaps.</li>
        </ol>
        <h2><span>15.</span> Governing Law And Jurisdiction</h2>
<p><span>15.1</span> This DPA (and any dispute, controversy, proceedings or claim of whatever nature arising out of or in any way relating to this DPA or its formation, including any non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of England and Wales and subject to the exclusive jurisdiction of the Courts of England and Wales.</p>
<h2 id="schedule1">Schedule 1  </h2>
<h3>Activities Of Processing Personal Data Data Subjects Concerned And Categories Of Client Personal Data</h3>
<table>
    <thead>
    <tr>
        <th>
            No.
        </th>
        <th>
            Item

        </th>
        <th>
            Description
        </th>
    </tr>
</thead>
<tbody>
    <tr>
        <th>
            1
        </th>
        <td>
        <strong>Subject matter of processing</strong>
        </td>
        <td>
            Provision of InSpire Due Diligence Services
        </td>
    </tr>
    <tr>
        <th>
            2
        </th>
        <td>
        <strong>Duration of processing</strong>
        </td>
        <td>
            For the duration of the Services
        </td>
    </tr>
    <tr>
        <th>
            3
        </th>
        <td>
        <strong>Nature of processing, e.g. means of processing</strong>
        </td>
        <td>
            Collection, organising, storing and transmission of personal data requested by Client
        </td>
    </tr>
    <tr>
        <th>
            4
        </th>
        <td>
        <strong>Purpose(s) of processing </strong>
        </td>
        <td>
            To deliver the Services 
        </td>
    </tr>
    <tr>
        <th>
            5
        </th>
        <td>
        <strong>Categories of data subjects</strong>
        </td>
        <td>
            Client Distributor Agreement counter parties
        </td>
    </tr>
    <tr>
        <th>
            6
        </th>
        <td>
        <strong>Types of personal data</strong>
        </td>
        <td>
            <ul>
                <li>Distributor personal contact details for key roles within the business, who support structured product distribution</li>
                <li>Distributor signatories for Distribution Agreement validation and authentication;</li>
            </ul>
        </td>
    </tr>
    <tr>
        <th>
            7
        </th>
        <td>
        <strong>Sensitive data (if relevant)</strong>
        </td>
        <td>
            None
        </td>
    </tr>
    <tr>
        <th>
            8
        </th>
        <td>
        <strong>Contact points for data protection enquiries</strong>
        </td>
        <td>
            Mark Aldous (COO)
        </td>
    </tr>
</tbody>
</table>


<h2>Schedule 2  </h2>
<h3>Data Security Standards</h3>

<h3>Entry Control</h3>
<ul>
    <li>Control of access to the building and to the rooms, where individual servers are located (e.g. by means of doorman, who controls ID; access only with smart cards; installation of video cameras).</li>
<li>Determination of personnel that is authorised to access building/server rooms.</li>
<li> Management and documentation of granted authorisations (detailed description of process of how to obtain access rights and how to revoke such rights; principal of ‘minimum access right’: personnel should only get access to such IT-systems as it is necessary for them to fulfil their tasks; authorised personnel may not transfer authentication media to third parties; other means: doormen have to be informed about any change in authorisation; personnel have to return access authorisation as soon as they do not need it anymore to fulfil their tasks).</li>
<li>Presence of security personnel in the building at all times.</li>
<li>Logging of access to building/rooms (e.g. cleaning personnel, employees).</li>
</ul>
<h3>Access Control I</h3>
<ul>
<li>Determination of authorised personnel.</li>
<li>Management and documentation of authorisation rights (detailed description of process of how to obtain access-rights and how to revoke such rights; principal of ‘minimum access right’; authorised personnel may not transfer authentication media to third parties etc.).</li>
<li>Control of access rights.</li>
<li>Integration of intrusion detection systems and intrusion prevention systems.</li>
<li>Installation of anti-virus and spyware program.</li>
<li>Automatic access lock in case workstation/terminal is not used for a couple of minutes.</li>
<li>Logging of any access to the IT-systems.</li>
</ul>
<h3>Access Control II</h3>
<ul>
<li>Encryption of data.</li>
<li>Introduction of an access concept that is not hierarchy bound but rather function bound (meaning employees get only access if they need access to fulfil their tasks, principal of ‘minimum access’).</li>
<li>Introduction of a user and identification management system (a system that identifies users and their authority to access the system).</li>
<li>Control of bags and belongings of authorised personnel when they leave the rooms, which are under supervision.</li>
<li>Introduction of a data loss prevention system, meaning an email filter that prevents specific data from being sent.</li>
<li>Logging of any access to data.</li>
</ul>
<h3>Transmission Control</h3>
<ul>
<li>Transfer of data only within closed networks.</li>
<li>If data has to be transferred outside of a closed network, data has to be encrypted.</li>
<li>Implementation of secure gateways.</li>
<li>Taking measures that only authorised personnel has the right to access data after its transmission.</li>
</ul>
<h3>Input Control</h3>
<ul>
<li>Documentation and logging of input, modification and removal of data.</li>
</ul>
<h3>Availability Control</h3>
<ul>
<li>Data-backups have to be carried out on a regular basis.</li>
<li>Data-backups have to be stored in a safe and secure place.</li>
<li>Data backups have to be tested regularly with regard to their performance.</li>
<li>The principal has to be immediately informed about any malfunctioning of the system.</li>
<li>Introduction of a recovery plan in case of a malfunction of the system.</li>
<li>Use of a fire detector and electricity back-ups.</li>
<li>Selection of a reliable outsourcing partner (if permitted).</li>
</ul>
<h3>Control of Purpose of Use</h3>
<ul>
<li>Introduction of concepts to manage the authorised access to personal data.</li>
<li>Logical separation of processed data.</li>
<li>Data has to be stored encrypted as appropriate, so that data cannot be read by any unauthorised person even if accidentally accessed.</li>
</ul>

    <h2>Schedule 3 </h2>
<h3>Approved Sub Processors</h3>

<p>The controller has authorized the use of the following sub-processors:</p>

<p>Not applicable</p>
</div>
</div>
`