import { DistributorContactsModule } from './kyd/distributor-contacts/distributor-contacts.module';
import { CSP_NONCE, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './views/dashboard.component';

import { AuthModule } from './auth/auth.module';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { DdqService } from './kyd/services/ddq.service';
import { CommentsService } from './kyd/services/comments.service';
import { QuestionnaireService } from './kyd/services/questionnaire.service';
import { KydRequestService } from './kyd/services/kyd-request.service';
import { DcNavbarComponent } from './ui-kit/dc-navbar/dc-navbar.component';
import { UiKitModule } from './ui-kit/ui-kit.module';
import { IssuerContactsModule } from './kyd/issuer-contacts/issuer-contacts.module';
import { TransferService } from './kyd/services/transfer.service';
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './views/terms-of-use/terms-of-use.component';
import { DataProtectionAddendumComponent } from './views/data-protection-addendum/data-protection-addendum.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    PageNotFoundComponent,
    DcNavbarComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    DataProtectionAddendumComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    AppRoutingModule,
    HttpClientModule,
    // FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbModule,
    UiKitModule,
    ScrollingModule,
    AuthModule,
    LoggerModule.forRoot({
      enableSourceMaps: true,
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
    IssuerContactsModule,
    DistributorContactsModule,
  ],
  providers: [
    DdqService,
    CommentsService,
    QuestionnaireService,
    KydRequestService,
    TransferService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
