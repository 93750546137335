import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { DcAlertService } from 'src/app/ui-kit/dc-alert/dc-alert.service';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
})
export class ResetPasswordFormComponent implements OnInit, OnDestroy {
  sub = new Subscription();

  displayed: boolean = true;
  submitted: boolean = false;

  faEyeSlash = faEyeSlash;
  faEye = faEye;
  pwdRegex =
    /^(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=\D*\d)(?=[^.\\+*?\[\^\]$(){}=!<>|:\-"@#%&\/,';_~`]*[.\\+*?\[\^\]$(){}=!<>|:\-"@#%&\/,';_~`])[A-Za-z0-9.\\+*?\[\^\]$(){}=!<>|:\-"@#%&\/,';_~`]{8,64}$/;

  username = new UntypedFormControl('', [Validators.required]);
  password1 = new UntypedFormControl('', [Validators.required, Validators.pattern(this.pwdRegex)]);
  password2 = new UntypedFormControl('', [Validators.required, Validators.pattern(this.pwdRegex)]);
  verificationCode = new UntypedFormControl('', [Validators.required]);

  showPassword: boolean;
  errorMessage: string;

  constructor(private authService: AuthService, private dcAlertService: DcAlertService) {}

  ngOnInit(): void {
    // console.log('dd', this.username);
    this.sub.add(
      this.authService.onResetPasswordRequired.subscribe((resetPasswordNeeded) => {
        this.displayed = resetPasswordNeeded;
        this.submitted = false;
      })
    );
    this.sub.add(
      this.authService.onAuthError.subscribe((err) => {
        this.errorMessage = err && err.message;
        this.submitted = false;
      })
    );

    this.username.reset();
    this.sub.add(
      this.username.valueChanges.subscribe(() => {
        this.errorMessage = undefined;
      })
    );

    this.password1.reset();
    this.sub.add(
      this.password1.valueChanges.subscribe(() => {
        this.errorMessage = undefined;
      })
    );

    this.password2.reset();
    this.sub.add(
      this.password2.valueChanges.subscribe(() => {
        this.errorMessage = undefined;
      })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  canSaveNewPassword() {
    return (
      !this.submitted &&
      this.username.valid &&
      this.password1.valid &&
      this.password2.valid &&
      this.verificationCode.valid
    );
  }

  saveNewPassword() {
    if (this.password1.value !== this.password2.value) {
      this.errorMessage = "Passwords don't match";
    } else {
      this.authService
        .resetPassword(this.username.value, this.password1.value, this.verificationCode.value)
        .then(() => {
          console.log(this.errorMessage, 'error message');
          if (this.errorMessage) {
            this.dcAlertService.showAlert({
              type: 'danger',
              message: 'Error setting new password!',
            });
          } else {
            this.dcAlertService.showAlert({
              type: 'success',
              message: `Password has been successfully reset, please log in using your new password`,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.dcAlertService.showAlert({
            type: 'danger',
            message: 'Error setting new password!',
          });
        });
    }
    // reset errorMessage
    this.errorMessage = undefined;
  }
}
