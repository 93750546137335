<div class="custom-control custom-checkbox">
  <input
    class="custom-control-input"
    type="checkbox"
    [formControl]="control"
    [id]="id"
    (change)="changed.emit($event)"
  />
  <label class="form-check-label custom-control-label" [for]="id">{{ label }}</label>
</div>
