export enum DDQStatus {
  pending = 'PENDING',
  open = 'OPEN',
  draft = 'DRAFT',
  requestSignoff = 'REQUESTSIGNOFF',
  signed = 'SIGNED',
  issued = 'ISSUED',
  completed = 'COMPLETED',
  chase = 'CHASE',
  onhold = 'ONHOLD',
  inspire = 'INSPIRE',
}
