import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {Observable} from 'rxjs';
import {tap} from "rxjs/operators";
import {DropdownItem} from "../model/dropdown";

@Injectable({
  providedIn: 'root'
})
export class ReferencesService {
  refs: Observable<any>

  lookups: any;

  constructor(
    private apiService: ApiService
  ) {
    console.log('LOADING')
    this.refs = this.apiService.get<any[]>('ddq/lookups').pipe(

    )
  }
  
  getLookups(): Observable<any> {
    return this.apiService.get<any>('ddq/lookups').pipe(
        tap((lookups) => this.lookups = lookups)
    );
  }

  makeDropdownItems(data, idField, displayValueField): DropdownItem[] {
    return data.map(dataItem => {
      return {
        id: dataItem[idField],
        displayValue: (Array.isArray(displayValueField)) ? (`${dataItem[displayValueField[0]]} (${dataItem[displayValueField[1]]})`) : dataItem[displayValueField]
      }
    });
  }
}
