<div class="container centre-screen" *ngIf="displayed">
  <div class="login-panel">
    <div class="login-logo">
      <img class="inspire-logo" src="assets/inspire-white-magenta.svg" />
    </div>

  <form id="confirmSignInForm" class="text-center">
    <div class="login-title">MFA Verification</div>
    <div class="alert alert-info">
      Please enter the MFA verification code that has been sent to your email address
    </div>
    <div class="form-group">
      <label for="totpVerificationCode" class="d-none">Verification Code</label>
      <input class="form-control form-control-lg" id="totpVerificationCode" type="text"
             [formControl]="verificationCode" required
             name="totpVerificationCode" placeholder="Verification code">
    </div>
    <div [hidden]="!errorMessage" class="alert alert-danger">
      {{errorMessage}}
    </div>
    <button [disabled]="!canConfirmSignIn()"
            class="btn btn-primary btn-lg"
            (click)="confirmSignIn()">
      <ng-container *ngIf="submitted">
        <span class="sign-in-progress spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
        Verifying Code
      </ng-container>
      <ng-container *ngIf="!submitted">
        Verify Code
      </ng-container>
    </button>
  </form>
  <div class="need-help">
   
  </div>
</div>
</div>
