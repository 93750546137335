import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
export function disableQuestionValidator(question): ValidatorFn {
  return (root: AbstractControl): ValidationErrors | null => {
    const parent = root.parent;
    if (parent) {
      if (root.value == true) {
        parent.controls[question.id].disable();
        parent.controls[question.id].reset();
      } else if (root.value == false && root.value !== null && root.value !== undefined && root.value !== '') {
        parent.controls[question.id].enable();
        question.fields.forEach((field) => {
          if (field?.disabled) {
            parent.controls[question.id].controls[field.id].disable();
          }
        });
      }

      if (true) {
        return null;
      }
    }
  };
}
