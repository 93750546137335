import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';
import { UsersService } from '../../services/users.service';
import { PermissionService } from '../../services/permission.service';
import { ApiService } from '../../services/api.service';
import { combineLatest } from 'rxjs';
import { Contact } from '../model/contact';
import { IssuersService } from './issuers.service';
import { Issuer } from '../model/issuer';
import { DistributorsService } from './distributors.service';
import { RequestTable } from '../model/request';
import { DcAlertService } from '../../ui-kit/dc-alert/dc-alert.service';

@Injectable({
  providedIn: 'root',
})
export class KydUserService {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private usersService: UsersService,
    private distributorsService: DistributorsService,
    private issuersService: IssuersService,
    private permissionsService: PermissionService,
    private dcAlertService: DcAlertService
  ) {}

  async sendInviteEmails(kydRequestRow: RequestTable) {
    const distributorKey = kydRequestRow.distributorKey;
    const distributorName = kydRequestRow.distributorName;

    await this.distributorsService
      .getDistributorContactsByDistributor(distributorKey)
      .toPromise()
      .then(async (contacts) => {
        // await kydRequestRow.contact.map(async (contact) => {
        await Promise.all(
          contacts.map(async (contact) => {
            await this.sendInviteEmail(contact, distributorKey, distributorName);
          })
        );
        return contacts;
      })
      .then(async (contacts) => {
        await Promise.all(
          contacts.map(async (contact) => {
            const result = await this.distributorsService.setContactToInvited(contact.id).toPromise();
            console.log(result, 'contact invite sent');
          })
        );
      })
      .then(() => {
        this.dcAlertService.showAlert({
          type: 'success',
          message: 'Invite and/or new account setup emails sent successfully.',
        });
      })
      .catch((err) => {
        console.log(err);
        this.dcAlertService.showAlert({
          type: 'danger',
          message: 'Error sending email and/or error creating user account',
        });
      });
  }

  async sendInviteEmail(contact, distributorKey, distributorName) {
    return new Promise(async (resolve) => {
      try {
        const distributorContact = contact.email;

        const contactFirstName = contact.name.split(' ')[0];

        const account = await this.getAccount(distributorContact);

        const permissions = {
          clients: [],
          distributors: [distributorKey],
        };

        const groups = [];

        const emailTemplateMetadata = {
          'contact.firstname': contactFirstName,
          DISTRIBUTOR_NAME: distributorName,
        };

        if (contact?.invitesentat) {
          await this.sendExistingUserEmail(contact, distributorName, 'ExistingDistributor');
          this.usersService.updateUser(account, distributorContact, distributorContact, groups).subscribe(
            () => {
              console.log('User Updated: ' + distributorContact);

              this.permissionsService.updatePermissionsFor(account, distributorContact, permissions).subscribe(
                () => {
                  resolve(true);
                },
                (e) => {
                  console.log(e, 'Error sending email');
                  resolve(false);
                }
              );
            },
            (e) => {
              console.log(e, 'Error sending email');
              resolve(false);
            }
          );
        } else {
          await this.usersService
            .addUserWithTemplate(
              account,
              distributorContact,
              distributorContact,
              groups,
              emailTemplateMetadata,
              'NewDistributor'
            )
            .then(async () => {
              console.log('User Created: ' + distributorContact);
              await this.distributorsService.setContactToInvited(contact.id).toPromise();
              this.permissionsService.updatePermissionsFor(account, distributorContact, permissions).subscribe(
                () => {
                  resolve(true);
                },
                (e) => {
                  console.log(e, 'Error creating user account');
                  resolve(false);
                }
              );
            })
            .catch((e) => {
              console.log(e, 'Error creating user account');
              resolve(false);
            });
        }
      } catch (e) {
        console.log('Error: ', e, 'Error creating user account');
        resolve(false);
      }
    });
  }

  async sendIssuerInviteEmail(contact, issuerKey) {
    return new Promise(async (resolve) => {
      try {
        const issuerContact = contact.email;

        const contactFirstName = contact.name.split(' ')[0];

        const account = await this.getAccount(issuerContact);

        const permissions = {
          clients: [issuerKey],
          distributors: [],
        };

        const groups = [];

        const emailTemplateMetadata = {
          'contact.firstname': contactFirstName,
        };

        if (contact?.invitesentat) {
          await this.sendExistingUserEmail(contact, '', 'ExistingIssuer');
          this.usersService.updateUser(account, issuerContact, issuerContact, groups).subscribe(
            () => {
              console.log('User Updated: ' + issuerContact);
              this.permissionsService.updatePermissionsFor(account, issuerContact, permissions).subscribe(
                () => {
                  resolve(true);
                },
                (e) => {
                  console.log(e);
                  resolve(false);
                }
              );
            },
            (e) => {
              console.log(e);
              resolve(false);
            }
          );
        } else {
          await this.usersService
            .addUserWithTemplate(account, issuerContact, issuerContact, groups, emailTemplateMetadata, 'NewIssuer')
            .then(async () => {
              console.log('User Created: ' + issuerContact);
              await this.issuersService.setIssuerSalesContactToInvited(contact.id).toPromise();
              this.permissionsService.updatePermissionsFor(account, issuerContact, permissions).subscribe(
                () => {
                  resolve(true);
                },
                (e) => {
                  console.log(e);
                  resolve(false);
                }
              );
            })
            .catch((e) => {
              console.log(e);
              resolve(false);
            });
        }
      } catch (e) {
        console.log('Error: ', e);
        resolve(false);
      }
    });
  }

  async sendExistingUserEmail(contact, distributorName, templateID) {
    return new Promise(async (resolve) => {
      const distributorContact = contact.email;
      const contactFirstName = contact.name.split(' ')[0];

      const clientMetadata = {
        'contact.firstname': contactFirstName,
        DISTRIBUTOR_NAME: distributorName,
      };

      await this.apiService
        .post<any, any>('ddq/ses/external', {
          recipient: distributorContact,
          clientMetadata,
          templateID,
        })
        .toPromise()
        .then((res) => {
          console.log(res);
          resolve(true);
        })
        .catch((e) => {
          console.log('Error: ', e);
          resolve(false);
        });
    });
  }

  async externalEmailHandlerWithIssuer(request, templateID, issuerID) {
    const distributorKey = request.details.distributor.name;
    return new Promise(async (resolve) => {
      await this.distributorsService
        .getDistributorContactsByDistributor(distributorKey)
        .toPromise()
        .then(async (contacts) => {
          await this.issuersService
            .getIssuer(issuerID)
            .toPromise()
            .then(async (issuer: Issuer) => {
              const sentEmailPromises = contacts.map((contact) => {
                return new Promise(async (innerResolve) => {
                  try {
                    if (!contact.invitesentat) {
                      innerResolve(false);
                    } else {
                      const recipient = contact.email;
                      const contactFirstName = contact.name.split(' ')[0];
                      const issuerName = issuer.name;

                      const clientMetadata = {
                        'contact.firstname': contactFirstName,
                        ISSUER: issuerName,
                      };

                      await this.apiService
                        .post<any, any>('ddq/ses/external', {
                          recipient,
                          clientMetadata,
                          templateID,
                        })
                        .toPromise()
                        .then((res) => {
                          console.log(res);
                          innerResolve(true);
                        })
                        .catch((e) => {
                          console.log(e);
                          innerResolve(false);
                        });
                    }
                  } catch (e) {
                    console.log(e);
                    innerResolve(false);
                  }
                });
              });
              await Promise.all(sentEmailPromises);
              resolve(true);
            })
            .catch((e) => {
              console.log(e);
              resolve(false);
            });
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  }

  async sendNewConnectionRequestEmail(request, issuerID) {
    await this.externalEmailHandlerWithIssuer(request, 'NewConnectionRequest', issuerID);
  }

  async externalEmailHandler(request, templateID) {
    return new Promise((resolve) => {
      combineLatest(
        request.details.distributor.contact.map((contact) => this.distributorsService.getDistributorContact(contact))
      ).subscribe(
        async (contacts: [Contact]) => {
          const sentEmailPromises = contacts.map((contact) => {
            return new Promise((innerResolve) => {
              try {
                if (!contact.invitesentat) {
                  innerResolve(false);
                } else {
                  const recipient = contact.email;
                  const contactFirstName = contact.name.split(' ')[0];

                  const clientMetadata = {
                    'contact.firstname': contactFirstName,
                  };

                  this.apiService
                    .post<any, any>('ddq/ses/external', {
                      recipient,
                      clientMetadata,
                      templateID,
                    })
                    .subscribe(
                      (res) => {
                        console.log(res);
                        innerResolve(true);
                      },
                      (e) => {
                        console.log(e);
                        innerResolve(false);
                      }
                    );
                }
              } catch (e) {
                console.log(e);
                innerResolve(false);
              }
            });
          });

          await Promise.all(sentEmailPromises);
          resolve(true);
        },
        (e) => {
          console.log(e);
          resolve(false);
        }
      );
    });
  }

  async sendNotifyEmail(request) {
    await this.externalEmailHandler(request, 'NewComments');
  }

  async sendDDQReturnedEmail(request) {
    await this.externalEmailHandler(request, 'ReturnedToDistributor');
  }

  async sendRequiredSignoffEmail(request) {
    await this.externalEmailHandler(request, 'RequiredSignOff');
  }

  async sendReopenEmail(request) {
    await this.externalEmailHandler(request, 'ReopenedDDQ');
  }

  async sendPublishedEmail(request) {
    await this.externalEmailHandler(request, 'PublishedDDQ');
  }

  async getAccount(distributorContact) {
    let relevantAccounts;

    await this.authService.administeredAccounts().subscribe((accounts) => (relevantAccounts = accounts));

    return this.getAccountFromEmail(relevantAccounts, distributorContact);
  }

  // TODO update for new environment
  getAccountFromEmail(accounts, distributorContact) {
    if (distributorContact.endsWith('deltacapita.com')) {
      if (accounts.length === 1) {
        return accounts[0];
      } else if (accounts.includes('Production')) {
        return 'Production';
      } else if (accounts.includes('Demo')) {
        return 'Demo';
      } else if (accounts.includes('Test')) {
        return 'Test';
      } else {
        throw new Error('Unexpected email / account');
      }
    } else {
      if (accounts.length === 1) {
        return accounts[0];
      } else if (accounts.includes('Client')) {
        return 'Client';
      } else if (accounts.includes('DemoClient')) {
        return 'DemoClient';
      } else if (accounts.includes('TestClient')) {
        return 'TestClient';
      } else {
        throw new Error('Unexpected email / account');
      }
    }
  }
}
