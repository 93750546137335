import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

// in the config, when setting the groupArr validator for checkbox groups...
// if there is a single group of checkboxes, use a flat array,
// if there is two or more checkbox groups in the same question, the first group should be flat, the second and third etc should be nested
// eg. groupArr: ['80d9d37b-bb4b-4a50-b964-fd456dcf6b19',  '60c28e3b-1d76-42cd-8dc5-ea19d5360f94', ['8e3afd98-7c02-484b-a30f-933e5abd7815', 'b98b2f0e-4f36-49f2-93cf-7099f7c17c89']]

export function enableDisableSelectValidator(conditional: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // const formGroup = control as FormGroup;
    const parent = control.parent;

    // console.log('control', control);
    // console.log('parent', parent);
    // console.log('conditional', conditional);
    if (parent) {
      if (control.value && control.value.includes('OTHER')) {
        if (conditional.enable) {
          conditional?.enable.forEach((conditionalId) => {
            // console.log('conditional', conditionalId);
            //     console.log('match', option.conditional);
            //     if (option.conditional.enable) {
            //       // enable here
            Object.keys(parent?.controls).forEach((key) => {
              const control = parent.controls[key];
              if (conditionalId.includes(key)) {
                //  console.log(key);
                control.enable();
                // control.updateValueAndValidity();
              }
            });
          });
        }
      } else {
        conditional?.enable.forEach((conditionalId) => {
          //  console.log('conditional', conditionalId);

          Object.keys(parent?.controls).forEach((key) => {
            const control = parent.controls[key];
            if (conditionalId.includes(key)) {
              //  console.log(key);
              control.disable();
              control.reset();
              //  control.updateValueAndValidity();
            }
          });
        });
      }
      // console.log('disableIds', disableIds);
      if (true) {
        return null;
      }
    }
  };
}
