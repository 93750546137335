import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private data: any = {};
  constructor() {}

  addData(data: { [name: string]: any }) {
    this.data = { ...this.data, ...data };
  }

  getData() {
    let temp = this.data;
    return temp;
  }

  clearData() {
    this.data = undefined;
  }

  removeEntry(entry: string) {
    if (this.data.hasOwnProperty(entry)) {
      delete this.data[entry];
    }
  }
}
