import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LegalEntityService {
  constructor(private apiService: ApiService) {}

  get(id: string): Observable<any> {
    return this.apiService.get<any>(`ddq/lei/${id}`);
  }

  getLeiApiLookup(leiId): Observable<any[]> {
    return this.apiService.get<any>(`ddq/lei/${leiId}`).pipe(map((records) => records.data.attributes.entity));
  }

  search(query: string): Observable<SearchResult[]> {
    return this.apiService.get<any>(`ddq/lei/search/auto-completion/${query}`);
  }

  searchFullText(query: string): Observable<SearchResult[]> {
    return this.apiService.get<any>(`ddq/lei/search/full-text/${query}`);
  }

  searchByLegalName(query: string): Observable<SearchResult[]> {
    return this.apiService.get<any>(`ddq/lei/search/legal-name/${query}`);
  }

  searchByLei(query: string): Observable<SearchResult[]> {
    return this.apiService.get<any>(`ddq/lei/search/lei/${query}`);
  }

  searchAll(query: string): Observable<SearchResult[]> {
    return this.apiService.get<any>(`ddq/lei/search-all/${query}`);
  }
}

export interface SearchResult {
  value: string;
  lei: string;
}
