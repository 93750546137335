<div class="outerBox">
  <div *ngIf="!noHeader" class="d-flex boxHeader justify-content-between align-items-center">
    <h2 *ngIf="!filters"><ng-content select=".heading"></ng-content></h2>
    <div *ngIf="filters" class="filters">
      <ng-content select=".filters"></ng-content>
    </div>
    <div class="headingAction" [class.headerSearch]="headerSearch">
      <ng-content select=".headingAction"></ng-content>
    </div>
  </div>
  <div
    class="innerBox"
    [class.no-padding]="noPadding"
    [class.no-header]="noHeader"
    [class.no-scrollbar]="noScrollBar"
    [class.no-scroll]="noScroll"
    [class.tableContainer]="tableContainer"
  >
    <app-dc-loading *ngIf="loading"></app-dc-loading>
    <ng-content></ng-content>
  </div>
</div>
