import { ElementRef, Injectable, ViewChild } from '@angular/core';
import jp from 'jsonpath';
import { ConfigField, ConfigQuestion, ConfigSection, ConfigSubsection } from '../model/ddq-config';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { QuestionnaireService } from './questionnaire.service';
import { requireCheckboxesToBeCheckedValidator } from '../ddq/active-section/require-checkbox-validator';
import { requirePercentControlValidator } from '../ddq/active-section/percent-control-validator';
import { requirePercentFormArrayValidator } from '../ddq/active-section/percent-validator-formarray';
import { enableDisableValidator } from '../ddq/active-section/enable-disable-validator';
import { enableDisableSelectValidator } from '../ddq/active-section/enable-disable-select-validator';
import { enableDisableCheckboxValidator } from '../ddq/active-section/enable-disable-checkbox-validator';
import { disableQuestionValidator } from '../ddq/active-section/disable-question-validator';

import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DdqService {
  formData;
  formAnswers;
  formIsReadOnly = true;

  formArrays = [];
  @ViewChild('onNotifyModal') onNotifyModal!: ElementRef;
  private snapshotData = new BehaviorSubject(null);
  currentSnapshotData = this.snapshotData.asObservable();
  constructor(
    private questionnaireService: QuestionnaireService,
  ) {}

  updateSnapshotData(data: any) {
    this.snapshotData.next(data)
    }


  allData() {
    let x = this.checkAnswersforFormArray(this.formAnswers);
    this.formArrays = x.filter((v, i, a) => a.findIndex((v2) => v2.key === v.key) === i);
   // console.log(this.formArrays);
    return this.formData;
  }

  sectionOne() {
    return this.formData[0] || null;
  }

  sectionOnePageOne() {
    return this.formData[0].pages[0] || null;
  }

  getQuestion(id) {
    const questions = [].concat(...jp.query(this.formData, '$..questions.*'));
    return questions.filter((question) => question.id == id);
  }

  getFields(id) {
    const fields = [].concat(...jp.query(this.formData, '$..fields.*'));
    return fields.filter((field) => {
      return field.id == id;
    });
  }

  getOptions(id) {
    const options = [].concat(...jp.query(this.formData, '$..options.*'));
    return options.filter((option) => {
      return option.id == id;
    });
  }

  addSection(section: ConfigSection): UntypedFormGroup {
    const sectionGroup = new UntypedFormGroup({});
    section.subsections.forEach((subsection) => {
      sectionGroup.addControl(subsection.id, this.addSubsection(subsection));
    });
    const sectionControlsArr = Object.values(sectionGroup.controls);
    const sectionStatusArr = sectionControlsArr.map((section) => section.touched);
    const dirtySectionStatus = sectionStatusArr.some((isTrue) => isTrue === true);
    if (dirtySectionStatus) {
      sectionGroup.markAsDirty();
      sectionGroup.markAsTouched();
    }

    return sectionGroup;
  }

  addSubsection(subsection: ConfigSubsection): UntypedFormGroup {
    const subsectionGroup = new UntypedFormGroup({});
    // let control: FormControl;
    // control = new FormControl('', {
    //   validators:[disableQuestionValidator()],
    //   updateOn: 'change',
    // });
    subsection.questions.forEach((question) => {
      subsectionGroup.addControl('DQ-' + question.id, this.returnControl(question));
      subsectionGroup.addControl('AN-' + question.id, this.returnControlWithoutValidatorOnBlur());
      subsectionGroup.addControl('ANC-' + question.id, this.returnControlWithoutValidator());
      subsectionGroup.addControl(question.id, this.addQuestion(question));
    });
    const quControlsArr = Object.values(subsectionGroup.controls);
    const quStatusArr = quControlsArr.map((qu) => qu.touched);
    const dirtyStatus = quStatusArr.some((isTrue) => isTrue === true);
    if (dirtyStatus) {
      subsectionGroup.markAsDirty();
      subsectionGroup.markAsTouched();
    }
    return subsectionGroup;
  }

returnControl(question){
  let control: UntypedFormControl;
    control = new UntypedFormControl('', {
      validators:[disableQuestionValidator(question)],
      updateOn: 'change',
    });
  return  control;
}

returnControlWithoutValidator() {
  let control: UntypedFormControl;
  control = new UntypedFormControl('', {
    validators:[],
    updateOn: 'change',
  });
return  control;
}

returnControlWithoutValidatorOnBlur() {
  let control: UntypedFormControl;
  control = new UntypedFormControl('', {
    validators:[],
    updateOn: 'blur',
  });
return  control;
}


// addQuestionWrapper(question: ConfigQuestion): FormGroup {
//   const questionWrapper = new FormGroup({});
//  // Add disable control
//  let control: FormControl;
//  control = new FormControl('', {
//    validators:[],
//    updateOn: 'change',
//  });
//  questionWrapper.addControl('XXX-' + question.id, control);


// return questionWrapper;
// }


  addQuestion(question: ConfigQuestion): UntypedFormGroup {
    const questionGroup = new UntypedFormGroup({}, this.addValidation(question));
       
    if (question.dynamic == true) {
      let control: UntypedFormControl;
      control = new UntypedFormControl('', {
        validators: [enableDisableCheckboxValidator(question.dynamicDisableObject?.conditional)],
        updateOn: 'change',
      });
      questionGroup.addControl(question.dynamicDisableObject.id, control);

      const test = this.formArrays.find((obj) => obj.key === question.dynamicDisableObject.arrayId);
     // console.log('T', test);
      if (test) {
        questionGroup.addControl(question.dynamicDisableObject.arrayId, this.addDynamicQuestion(question, test));
      }
    } else {
      question.fields.forEach((field: ConfigField) => {
        //const fieldValue = field.value ? field.value : '';
        const fieldValue = '';
        if (Array.isArray(field)) {
          field.forEach((subField) => {
            questionGroup.addControl(subField.id, this.addField(subField, question));
          });
        } else questionGroup.addControl(field.id, this.addField(field, question));

        if (fieldValue !== '') {
          questionGroup.markAsDirty();
          questionGroup.markAsTouched();
        }
      });
    }
    
    return questionGroup;
  }

  addValidation(question) {
    if (question.validate != undefined && question.validate.groupArr != undefined) {
      return requireCheckboxesToBeCheckedValidator(question?.validate?.groupArr);
    } else {
      return;
    }
  }

  addDynamicQuestion(question: ConfigQuestion, test): UntypedFormArray {
    const fieldArray = new UntypedFormArray([], requirePercentFormArrayValidator(question?.validate?.percent));
    const fieldGroups = question.fields;

    let markFormArrayAsDirty = false;
   // console.log(test);
    for (let i = 0; i < test['length']; i++) {
      fieldGroups.forEach((fields: ConfigField[]) => {
        let markGroupAsDirty = false;
        let fieldGroup = new UntypedFormGroup({}, this.addValidation(question));
        fields.forEach((field: ConfigField) => {
          if (field.value || question.disabled) {
            markFormArrayAsDirty = true;
            markGroupAsDirty = true;
          }
          field.disabled = question.disabled;
          fieldGroup.addControl(field.id, this.addField(field, question));
        });

        if (markGroupAsDirty) {
          fieldGroup.markAsDirty();
          fieldGroup.markAsTouched();
          fieldGroup.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }

        fieldArray.push(fieldGroup);
      });
    }

    if (markFormArrayAsDirty) {
      fieldArray.markAsDirty();
      fieldArray.markAsTouched();
      fieldArray.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }

    return fieldArray;
  }

  checkAnswersforFormArray(answers) {
    let array = [];
    const iterate = (obj) => {
      Object.keys(obj).forEach((key) => {
        if (Array.isArray(obj[key])) {
          let answersArray = [];
          obj[key].forEach((element) => {
            if (typeof element === 'object') {
              answersArray.push(element);
              let item = {
                key: key,
                length: obj[key].length,
                answers: answersArray,
              };
              array.push(item);
            }
          });
        }
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          iterate(obj[key]);
        }
      });
    };
    iterate(answers);
    return array;
  }

  addDynamicFields(question) {
    let markGroupAsDirty = false;
    let fields = question.fields;
    let fieldGroup = new UntypedFormGroup({}, this.addValidation(question));
    fields.forEach((field: ConfigField) => {
      if (field.value || question.disabled) {
        //  markFormArrayAsDirty = true;
        markGroupAsDirty = true;
      }
      field.disabled = question.disabled;
      fieldGroup.addControl(field.id, this.addField(field, question));
    });

    if (markGroupAsDirty) {
      fieldGroup.markAsDirty();
      fieldGroup.markAsTouched();
      fieldGroup.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }

    return fieldGroup;
  }

  addField(field: ConfigField, question: ConfigQuestion): UntypedFormControl {
    //const fieldValue = field.value ? field.value : '';
    const fieldValue = '';
    const disabled = field.disabled ? field.disabled : '';
    let control: UntypedFormControl;

    if (field.validate) {
      if (field.validate.required && (field.type === 'radio-inline' || field.type === 'radio-list')) {
        control = new UntypedFormControl(fieldValue, {
          validators: [Validators.required, enableDisableValidator(field, this.getFields(field?.id))],
          updateOn: 'change',
        });
      } else if (field.validate.required === true && field.type === 'dropdown') {
        control = new UntypedFormControl(fieldValue, {
          validators: [Validators.required, enableDisableSelectValidator(field?.conditional)],
          updateOn: 'change',
        });
      } else if (field.validate.required === true) {
        control = new UntypedFormControl(fieldValue, {
          validators: Validators.required,
          updateOn: 'blur',
        });
      } else if (field.validate.requiredCheckbox === true) {
        control = new UntypedFormControl(fieldValue, {
          //validators: Validators.requiredTrue,
          updateOn: 'change',
        });
      } else if (field.validate.email === true) {
        control = new UntypedFormControl(fieldValue, {
          validators: Validators.email,
          updateOn: 'blur',
        });
      } else if (field.validate.requiredEmail === true) {
        control = new UntypedFormControl(fieldValue, {
          validators: [Validators.required, Validators.email],
          updateOn: 'blur',
        });
      } else if (field.validate.requiredPhone === true) {
        control = new UntypedFormControl(fieldValue, {
          validators: [Validators.required, Validators.pattern('^(0|\\+)\\d{8,15}$')],
          updateOn: 'blur',
        });
      } else if (field.validate.percent !== undefined) {
        control = new UntypedFormControl(fieldValue, {
          validators: [Validators.required, requirePercentControlValidator(field?.validate?.percent)],
          updateOn: 'blur',
        });
      }
    } else {
      if (field.type == 'radio-inline' || field.type == 'radio-list') {
        control = new UntypedFormControl(fieldValue, {validators: [enableDisableValidator(field,  this.getFields(field?.id))], updateOn: 'change' });
      } else if (field.type == 'checkbox') {
        control = new UntypedFormControl(fieldValue, {
          validators: [enableDisableCheckboxValidator(field?.conditional)],
          updateOn: 'change',
        });
      } else {
        control = new UntypedFormControl(fieldValue, { updateOn: 'blur' });
      }
    }

    if (fieldValue) {
      control.markAsDirty();
      control.markAsTouched();
    }

    if (disabled) {
      control.disable();
    }
    // removed to ddd component to save whole answers object
    // control.valueChanges.subscribe((val) => {
    //   if (!this.questionnaireService.disableSaving) {
    //     this.questionnaireService.saveDDQ(val, field, question, this.formData, control);
    //   }
    // });

    return control;
  }
}
