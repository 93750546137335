import { Component, OnInit } from '@angular/core';
import { PRIVACYPOLICY } from './privacy-policy-text';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  host: {'class': 'printDoc'}
})
export class PrivacyPolicyComponent implements OnInit {
  text = PRIVACYPOLICY;
  constructor() {}

  ngOnInit(): void {}
}
