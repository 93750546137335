import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function requirePercentControlValidator(validateIds: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const parentGroup = control.parent;
    let percentGroup = [];
    let sumtotal = 0;

    if (parentGroup) {
      Object.keys(parentGroup.controls).forEach((key) => {
        const control = parentGroup.controls[key];

        if (validateIds.includes(key)) {
          let controlValue = control.value;

          if (controlValue == null || control.value === '') {
            percentGroup.push(0);
          } else {
            if (Number(controlValue) > 0) {
              percentGroup.push(Number(controlValue) * 100);
            }
          }

          let sum = percentGroup.reduce((a, b) => a + b, 0);
          sumtotal = sum;
        }
      });

      if (sumtotal !== 100) {
        validateIds.forEach((key) => {
          const control = parentGroup.controls[key];
          if (control.value != null && control.value != '') {
            control.setErrors({ requirePercentControlValidator: true });
          }
        });
        return {
          requirePercentControlValidator: true,
        };
      } else if (sumtotal == 100) {
        validateIds.forEach((key) => {
          const control = parentGroup.controls[key];
          if (control.value != null && control.value != '') {
            control.setErrors(null);
          }
        });
        return null;
      }
    }
  };
}
