import {Component, OnDestroy, OnInit} from '@angular/core'
import {AuthService} from '../services/auth.service'
import {Subscription} from 'rxjs'

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss'],
})
export class LoginScreenComponent implements OnInit, OnDestroy {
  private sub = new Subscription()

  themeSlant: string = 'triangle';
  themePosition: string = 'right';

  loginForm: boolean = false
  newPasswordForm: boolean = false
  setupMfaForm: string = undefined
  confirmSignInForm: boolean = false
  resetPasswordForm: boolean = false;
  
  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.sub.add(
      this.authService.onLoginRequired.subscribe(loginRequired => {
        this.loginForm = loginRequired
      }),
    )
    this.sub.add(
      this.authService.onNewPasswordRequired.subscribe(passwordChangeRequired => {
        this.newPasswordForm = passwordChangeRequired
      }),
    )
    this.sub.add(
      this.authService.onSetupMfaRequired.subscribe(code => {
        this.setupMfaForm = code
      }),
    )
    this.sub.add(
      this.authService.onResetPasswordRequired.subscribe((resetPassword) => {
        this.resetPasswordForm = resetPassword;
      })
    );
    this.sub.add(
      this.authService.onConfirmSignInRequired.subscribe(confirmSignInRequired => {
        this.confirmSignInForm = confirmSignInRequired
      }),
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }
}
