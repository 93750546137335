import {Injectable} from '@angular/core'
import {Observable, Subject, timer} from 'rxjs'
import {ApiService} from './api.service'
import {shareReplay, switchMap, takeUntil} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(
    private apiService: ApiService,
  ) {
  }

  private cache$: Map<string, Observable<Array<any>>> = new Map();
  private reload$: Map<string, Subject<void>> = new Map();

  keys<T>(type: string): Observable<T[]> {
    return this.apiService.get(`data/${type}/list`)
  }

  ping<T>(): Observable<T> {
    return this.apiService.get(`data/ping`)
  }

  all<T>(type: string): Observable<T[]> {
    if (!this.cache$.has(type)) {
      const timer$ = timer(0, 10000)

      this.reload$.set(type, new Subject<void>());

      const value: Observable<Array<T>> = timer$.pipe(
        switchMap(_ => this.apiService.get<T[]>(`data/${type}/all`)),
        takeUntil(this.reload$.get(type)),
        shareReplay(100)
      );

      this.cache$.set(type, value);
    }
    return this.cache$.get(type);
  }

  forceReload(type: string) {
    this.reload$.get(type).next();
    this.cache$.delete(type);
  }

  get<T>(type: string, id: string): Observable<T> {
    return this.apiService.get(`data/${type}/${id}`)
  }

  create<T, R>(type: string, payload: T): Observable<R> {
    return this.apiService.post(`data/${type}/new`, payload)
  }

  replace<T, R>(type: string, id: string, payload: T): Observable<R> {
    return this.apiService.put(`data/${type}/${id}`, payload)
  }

  update<T, R>(type: string, id: string, payload: T): Observable<R> {
    return this.apiService.patch(`data/${type}/${id}`, payload)
  }

  delete<T>(type: string, id: string): Observable<T> {
    return this.apiService.delete(`data/${type}/${id}`)
  }
}
