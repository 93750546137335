<header class="d-flex justify-content-between align-items-center">
  <ng-template [ngIf]="!back">
    <h1 class="mr-auto text-truncate"><ng-content select=".heading"></ng-content></h1>
  </ng-template>
  <ng-template [ngIf]="back">
    <h1 class="mr-auto text-truncate">
      <a (click)="navLink(backLink)" class="backLink">{{ title }}</a>
    </h1>
  </ng-template>
  <div class="d-flex justify-content-between align-items-center"><ng-content select=".actions"></ng-content></div>
</header>
<ng-content></ng-content>
