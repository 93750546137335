import { ListIssuerContactsComponent } from './list-issuer-contacts/list-issuer-contacts.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiKitModule } from '../../ui-kit/ui-kit.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateIssuerContactComponent } from './create-issuer-contact/create-issuer-contact.component';
import { GlobalComponentsModule } from '../global-components/global-components.module';
import { EditIssuerContactComponent } from './edit-issuer-contact/edit-issuer-contact.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ListIssuerContactsComponent, CreateIssuerContactComponent, EditIssuerContactComponent],
  exports: [CreateIssuerContactComponent],
  imports: [
    CommonModule,
    UiKitModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbDropdownModule,
    NgbPopoverModule,
    GlobalComponentsModule,
    RouterModule
  ],
})
export class IssuerContactsModule {}
