import { Component, OnInit } from '@angular/core';
import { TERMSOFUSE } from './terms-of-use-text';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
  host: {'class': 'printDoc'}
})
export class TermsOfUseComponent implements OnInit {
  text = TERMSOFUSE;
  constructor() { }

  ngOnInit(): void {
  }

}
